export const paths = {
  home: "/",
  login: "/login",
  dashboard: "/dashboard",
  saleDashboard: "/sale_dashboard",
  orderDashboard: "/order_dashboard",
  productDashboard: "/product_dashboard",
  report: "/report",
  productStocks: "/product_stocks",
  productPrices: "/product_prices",
  productSort: "/product_sorting",
  productImport: "/product_import",
  products: "/products",
  productDetail: "/product/:id",
  getProductDetailRoute: (id: string) => `/product/${id}`,
  orders: "/orders",
  orderDetail: "/order/:id",
  getOrderDetailRoute: (id: string) => `/order/${id}`,
  saleReport: "/sale_report",
  orderReport: "/order_report",
  customerDetail: "/customer/:id",
  getCustomerDetailRoute: (id: string) => `/customer/${id}`,
  customers: "/customers",
  coupons: "/coupons",
  create_coupon: "/create_coupon",
  pointDashboard: "/point_dashboard",
  rewardDashboard: "/reward_dashboard",
  memberDashboard: "/member_dashboard",
  pointHistory: "/point_history",
  rewardHistory: "/reward_history",
  pointExpire: "/point_expire",
  members: "/all_members",
  topMembers: "/top_members",
  activityMember: "/activity_members",
  tierMember: "/tier_members",
  memberDetail: "/member/:id",
  getMemberDetailRoute: (id: string) => `/member/${id}`,
  facebookUsers: "/facebook_users",
  expense: "/expense",
  income: "/income",
  pitiIncome: "/piti-income",
  summary: "/summary",
  ninjavanPrice: "/ninjavan_prices",
  arlupostPrice: "/arlupost_prices",
  shippingFees: "/shipping_fees",
  shipping_fee: "/shipping_fee/:id",
  getShippingFeeDetailRoute: (id: string) => `/shipping_fee/${id}`,
};
