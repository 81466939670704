import React, { useEffect, useState } from "react";
import withUser from "../../hocs/with_user";
import { RewardHistory } from "../state";
import { format, subDays, addMonths } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Tag, Card } from "antd";
import { Link } from "react-router-dom";
import { paths } from "../../routes/paths";
interface P {
  data: RewardHistory[];
  showHearder?: boolean;
  title?: string;
}
const RecentHistory = (props: P) => {
  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: (v: number, record: RewardHistory) => (
        <Avatar src={record.rewardPhoto} size={45} />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (v: number, record: RewardHistory) => {
        const val = record.rewardName || "";
        return val;
      },
    },
    {
      title: "Claimed Date",
      dataIndex: "claimedDate",
      render: (v: number, record: RewardHistory) => {
        const val = record.claimedAt
          ? format(new Date(Number(record.createdAt)), "yyyy-MMM-dd")
          : "no";
        return val;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (v: number, record: RewardHistory) => {
        return record.status ? (
          <Tag color="green"> claimed</Tag>
        ) : (
          <Tag color="volcano">redeem</Tag>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Card
        style={styles.card}
        title={props.title || "Recent"}
        extra={<Link to={paths.rewardHistory}>Show more...</Link>}>
        <Table
          showHeader={props.showHearder || false}
          columns={columns}
          dataSource={props.data.map((row, i) => {
            return { ...row, key: i + 1 };
          })}
          pagination={{
            pageSize: 5,
            position: ["bottomRight"],
          }}
        />
      </Card>
    </React.Fragment>
  );
};
const styles = {
  card: {
    height: "100%",
  },
};
export default withUser(RecentHistory);
