import React, { useState } from 'react'
import { Table, Card, Tag } from "antd";
import { useQuery } from '@apollo/client';
import { get_order_variables, get_orders } from "../../gql/gql_query";
import withUser from '../../hocs/with_user';
import { subDays } from "date-fns";
import { AuthState, Order } from '../../store';
import { format } from "date-fns";
interface P {
    user: AuthState
}
const RecentOrder = (props: P) => {
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 7), endDate: new Date() })
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const { loading, error, data } = useQuery(get_orders, { variables: get_order_variables(merchantId, ranges.startDate, ranges.endDate) });
    const columns = [
        {
            title: 'OrderNo',
            dataIndex: 'order_number',
            key: 'order_number',
            ellipsis: true,
            sorter: (a: Order, b: Order) =>
                a.order_number.localeCompare(b.order_number),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a: Order, b: Order) => new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
            render: (v: number, record: Order) => {
                return format(new Date(record.created_at), 'yyyy-MMM-dd');
            },
        },
        {
            title: 'Customer',
            dataIndex: 'merchant_customer',
            key: 'merchant_customer',
            render: (v: number, record: Order) => {
                const val = (record.merchant_customer && record.merchant_customer.customer) ? record.merchant_customer.customer.name : "";
                return val;
            },
            sorter: (a: Order, b: Order) =>
                ((a.merchant_customer && a.merchant_customer.customer) ? a.merchant_customer.customer.name : "").localeCompare((b.merchant_customer && b.merchant_customer.customer) ? b.merchant_customer.customer.name : ""),
        },
        {
            title: 'Amount',
            dataIndex: 'grand_total',
            key: 'grand_total',
            sorter: (a: Order, b: Order) => a.grand_total - b.grand_total,
            render: (v: number, record: Order) => {
                return record.grand_total;
            },
        },
        {
            title: 'Status',
            dataIndex: 'order_status',
            key: 'order_status',
            render: (v: number, record: Order) => {
                let color = "orange";
                let status: string = record.order_status;
                if (record.order_status === "ACCEPT") color = "lime";
                if (record.order_status === "COMPLETE") color = "green";
                if (record.order_status === "CUSTOMER_CANCEL" || record.order_status === 'MERCHANT_CANCEL') {
                    color = "red";
                    status = "CANCEL";
                };
                if (record.order_status === "PAID") color = "cyan"
                return <Tag color={color}>{status}</Tag>;
            },
        },
    ];
    return (
        <React.Fragment>
            {loading && <Card loading={loading} />}
            {(!loading && !error) &&
                <>
                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={(data && data.orders) ? data.orders : []}
                        pagination={{
                            pageSize: 5,
                            position: ['none',]
                        }}
                    />

                </>
            }
        </React.Fragment>
    )
}

export default withUser(RecentOrder) as any;
