import {
  Button,
  Modal,
  Tabs,
  Select,
  Row,
  Timeline,
  Card,
  Checkbox,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  doc,
  collection,
  onSnapshot,
  getFirestore,
  query,
  orderBy,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import DeliveryButton from "./delivery_button";
import {
  genterateNinjaWayBill,
  sendNinjaDelveryOrderCreateOrCancel,
} from "../helpers/delivery_helper";
import { utcDateToZonedDate } from "../helpers/util";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import { startOfDay, format, addDays } from "date-fns";
import {
  get_states,
  get_townships,
  getStateVariables,
  getTownshipVariables,
} from "../gql/gql_query";
import { useQuery, useLazyQuery } from "@apollo/client";

import DatePicker from "../components/date";
import * as yup from "yup";
import { Order } from "../store";
const { TabPane } = Tabs;
const { Option } = Select;
interface P {
  merchantId: string;
  orderNo: string;
  customerAddress?: string;
  orderId: string;
  order: Order;
}
const shipFormData = {
  deliverDate: startOfDay(addDays(new Date(), 1)),
  deliverType: "home",
  deliverTime: "9To18",
  deliverNote: "",
  deliverWeight: "",
  //customerCity: "",
  //customerState: "",
  cashOnDelivery: "",
  allowWeekendDelivery: false,
  postCode: "",
  customerAddress: "",
  serviceLevel: "Nextday",
};
const shipSchema = yup.object().shape({
  deliverDate: yup.string().required("Delivery date is required"),
  deliverType: yup.string().required("Delivery type  is required"),
  deliverTime: yup.string().required("Delivery time required"),
  deliverNote: yup.string().required("Delivery instruction  is required"),
  deliverWeight: yup.number().required("Parcel weight  is required"),
  customerAddress: yup.string().required("Customer address  is required"),
  // customerCity: yup.string().required("customer city  is required"),
  // customerState: yup.string().required("customer state  is required"),
  postCode: yup.number().required("customer postcode  is required"),
  serviceLevel: yup.string().required("Delivery service level  is required"),
  cashOnDelivery: yup.number().required("Delivery amount  is required"),
  allowWeekendDelivery: yup
    .boolean()
    .required("Delivery on weekend  is required"),
});
const deliveryTimes = ["9To12", "9To18", "9To22", "12To15", "15To18", "18To22"];
const serviceLevels = ["Standard", "Nextday"];
const generateDeliveryWeight = (start: number, end: number) => {
  const decimalArr = Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx + 0.5);
  const intArr = Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);
  const weights = intArr.concat(decimalArr);
  weights.sort();
  return weights;
};
const DeliveryInfo: React.FC<P> = (props: P) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [trackNo, setTrackNo] = useState(null);
  const [activeKey, setActiveKey] = useState("1");
  const [logs, setLogs] = useState([] as any);
  const [checkpull, setCheckPull] = useState(false);
  const [historyPull, setHistoryPull] = useState(false);
  const [
    getStates,
    { loading: stateLoading = true, error: stateError, data: stateData },
  ] = useLazyQuery(get_states);
  const [
    getTownships,
    { loading: tspLoading = true, error: tspError, data: tspData },
  ] = useLazyQuery(get_townships);
  const [formData, setFormDat] = useState(
    props.customerAddress
      ? Object.assign(shipFormData, { customerAddress: props.customerAddress })
      : shipFormData
  );
  useEffect(() => {
    let unsub = null;
    if (checkpull) {
      getStates({ variables: getStateVariables() });
      getTownships({ variables: getTownshipVariables("") });
      unsub = onSnapshot(
        doc(
          getFirestore(getApp()),
          `merchants/${props.merchantId}/ninjaEvents`,
          props.orderId
        ),
        (doc) => {
          const data = doc.data();
          if (data) {
            if (data.data?.trackingNo) setTrackNo(data.data.trackingNo);
            setFormDat(Object.assign(formData, { ...data }));
            setActiveKey("2");
          }
          setCheckPull(false);
        }
      );
    }
    if (unsub) return unsub;
  }, [checkpull]);
  useEffect(() => {
    let unsub = null;
    if (historyPull) {
      const colPath = `merchants/${props.merchantId}/ninjaEvents/${props.orderId}/logs`;
      const q = query(
        collection(getFirestore(getApp()), colPath),
        orderBy("createdOn", "desc")
      );
      unsub = onSnapshot(q, (querySnapshot) => {
        let logs: any[] = [];
        querySnapshot.forEach((doc) => {
          logs.push(doc.data());
        });
        setLogs(logs);
      });
    }
    if (unsub) return unsub;
  }, [historyPull]);
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {};
  const handleCancel = () => {
    setVisible(false);
  };
  const handleToShip = async (data: any) => {
    let shipInfo = data as {
      deliverDate: string;
      deliverType: string;
      deliverTime: string;
      deliverNote: string;
      deliverWeight: string | number;
      customerAddress: string;
      customerState: string;
      customerCity: string;
      postCode: number;
      cashOnDelivery: number;
      allowWeekendDelivery: boolean;
      serviceLevel: "Standard" | "Nextday";
      other?: any;
    };
    //if (!tspData || !stateData) return;
    // const states = (stateData["stateRegions"] || []).filter((state: any) => state.sr_pcode === shipInfo.customerState);
    //const cities = (tspData["townships"] || []).filter((tsp: any) => tsp.tsp_pcode === shipInfo.customerCity);
    //if (states.length > 0) {
    //  shipInfo.customerState = states[0].sr_name_eng;
    //}
    //if (cities.length > 0) {
    //  shipInfo.customerCity = cities[0].township_name_eng;
    //}
    shipInfo.customerCity =
      props.order.shipment?.to_township?.township_name_eng || "";
    shipInfo.customerState =
      props.order.shipment?.to_township?.sr.sr_name_eng || "";
    shipInfo.deliverDate = addDays(new Date(shipInfo.deliverDate), 1)
      .toISOString()
      .split("T")[0];
    shipInfo.deliverWeight = parseFloat(shipInfo.deliverWeight as string);
    shipInfo.other = {
      isPickup: true,
      serviceLevel: shipInfo.serviceLevel,
      cashOnDelivery: shipInfo.cashOnDelivery,
      allowWeekendDelivery: shipInfo.allowWeekendDelivery,
    };
    // console.log('ship info', shipInfo)
    setLoading(true);
    await sendNinjaDelveryOrderCreateOrCancel({
      type: "createOrder",
      merchantId: props.merchantId,
      orderId: props.orderId,
      env: "production",
      ...shipInfo,
    });
    setActiveKey("2");
    setLoading(false);
  };
  const handleToCancel = async () => {
    setLoading(true);
    await sendNinjaDelveryOrderCreateOrCancel({
      type: "cancelOrder",
      merchantId: props.merchantId,
      orderId: props.orderId,
      trackingNo: trackNo!,
    });
    setActiveKey("2");
    setLoading(false);
  };
  const handleToWaybill = async () => {
    if (!trackNo) return;
    setLoading(true);
    await genterateNinjaWayBill(props.merchantId, trackNo!, "production");
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };
  const onChange = (tabKey: any) => {};
  const Shipping = () => {
    return (
      <Formik
        initialValues={formData}
        validationSchema={shipSchema}
        enableReinitialize={true}
        onSubmit={handleToShip}
      >
        {({
          dirty,
          values,
          isValid,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <FormikForm autoComplete="off">
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                {" "}
                Delivery Date{" "}
              </label>
              <Field
                id="deliverDate"
                name="deliverDate"
                type="text"
                render={() => (
                  <DatePicker
                    date={values.deliverDate}
                    minDate={addDays(new Date(), 1)}
                    title="Delivery Date"
                    onChange={(utc: string) => {
                      setFieldValue("deliverDate", utc);
                    }}
                  />
                )}
              />
              <br />
              <ErrorMessage
                name="deliverDate"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Delivery Type{" "}
              </label>
              <Field
                id="deliverType"
                name="deliverType"
                type="text"
                render={() => (
                  <Select
                    defaultValue={values.deliverType}
                    style={{ width: 120 }}
                    onChange={(val: any) => handleChange("deliverType")(val)}
                  >
                    <Option value={"home"}>Home</Option>
                    <Option value={"office"}>Office</Option>
                  </Select>
                )}
              />
              <br />
              <ErrorMessage
                name="deliverType"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Parcel Weight{" "}
              </label>
              <Field
                id="deliverWeight"
                name="deliverWeight"
                type="number"
                render={() => (
                  <Select
                    defaultValue={values.deliverWeight}
                    style={{ width: 120 }}
                    onChange={(val: any) => handleChange("deliverWeight")(val)}
                  >
                    {generateDeliveryWeight(1, 9).map((w) => (
                      <Option value={`${w}`}>{`${w} Kg`}</Option>
                    ))}
                  </Select>
                )}
              />
              <br />
              <ErrorMessage
                name="deliverWeight"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Delivery Time
              </label>
              <Field
                id="deliverTime"
                name="deliverTime"
                render={() => (
                  <Select
                    defaultValue={values.deliverTime}
                    style={{ width: 120 }}
                    onChange={(val: any) => handleChange("deliverTime")(val)}
                  >
                    {deliveryTimes.map((dt) => (
                      <Option key={`dt_${dt}`} value={dt}>
                        {dt}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              <br />
              <ErrorMessage
                name="deliverTime"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Service Level
              </label>
              <Field
                id="serviceLevel"
                name="serviceLevel"
                render={() => (
                  <Select
                    defaultValue={values.serviceLevel}
                    style={{ width: 120 }}
                    onChange={(val: any) => handleChange("serviceLevel")(val)}
                  >
                    {serviceLevels.map((dt) => (
                      <Option key={`dt_${dt}`} value={dt}>
                        {dt}
                      </Option>
                    ))}
                  </Select>
                )}
              />
              <br />
              <div style={{ marginTop: 7 }}>
                <label htmlFor="email" style={{ paddingRight: 10 }}>
                  Shipment To{" "}
                </label>
                <text
                  style={{ fontSize: 12, fontWeight: "bold", color: "green" }}
                >
                  {props.order.shipment?.to_township?.township_name_eng},
                  {props.order.shipment?.to_township?.sr.sr_name_eng}{" "}
                </text>
                <div />
              </div>
              <ErrorMessage
                name="serviceLevel"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Delivery on Weekend
              </label>
              <Field
                id="allowWeekendDelivery"
                name="allowWeekendDelivery"
                render={() => (
                  <Checkbox
                    checked={values.allowWeekendDelivery}
                    onChange={(v: any) =>
                      setFieldValue("allowWeekendDelivery", v.target.checked)
                    }
                  >
                    {values.allowWeekendDelivery ? "Allowed" : "Not allowed"}{" "}
                    {values.allowWeekendDelivery}
                  </Checkbox>
                )}
              />
              <br />
              <ErrorMessage
                name="allowWeekendDelivery"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Cash On delivery
              </label>
              <Field
                id="cashOnDelivery"
                name="cashOnDelivery"
                type="number"
                value={values.cashOnDelivery}
              />
              <br />
              <ErrorMessage
                name="cashOnDelivery"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              {/* <div style={{ display: 'flex', }}>
                                <div>
                                    <label htmlFor="email" style={{ padding: 5 }}>State</label>
                                    <Field
                                        id="customerState"
                                        name="customerState"
                                        component="div"
                                        render={() =>
                                        (<Select placeholder="Select State"
                                            loading={stateLoading}
                                            defaultValue={values.customerState}
                                            style={{ width: 120 }}
                                            onChange={(val: any) => {
                                                setFieldValue('customerCity', "");
                                                handleChange('customerState')(val);
                                                // setCurrentState(val);
                                            }}>
                                            {(stateData && stateData["stateRegions"] || []).map((dt: any) => (<Option key={`dt_${dt.sr_pcode}`} value={dt.sr_pcode}>{dt.sr_name_eng}</Option>))}
                                        </Select>)
                                        }

                                    />
                                    <br />
                                    <ErrorMessage
                                        name="customerState"
                                        component="div"
                                        className="field-error"
                                        render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                    />
                                    <br />
                                </div>
                                <div style={{ paddingLeft: 5 }}>
                                    <label htmlFor="email" style={{ paddingRight: 5 }}>TownShip</label>
                                    <Field
                                        id="customerCity"
                                        name="customerCity"
                                        render={() =>
                                        (<Select
                                            loading={tspLoading}
                                            // searchValue={values.customerState}
                                            defaultValue={values.customerCity}
                                            filterOption={(input: any, option: any) => {
                                                return (option!.value as unknown as string).startsWith(input);
                                            }}
                                            placeholder="Select Township" style={{ width: 120 }} onChange={(val: any) => handleChange('customerCity')(val)}>
                                            {(tspData && tspData["townships"] || []).filter((tsp: any) => tsp.tsp_pcode.startsWith(values.customerState)).map((dt: any) => (<Option key={`dtsp_${dt.tsp_pcode}`} value={dt.tsp_pcode}>{dt.township_name_eng}</Option>))}
                                        </Select>)
                                        }

                                    />
                                    <br />
                                    <ErrorMessage
                                        name="customerCity"
                                        component="div"
                                        className="field-error"
                                        render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                    /> <br />
                                </div>
                            </div> */}
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Postal Code
              </label>
              <Field
                id="postCode"
                name="postCode"
                type="number"
                value={values.postCode}
              />
              <br />
              <ErrorMessage
                name="postCode"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <div style={{ display: "grid" }}>
                <label htmlFor="email">Customer Address</label>
                <Field
                  id="customerAddress"
                  name="customerAddress"
                  component="textarea"
                  rows="3"
                  value={values.customerAddress}
                />
                <ErrorMessage
                  name="customerAddress"
                  component="div"
                  className="field-error"
                  render={(error) => (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                />{" "}
                <br />
              </div>
              <div style={{ display: "grid" }}>
                <label htmlFor="email">Delivery Notes </label>
                <Field
                  id="deliverNote"
                  name="deliverNote"
                  component="textarea"
                  rows="3"
                  value={values.deliverNote}
                />
                <ErrorMessage
                  name="deliverNote"
                  component="div"
                  className="field-error"
                  render={(error) => (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                />{" "}
                <br />
              </div>
              <Button disabled={!dirty} type="primary" htmlType="submit">
                Send To Ship
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <>
      <DeliveryButton
        loading={loading}
        onClick={() => {
          showModal();
          setCheckPull(true);
          setHistoryPull(true);
        }}
      />
      <Modal
        destroyOnClose={true}
        zIndex={500}
        visible={visible}
        title={
          trackNo
            ? `Delivery(OrderNo:${props.orderNo},TrackNo:${trackNo})`
            : `Delivery(OrderNo:${props.orderNo})`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {(checkpull || loading) && <Card loading={true} />}
        {!checkpull && !loading && (
          <Row justify="center">
            <Tabs size="large" defaultActiveKey={activeKey} onChange={onChange}>
              <TabPane tab="Shipping" key="1" disabled={trackNo}>
                <Shipping />
                {/* <Button loading={loading}
                                    style={{ margin: 60, backgroundColor: "green" }}
                                    onClick={handleToShip}>
                                    Send To Ship
                                </Button> */}
              </TabPane>
              <TabPane tab="History" key="2">
                <Timeline>
                  {logs.map((lg: any, i: number) => (
                    <Timeline.Item key={`deli_log_${i}`}>
                      {lg && lg.status && <p>{lg.status}</p>}
                      {lg && lg.comments && <p>{lg.comments}</p>}
                      {lg && lg.timestamp && (
                        <p>{utcDateToZonedDate(lg.timestamp).toString()}</p>
                      )}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </TabPane>
              <TabPane tab="Waybill" key="3" disabled={!trackNo}>
                <Button
                  loading={loading}
                  style={{
                    margin: 60,
                    backgroundColor: "cyan",
                    justify: "center",
                    alignItem: "center",
                  }}
                  onClick={handleToWaybill}
                >
                  Generate Waybill
                </Button>
              </TabPane>
              <TabPane tab="Rollback" key="4" disabled={!trackNo}>
                <Button
                  loading={loading}
                  style={{ margin: 60, backgroundColor: "red" }}
                  onClick={handleToCancel}
                >
                  Cancel To Ship
                </Button>
              </TabPane>
            </Tabs>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default DeliveryInfo;
