import React from "react";
import { Order, Product } from "../store";
import { Card, Row, Col, Avatar, Typography } from "antd";
import { formatMoney } from "../helpers/util";
import { orderBy } from "lodash";
const { Meta } = Card;
export default function DetialInfo(props: {
  product: Product;
  orders: Order[];
  totalPrice:any
}) {
  // console.log('====================================');
  // console.log(props.product,"product");
  // console.log(props.orders,"orders");
  
  // console.log('====================================');
  // const calTotal = () => {
  //   let total = 0;
  //   for (const order of props.orders) {
  //     const stockIds =
  //       props.product.product_stock_items?.map((psi) => psi.id) || [];
  //     const orgPrice =
  //       props.product.product_stock_items?.map((psi) => psi.original_price) ||
  //       [];
  //       // console.log('====================================');
  //       // console.log(orgPrice);
  //       // console.log('====================================');
  //     if (order.order_items) {
  //       order.order_items.forEach((oi) => {
  //         if (oi.stock_item_id && stockIds.includes(oi.stock_item_id)) {
  //           total += oi.count * Number(orgPrice);
  //         }
  //       });
  //     }
  //   }
  //   return total;
  // };
  // console.log(props.product.product_stock_items,"PSI");
  
  const { product } = props;
  return (
    <Card
      style={styles.card}
      title={
        <Typography.Title level={5} style={styles.cardTitle}>
          {product.name}
        </Typography.Title>
      }
      bordered={false}
      size="small"
    >
      <Meta
        style={styles.cardMeta}
        avatar={<Avatar size={190} src={product.image} />}
      />
      <Row gutter={24} style={{ marginTop: 5 }}>
        <Col span={10}>
          <Typography.Title level={5}> Total Sales</Typography.Title>
        </Col>
        <Col span={14}>{formatMoney(props.totalPrice, 0)} Ks</Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}> Category</Typography.Title>
        </Col>
        <Col span={14}>
          {(product.product_categories || []).map((pc) => pc.name).join(",")}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}> Variations</Typography.Title>
        </Col>
        <Col span={14}>
          {(product.variations || []).map((pc) => pc.name).join(",")}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={6}>
          <Typography.Title level={5}>Stocks</Typography.Title>
        </Col>
        <Col span={18}>
          <Typography.Paragraph>
            {" "}
            {(product.product_stock_items || [])
              .map((psi) => psi.name)
              .join(",")}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
}

const styles = {
  card: {
    height: "100%",
  },
  cardTitle: {
    textAlign: "center",
  },
  cardMeta: {
    justifyContent: "center",
    display: "flex",
  },
};
