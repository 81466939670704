import React, { useState } from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import { CalendarOutlined } from "@ant-design/icons";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { startOfDay, format, addHours, endOfDay, subDays } from 'date-fns'
import { Modal, Button } from 'antd';
type TimeOn = "StartOfDay" | "EndOfDay" | "MidOfDay"

interface P {
    date?: Date,
    disable?: boolean,
    minDate?: Date,
    title?: string;
    timeOn?: TimeOn;
    onChange?: (utc: string) => any;
}
const convertTime = (dateTime: TimeOn, date: Date) => {
    if (dateTime === 'EndOfDay') {
        return endOfDay(date);
    } else if (dateTime === 'MidOfDay') {
        return addHours(date, 12);
    } else {
        return endOfDay(subDays(date, 1))
    }
}
const convertLabel = (date: Date, showTime: boolean) => {
    if (showTime) {
        return format(date, 'dd MMM,yyyy HH:mm');
    }
    return format(date, 'dd MMM,yyyy')
}
export default function DatePicker(props: P) {
    const date = props.date ? props.date : startOfDay(new Date());
    const withTime = props.timeOn;
    const [selectDate, setSelectDate] = useState(utcToZonedTime(date, 'Asia/Yangon'));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [label, setLabel] = useState(props.date ? convertLabel(selectDate, withTime ? true : false) : "select date")

    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        const date = withTime ? convertTime(withTime, selectDate) : selectDate;
        const utcDate = zonedTimeToUtc(date, 'Asia/Yangon')

        //check valid
        // const isoStr = utcDate.toISOString();
        // console.log('uto to iso', isoStr)
        // const convertToZone = utcToZonedTime((new Date(isoStr)), 'Asia/Yangon');
        // console.log('\nconvert zone', convertToZone)
        // const zoneTime = convertLabel(convertToZone, true)
        // console.log('\n zone time', zoneTime)


        const label = convertLabel(date, withTime ? true : false);
        setLabel(label);
        if (props.onChange) props.onChange(utcDate.toISOString());
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <>
            <Button icon={<CalendarOutlined />} onClick={showModal} disabled={props.disable || false}>
                {label}
            </Button>
            <Modal bodyStyle={{ backgroundColor: 'none' }} width={400} style={{ top: 20 }} title={props.title || "Date"} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Calendar
                    date={selectDate}
                    onChange={setSelectDate}
                    minDate={props.minDate}
                />
            </Modal>
        </>
    )
}
