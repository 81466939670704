import React from "react";
import { connect } from "react-redux";
import { AppState } from "../store";
const withMerchant = (WrappedComponent: any) => {
    const HOC = (props: any) => {
        return <WrappedComponent {...props} />
    };
    const mapStateToProps = (state: AppState) => {
        return {
            merchant: state.merchantState.merchant,
        };
    };
    const mapStateToDispatch = (dispatch: any) => {
        return {
            dispatch
        }
    }
    return connect(mapStateToProps, mapStateToDispatch)(HOC);
};

export default withMerchant;
