import { Button, Modal, Tabs, Select, Row, Timeline, Card, Result } from 'antd';
import React, { useState, useEffect } from 'react';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { PayPointSystem } from "../store";
import { sendPayPoint } from "../api/pay_point";
import * as yup from 'yup';
interface P {
    merchantId: string;
    orderNo: string;
    phoneNo?: string;
    apiKey: string;
    orderId: string;
    totalAmount: number;
    payPointSystem: PayPointSystem;
}
const payFormData = {
    phoneNumber: "",
    ref: ""
};
const shipSchema = yup.object().shape({
    phoneNumber: yup.string().required("PhoneNumber  is required"),
    ref: yup.string().required("Pay reference is required"),
});
const convertNormalPhone = (phone: string) => {
    if (phone.startsWith('+959')) {
        return "0" + phone.substring(3);
    }
    if (phone.startsWith('+95 9')) {
        return "0" + phone.substring(4);
    }
    return phone;
}
const PayPoint = (props: P) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [currentForm, setCurrentForm] = useState("send");
    const [message, setMessage] = useState("");
    const [payPoint, setPayPoint] = useState(0)
    const [formData, setFormData] = useState(Object.assign(payFormData, { ref: `OrderNo:${props.orderNo}`, phoneNumber: convertNormalPhone(props.phoneNo || "") }));
    const showModal = () => {
        setVisible(true);
    };
    const handleOk = () => {
    };
    const handleCancel = () => {
        setVisible(false);
    };
    const calPayPoint = () => {
        let payPoint = 0;
        const { payPointSystem, totalAmount } = props;
        if (payPointSystem.type === 'exchange') {
            payPoint = Math.trunc(totalAmount / payPointSystem.value);
        }
        if (payPointSystem.type === 'percentage') {
            payPoint = Math.trunc(totalAmount * (payPointSystem.value / 100));
        }
        return payPoint;
    }
    const handleSubmit = async (data: any) => {
        const { phoneNumber, ref } = data as { phoneNumber: string, ref: string }
        if (!phoneNumber.startsWith('09')) return;
        const point = calPayPoint();
        setLoading(true);
        const res = await sendPayPoint({ merchantId: props.merchantId, memberIdOrPhone: phoneNumber, ref, point, apiKey: props.apiKey, orderId: props.orderId });
        let msg = "", form = "error";
        if (res.success) {
            msg = `Paid ${point} points to phone (${phoneNumber}) for orderNo ${props.orderNo}`;
            form = "success";
        } else {
            msg = res.code || "";
            form = "error";
        }
        setMessage(msg);
        setCurrentForm(form);
        setLoading(false)
    }
    return (
        <>
            <Button type="primary" disabled={loading} loading={loading} onClick={() => {
                showModal();
            }} >
                Give PayPoint
            </Button>
            <Modal
                destroyOnClose={true}
                zIndex={500}
                visible={visible}
                title={
                    `Pay Point (${props.orderNo})`
                }
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                {loading && <Card loading={true} />}
                {(!loading && currentForm === 'send') &&
                    <Row justify="center">
                        <Formik
                            initialValues={formData}
                            validationSchema={shipSchema}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}
                        >
                            {({ dirty, values, isValid, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                                return (
                                    <FormikForm autoComplete="off">
                                        <div style={{ display: 'grid' }}>
                                            <label htmlFor="email">PhoneNo *startWith(09) </label>
                                            <Field
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                type="text"
                                                value={values.phoneNumber}
                                            />
                                            <br />
                                            <ErrorMessage
                                                name="phoneNumber"
                                                component="div"
                                                className="field-error"
                                                render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                            /> <br />
                                        </div>
                                        <div style={{ display: 'grid' }}>
                                            <label htmlFor="email">Reference </label>
                                            <Field
                                                id="ref"
                                                name="ref"
                                                component="textarea"
                                                rows="3"
                                                value={values.ref}
                                            />
                                            <ErrorMessage
                                                name="ref"
                                                component="div"
                                                className="field-error"
                                                render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                            /> <br />
                                        </div>
                                        {calPayPoint() > props.payPointSystem.points && <> <span style={{ color: 'red', }}>{"Not enough points to pay"}</span><br /></>}
                                        <Button
                                            disabled={!isValid || calPayPoint() < 0 || calPayPoint() > props.payPointSystem.points}
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                        >
                                            Give {calPayPoint()} PayPoints
                                        </Button>
                                    </FormikForm>
                                );
                            }
                            }
                        </Formik >
                    </Row>
                }
                {(!loading && currentForm === 'success') &&
                    <Result
                        status={currentForm}
                        title="Successfully PayPoint!"
                        subTitle={message}
                    />
                }
                {(!loading && currentForm === 'error') &&
                    <Result
                        status={currentForm}
                        title="PayPoint Failed"
                        subTitle={message}
                    />
                }
            </Modal>
        </>
    );
}

export default PayPoint;