import axios from "axios";

export const getMerchantData = async (merchantId: string) => {
    const url = "https://asia-southeast1-piti-commerce.cloudfunctions.net/apiv1/v1/rewards/" + merchantId;
    const options = {
        method: "get",
        headers: {
            ["Content-Type"]: "application/json",
            ["Authorization"]: `${merchantId}`
        },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200 && res.data) {
        return { success: true, data: res.data.data }

    }
    return { success: false, msg: "Something was wrong" }
}