import React, { useState, useEffect } from "react";
import { get_order_variables, get_orders } from "../../gql/gql_query";
import { useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_spinner";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Typography,
  Card,
  Progress,
  Button,
  Tag,
  Badge,
  Table,
} from "antd";
import { subDays, format } from "date-fns";
import * as _ from "lodash";
import { ShoppingCartOutlined } from "@ant-design/icons";
import {
  MoneyIcon,
  UpcommingIcon,
  UserIcon,
} from "../../assets/icons/svg_icons";
import DateRange from "../../components/date_range";
import { paths } from "../../routes/paths";
import PanelCard from "../../components/panel_card";
import CircleProgress from "../../components/circle_progress";
import { AuthState, Order } from "../../store";
import { formatMoney, numFormatter } from "../../helpers/util";
import OrderLineChart from "../../charts/order_dashboard_linechart";
import OrderPieChart from "../../charts/order_dashboard_piechart";
import RecentOrder from "../../pages/order/recent_list";
import withUser from "../../hocs/with_user";
const panelList = [
  {
    title: "Total orders",
    key: "orders",
    icon: ShoppingCartOutlined,
    isIcon: true,
    value: 0,
    isMoney: false,
  },
  {
    title: "POS",
    key: "pos",
    icon: CircleProgress,
    isIcon: false,
    color: "blue",
    value: 0,
    isMoney: false,
  },
  {
    title: "Online ",
    key: "online",
    icon: CircleProgress,
    isIcon: false,
    color: "blue",
    value: 0,
    isMoney: false,
  },
  {
    title: "Customers",
    key: "customers",
    icon: UserIcon,
    value: 0,
    isIcon: true,
    isMoney: false,
  },
];
interface P {
  user: AuthState;
}
const Dashboard = (props: P) => {
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  const { loading, error, data } = useQuery(get_orders, {
    variables: get_order_variables(
      merchantId,
      ranges.startDate,
      ranges.endDate
    ),
    fetchPolicy: "network-only",
  });
  const onSelect = async (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    //await refetch();
  };
  const columns2 = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: { status: string }, b: { status: string }) =>
        a.status.localeCompare(b.status),
      render: (v: number, record: { status: string }) => {
        let color = "orange";
        if (record.status === "progress") color = "lime";
        if (record.status === "complete") color = "green";
        if (record.status === "cancel") color = "red";
        return <Tag color={color}>{record.status}</Tag>;
      },
    },
    {
      title: "Channel",
      key: "channel",
      render: (v: number, record: { channel: string }) => {
        return record.channel;
      },
    },
    {
      title: "Count",
      key: "count",
      sorter: (a: { count: number }, b: { count: number }) => a.count - b.count,
      render: (v: number, record: { count: number }) => {
        return (
          <Badge count={record.count} style={{ backgroundColor: "blue" }} />
        );
      },
    },
  ];
  const panelData = () => {
    let orders: Order[] = data && data.orders ? data.orders : [];
    let customerIds: string[] = [];
    let posCount = 0,
      onlineCount = 0,
      count = 0;
    orders.forEach((or) => {
      count += 1;
      if (or.sale_channel === "WEB") {
        onlineCount += 1;
      }
      if (or.sale_channel === "POS") {
        posCount += 1;
      }
      if (or.merchant_customer) {
        customerIds.push(or.merchant_customer.id);
      }
    });
    const customers = _.uniq(customerIds);
    return {
      orders: count,
      customers: customers.length,
      pos: posCount,
      online: onlineCount,
    };
  };

  const control = () => (
    <Row>
      <Col flex={4}>
        <Row>
          <Col span={12}>
            <Typography level={3}> Order Dashboard </Typography>
          </Col>
          <Col span={12}> </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
  const panels = () => {
    const _data = panelData() as any;
    return (
      <Row style={{ marginTop: 4 }} gutter={[48, 16]}>
        {panelList.map((panel) => (
          <Col span={6}>
            <PanelCard
              loading={false}
              icon={
                panel.isIcon ? (
                  <panel.icon style={{ fontSize: 60 }} />
                ) : (
                  <panel.icon
                    width={80}
                    total={_data["orders"]}
                    value={_data[panel.key]}
                    color={panel.color || "blue"}
                    showPercent={true}
                  />
                )
              }
              title={panel.title}
              primary={
                panel.isMoney
                  ? formatMoney(_data[panel.key] || panel.value)
                  : numFormatter(_data[panel.key] || panel.value)
              }
              secondary={panel.isMoney ? "MMK" : null}
            />
          </Col>
        ))}
      </Row>
    );
  };
  const orderRowData = () => {
    let _labels: string[] = [],
      days: any[] = [];
    let pendingData: string[] = [],
      completeData: string[] = [],
      progressData: string[] = [],
      cancelData: string[] = [];
    let orders: Order[] = data && data.orders ? data.orders : [];
    let posCount = 0,
      webCount = 0,
      otherCount = 0;
    let pendingCount = 0,
      completeCount = 0,
      cancelCount = 0,
      progressCount = 0;
    let onlineCancel = 0,
      onlinePending = 0,
      onlineComplete = 0;
    let posCancel = 0,
      posPending = 0,
      posComplete = 0;
    orders = orders.map((or) => {
      if (or.sale_channel === "WEB") {
        webCount += 1;
        if (or.order_status === "PENDING") onlinePending += 1;
        if (or.order_status === "ACCEPT") onlinePending += 1;
        if (or.order_status === "PAID") onlinePending += 1;
        if (or.order_status === "COMPLETE") onlineComplete += 1;
        if (or.order_status === "MERCHANT_CANCEL") onlineCancel += 1;
        if (or.order_status === "CUSTOMER_CANCEL") onlineCancel += 1;
      }
      if (or.sale_channel === "POS") {
        posCount += 1;
        if (or.order_status === "PENDING") posPending += 1;
        if (or.order_status === "ACCEPT") posPending += 1;
        if (or.order_status === "PAID") posPending += 1;
        if (or.order_status === "COMPLETE") posComplete += 1;
        if (or.order_status === "MERCHANT_CANCEL") posCancel += 1;
        if (or.order_status === "CUSTOMER_CANCEL") posCancel += 1;
      }
      if (or.sale_channel === "OTHER") {
        otherCount += 1;
      }
      if (or.order_status === "PENDING") pendingCount += 1;
      if (or.order_status === "ACCEPT") progressCount += 1;
      if (or.order_status === "PAID") progressCount += 1;
      if (or.order_status === "COMPLETE") completeCount += 1;
      if (or.order_status === "MERCHANT_CANCEL") cancelCount += 1;
      if (or.order_status === "CUSTOMER_CANCEL") cancelCount += 1;
      const day = format(new Date(or.created_at), "yyyy-MMM-dd ");
      return {
        ...or,
        filter: day,
      };
    });
    const dayGroups = _.groupBy(orders, "filter");
    Object.keys(dayGroups).forEach((day) => {
      const date = day;
      let totalSale = 0;
      let pending = 0,
        progress = 0,
        cancel = 0,
        complete = 0;
      dayGroups[day].forEach((or) => {
        if (or.order_status === "PENDING") pending += 1;
        if (or.order_status === "ACCEPT") progress += 1;
        if (or.order_status === "PAID") progress += 1;
        if (or.order_status === "COMPLETE") complete += 1;
        if (or.order_status === "MERCHANT_CANCEL") cancel += 1;
        if (or.order_status === "CUSTOMER_CANCEL") cancel += 1;
        totalSale += Number(or.grand_total);
      });
      days.push({ date, totalSale, pending, progress, cancel, complete });
    });
    _.orderBy(days, "date", "asc").forEach((f) => {
      _labels.push(f.date);
      pendingData.push(f.pending);
      progressData.push(f.progress);
      cancelData.push(f.cancel);
      completeData.push(f.complete);
    });
    const onlineOrders = [
      { status: "pending", count: onlinePending, channel: "online" },
      { status: "complete", count: onlineComplete, channel: "online" },
      { status: "cancel", count: onlineCancel, channel: "online" },
    ];
    const posOrders = [
      { status: "pending", count: posPending, channel: "pos" },
      { status: "complete", count: posComplete, channel: "pos" },
      { status: "cancel", count: posCancel, channel: "pos" },
    ];
    return {
      _labels,
      pendingData,
      progressData,
      cancelData,
      completeData,
      posCount,
      webCount,
      otherCount,
      pendingCount,
      completeCount,
      progressCount,
      cancelCount,
      onlineOrders,
      posOrders,
    };
  };
  const orderRow = () => {
    const rowData = orderRowData();
    return (
      <Row style={{ marginTop: 8 }} gutter={24}>
        <Col span={16}>
          <Card
            bordered={false}
            hoverable={true}
            size="small"
            style={styles.card}
          >
            <OrderLineChart
              lables={rowData._labels}
              pendingData={rowData.pendingData}
              cancelData={rowData.cancelData}
              completeData={rowData.completeData}
              progressData={rowData.progressData}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            bordered={false}
            hoverable={true}
            size="small"
            style={styles.card}
          >
            <OrderPieChart
              cancel={rowData.cancelCount}
              complete={rowData.completeCount}
              progress={rowData.progressCount}
              pending={rowData.pendingCount}
            />
          </Card>
        </Col>
      </Row>
    );
  };
  const recentRow = () => {
    const rowData = orderRowData();
    return (
      <Row style={{ marginTop: 8 }} gutter={24}>
        <Col span={14}>
          <Card
            size="small"
            title="Recent"
            bordered={false}
            style={styles.card}
            extra={
              <Link to={paths.orders}>
                <Typography.Link>Show more...</Typography.Link>
              </Link>
            }
          >
            <RecentOrder />
          </Card>
        </Col>
        <Col span={10}>
          <Card
            bordered={false}
            hoverable={true}
            size="small"
            style={styles.card}
          >
            <Table
              showHeader={false}
              columns={columns2}
              dataSource={rowData.onlineOrders}
              pagination={{
                pageSize: 4,
                position: ["none"],
              }}
            />
            <Table
              showHeader={false}
              columns={columns2}
              dataSource={rowData.posOrders}
              pagination={{
                pageSize: 4,
                position: ["none"],
              }}
            />
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <React.Fragment>
      {loading && <LoadingSpinner />}
      {!loading && !error && (
        <>
          {control()}
          {panels()}
          {orderRow()}
          {recentRow()}
        </>
      )}
    </React.Fragment>
  );
};
export default withUser(Dashboard);
const styles = {
  card: {
    height: "100%",
  },
  card2: {
    height: "60%",
  },
};
