import React from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { AppState, AuthState } from "../store";
const withUser = (WrappedComponent: any) => {
  const HOC = (props: any) => {
    const location = useLocation();
    const { pathname } = location;
    const auth = props.user as AuthState;
    return (auth.status === 'loggedIn') ? <WrappedComponent {...props} /> : <Navigate to={`/login?redirect=${pathname ? pathname : '/home'}`} replace />;
  };
  const mapStateToProps = (state: AppState) => {
    return {
      user: state.authState,
    };
  };
  const mapStateToDispatch = (dispatch: any) => {
    return {
      dispatch
    }
  }
  return connect(mapStateToProps, mapStateToDispatch)(HOC);
};

export default withUser;
