import React from 'react'
import { Order } from "../store";
import { format } from "date-fns";
import { Tag, Table, Empty } from "antd";
export default function CustomerOrder(props: { data: Order[] }) {
    const { data } = props;
    const columns = [
        {
            title: 'OrderNo',
            dataIndex: 'order_number',
            key: 'order_number',
            width: 100,
            sorter: (a: Order, b: Order) =>
                a.order_number.localeCompare(b.order_number),
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 100,
            sorter: (a: Order, b: Order) => new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
            render: (v: number, record: Order) => {
                return format(new Date(record.created_at), 'yyyy-MMM-dd');
            },
        },
        {
            title: 'Customer',
            dataIndex: 'merchant_customer',
            key: 'merchant_customer',
            render: (v: number, record: Order) => {
                const val = (record.merchant_customer && record.merchant_customer.customer) ? record.merchant_customer.customer.name : "";
                return val;
            },
            sorter: (a: Order, b: Order) =>
                ((a.merchant_customer && a.merchant_customer.customer) ? a.merchant_customer.customer.name : "").localeCompare((b.merchant_customer && b.merchant_customer.customer) ? b.merchant_customer.customer.name : ""),
        },
        {
            title: 'Amount',
            dataIndex: 'grand_total',
            key: 'grand_total',
            sorter: (a: Order, b: Order) => a.grand_total - b.grand_total,
            render: (v: number, record: Order) => {
                return record.grand_total;
            },
        },
        {
            title: 'Type',
            dataIndex: 'order_type',
            key: 'order_type',
            render: (v: number, record: Order) => {
                return record.sale_channel;
            },
        },
        {
            title: 'Status',
            dataIndex: 'order_status',
            key: 'order_status',
            render: (v: number, record: Order) => {
                let color = "orange";
                let status: string = record.order_status;
                if (record.order_status === "ACCEPT") color = "lime";
                if (record.order_status === "COMPLETE") color = "green";
                if (record.order_status === "CUSTOMER_CANCEL" || record.order_status === 'MERCHANT_CANCEL') {
                    color = "red";
                    status = "CANCEL";
                };
                if (record.order_status === "PAID") color = "cyan"
                return <Tag color={color}>{status}</Tag>;
            },
        },
    ];
    return (
        <React.Fragment>
            {
                data.length === 0 ?
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                Empty Data
                            </span>
                        }
                    /> :

                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            pageSize: 5,
                            // position: ['none',]
                        }}
                    />
            }
        </React.Fragment>
    )
}
