import { gql } from "@apollo/client";
import { Coupon } from "../store";
export const update_stock_item = gql`
mutation UpdateProductStockItem($data: ProductStockItemUpdateInput!, $where: ProductStockItemWhereUniqueInput!) {
    updateProductStockItem(data: $data, where: $where) {
      id
    }
  }
`;

export const get_update_stock_variables = (data: { id: string, stock?: number, price: number | string, origin_price: string | number }) => {
    let variables: any = {};
    variables.where = {
        id: data.id,
    }
    variables.data = {};
    if (data.stock !== undefined) {
        variables.data = {
            stock: {
                set: data.stock
            }
        }
    }
    if (data.origin_price) {
        variables.data = {
            ...variables.data, original_price: {
                set: `${data.origin_price}`
            }
        }
    }
    if (data.price) {
        variables.data = {
            ...variables.data, price: {
                set: `${data.price}`
            }
        }
    }
    // ({
    //     "data": {
    //         "price": {
    //             "set": `${data.price}`
    //         },
    //         "stock": {
    //             "set": data.stock
    //         },
    //         "original_price": {
    //             "set": `${data.origin_price}`
    //         }
    //     },
    //     "where": {
    //         id: data.id
    //     }
    // })
    return variables;
};
export const update_coupon = gql`
mutation UpdateCoupon($data: CouponUpdateInput!, $where: CouponWhereUniqueInput!) {
    updateCoupon(data: $data, where: $where) {
      id
    }
  }
`;

export const get_update_coupon_variables = (id: string, coupon: Coupon) => ({
    "data": {
        "status": {
            "set": coupon.status
        },
        "price": {
            "set": `${Number(coupon.price)}`
        },
        "price_type": {
            "set": coupon.price_type
        },
        "total_count": {
            "set": Number(coupon.total_count)
        },
        "per_customer_limit": {
            "set": Number(coupon.per_customer_limit)
        },
        "min_amount": {
            "set": Number(coupon.min_amount)
        },
        "remain_count": {
            "set": Number(coupon.remain_count)
        },
        "start_date": {
            "set": coupon.start_date
        },
        "expire_month": {
            "set": coupon.expire_month
        },
        "expire_date": {
            "set": coupon.expire_date.toISOString()
        }
    },
    "where": {
        id
    }
});


export const create_coupon = gql`
mutation CreateCoupon($data: CouponCreateInput!) {
    createCoupon(data: $data) {
      id
    }
  }
`;

export const get_create_coupon_variable = (c: Coupon, merchantId: string) => ({
    "data": {
        "code": c.code.toUpperCase(),
        "status": c.status,
        "price": `${Number(c.price)}`,
        "price_type": c.price_type,
        "total_count": Number(c.total_count),
        "per_customer_limit": Number(c.per_customer_limit),
        "min_amount": Number(c.min_amount),
        "remain_count": Number(c.total_count),
        "start_date": c.start_date,
        "expire_month": Number(c.expire_month),
        "expire_date": c.expire_date.toISOString(),
        "merchant": {
            "connect": {
                "id": merchantId
            }
        },
    }
});


export const upsert_system_data = gql`
    mutation UpsertSystemData(
    $where: SystemDataWhereUniqueInput!
    $create: SystemDataCreateInput!
    $update: SystemDataUpdateInput!
  ) {
    upsertSystemData(where: $where, create: $create, update: $update) {
      code
      metadata
      merchant_id
    }
  }
`;
export const get_upsert_system_data_varible = (data: { merchantId: string, code: string, metadata: any }) => {
    const { merchantId, code, metadata } = data;
    let variables: any = {};
    variables.where = { "code_merchant_id": { "merchant_id": merchantId, "code": code } };
    variables.create = {
        code,
        metadata,
        "merchant": {
            "connect": {
                "id": merchantId
            }
        }
    };
    variables.update = {
        "metadata": { "set": metadata },
    };
    return variables;
}
export const update_merchant_customer = gql`
mutation UpdateMerchantCustomer($data: MerchantCustomerUpdateInput!, $where: MerchantCustomerWhereUniqueInput!) {
    updateMerchantCustomer(data: $data, where: $where) {
      id
    }
  }
`;
type merchantCustomerUpdateType =
    { type: "name", name: string }
    | { type: "owner", ownerId: string }
    | { type: "email", email: string }
    | { type: "dob", dateOfBirth: string }
    | { type: "address", address: string }

export const getUpdateMerchantCustomerVaribales = (data: { merchantId: string, customerId: string, data: merchantCustomerUpdateType }) => {
    const { merchantId, customerId, data: updateData } = data
    let variables: any = {}
    variables.where = {
        "customer_id_merchant_id": {
            "customer_id": customerId,
            "merchant_id": merchantId
        }
    }
    if (updateData.type === 'owner') {
        variables.data = {
            "owner": {
                "connect": {
                    "id": updateData.ownerId
                }
            }
        }
    }
    if (updateData.type === "address") {
        variables.data = {
            "customer": {
                "update": {
                    "shipping_address": {
                        "set": updateData.address
                    }
                }
            }
        }
    }
    if (updateData.type === 'email') {
        variables.data = {
            "email": {
                "set": updateData.email
            }
        };
    }
    if (updateData.type === "dob") {
        variables.data = {
            "date_of_birth": {
                "set": updateData.dateOfBirth
            }
        }
    }
    return variables;
}

export const update_product = gql`mutation UpdateProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
    updateProduct(data: $data, where: $where) {
      id
    }
  }`;
export const get_update_product_variables = (data: { id: string, order: number }) => {
    let variables: any = {};

    variables.where = {
        id: data.id,
    }
    variables.data = {
        order: {
            set: data.order
        }
    }
    return variables
}

export const update_order = gql`mutation UpdateOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
    updateOrder(data: $data, where: $where) {
      id
    }
  }`;

export const updateOrderMerchantNoteVariables = (data: { orderId: string, merchant_note: any }) => ({
    data: {
        merchant_note: {
            set: data.merchant_note
        }
    },
    where: {
        id: data.orderId
    }
})
export const getLinkOrderCustomerVariables = (data: { orderId: string, merchantCustomerId: string }) => ({
    data: {
        merchant_customer: {
            connect: {
                id: data.merchantCustomerId
            }
        }
    },
    where: {
        id: data.orderId
    }
})
export const getUnLinkOrderCustomerVariables = (data: { orderId: string, merchantCustomerId: string }) => ({
    data: {
        merchant_customer: {
            disconnect: true
        }
    },
    where: {
        id: data.orderId
    }
});

export const update_many_product = gql`mutation UpdateManyProduct($data: ProductUpdateManyMutationInput!, $where: ProductWhereInput) {
    updateManyProduct(data: $data, where: $where) {
      count
    }
  }`;
export const getUpdateManyProductSortVariables = (data: { order: number, Ids: string[], merchantId: string }) => ({
    "data": {
        "order": {
            "set": data.order
        }
    },
    "where": {
        "id": {
            "in": data.Ids
        },
        "merchant": {
            "is": {
                "id": {
                    "equals": data.merchantId
                }
            }
        }
    }
});

export const create_product_stock_item_log = gql`
 mutation CreateProductStockItemLog($data: ProductStockItemLogCreateInput!) {
    createProductStockItemLog(data: $data) {
      id
    }
  }
`
export const getCreateProductStockItemLogVariables = (data: {
    psiId: string,
    userId: string,
    type: "PRICE" | "STOCK" | "PRICEANDSTOCK",
    price?: number | string,
    b4_price: number | string,
    stock?: number | string,
    b4_stock?: number | string,
    ref?: string,
    comment?: string,
    branchId?: string,
}) => {
    let variables: any = {};
    variables.data = {
        type: data.type,
        product_stock_item: {
            connect: {
                id: data.psiId
            }
        },
        user: {
            connect: {
                uid: data.userId
            }
        }
    }
    if (data.price) {
        variables = { ...variables, data: { ...variables.data, price: `${data.price}` } }
    }
    if (data.price && data.b4_price) {
        variables = { ...variables, data: { ...variables.data, price_b4_update: `${data.b4_price}` } };
    }
    if (data.stock) {
        variables = { ...variables, data: { ...variables.data, stock: data.stock } };
    }
    if (data.stock && data.b4_stock) {
        variables = { ...variables, data: { ...variables.data, stock_b4_update: data.b4_stock } };
    }
    if (data.ref) {
        variables = { ...variables, data: { ...variables.data, ref: data.ref } };
    }
    if (data.comment) {
        variables = { ...variables, data: { ...variables.data, comment: data.comment } };
    }
    if (data.branchId) {
        variables = {
            ...variables, data: {
                ...variables.data,
                branch: {
                    connect: {
                        id: data.branchId
                    }
                }
            }
        };
    }
    return variables;
}

export const update_ordershipment_price = gql`
mutation UpdateOrderShipmentPrice($data: OrderShipmentPriceUpdateInput!, $where: OrderShipmentPriceWhereUniqueInput!) {
    updateOrderShipmentPrice(data: $data, where: $where) {
      id
    }
  }
`
export const getUpdateOrderShipmentPriceVariables = (id: string, price: string | number, active: boolean) => ({
    "data": {
        "price": {
            "set": price + ""
        },
        "active": {
            "set": active
        }
    },
    "where": {
        id
    }
});

export const set_ninjavan_price = gql`
mutation UpsertOrderShipmentPrice($where: OrderShipmentPriceWhereUniqueInput!, $create: OrderShipmentPriceCreateInput!, $update: OrderShipmentPriceUpdateInput!) {
  upsertOrderShipmentPrice(where: $where, create: $create, update: $update) {
    id
  }
}
`;

export const set_arlupost_price = gql`
mutation UpsertOrderShipmentPrice($where: OrderShipmentPriceWhereUniqueInput!, $create: OrderShipmentPriceCreateInput!, $update: OrderShipmentPriceUpdateInput!) {
  upsertOrderShipmentPrice(where: $where, create: $create, update: $update) {
    id
  }
}
`;

export const getSetNinjavanPriceVaraiables = (data: { from: string, to: string, price: string | number }) => {
    const { from, to, price } = data;
    const shipmentId = `${from}_${to}`;
    const agent = "NINJAVAN"
    let variables: any = {}, create: any = {};
    variables.where = {
        "shipment_id_shipment_agent": {
            "shipment_id": shipmentId,
            "shipment_agent": agent
        }
    }
    create = {
        "shipment_id": shipmentId,
        "shipment_agent": agent,
        "from_address_type": "STATEREGION",
        "to_address_type": "TOWNSHIP",
        "price": `${price}`,
        "active": true,
        "from_region": {
            "connect": {
                "sr_pcode": from
            }
        },
        "to_township": {
            "connect": {
                "tsp_pcode": to
            }
        }
    }
    variables.create = create;
    variables.update = {}
    return variables;
}

export const getSetArLuPostPriceVaraiables = (data: { from: string, to: string, price: string | number }) => {
    const { from, to, price } = data;
    const shipmentId = `${from}_${to}`;
    const agent = "ARLUPOST"
    let variables: any = {}, create: any = {};
    variables.where = {
        "shipment_id_shipment_agent": {
            "shipment_id": shipmentId,
            "shipment_agent": agent
        }
    }
    create = {
        "shipment_id": shipmentId,
        "shipment_agent": agent,
        "from_address_type": "STATEREGION",
        "to_address_type": "TOWNSHIP",
        "price": `${price}`,
        "active": true,
        "from_region": {
            "connect": {
                "sr_pcode": from
            }
        },
        "to_township": {
            "connect": {
                "tsp_pcode": to
            }
        }
    }
    variables.create = create;
    variables.update = {}
    return variables;
}
export const delete_many_shipping_fee_rate = gql`
mutation DeleteManyShippingFeeRate($where: ShippingFeeRateWhereInput) {
    deleteManyShippingFeeRate(where: $where) {
      count
    }
  }
`;

export const getDeleteSFRByIdVar = (shippingFeeId: string) => ({
    where: { shipping_fee_id: { equals: shippingFeeId } }
});
export const delete_one_shipping_fee = gql`
mutation DeleteShippingFee($where: ShippingFeeWhereUniqueInput!) {
    deleteShippingFee(where: $where) {
      id
    }
  }
`;
export const getDeleteSFVar = (id: string) => ({ where: { id } });

export const create_one_shipping_fee = gql`
mutation CreateShippingFee($data: ShippingFeeCreateInput!) {
    createShippingFee(data: $data) {
      id
    }
  }
`

export const update_one_shipping_fee = gql`
mutation UpdateShippingFee($where: ShippingFeeWhereUniqueInput!, $data: ShippingFeeUpdateInput!) {
    updateShippingFee(where: $where, data: $data) {
      id
    }
  }
`;

export const update_new_psi = gql`
mutation UpdateNewProductStockItem(
    $data: ProductStockItemUpdateInput!
    $where: ProductStockItemLogWhereUniqueInput!
    $merchantUserId: String!
  ) {
    updateNewProductStockItem(
      data: $data
      where: $where
      merchant_user_id: $merchantUserId
    ) {
      id
    }
  }  
`;


export const getUpadteNewPSIVar = () => {
    return {}
}