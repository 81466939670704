import { invoice_url } from '../constants/api';
import { Order } from '../store';
import { format } from "date-fns";
import axios from 'axios';
interface InvoiceInfo { invoiceLogo: any | null, invoiceNote: any | null }
const makeRequestData = (o: Order, info: InvoiceInfo) => {
    let merchantId = "", businessName = "", businessAddress = "", businessPhone = "", businessWebsite = "", businessPhoto = ""
    if (o.merchant) {
        merchantId = o.merchant.id
        businessName = o.merchant.name || "";
        businessAddress = o.merchant.address || "";
        businessPhone = o.merchant.phone || "";
        if (o.merchant?.image) businessPhoto = o.merchant.image;
        if (o.merchant.urls && o.merchant.urls.length > 0) {
            businessWebsite = `https://${o.merchant.urls[0].url}`;
        }
    }
    if (info.invoiceLogo) businessPhoto = info.invoiceLogo;
    let customerName = "", customerPhone = "", customerAddress = "", customerShippingAddress = "", customerNote = "";
    if (o.merchant_customer && o.merchant_customer.customer) {
        let _addresses: any[] = [];
        const c = o.merchant_customer.customer;
        customerName = c.name;
        customerPhone = c.contact_number;
        if (o.shipping_address) _addresses.push(o.shipping_address);
        if (o.shipping_township) _addresses.push(o.shipping_township);
        if (o.shipping_region) _addresses.push(o.shipping_region);
        customerAddress = _addresses.join(',')
        customerShippingAddress = o.shipping_fee_name || "";
        customerNote = o.customer_note || ""
    }
    const displayOrderId = o.order_number || o.id, orderDocId = o.id, orderDate = format(new Date(o.updated_at || o.created_at), 'yyyy-MMM-dd');
    const totalAmount = o.grand_total, totalNetAmount = o.total_price, totalTaxAmount = o.tax, shippingAmount = o.shipping_fee_price || 0;
    const discountAmount = Number(o.discount || 0) + Number(o.point_discount || 0) + Number(o.cupon_discount || 0);
    let totalQty = 0;
    let discountLabel = null;
    if (o.point_discount > 0) {
        discountLabel = "point"
    }
    if (o.cupon_code) {
        discountLabel = discountLabel ? `${discountLabel},${o.cupon_code}` : o.cupon_code
    }
    let items: Array<{ name: string, qty: number, price: number }> = [];
    o.order_items.forEach(oi => {
        let name = oi.stock_item?.name || oi.quick_order_item_name || "";
        if (oi.stock_item && oi.stock_item.product) {
            name = `${oi.stock_item.product.name}(${name})`
        }
        const qty = oi.count;
        const price = Number(oi.price) / Number(oi.count);
        totalQty += qty;
        items.push({ name, qty, price });
    });
    return {
        merchant: {
            merchantId,
            businessName,
            businessAddress,
            businessPhone,
            businessWebsite,
            businessPhoto,
            invoiceNote: info.invoiceNote || ""
        },
        customer: {
            customerName,
            customerAddress,
            customerPhone,
            customerShippingAddress,
            customerNote,
        },
        order: {
            displayOrderId,
            orderDocId,
            totalAmount,
            discountAmount,
            discountLabel,
            orderDate,
            totalNetAmount,
            totalTaxAmount,
            totalQty,
            shippingAmount,
            items
        }
    }
}

export const generateInvoice = (order: Order, invoiceInfo: InvoiceInfo) => {
    axios(invoice_url,
        {
            method: 'post',
            responseType: "blob",
            headers: { ["Content-Type"]: "application/json" },
            data: JSON.stringify(makeRequestData(order, invoiceInfo))
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${order.order_number}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
}