import { useEffect, useState } from "react";
import DeliveryButton from "./delivery_button";
import { Modal } from "antd";
import { Row } from "antd";
import { Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { Order } from "../store";
import { Card } from "antd";
import * as yup from "yup";
import { Formik, Field, Form as FormikForm } from "formik";
import {
  checkDeliveryStatus,
} from "../api/dee_delivery";
import { Button } from "antd";
import { Timeline } from "antd";
import { utcDateToZonedDate } from "../helpers/util";
import Input from "antd/lib/input/Input";
import { sendArlupostDelivery } from "../api/arlupost_delivery";
import { useQuery } from "@apollo/client";
import {
  getOrderDeliveryShipmentVariables,
  order_delivery_shipments,
} from "../gql/gql_query";
import { HistoryOutlined, ReloadOutlined } from "@ant-design/icons";

interface P {
  merchantId: string;
  orderNo: string;
  shipmentId: string;
  customerAddress?: string;
  orderId: string;
  order: Order;
}
const shipFormData = {
  customerAddress: "",
  customerNote: "",
  customerName: "",
  customerPhone: "",
  customerCity: "",
  customerState: "",
  amount: "",
};
const shipSchema = yup.object().shape({
  customerAddress: yup.string().required("Customer address  is required"),
  amount: yup.string().required("Amount  is required"),
});
const ArlupostDelivery = (props: P) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [trackNo, setTrackNo] = useState<any>(null);
  const [activeKey, setActiveKey] = useState("1");
  const [formData, setFormDat] = useState(
    props.customerAddress
      ? Object.assign(shipFormData, {
        customerAddress: props.customerAddress,
        customerNote: props.order.customer_note,
        customerName: props?.order?.merchant_customer?.name,
        customerPhone:
          props?.order?.merchant_customer?.customer?.contact_number,
        customerCity: props?.order?.shipping_township,
        customerState: props?.order?.shipping_region,
        amount: props?.order?.grand_total,
      })
      : shipFormData
  );

  const {
    loading: shipLoading,
    error: shipError,
    data: shipData,
    refetch
  } = useQuery(order_delivery_shipments, {
    variables: getOrderDeliveryShipmentVariables(props.orderId),
  });

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => { };
  const handleCancel = () => {
    setVisible(false);
  };
  useEffect(() => {
    if (shipData && shipData.orderShipments && shipData.orderShipments.length > 0) {
      setActiveKey('2')
    }
  }, [shipData])
  const handleToShip = async (data: any) => {
    const payload = data as {
      customerAddress: string;
      customerNote: string;
      customerName: string;
      customerPhone: string;
      customerCity: string;
      customerState: string;
      amount: string;
    };
    const shipInfo: any = {
      ...payload,
      merchantId: props.merchantId,
      orderId: props.orderId,
      customerName:
        props.order.merchant_customer?.name ||
        props.order.merchant_customer?.customer?.name ||
        "",
      customerPhone: props.order.merchant_customer?.customer?.contact_number!,
      customerAddress: props?.order?.shipping_address,
      customerCity: props?.order?.shipping_township,
      customerState: props?.order?.shipping_region,
      amount: props?.order?.grand_total,
    };
    setLoading(true);
    // console.log(shipInfo, "ship info");
    await sendArlupostDelivery(shipInfo);
    setActiveKey("2");
    setLoading(false);
  };


  const onChange = (tabKey: any) => {
    setActiveKey(`${tabKey}`);
  };
  const Shipping = () => {
    return (
      <Formik
        initialValues={formData}
        validationSchema={shipSchema}
        enableReinitialize={true}
        onSubmit={handleToShip}>
        {({
          dirty,
          values,
          isValid,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => {
          console.log(values, "arlu values");
          return (
            <FormikForm autoComplete="off">
              <div style={{ display: "grid" }}>
                <label htmlFor="name">Customer Name</label>
                <h3>{values.customerName}</h3>
                <br />
              </div>
              <div style={{ display: "grid" }}>
                <label htmlFor="phone">Customer Phone</label>
                <h3>{values.customerPhone}</h3>
                <br />
              </div>
              <div style={{ display: "grid" }}>
                <label htmlFor="email">Amount</label>
                <Input
                  type="number"
                  contentEditable
                  defaultValue={values.amount}
                />
                {/* <ErrorMessage
                  name="amount"
                  component="div"
                  className="field-error"
                  render={(error) => (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                /> */}
                <br />
              </div>
              <div style={{ display: "grid" }}>
                <label htmlFor="email">Customer Address</label>
                <Field
                  id="customerAddress"
                  name="customerAddress"
                  component="textarea"
                  rows="3"
                  value={values.customerAddress}
                />
                {/* <ErrorMessage
                  name="customerAddress"
                  component="div"
                  className="field-error"
                  render={(error) => (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                /> */}
                <br />
              </div>
              <div style={{ display: "grid" }}>
                <label htmlFor="email">Customer Note</label>
                <Field
                  id="customerNote"
                  name="customerNote"
                  component="textarea"
                  rows="3"
                  value={values.customerNote}
                />
                {/* <ErrorMessage
                  name="customerNote"
                  component="div"
                  className="field-error"
                  render={(error) => (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                /> */}
                <br />
              </div>
              <Button disabled={!dirty} type="primary" htmlType="submit">
                Send To Ship
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <>
      <DeliveryButton
        loading={loading}
        onClick={() => {
          showModal();
        }}
      />
      <Modal
        destroyOnClose={true}
        zIndex={500}
        visible={visible}
        title={
          trackNo
            ? `ARLUPOST Delivery(OrderNo:${props.orderNo},TrackNo:${trackNo})`
            : `ARLUPOST Delivery(OrderNo:${props.orderNo})`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}>
        {(loading || shipLoading) && <Card loading={true} />}
        {!loading && (
          <Row justify="center">
            <Tabs size="large" defaultActiveKey={activeKey} onChange={onChange}>
              <TabPane tab="Shipping" key="1" disabled={(shipData?.orderShipments || []).length > 0}>
                <Shipping />
              </TabPane>
              <TabPane tab="History" key="2">
                <Timeline>
                  {shipData?.orderShipments
                    ?.reverse()
                    ?.map((item: any, i: any) => (
                      <>
                        <Timeline.Item>
                          {item && item.status && <p>{item.status}</p>}
                          {item && item.comment && <p>{item.comment}</p>}
                          {item && item?.updated_at && (
                            <p>
                              {utcDateToZonedDate(item.updated_at).toString()}
                            </p>
                          )}
                        </Timeline.Item>
                      </>
                    ))}
                </Timeline>
                <Button icon={<ReloadOutlined />} onClick={async () => {
                  await refetch();
                }}>Refresh</Button>
              </TabPane>
            </Tabs>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default ArlupostDelivery;
