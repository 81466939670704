import React, { useState } from 'react'
import { Table, Row, Col, Typography, Card, Badge, Tag } from "antd";
import LoadingSpinner from '../../components/loading_spinner';
import { useQuery } from '@apollo/client';
import { get_order_variables, get_orders } from "../../gql/gql_query";
import withUser from '../../hocs/with_user';
import { subDays } from "date-fns";
import { AuthState, Order } from '../../store';
import DateRange from '../../components/date_range';
import { format, getWeekOfMonth } from "date-fns";
import { formatMoney } from '../../helpers/util';
import RecentOrder from '../../pages/order/recent_list';
import * as _ from 'lodash';
import ExportButton from '../../components/export_button';
import { ExportExcel } from '../../services/xlsx_service';
import { paths } from "../../routes/paths";
import { Link } from 'react-router-dom';
interface P {
    user: AuthState
}
type reportType = "daily" | "weekly" | "monthly"
const SaleReport = (props: P) => {
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: new Date() })
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const { loading, error, data } = useQuery(get_orders, { variables: get_order_variables(merchantId, ranges.startDate, ranges.endDate), fetchPolicy: 'network-only' });
    const onSelect = async (startDate: Date, endDate: Date) => {
        setRanges({ startDate, endDate });
        //await refetch(); no need refresh again , auto load with state change
    }
    const paymentAndChannelData = () => {
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        orders = orders.filter(ov => ov.order_status === 'COMPLETE');
        let cashPos = 0, cashWeb = 0, cashOther = 0;
        let cashAmountPos = 0, cashAmountWeb = 0, cashAmountOther = 0;
        let bankPos = 0, bankWeb = 0, bankOther = 0;
        let bankAmountPos = 0, bankAmountWeb = 0, bankAmountOther = 0;
        let otherPos = 0, otherWeb = 0, otherToOther = 0, otherAmount = 0;
        let otherAmountPos = 0, otherAmountWeb = 0, otherAmountOther = 0;
        orders.forEach(or => {
            if (or.sale_channel === 'WEB') {
                if (or.payment_method === 'CASH') {
                    cashWeb += 1;
                    cashAmountWeb += Number(or.grand_total);
                } else if (or.payment_method !== 'OTHER') {
                    bankWeb += 1;
                    bankAmountWeb += Number(or.grand_total);
                } else {
                    otherWeb += 1;
                    otherAmountWeb += Number(or.grand_total);
                }
            }
            if (or.sale_channel === 'POS') {
                if (or.payment_method === 'CASH') {
                    cashPos += 1;
                    cashAmountPos += Number(or.grand_total);
                } else if (or.payment_method !== 'OTHER') {
                    bankPos += 1;
                    bankAmountPos += Number(or.grand_total);
                } else {
                    otherPos += 1;
                    otherAmountPos += Number(or.grand_total);
                }
            }
            if (or.sale_channel === 'OTHER') {
                if (or.payment_method === 'CASH') {
                    cashOther += 1;
                    cashAmountOther += Number(or.grand_total);
                } else if (or.payment_method !== 'OTHER') {
                    bankOther += 1;
                    bankAmountOther += Number(or.grand_total);
                } else {
                    otherToOther += 1;
                    otherAmountOther += Number(or.grand_total);
                }
            }
        });
        const totalBankAmount = bankAmountPos + bankAmountWeb + bankAmountOther;
        const totalCashAmount = cashAmountPos + cashAmountWeb + cashAmountOther;
        const totalOtherAmount = otherAmountPos + otherAmountWeb + otherAmountOther;

        const totalBankCount = bankPos + bankWeb + bankOther;
        const totalCashCount = cashPos + cashWeb + cashOther;
        const totalOtherCount = otherPos + otherWeb + otherToOther;
        const payments = [
            { status: "Bank", amount: totalBankAmount, count: totalBankCount },
            { status: "Cash", amount: totalCashAmount, count: totalCashCount },
            //  { status: "Other", amount: totalOtherAmount, count: totalOtherCount }
        ]

        const totalWebChannelAmount = cashAmountWeb + bankAmountWeb + otherAmountWeb;
        const totalPOSChannelAmount = cashAmountPos + bankAmountPos + otherAmountPos;
        const totalOtherChannelAmount = cashAmountOther + bankAmountOther + otherAmountOther;

        const totalWebChannelCount = cashWeb + bankWeb + otherWeb;
        const totalPOSChannelCount = cashPos + bankPos + otherPos;
        const totalOtherChannelCount = otherPos + bankOther + otherToOther;

        const channels = [
            { status: "POS", amount: totalPOSChannelAmount, count: totalPOSChannelCount },
            { status: "Online", amount: totalWebChannelAmount, count: totalWebChannelCount },
            // { status: "Other", amount: totalOtherChannelAmount, count: totalOtherChannelCount }
        ]

        return { payments, channels }
    }
    const groupDays = (orders: Order[] = []) => {
        let days: any[] = [];
        orders = orders.map(or => {
            const day = format(new Date(or.created_at), 'yyyy-MMM-dd ')
            return {
                ...or,
                filter: day
            }
        });
        const dayGroups = _.groupBy(orders, "filter");
        Object.keys(dayGroups).forEach(day => {
            const date = day;
            let totalSale = 0, growSale = 0, totalTax = 0;
            dayGroups[day].forEach(or => {
                if (or.order_status === "COMPLETE") {
                    totalTax += Number(or.tax);
                    totalSale += Number(or.grand_total);
                }
                if (or.order_status === "PENDING") growSale += Number(or.grand_total);
                if (or.order_status === "PAID") growSale += Number(or.grand_total);
            });
            days.push({ date, totalSale, growSale: growSale + totalSale, totalTax });
        });
        return _.sortBy(days, 'date', 'desc')
    }
    const allChannel = () => {
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        let pCount = 0, pAmount = 0, psCount = 0, psAmount = 0, cCount = 0, cAmount = 0, caCount = 0, caAmount = 0;
        orders.forEach(or => {
            if (or.order_status === "ACCEPT") {
                psCount += 1;
                psAmount += Number(or.grand_total);
            }
            if (or.order_status === "PAID") {
                psCount += 1;
                psAmount += Number(or.grand_total);
            }
            if (or.order_status === 'PENDING') {
                pCount += 1;
                pAmount += Number(or.grand_total);
            }
            if (or.order_status === 'COMPLETE') {
                cCount += 1;
                cAmount += Number(or.grand_total);
            }
            if (or.order_status === 'CUSTOMER_CANCEL') {
                caCount += 1;
                caAmount += Number(or.grand_total);
            }
            if (or.order_status === "MERCHANT_CANCEL") {
                caCount += 1;
                caAmount += Number(or.grand_total);
            }
        });
        return [
            { status: 'pending', count: pCount, amount: pAmount },
            { status: 'complete', count: cCount, amount: cAmount },
            { status: 'progress', count: psCount, amount: psAmount },
            { status: 'cancel', count: caCount, amount: caAmount },
        ];
    }
    const groupMoths = (orders: Order[] = []) => {
        let days: any[] = [];
        orders = orders.map(or => {
            const month = format(new Date(or.created_at), 'yyyy-MMM ')
            return {
                ...or,
                filter: month
            }
        });
        const dayGroups = _.groupBy(orders, "filter");
        Object.keys(dayGroups).forEach(month => {
            const date = month;
            let totalSale = 0, growSale = 0, totalTax = 0;
            dayGroups[month].forEach(or => {
                if (or.order_status === "COMPLETE") {
                    totalTax += Number(or.tax);
                    totalSale += Number(or.grand_total);
                }
                if (or.order_status === "PENDING") growSale += Number(or.grand_total);
                if (or.order_status === "PAID") growSale += Number(or.grand_total);
            });
            days.push({ date, totalSale, growSale, totalTax });
        });
        return _.sortBy(days, 'date', 'desc')
    }
    const groupWeeks = (orders: Order[] = []) => {
        let days: any[] = [];
        orders = orders.map(or => {
            const month = format(new Date(or.created_at), 'yyyy-MMM ')
            const week = getWeekOfMonth(new Date(or.created_at));
            const monthWeek = `${month}(${week})`
            return {
                ...or,
                filter: monthWeek
            }
        });
        const dayGroups = _.groupBy(orders, "filter");
        Object.keys(dayGroups).forEach(mw => {
            const date = mw;
            let totalSale = 0, growSale = 0, totalTax = 0;
            dayGroups[mw].forEach(or => {
                if (or.order_status === "COMPLETE") {
                    totalTax += Number(or.tax);
                    totalSale += Number(or.grand_total);
                }
                if (or.order_status === "PENDING") growSale += Number(or.grand_total);
                if (or.order_status === "PAID") growSale += Number(or.grand_total);
            });
            days.push({ date, totalSale, growSale, totalTax });
        });
        return _.sortBy(days, 'date', 'desc')
    }
    const onExport = (exportType: reportType) => {
        let excelData: any[] = [], fileName: string = "sales";
        const orders = (data && data.orders) ? data.orders : [];
        fileName = format(ranges.startDate, 'yyyy-MMM-dd') + "_" + format(ranges.endDate, 'yyyy-MMM-dd');
        if (exportType === 'daily') {
            excelData = groupDays(orders);
            fileName = "daily_sales(" + fileName + ")";
        }
        if (exportType === 'weekly') {
            excelData = groupDays(orders);
            fileName = "weekly_sales(" + fileName + ")";
        }
        if (exportType === 'monthly') {
            excelData = groupDays(orders);
            fileName = "monthly_sales(" + fileName + ")";
        }
        ExportExcel(excelData, fileName);
    }
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a: { date: string }, b: { date: string }) => a.date.localeCompare(b.date),
        },
        {
            title: 'Net Sale',
            key: 'totalSale',
            sorter: (a: { totalSale: number }, b: { totalSale: number }) => a.totalSale - b.totalSale,
            render: (v: number, record: { totalSale: number }) => {
                return formatMoney(record.totalSale);
            },
        },
        {
            title: 'Total tax',
            key: 'totalTax',
            sorter: (a: { totalTax: number }, b: { totalTax: number }) => a.totalTax - b.totalTax,
            render: (v: number, record: { totalTax: number }) => {
                return formatMoney(record.totalTax);
            },
        },
        {
            title: 'Total Sale',
            dataIndex: 'growSale',
            key: 'growSale',
            sorter: (a: { growSale: number }, b: { growSale: number }) => a.growSale - b.growSale,
            render: (v: number, record: { growSale: number }) => {
                return formatMoney(record.growSale);
            },
        },
    ];

    const columns2 = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: { status: string }, b: { status: string }) => a.status.localeCompare(b.status),
            render: (v: number, record: { status: string }) => {
                let color = "orange";
                if (record.status === "progress") color = "lime";
                if (record.status === "complete") color = "green";
                if (record.status === "cancel") color = "red";
                return <Tag color={color}>{record.status}</Tag>;
            },
        },
        {
            title: 'Amount',
            key: 'amount',
            sorter: (a: { amount: number }, b: { amount: number }) => a.amount - b.amount,
            render: (v: number, record: { amount: number }) => {
                return formatMoney(record.amount) + " MMK";
            },
        },
        {
            title: 'Count',
            key: 'count',
            sorter: (a: { count: number }, b: { count: number }) => a.count - b.count,
            render: (v: number, record: { count: number }) => {
                return <Badge count={record.count} style={{ backgroundColor: "blue" }} />;
            },
        },

    ];

    const controlCard = () => (
        <Row>
            <Col flex={4}>
                <Row >
                    <Col span={12}><Typography level={2}> Sale Report </Typography></Col>
                    <Col span={12} ></Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
            </Col>
        </Row>
    );
    const dailySaleRow = () => {
        const rowData = paymentAndChannelData();
        return (
            <Row justify="space-between" style={{ marginTop: 5 }} gutter={24} >
                <Col span={12}>
                    <Card title="Payments" size="small" bordered={false}>
                        <Table
                            showHeader={false}
                            columns={columns2}
                            dataSource={rowData.payments}
                            pagination={{
                                pageSize: 4,
                                position: ['none',]
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card title="Channels" size="small" bordered={false} extra={<></>}>
                        <Table
                            showHeader={false}
                            columns={columns2}
                            dataSource={rowData.channels}
                            pagination={{
                                pageSize: 4,
                                position: ['none',]
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
    const historyRow = () => {
        return (
            <Row justify="space-between" gutter={24}>
                <Col span={12} style={{ marginTop: '3%' }}>
                    <Card size="small" title="Daily Sale Summary" bordered={false} style={styles.card}
                        extra={<ExportButton transparent={true} loading={loading} onClick={() => onExport('daily')} />}
                    >
                        <Table
                            style={{ background: "transparent" }}
                            columns={columns}
                            dataSource={groupDays((data && data.orders) ? data.orders : [])}
                            pagination={{
                                pageSize: 5,
                                position: ['bottomCenter',]
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12} style={{ marginTop: '3%' }}>
                    <Card size="small" title="Recent" bordered={false}
                        style={styles.card}
                        extra={
                            <Link to={paths.orders}>
                                <Typography.Link>
                                    Show more...
                                </Typography.Link>
                            </Link>
                        }
                    >
                        <RecentOrder />
                    </Card>

                </Col>
            </Row>
        );
    }
    return (
        <React.Fragment>
            {loading && <LoadingSpinner />}
            {(!loading && !error) &&
                <>
                    {controlCard()}
                    {/* <Row justify="space-between" style={{ marginTop: 4 }}>
                        <Col span={12}>
                            <Card size="small" title="Monthly Sale" bordered={false} style={{ background: "transparent" }}
                                extra={<ExportButton transparent={true} loading={loading} onClick={() => onExport('monthly')} />}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={groupMoths((data && data.orders) ? data.orders : [])}
                                    pagination={{
                                        pageSize: 2,
                                        position: ['bottomCenter',]
                                    }}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card size="small" title="Weekely Sale" bordered={false} style={{ background: "transparent" }}
                                extra={<ExportButton transparent={true} loading={loading} onClick={() => onExport('weekly')} />}
                            >
                                <Table
                                    columns={columns}
                                    dataSource={groupWeeks((data && data.orders) ? data.orders : [])}
                                    pagination={{
                                        pageSize: 2,
                                        position: ['bottomCenter',]
                                    }}
                                />
                            </Card>

                        </Col>
                    </Row> */}
                    {dailySaleRow()}
                    {historyRow()}
                </>
            }
        </React.Fragment>
    )
}
const styles = {
    card: {
        height: "100%"
    }
}
export default withUser(SaleReport) as any;
