import React, { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { UserInfo, actions } from '../store';
import { connect } from "react-redux";
import { paths } from "../routes/paths";
import LoginForm from './login_form'
import { getMerchantData } from "../api/merchant_api";
import { availableCRM } from "../helpers/crm_helper";

interface P {
    loggedIn: (info: UserInfo) => void,
    setMerchantData: (data: any) => void
}
const Login = (props: P) => {
    const Navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const authListener = onAuthStateChanged(getAuth(),
            async (authUser) => {
                if (authUser) {
                    let path = paths.orders;
                    const token_result = await authUser.getIdTokenResult();
                    const { user_id, merchantId } = token_result.claims as { user_id: string, merchantId: string, role: string }
                    props.loggedIn({ token: token_result.token, userId: user_id, merchantId } as UserInfo)
                    if (location.search && (typeof location.search === 'string')) {
                        //custom route
                        const nextroutes = location.search.split('=');
                        if (nextroutes.length === 2) {
                            path = nextroutes[1];
                        }
                    }
                    Navigate(path, { replace: true, });
                }
            }
        );
        return authListener;

    }, [Navigate, props, location]);


    useEffect(() => {
        const authListener = onAuthStateChanged(getAuth(),
            async (authUser) => {
                if (authUser) {
                    const token_result = await authUser.getIdTokenResult();
                    const { merchantId } = token_result.claims as { user_id: string, merchantId: string, role: string }
                    if (merchantId && availableCRM()) {
                        getMerchantData(merchantId).then(res => {
                            if (res.success) {
                                props.setMerchantData(res.data)
                            }
                        })
                    }
                }
            }
        );
        return authListener;
    }, []);
    return (<LoginForm />);
}
const mapStateToDispatch = (dispatch: any) => {
    return {
        loggedIn: (info: UserInfo) => dispatch(actions.authAction.loggedIn(info)),
        setMerchantData: (data: any) => dispatch(actions.merchantAction.setMerchantData(data))
    }
}
export default connect(null, mapStateToDispatch)(Login) as any;
