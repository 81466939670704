import React, { useState, useEffect } from 'react';
import { ApolloResult, AuthState, ShippingFeeType, ShipppingFee, StateRegion, Township } from '../../store';
import withUser from '../../hocs/with_user';
import { Card, Form, Select, Input, Button, Space } from "antd";
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import type { FormInstance } from 'antd/es/form';
import MultiSelect from '../../components/multi_select';
import { getOneSFVar, getSFByTypeVar, get_one_shipping_fee, get_sf_by_type, get_states, get_townships } from '../../gql/gql_query';
import { create_one_shipping_fee, update_one_shipping_fee } from '../../gql/gql_mutation';
import { paths } from '../../routes/paths';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const { Option } = Select;
const { Search, TextArea } = Input;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
interface P {
    user: AuthState,
}
interface RateOption {
    id: string,
    name?: string,
    price: string | number,
    min_amount: string | number,
    max_amount: string | number,
}
interface FormData {
    name: string,
    price: number,
    type?: ShippingFeeType,
    townships?: string[],
    state_regions?: string[],
    fee_rates: RateOption[],
}


function ShippingFeeDetail(props: P) {
    const navigate = useNavigate();
    const { id: pSfId } = useParams() as { id: string }
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const [error, setError] = useState<string | null>(null)
    const [sfId, setSfId] = useState<string>(pSfId);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [supportType, setSupportType] = useState<ShippingFeeType>("SUPPORT_IN_ALL")
    const isNew = sfId === 'new';
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const { data: tspData, loading: tspLoading } = useQuery<ApolloResult<"townships", Township[]>>(get_townships)
    const { data: srData, loading: srLoading } = useQuery<ApolloResult<"stateRegions", StateRegion[]>>(get_states)
    const { data: chkData, loading: chkLoading } = useQuery<ApolloResult<"shippingFees", ShipppingFee[]>>(get_sf_by_type, { variables: getSFByTypeVar({ merchantId, type: supportType, ids: selectedIds }), fetchPolicy: 'network-only' });
    const [getSF, { loading, data, }] = useLazyQuery<ApolloResult<"shippingFee", ShipppingFee>>(get_one_shipping_fee, { fetchPolicy: 'network-only' })
    const [createSF, { loading: cLoading }] = useMutation<ApolloResult<"createShippingFee", ShipppingFee>>(create_one_shipping_fee);
    const [updateSF, { loading: uLoading }] = useMutation<ApolloResult<"updateShippingFee", ShipppingFee>>(update_one_shipping_fee);
    useEffect(() => {
        if (!isNew) {
            formRef.current?.resetFields();
            getSF({ variables: getOneSFVar(sfId) });
        }
    }, [isNew]);

    useEffect(() => {
        if (data) {
            const sf = data.shippingFee;
            let fData: FormData = {
                name: sf.name,
                type: sf.type,
                price: Number(sf.price),
                townships: sf.townships.map(t => t.tsp_pcode),
                state_regions: sf.state_regions.map(sr => sr.sr_pcode),
                fee_rates: sf.fee_rates.map(fr => ({ id: fr.id, name: fr.name, price: fr.price, min_amount: fr.min_amount, max_amount: fr.max_amount }))
            }
            formRef.current?.setFieldsValue({ ...fData });
            setSupportType(sf.type || "SUPPORT_IN_ALL");
            if (sf.type && sf.type !== 'SUPPORT_IN_ALL') {
                const ids = sf.type === 'SUPPORT_IN_SR' ? fData.state_regions : fData.townships;
                setSelectedIds(ids || []);
            }
        }
    }, [data]);
    const handleTypeChange = (t: ShippingFeeType) => {
        formRef.current?.setFieldsValue({ type: t });
        setSupportType(t);
    }
    const onSubmit = async (val: FormData) => {
        await isNew ? create(val) : update(val);
    }
    const create = async (val: FormData) => {
        let variables: any = {}, data: any = {};
        data.name = val.name;
        data.price = `${val.price}`
        data.type = val.type;
        data.merchant = { connect: { id: merchantId } }
        if (val.type === 'SUPPORT_IN_TSP') {
            data.townships = { connect: (val.townships || []).map(tsp_pcode => ({ tsp_pcode })) }
        }
        if (val.type === 'SUPPORT_IN_SR') {
            data.state_regions = { connect: (val.state_regions || []).map(sr_pcode => ({ sr_pcode })) }
        }
        if (val.fee_rates && val.fee_rates.length > 0) {
            data.fee_rates = {
                createMany: {
                    data: val.fee_rates.map(fr => ({
                        name: fr.name || null,
                        price: `${fr.price}`,
                        min_amount: `${fr.min_amount}`,
                        max_amount: `${fr.max_amount}`,
                    }))
                }
            }
        }
        variables.data = data;
        const r = await createSF({ variables })
        if (r.data && r.data.createShippingFee) {
            navigate(paths.shippingFees)
        } else {
            setError("failed")
        }
    }
    const update = async (val: FormData) => {
        let variables: any = {}, updateData: any = {};
        updateData.name = { set: val.name }
        updateData.price = { set: `${val.price}` }
        updateData.type = { set: val.type }
        const sf = data?.shippingFee!;
        if (val.type === 'SUPPORT_IN_ALL') {
            // remove unsupport tsp
            if (sf.townships.length > 0) {
                updateData.townships = { disconnect: sf.townships.map(tsp => ({ tsp_pcode: tsp.tsp_pcode })) };
            }
            // remove unsupport sr
            if (sf.state_regions.length > 0) {
                updateData.state_regions = { disconnect: sf.state_regions.map(sr => ({ sr_pcode: sr.sr_pcode })) }
            }
        }
        if (val.type === 'SUPPORT_IN_TSP') {
            // new tsp
            if (val.townships) {
                const _removes = sf.townships.filter(tsp => !val.townships?.includes(tsp.tsp_pcode))
                updateData.townships = {
                    disconnect: _removes.map(tsp => ({ tsp_pcode: tsp.tsp_pcode })),
                    connect: val.townships.map(tsp_pcode => ({ tsp_pcode }))
                };
            }
            // remove unsupport sr
            if (sf.state_regions.length > 0) {
                updateData.state_regions = { disconnect: sf.state_regions.map(sr => ({ sr_pcode: sr.sr_pcode })) }
            }
        }
        if (val.type === 'SUPPORT_IN_SR') {
            //remove unsupport townships
            if (sf.townships.length > 0) {
                updateData.townships = { disconnect: sf.townships.map(tsp => ({ tsp_pcode: tsp.tsp_pcode })) };
            }
            //connect new 
            if (val.state_regions) {
                const _removes = sf.state_regions.filter(sr => !val.state_regions?.includes(sr.sr_pcode))
                updateData.state_regions = {
                    disconnect: _removes.map(sr => ({ sr_pcode: sr.sr_pcode })),
                    connect: val.state_regions.map(sr_pcode => ({ sr_pcode }))
                }
            }
        }
        // rate update 
        let fee_rates: any | null = null;
        const _oldIds = sf.fee_rates.map(f => f.id);
        const _newIds = val.fee_rates.map(f => f.id);
        const _deletes = sf.fee_rates.filter(f => {
            return !_newIds.includes(f.id);
        })
        const _updates = val.fee_rates.filter(f => {
            return _oldIds.includes(f.id);
        });
        const _news = val.fee_rates.filter(f => f.id === 'new');
        if (_news.length > 0) {
            fee_rates = {
                createMany: {
                    data: _news.map(f => ({
                        name: f.name || null,
                        min_amount: `${f.min_amount}`,
                        max_amount: `${f.max_amount}`,
                        price: `${f.price}`
                    }))
                }
            }
        }
        if (_updates.length > 0) {
            fee_rates = fee_rates || {};
            fee_rates = {
                ...fee_rates || {},
                updateMany: _updates.map(f => ({
                    where: { id: { equals: f.id } },
                    data: {
                        name: { set: f.name || null },
                        min_amount: { set: `${f.min_amount}` },
                        max_amount: { set: `${f.max_amount}` },
                        price: { set: `${f.price}` }
                    }
                }))
            }
        }
        if (_deletes.length > 0) {
            fee_rates = fee_rates || {};
            fee_rates = { ...fee_rates, deleteMany: _deletes.map(f => ({ id: { equals: f.id } })) };
        }
        if (fee_rates) {
            updateData.fee_rates = fee_rates;
        }
        // console.log('deletes', _deletes, '\nupdates', _updates, '\n news', _news)
        variables.where = { id: sfId }
        variables.data = updateData;
        // console.log('update', variables)
        const r = await updateSF({ variables })
        if (r.data && r.data.updateShippingFee) {
            navigate(paths.shippingFees)
        } else {
            setError("failed")
        }
    }
    return (
        <Card
            loading={srLoading || tspLoading || loading}
            title={isNew ? 'Create New Shipping Fee' : "Edit Shipping Fee"}
        >
            <Form
                {...layout}
                ref={formRef}
                name="control-ref"
                onFinish={onSubmit}
                style={{ maxWidth: 600 }}
            >
                <Form.Item name="name" label="Name" rules={[{ required: true, message: "Name is requried" },]}>
                    <Input />
                </Form.Item>
                <Form.Item name="price" label="Price" rules={[{ required: true, message: "Price is requried" },]} >
                    <Input type="number" />
                </Form.Item>
                <Form.Item name="type" label="Support Type" rules={[{ required: true, message: "Please select transfer type" }]}>
                    <Select
                        key="type_select"
                        placeholder="select shipping type"
                        style={{}}
                        onChange={handleTypeChange}
                        options={[
                            { value: 'SUPPORT_IN_ALL', label: 'Available In All' },
                            { value: 'SUPPORT_IN_SR', label: 'Available In Regions/States' },
                            { value: 'SUPPORT_IN_TSP', label: 'Available In Townships' },
                        ]}
                    />
                </Form.Item>
                {
                    supportType === 'SUPPORT_IN_SR' &&
                    <Form.Item name="state_regions" label="Regions/States" rules={[{ required: true, message: "region/state is required" }]}>
                        <Spin spinning={chkLoading}>
                            <MultiSelect
                                options={(srData?.stateRegions || []).map(sr => ({ value: sr.sr_pcode, label: `${sr.sr_name_mmr}(${sr.sr_name_eng})` }))}
                                selected={selectedIds}
                                placeholder='select regions/states'
                                onChange={(vl) => {
                                    formRef.current?.setFieldsValue({ state_regions: vl });
                                    setSelectedIds(vl)
                                }}
                            />
                        </Spin>
                        {chkData &&
                            <span><ul>
                                {chkData.shippingFees.filter(sf => sf.id !== sfId).map((s, i) => {
                                    return s.state_regions.filter(sr0 => selectedIds.includes(sr0.sr_pcode)).map(sr => (<li style={{ color: 'blueviolet' }} key={`${s.id}_${sr.sr_pcode}`}>({sr.sr_name_mmr}) already exist in ({s.name})</li>));
                                })}
                            </ul></span>
                        }

                    </Form.Item>
                }
                {
                    supportType === 'SUPPORT_IN_TSP' &&
                    <Form.Item name="townships" label="Townships" rules={[{ required: true, message: "townships is required" }]}>
                        <Spin spinning={chkLoading}>
                            <MultiSelect
                                options={(tspData?.townships || []).map(tsp => ({ value: tsp.tsp_pcode, label: `${tsp.township_name_mmr}(${tsp.sr.sr_name_mmr})` }))}
                                selected={selectedIds}
                                placeholder='select support townships'
                                onChange={(val) => {
                                    formRef.current?.setFieldsValue({ townships: val });
                                    setSelectedIds(val)
                                }}
                            />
                        </Spin>
                        {chkData &&
                            <span><ul>
                                {chkData.shippingFees.filter(sf => sf.id !== sfId).map((s, i) => {
                                    return s.townships.filter(t1 => selectedIds.includes(t1.tsp_pcode)).map(t2 => (<li style={{ color: 'blueviolet' }} key={`${s.id}_${t2.tsp_pcode}`}>({t2.township_name_mmr}) already exist in ({s.name})</li>));
                                })}
                            </ul></span>
                        }
                    </Form.Item>
                }
                <Form.Item {...tailLayout}>

                    <Form.List name="fee_rates" {...layout} style={{ marginLeft: 35 }}>
                        {(fields: any, { add, remove }: any) => (
                            <>
                                {fields.map(({ key, name, ...restField }: any) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            label="id"
                                            name={[name, 'id']}
                                            style={{ display: 'none' }}
                                        //  rules={[{ required: true, message: 'Missing first name' }]}
                                        >
                                            <Input placeholder="id" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Shipping Name"
                                            name={[name, 'name']}
                                        //  rules={[{ required: true, message: 'Missing first name' }]}
                                        >
                                            <Input placeholder="name" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Shipping Price"
                                            name={[name, 'price']}
                                            rules={[{ required: true, message: 'price is required' }]}
                                        >
                                            <Input placeholder="price" type="number" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Min Purchased Amount"
                                            name={[name, 'min_amount']}
                                            rules={[{ required: true, message: 'min amount is required' }]}
                                        >
                                            <Input placeholder="min amount" type="number" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            label="Max Purchased Amount"
                                            name={[name, 'max_amount']}
                                            rules={[{ required: true, message: 'max amount is required' }]}
                                        >
                                            <Input placeholder="max amount" type="number" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add({ id: "new" })} block icon={<PlusCircleOutlined />}>
                                        Add Rate
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit" loading={cLoading || uLoading} disabled={error}>
                        {isNew ? "create" : "save"}
                    </Button>
                </Form.Item>
                {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
            </Form>
        </Card>
    )
}

export default withUser(ShippingFeeDetail)