import React, { useEffect, useState } from 'react'
import { PointHistory, Member } from "../state";
import { Badge, Table, Card, Avatar } from "antd";
import { format, addMonths } from "date-fns";
import { getRecentMember } from "../../api/member_api";
import { Link } from 'react-router-dom';
import { paths } from '../../routes/paths';
import defaultUser from '../../assets/image/user.png';
interface P {
    merchantId: string
}
type S = { status: "loading" } | { status: "loaded", data: Member[] }
export default function RecentHistory(props: P) {
    const [state, setState] = useState<S>({ status: 'loading' });
    useEffect(() => {
        if (props.merchantId) {
            getRecentMember({ merchantId: props.merchantId }).then(res => {
                if (res.success) {
                    setState({ status: 'loaded', data: res.data || [] })
                }
            })
        }
    }, [props])
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'image',
            render: (v: number, record: Member) => < Avatar src={record.photo || defaultUser} size={45} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (v: number, record: Member) => {
                const val = record.displayName || record.phone || ""
                return val;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            render: (v: number, record: Member) => {
                const val = record.phone || ""
                return val;
            },
        },
        {
            title: 'Current Point',
            dataIndex: 'memberPoint',
            render: (v: number, record: Member) => {
                const val = record.memberPoint || 0
                return val;
            },
        },
        {
            title: 'Total Point',
            dataIndex: 'totalPoint',
            render: (v: number, record: Member) => {
                const val = record.totalPoint || 0
                return val;
            },
        },
        {
            title: 'Engagement',
            dataIndex: 'pF',
            render: (v: number, record: Member) => {
                const val = record.pF || 0
                return val;
            },
        },
    ];
    return (
        <React.Fragment>
            {state.status === 'loading' && <Card loading={true} />}
            {state.status === 'loaded' &&
                <Card
                    title="Recent"
                    style={styles.card}
                    extra={
                        <Link to={paths.members}>
                            Show more...
                        </Link>
                    }>
                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={(state.status === 'loaded' ? state.data : []).map((row, i) => { return { ...row, key: i + 1 } })}
                        pagination={{
                            pageSize: 6,
                            position: ['none',]
                        }}
                    />
                </Card>

            }
        </React.Fragment>
    )
}
const styles = {
    card: {
        height: "100%"
    }
}