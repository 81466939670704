import axios from "axios";
const base_url = "https://deeedelivery2-judrztptdq-uc.a.run.app/api"

export const sendDeeDelivery = async (data: {
    shipmentId: string;
    merchantId: string;
    orderId: string;
    cashOnDelivery: boolean;
    cashOnDeliveryAmount?: number;
    itemAmount?: number;
    customerAddress: string;
    customerPhone: string,
    customerName: string,
}) => {
    const uri = base_url + '/createOrder';
    const APIOptions = {
        method: "post",
        headers: {
            ["Content-Type"]: "application/json",
            ["Authorization"]: `ff5e8581-f243-4d73-b55f-0e199a722e29`
        },
        data: JSON.stringify({
            ...data
        })
    } as any;
    const res = await axios(uri, APIOptions);
    if (res.status === 200) {
        const { success, code } = res.data as { success: boolean, code?: string }
        return { success, code }
    }
    return { success: false, code: 'bad-request' }
}

export const checkDeliveryStatus = async (trakingId: string) => {
    const uri = base_url + `/trackOrder/${trakingId}`;
    const APIOptions = {
        method: "get",
        headers: {
            ["Content-Type"]: "application/json",
            ["Authorization"]: `ff5e8581-f243-4d73-b55f-0e199a722e29`
        },

    } as any;
    const res = await axios(uri, APIOptions);
    if (res.status === 200) {
        const d = res.data as { success: boolean, code?: string, Status?: string }
        return { ...d }
    }
    return { success: false, code: 'bad-request' }
}

export const generateWaybill = async (data: { merchantId: string, orderId: string, trackingId: string }) => {
    const uri = base_url + `/waybill`
    axios(uri,
        {
            method: 'post',
            responseType: "blob",
            headers: { ["Content-Type"]: "application/json", ["Authorization"]: `${data.merchantId},ff5e8581-f243-4d73-b55f-0e199a722e29` },
            data: JSON.stringify({ ...data })
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data.trackingId}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
}

type sentRequest ={
    type: "cancelOrder",
    orderId: string,
    merchantId: string
    trackingNo: string,
}

export const sendDeeDeliveryCancel = async (data: sentRequest) => {
    const { type } = data;
    let url = base_url + `/${type}`;

    const options = {
        method: "post",
        headers: { ["Content-Type"]: "application/json", ["Authorization"]: "ff5e8581-f243-4d73-b55f-0e199a722e29" },
        data: JSON.stringify({
            ...data
        })
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { success } = res.data as { success: boolean }
        if (success) {

            return { success: true, data: data || [] }
        }
        return { success: false, msg: "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}