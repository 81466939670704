import { Button, Modal, Select, Row, Tag, Col } from "antd";
import React, { useState, useEffect } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { MerchantUser } from "../store";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  get_states,
  get_townships,
  getStateVariables,
  getTownshipVariables,
} from "../gql/gql_query";
import {
  set_arlupost_price,
  getSetArLuPostPriceVaraiables,
} from "../gql/gql_mutation";
import { InputNumber, Input } from "antd";
const { Option } = Select;
interface P {
  onFinish: () => void;
}

const CreateArLuPostPrice: React.FC<P> = (props: P) => {
  const [visible, setVisible] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [price, setPrice] = useState(0);
  const [
    getStates,
    { loading: stateLoading, error: stateError, data: stateData },
  ] = useLazyQuery(get_states);
  const [
    getTownships,
    { loading: tspLoading, error: tspError, data: tspData },
  ] = useLazyQuery(get_townships);
  const [createPrice, { loading, data, error }] =
    useMutation(set_arlupost_price);
  useEffect(() => {
    getStates({ variables: getStateVariables() });
    getTownships({ variables: getTownshipVariables("") });
  }, []);
  useEffect(() => {
    if (data) {
      setVisible(false);
      props.onFinish();
    }
  }, [data]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    // setLoading(true);
    // console.log("arlupost create");
    if (from.length <= 0 || to.length <= 0) return;
    const val = { from, to, price };
    const variables = getSetArLuPostPriceVaraiables(val);
    // console.log("var", variables);
    await createPrice({ variables });
  };

  const handleCancel = () => {
    setTo("");
    setFrom("");
    setPrice(0);
    setVisible(false);
  };
  return (
    <>
      <Button type="primary" icon={<PlusCircleOutlined />} onClick={showModal}>
        add
      </Button>
      <Modal
        visible={visible}
        title={"Create Ninjavan Price"}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}>
            create
          </Button>,
        ]}>
        <Row>
          <Col>
            <Typography style={{ marginTop: 4 }}>From</Typography>
            <Select
              showSearch
              loading={stateLoading}
              style={{ width: 250, marginLeft: 5 }}
              placeholder="select state/region"
              optionFilterProp="children"
              defaultValue={from}
              onChange={(val: any) => setFrom(val)}>
              {((stateData && stateData["stateRegions"]) || []).map(
                (s: any) => (
                  <Option value={s.sr_pcode}>
                    <Typography />
                    {s.sr_name_mmr}
                    <Typography />
                  </Option>
                )
              )}
            </Select>
          </Col>
          <Col>
            <Typography style={{ marginTop: 4 }}>To</Typography>
            <Select
              showSearch
              loading={tspLoading}
              style={{ width: 250, marginLeft: 5 }}
              placeholder="select township"
              optionFilterProp="children"
              defaultValue={to}
              onChange={(val: any) => setTo(val)}>
              {((tspData && tspData["townships"]) || []).map((s: any) => (
                <Option value={s.tsp_pcode}>
                  <Typography />
                  {s.township_name_mmr}
                  {s.sr?.sr_name_mmr && `(${s.sr?.sr_name_mmr})`}
                  <Typography />
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Typography style={{ marginTop: 4 }}>Price</Typography>
            <Input
              type="number"
              style={{ width: 250, marginLeft: 5 }}
              onChange={(e: any) => setPrice(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default CreateArLuPostPrice;
