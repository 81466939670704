import axios from "axios";
const base_url = "https://asia-southeast1-piti-commerce.cloudfunctions.net/apiv1"

export const sendPayPoint = async (data: {
    merchantId: string,
    memberIdOrPhone: string,
    orderId: string
    apiKey: string,
    point: number,
    ref: string,
}) => {
    const uri = base_url + '/v1/payPoint';
    const APIOptions = {
        method: "post",
        headers: {
            ["Content-Type"]: "application/json",
            ["Authorization"]: `${data.merchantId},${data.apiKey}`
        },
        data: JSON.stringify({
            point: data.point,
            type: "earn",
            ref: data.ref,
            memberIdOrPhone: data.memberIdOrPhone,
            orderId: data.orderId
        })
    } as any;
    const res = await axios(uri, APIOptions);
    if (res.status === 200) {
        const { success, code } = res.data as { success: boolean, code?: string }
        return { success, code }
    }
    return { success: false, code: 'bad-request' }
}