import React from 'react'
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ExportIcon } from '../assets/icons/svg_icons'

interface P {
    loading: boolean;
    transparent?: boolean;
    label?: string;
    onClick: () => void;
}

export default function ExportButton(props: P) {
    return <Button disabled={props.loading} style={{ marginLeft: 3, backgroundColor: props.transparent ? 'transparent' : 'none' }} icon={props.loading ? <LoadingOutlined /> : <ExportIcon />} onClick={props.onClick}  >{props.loading ? 'exporting...' : props.label || 'excel export'} </Button>
}
