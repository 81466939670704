import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const formatMoney = (
    amount: number,
    decimalCount = 2,
    decimal = '.',
    thousands = ',',
) => {
    if (!amount || amount === 0) {
        return '0';
    }
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
        const negativeSign = amount < 0 ? '-' : '';
        amount = Math.abs(Number(amount) || 0);
        let i = parseInt(amount.toFixed(decimalCount)).toString();
        let j = i.length > 3 ? i.length % 3 : 0;
        let formatted =
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
                ? decimal +
                Math.abs(amount - Number(i))
                    .toFixed(decimalCount)
                    .slice(2)
                : '');
        return formatted.replace('.00', '');
    } catch (e) {
        console.log(e);
    }
    return '0'
}

export const numFormatter = (num: number) => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
    } else if (num < 900) {
        return num; // if value < 1000, nothing to do
    }
    return num;
}

export const utcDateToZonedDate = (utcDate: Date) => {
    const date = new Date(utcDate);
    return utcToZonedTime(date, 'Asia/Yangon')
}

export const zonedDateToUtcDate = (zonedDate: Date) => {
    return zonedTimeToUtc(zonedDate, 'Asia/Yangon')
}

function createCombinations(fields: any, currentCombinations: any = []): any {
    const tempFields = fields.slice();
    if (!tempFields.length) {
        return currentCombinations;
    }
    const combinations = [];
    const field = tempFields.pop();

    for (const value of field) {
        if (!currentCombinations.length) {
            combinations.push(value);
        } else {
            for (const combination of currentCombinations) {
                const c = `${value},${combination}`;
                combinations.push(c);
            }
        }
    }
    return createCombinations(tempFields, combinations);
}
export { createCombinations }