import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { getCashFlow, get_cash_flow } from "../../gql/expense_gql";
import withUser from "../../hocs/with_user";
import moment from "moment";
import { AuthState, CashFlowCategory, CashFlow } from "../../store";
import { Row, Col, Table, Input } from "antd";
import LoadingSpinner from "../../components/loading_spinner";
import { Card } from "antd";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import { Typography } from "antd";
const { Search } = Input;
interface P {
  user: AuthState;
}
const Income = (props: P) => {
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  const [exportLoading, setExportLoading] = useState(false);

  const { data: incomeData, loading } = useQuery(get_cash_flow, {
    variables: getCashFlow(merchantId, "INCOME"),
  });
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const filteredData = data?.filter((item: CashFlow) =>
    item?.cateogry?.label?.toLowerCase()?.includes(searchText?.toLowerCase())
  );

  const exportHandler = () => {
    setExportLoading(true);
    const exportData = filteredData?.length
      ? filteredData?.map((item: any) => ({
          Date: moment(item.created_at).format("L"),
          Category: item?.cateogry?.label,
          Account: item?.type,
          Amount: item?.amount,
          Name: item?.user?.name,
        }))
      : data?.map((item: any) => ({
          Date: moment(item.created_at).format("L"),
          Category: item?.cateogry?.label,
          Account: item?.type,
          Amount: item?.amount,
          Name: item?.user?.name,
        }));
    ExportExcel(exportData, "income summary");
    setExportLoading(false);
  };

  useEffect(() => {
    incomeData?.cashFlows?.length && setData(incomeData?.cashFlows);
  }, [incomeData]);

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (v: number, record: CashFlow) => {
        const val = moment(record.created_at).format("L");
        return val;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (v: number, record: CashFlow) => {
        const val = record?.cateogry?.label;
        return val;
      },
    },
    {
      title: "Account",
      dataIndex: "type",
      key: "type",
      render: (v: number, record: CashFlow) => {
        const val = record?.type;
        return val;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (v: number, record: CashFlow) => {
        const val = record?.amount;
        return val;
      },
    },
    {
      title: "Name",
      dataIndex: "user",
      key: "name",
      render: (v: number, record: CashFlow) => {
        const val = record.user.name;
        return val;
      },
    },
  ];

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={8}>
                  <Typography.Text strong>Income</Typography.Text>
                </Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <Search
                    defaultValue={searchText}
                    // addonBefore={selectBefore}
                    placeholder={`search by category ${data?.length}...`}
                    size="middle"
                    allowClear
                    onSearch={(val: any) => setSearchText(val.toLowerCase())}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                <ExportButton
                  loading={exportLoading || loading}
                  onClick={exportHandler}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <Table
                columns={tableColumns}
                dataSource={filteredData?.length ? filteredData : data}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withUser(Income);
