import React from "react";
import 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { primaryColor } from "../constants/color";
interface P {
    lables: string[];
    data: string[];
    title: string;
}
export default function LineChart(props: P) {
    const data = {
        labels: props.lables,
        datasets: [
            {
                label: props.title,
                data: props.data,
                fill: true,
                display: false,
                backgroundColor: "rgba(133, 175, 255,0.2)",
                borderColor: primaryColor
            },
        ]
    };
    const options = {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: props.title
        },
        legend: {
            display: true,
            position: "right"
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },

    };
    return (
        <div className="canvas-container">
            <Line data={data} options={options} />
        </div>
    );
}
