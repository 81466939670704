import React, { useState } from 'react';
import { Modal, Input } from 'antd'
import { Typography } from 'antd';

const { TextArea } = Input;
interface Props {
    onOk: (note: string) => void,
    onCancel: () => void,
    controller: React.ReactElement,
    label: string
}

export default function ConfirmPopupWithNote(props: Props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [note, setNote] = useState("");
    const okIsDisabled = !note || !(typeof note === 'string') || note.length === 0

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        if (props.onOk) props.onOk(note)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        if (props.onCancel) props.onCancel();
    };
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
    }
    return (
        <>
            <div onClick={showModal}>
                {props.controller}
            </div>
            <Modal
                zIndex={1200}
                title={props.label}
                visible={isModalOpen}
                okText="save"
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: okIsDisabled }}
            >
                <TextArea
                    placeholder="enter description"
                    rows={6}
                    onChange={onChange} defaultValue={note} />
            </Modal>
        </>
    )
}
