import React from 'react'
import { Button, Tooltip } from "antd";
import { ImportOutlined } from "@ant-design/icons";

interface P {
    loading: boolean;
    title: string;
    label: string;
    onClick: () => void;
}

export default function ImportButton(props: P) {
    return <Tooltip title={props.title}>
        <Button onClick={props.onClick} icon={<ImportOutlined />} >{props.label}</Button>
    </Tooltip>
}
