import React, { useState, useEffect } from 'react'
import { get_merchant_coupon_variables, get_merchant_coupons, } from "../../gql/gql_query";
import { useQuery } from '@apollo/client';
import { Button, Table, Input, Card, Empty, notification, Select, Tooltip, InputNumber, Popconfirm, Form, Col, Tag, Row, Typography } from "antd";
import { EditOutlined, SaveOutlined, LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ButtonGroup from 'antd/lib/button/button-group';
import { AuthState, Coupon, CouponCustomer } from '../../store'
import LoadingSpinner from '../../components/loading_spinner';
import withUser from '../../hocs/with_user';
import { format, differenceInDays, addDays, differenceInMonths } from "date-fns";
import { useToasts } from 'react-toast-notifications';
import { updateCoupon } from '../../helpers/gql_helper';
import ExportButton from '../../components/export_button';
import { ExportExcel } from '../../services/xlsx_service';
import DatePicker from '../../components/date';
import { useNavigate } from "react-router-dom";
import { utcDateToZonedDate, zonedDateToUtcDate } from '../../helpers/util';
import { paths } from '../../routes/paths';

const { Search } = Input
const { Option } = Select

interface P {
    user: AuthState
}
type InputType = 'number' | 'text' | 'select' | 'comment' | "date"
const calExpireDays = (coupon: Coupon) => {
    const startDate = utcDateToZonedDate(coupon.start_date);
    const endDate = utcDateToZonedDate(coupon.expire_date);
    const days = differenceInDays(endDate, startDate);
    return days;
}
const calExpireDaysToMonths = (sDate: Date, days: number) => {
    const startDate = utcDateToZonedDate(sDate);
    const endDate = addDays(startDate, days);
    const months = differenceInMonths(endDate, startDate);
    return months;
}
const calExpireDate = (sDate: Date, days: number) => {
    const startDate = utcDateToZonedDate(sDate);
    const endDate = addDays(startDate, days);
    return zonedDateToUtcDate(endDate);
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: InputType;
    record: Coupon;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    let inputNode = <Input disabled={true} />;
    if (record) {
        const disable = record.total_count !== record.remain_count;
        const statusTypeInput = (
            <Select defaultValue={record.status} className="select-before">
                <Option value={true}>active</Option>
                <Option value={false}>inactive</Option>
            </Select>
        );
        const priceTypeInput = (
            disable ? <Input disabled={disable} /> :
                <Select defaultValue={record.price_type} className="select-before">
                    <Option value={"VALUE"}>VALUE</Option>
                    <Option value={"PERCENTAGE"}> PERCENTAGE</Option>
                </Select>
        );
        if (inputType === "number") {
            inputNode = <InputNumber disabled={disable} />;
        } else if (inputType === 'date') {
            const isStart = dataIndex === "start_date"
            inputNode = <DatePicker
                title={isStart ? "Start Date" : "Expire Date"}
                timeOn={isStart ? "StartOfDay" : "EndOfDay"}
                date={new Date(isStart ? record.start_date : record.expire_date)}
                disable={isStart ? disable : true}
            />
        } else if (inputType === "select") {
            const isStatus = dataIndex === "status"
            inputNode = isStatus ? statusTypeInput : priceTypeInput
        } else {
            <Input disabled={true} />
        }
    }


    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
const notiKey = "updatable"
const CouponList = (props: P) => {
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [searchText, setSearch] = useState(null as string | null);
    const [editingKey, setEditingKey] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(null as boolean | null);
    const [form] = Form.useForm();
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const { loading, error, data, refetch } = useQuery(get_merchant_coupons, { variables: get_merchant_coupon_variables(merchantId), fetchPolicy: 'network-only' });
    const isEditing = (record: Coupon) => record.id === editingKey;
    useEffect(() => {
        const mins = 1 * 60 * 1000 // 1000*60*3 (1000 miliseconds, times 60 seconds, times 3 minutes) 
        const timer = setInterval(() => {
            refetch().then((res: any) => { });
        }, mins);
        return () => clearInterval(timer);
    }, [refetch])
    const edit = (record: Partial<Coupon>) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id!);
    };
    const onSave = async (id: string, data: Coupon) => {
        setSaveLoading(true);
        let formData = (await form.validateFields()) as Coupon;
        if (formData.total_count !== data.total_count) {
            formData.remain_count = formData.total_count;
        }
        const expireInMoth = calExpireDaysToMonths(formData.start_date, formData.expire_day || 0);
        const expireDate = calExpireDate(formData.start_date, formData.expire_day || 0);
        formData.expire_month = expireInMoth
        formData.expire_date = expireDate;
        const res = await updateCoupon(id, formData);
        if (res) {
            addToast(`Success save to ${data.code}`, { autoDismiss: true, appearance: 'success' })
            await refetch();
        } else {
            addToast(`Failed save to ${data.code}`, { autoDismiss: true, appearance: 'error' })
        }
        setEditingKey('')
        setSaveLoading(false);
    }
    const onExport = () => {
        setExportLoading(true);
        const fileName = "coupons";
        let excelData: any[] = [];
        ((data && data.coupons) ? data.coupons : []).forEach((val: Coupon) => {
            const code = val.code, amount = Number(val.price), type = val.price_type;
            const customerLimit = val.per_customer_limit < 0 ? 'unlimit' : val.per_customer_limit;
            const status = val.status === true ? 'active' : 'inactive';
            const couponTotalCount = val.total_count, couponTotalRemainCount = val.remain_count;
            const couponTotalUsedCount = val.total_count - val.remain_count;
            let customerTotalUsedCount = 0, customerTotalRequestCount = 0;
            const startDate = format(new Date(val.start_date), 'yyyy-MMM-dd HH:mm');
            const expireDate = format(new Date(val.expire_date), 'yyyy-MMM-dd HH:mm');
            const createdAt = format(new Date(val.created_at), 'yyyy-MMM-dd');
            if (val.coupon_customers) {
                val.coupon_customers.forEach(cc => {
                    customerTotalUsedCount += cc.used_count;
                    customerTotalRequestCount += cc.request_count;
                });
            }
            excelData.push({
                code,
                amount,
                type,
                customerLimit,
                status,
                startDate, expireDate,
                couponTotalCount, couponTotalUsedCount, couponTotalRemainCount,
                customerTotalUsedCount,
                customerTotalRequestCount,
                createdAt
            })
        })
        ExportExcel(excelData, fileName);
        setExportLoading(false)
    }

    const columns = [
        {
            title: 'code',
            dataIndex: 'code',
            width: 100,
            inputType: 'text' as InputType,
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value: string, record: Coupon) => record.code.toLowerCase().includes(value),
            render: (v: number, record: Coupon) => <Typography level={1}> {record.code} </Typography>,
            editable: false,
        },
        {
            title: 'amount',
            dataIndex: 'price',
            key: 'price',
            width: 120,
            editable: true,
            inputType: 'number' as InputType,
            sorter: (a: Coupon, b: Coupon) => Number(a.price) - Number(b.price),
            render: (v: number, record: Coupon) => {
                return record.price;
            },
        },
        {
            title: <Tooltip placement="topLeft" title={"Minimum purchase amount"}>minAmount </Tooltip>,
            dataIndex: 'min_amount',
            key: 'min_amount',
            inputType: 'number' as InputType,
            width: 120,
            editable: true,
            sorter: (a: Coupon, b: Coupon) => Number(a.min_amount) - Number(b.min_amount),
            render: (v: number, record: Coupon) => {
                return record.min_amount;
            },
        },
        {
            title: <Tooltip placement="topLeft" title={"Coupon value type"}>type</Tooltip>,
            dataIndex: 'price_type',
            key: 'price_type',
            width: 140,
            inputType: 'select' as InputType,
            editable: true,
            render: (v: number, record: Coupon) => {
                return <Tag color={'cyan'}>{record.price_type}</Tag>;
            },
        },
        {
            title: <Tooltip placement="topLeft" title={"Per Customer Limit"}>perLimit </Tooltip>,
            dataIndex: 'per_customer_limit',
            key: 'per_customer_limit',
            width: 120,
            inputType: 'number' as InputType,
            sorter: (a: Coupon, b: Coupon) => Number(a.per_customer_limit) - Number(b.per_customer_limit),
            editable: true,
            render: (v: number, record: Coupon) => {
                const isNotlimit = record.per_customer_limit < 0;
                return isNotlimit ? <Tag color={"lime"}>unlimit</Tag> : record.per_customer_limit;
            },
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            editable: true,
            inputType: 'select' as InputType,
            render: (v: number, record: Coupon) => {
                const active = record.status;
                return <Tag color={active ? 'green' : 'gray'}>{active ? 'active' : 'inactive'}</Tag>;
            },
        },
        {
            title: 'startAt',
            dataIndex: 'start_date',
            key: 'start_date',
            width: 200,
            inputType: 'date' as InputType,
            editable: true,
            sorter: (a: Coupon, b: Coupon) => new Date(a.start_date).getDate() - new Date(b.start_date).getDate(),
            render: (v: number, record: Coupon) => {
                return format(new Date(record.start_date), 'yyyy-MMM-dd HH:mm');
            },
        },
        {
            title: <Tooltip placement="topLeft" title={"Expire days after start date"}>expireInDay </Tooltip>,
            dataIndex: 'expire_day',
            key: 'expire_day',
            inputType: 'number' as InputType,
            width: 120,
            editable: true,
            sorter: (a: Coupon, b: Coupon) => (a.expire_day || 0) - (b.expire_day || 0),
            render: (v: number, record: Coupon) => {
                return record.expire_day || 0;
            },
        },
        {
            title: 'expireAt',
            dataIndex: 'expire_date',
            key: 'expire_date',
            inputType: 'date' as InputType,
            width: 200,
            editable: true,
            sorter: (a: Coupon, b: Coupon) => new Date(a.expire_date).getDate() - new Date(b.start_date).getDate(),
            render: (v: number, record: Coupon) => {
                return format(new Date(record.expire_date), 'yyyy-MMM-dd HH:mm');
            },
        },
        {
            title: <Tooltip placement="topLeft" title={"Coupon total count"}>total </Tooltip>,
            dataIndex: 'total_count',
            key: 'total_count',
            inputType: 'number' as InputType,
            width: 120,
            editable: true,
            ellipsis: true,
            sorter: (a: Coupon, b: Coupon) => a.total_count - b.total_count
        },
        {
            title: <Tooltip placement="topLeft" title={"Total remain count"}>remain</Tooltip>,
            dataIndex: 'remain_count',
            key: 'remain_count',
            inputType: 'comment' as InputType,
            width: 100,
            editable: true,
            sorter: (a: Coupon, b: Coupon) => a.remain_count - b.remain_count,
            render: (v: number, record: Coupon) => {
                return record.remain_count;
            },
        },
        {
            title: <Tooltip placement="topLeft" title={"Total used count"}>used</Tooltip>,
            dataIndex: 'used',
            key: 'used',
            inputType: 'comment' as InputType,
            width: 100,
            editable: false,
            sorter: (a: Coupon, b: Coupon) => (a.total_count - a.remain_count) - (b.total_count - b.remain_count),
            render: (v: number, record: Coupon) => {
                return record.total_count - record.remain_count;
            },
        },
        // {
        //     title: 'createdAt',
        //     dataIndex: 'created_at',
        //     key: 'created_at',
        //     editable: false,
        //     sorter: (a: Coupon, b: Coupon) => new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
        //     render: (v: number, record: Coupon) => {
        //         return format(new Date(record.expire_date), 'yyyy-MMM-dd ');
        //     },
        // },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 100,
            inputType: 'comment' as InputType,
            editable: false,
            fixed: 'right',
            render: (v: number, record: Coupon) => {
                const editable = isEditing(record);
                return (
                    <ButtonGroup>
                        {editable ?
                            (
                                saveLoading ? <Button type="primary" icon={<LoadingOutlined />} >saving...</Button> :
                                    < Popconfirm placement="topRight" title={`Sure to save ${record.code}?`} onConfirm={() => onSave(record.id, record)} onCancel={() => setEditingKey('')}>
                                        <Button type='primary' icon={<SaveOutlined />} >save</Button>
                                    </Popconfirm>
                            )
                            :
                            <Button shape="circle" icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                        }
                    </ButtonGroup >
                );

            }
        }
    ];
    const expandedRowRender = (record: Coupon) => {
        const rowData = record.coupon_customers || "";
        const empty = record.coupon_customers && record.coupon_customers.length === 0;
        const columns = [
            {
                title: 'name',
                dataIndex: 'name',
                key: 'name',
                render: (v: number, record: CouponCustomer) => {
                    const customer = (record.merchant_customer && record.merchant_customer.customer) ? record.merchant_customer.customer : null
                    return customer ? customer.name || customer.contact_number : "";
                },
            },
            {
                title: 'phone',
                dataIndex: 'phone',
                key: 'phone',
                render: (v: number, record: CouponCustomer) => {
                    const customer = (record.merchant_customer && record.merchant_customer.customer) ? record.merchant_customer.customer : null
                    return customer ? customer.contact_number : "";
                },
            },
            {
                title: 'used',
                dataIndex: 'used',
                key: 'used',
                sorter: (a: CouponCustomer, b: CouponCustomer) => a.used_count - b.used_count,
                render: (v: number, record: CouponCustomer) => {
                    return record.used_count;
                },
            },
            {
                title: 'request',
                dataIndex: 'request',
                key: 'request',
                sorter: (a: CouponCustomer, b: CouponCustomer) => a.request_count - b.request_count,
                render: (v: number, record: CouponCustomer) => {
                    return record.request_count;
                },
            },
            {
                title: 'total',
                dataIndex: 'total',
                key: 'total',
                sorter: (a: CouponCustomer, b: CouponCustomer) => (a.request_count + a.used_count) - (b.request_count + b.used_count),
                render: (v: number, record: CouponCustomer) => {
                    return record.request_count + record.used_count;
                },
            },
        ];
        return empty ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<Typography.Text italic>Empty customer</Typography.Text>} /> :
            <Card> <Table showHeader={true} size="small" columns={columns} dataSource={rowData} pagination={{ pageSize: 5, position: ['topLeft',] }} /> </Card>;
    };
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Coupon) => ({
                record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const isEmpty = () => {
        const isTrue = !loading && data && data.coupons && data.coupons.length === 0;
        if (isTrue) {
            notification.info({
                key: notiKey,
                message: 'Coupon usage',
                description:
                    'Create coupons using Piti+ mobile application.You can summarize coupon usage using piti ecommerce data reporting.',
                duration: 0,
            });
        }
        if (error) {
            return true;
        }
        return isTrue;
    }
    return (
        <>
            {loading && <LoadingSpinner />}
            {isEmpty() &&
                <Empty className="loading-spinner"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span>
                            {/* <Typography.Text italic>No coupons</Typography.Text><br /> */}
                            <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate(paths.create_coupon)} >create</Button>
                        </span>
                    }
                />
            }
            {(!loading && !isEmpty()) &&
                <>
                    <Row>
                        <Col flex={4}>
                            <Row >
                                <Col span={8}>
                                    <Row>
                                        <Typography level={3}> Coupons </Typography>

                                    </Row>
                                </Col>
                                <Col span={8} offset={8}>
                                    <Search
                                        placeholder="search..."
                                        allowClear
                                        size="default"
                                        onSearch={(val: any) => setSearch(val.toLowerCase())}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex={0}>
                            <ExportButton loading={exportLoading || loading} onClick={onExport} />
                            <Button style={{ marginLeft: 4 }} type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate(paths.create_coupon)} >create</Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 }}>
                        <Form form={form} component={false}>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                scroll={{ x: 1300 }}
                                dataSource={((data && data.coupons) ? data.coupons as Coupon[] : []).map((row, i) => { return { ...row, expire_day: calExpireDays(row), key: i + 1 } })}
                                columns={mergedColumns}
                                rowClassName="editable-row"
                                pagination={{
                                    onChange: () => setEditingKey(''),
                                }}
                                expandable={{
                                    expandedRowRender,
                                }}
                            />
                        </Form>
                    </Row>
                </>
            }
        </>
    )
}
export default withUser(CouponList) as any;