import React, { useState } from 'react'
import { Row, Col, Typography, Empty } from 'antd';
import Search from 'antd/lib/input/Search';
import { Form } from 'antd';
import { Table } from 'antd';
import { addDays, format, subDays } from 'date-fns';
import ButtonGroup from 'antd/lib/button/button-group';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/loading_spinner';
import Error from '../../components/apollo_error';
import { ApolloResult, AuthState, ShipppingFee } from '../../store';
import withUser from '../../hocs/with_user';
import { getShippingFeeVar, get_shipping_fees } from '../../gql/gql_query';
import { Tag, Popconfirm } from 'antd';
import { Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { delete_many_shipping_fee_rate, delete_one_shipping_fee, getDeleteSFRByIdVar, getDeleteSFVar, } from '../../gql/gql_mutation';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes/paths';
interface P {
    user: AuthState,
}
const ShippingFeePage = (props: P) => {
    const navigate = useNavigate();
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const [searchText, setSearchText] = useState<string | null>(null);
    const [form] = Form.useForm();
    const [editKey, setEditKey] = useState("");
    const variables = getShippingFeeVar(merchantId);
    const { loading, data, error, refetch } = useQuery<ApolloResult<"shippingFees", ShipppingFee[]>>(get_shipping_fees, { variables, fetchPolicy: 'network-only' })
    const [deleteShippingFeeRates, { loading: dsfrLoad, data: dsfrData }] = useMutation<ApolloResult<"deleteManyShippingFeeRate", { count: number }>>(delete_many_shipping_fee_rate);
    const [deleteShippingFee, { loading: dsfLoad }] = useMutation<ApolloResult<"deleteShippingFee", ShipppingFee>>(delete_one_shipping_fee);
    const isEditing = (r: ShipppingFee) => r.id === editKey;
    if (loading) return <LoadingSpinner />
    if (error) return <Error error={error} />
    if (!data) return <Empty />
    const handleDelete = async (id: string) => {
        setEditKey(id);
        await deleteShippingFeeRates({ variables: getDeleteSFRByIdVar(id) });
        await deleteShippingFee({ variables: getDeleteSFVar(id) });
        await refetch();
        setEditKey("");
    }

    const columns: any[] = [
        {
            title: 'Name',
            dataIndex: "name",
            key: 'name',
            editable: false,
            align: "center",
            filteredValue: searchText ? [searchText] : null,
            sorter: (a: ShipppingFee, b: ShipppingFee) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            onFilter: (value: string, record: ShipppingFee) => (record.name).toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: 'Price',
            dataIndex: "price",
            key: 'price',
            editable: false,
            sorter: (a: ShipppingFee, b: ShipppingFee) => Number(a.price) - Number(b.price),
        },
        {
            title: 'Type',
            dataIndex: "type",
            key: "type",
            editable: false,
            sorter: (a: ShipppingFee, b: ShipppingFee) => (a.type || "").toLowerCase().localeCompare(b.type || ""),
            render: (v: ShipppingFee, r: ShipppingFee) => {
                let l = "-";
                if (r.type) {
                    if (r.type === 'SUPPORT_IN_ALL') {
                        l = 'all';
                    }
                    if (r.type === 'SUPPORT_IN_SR') {
                        l = "regions";
                    }
                    if (r.type === 'SUPPORT_IN_TSP') {
                        l = "townships"
                    }
                    if (r.type === 'SUPPORT_IN_INTL') {
                        l = "international"
                    }
                }
                return <Tag>{l}</Tag>
            },
        },
        {
            title: 'UpdateAt',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a: ShipppingFee, b: ShipppingFee) => new Date(a.updated_at).getDate() - new Date(b.updated_at).getDate(),
            render: (v: number, record: ShipppingFee) => {
                return format(new Date(record.updated_at), 'yyyy-MMM-dd');
            },
        },

        {
            title: 'Action',
            key: 'action',
            // align: 'center',
            width: "12%",
            fixed: 'right',
            render: (v: number, record: ShipppingFee) => {
                const edit = isEditing(record);
                return (
                    <ButtonGroup style={{ alignItems: "flex-end" }}>
                        <Button icon={<EditOutlined />} onClick={() => navigate(paths.getShippingFeeDetailRoute(record.id))} />
                        < Popconfirm placement="topRight" title={`Sure to delete ${record.name}?`} onConfirm={() => handleDelete(record.id)} >
                            <Button icon={<DeleteOutlined />} loading={edit && (dsfrLoad || dsfLoad)} disabled={dsfrLoad || dsfLoad} />
                        </Popconfirm>

                    </ButtonGroup >
                );

            }
        }
    ];
    const control = (
        <Row style={{ marginTop: 10 }}>
            <Col flex={4}>
                <Row >
                    <Col span={8}>
                        <Typography level={2}>Shipping Fees</Typography>

                    </Col>
                    <Col span={8} offset={8}>
                        <Search
                            defaultValue={searchText || undefined}
                            placeholder="search ...."
                            allowClear
                            size="middle"
                            onSearch={(val: any) => setSearchText(val)}
                        />
                    </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => navigate(paths.getShippingFeeDetailRoute("new"))} >Add</Button>
            </Col>
        </Row>
    );
    const context = (
        <Row style={{ marginTop: 8 }}>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table
                        showHeader={data.shippingFees.length > 0}
                        dataSource={data.shippingFees.map(sf => ({ ...sf, key: sf.id }))}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Col>
        </Row>
    );

    return (
        <React.Fragment>
            {control}
            {context}
        </React.Fragment>
    )
}

export default withUser(ShippingFeePage);
