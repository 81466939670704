import React, { useEffect, useState } from "react";
import { PointHistory } from "../state";
import { Badge, Table, Card } from "antd";
import { format, addMonths } from "date-fns";
import { getRecentHistory } from "../../api/point_api";
import { Link } from "react-router-dom";
import { paths } from "../../routes/paths";
import moment from "moment";
interface P {
  merchantId: string;
  data?: PointHistory[];
  showHearder?: boolean;
  title?: string;
  showPage?: boolean;
  columnType?: "one" | "two";
}
type S = { status: "loading" } | { status: "loaded"; data: PointHistory[] };
export default function RecentHistory(props: P) {
  const [state, setState] = useState<S>({
    status: props.data ? "loaded" : "loading",
    data: props.data || [],
  });
  useEffect(() => {
    if (props.merchantId && !props.data) {
      getRecentHistory({ merchantId: props.merchantId }).then((res) => {
        if (res.success) {
          setState({ status: "loaded", data: res.data || [] });
        }
      });
    }
  }, [props]);
  const columns = [
    {
      title: "From",
      dataIndex: "from",
      render: (v: number, r: PointHistory) => {
        const val = r.uId ? r.uId.split("@")[0] : "admin";
        return val;
      },
    },
    {
      title: "To",
      dataIndex: "to",
      render: (v: number, r: PointHistory) => {
        const val = r.memberName || "";
        return val;
      },
    },
    {
      title: "Date",
      dataIndex: "date",

      render: (v: number, r: PointHistory) => {
        return format(new Date(r.createdDate.value), "yyyy-MMM-dd");
      },
    },
    {
      title: "Expire Date",
      dataIndex: "expireDate",

      render: (v: number, r: PointHistory) => {
        const date = r.expiredAt
          ? new Date(r.expiredAt)
          : addMonths(new Date(r.createdDate.value), r.expireInMonth);
        const formattedDate = moment(date).format("YYYY-MMM-DD");
        return formattedDate;
      },
    },
    {
      title: "Reference ",
      dataIndex: "reference",

      render: (v: number, record: PointHistory) => {
        const val = record.referenceNo || "";
        return val;
      },
    },
    {
      title: "+100 Points",
      dataIndex: "point",
      render: (v: number, record: PointHistory) => {
        const val = record.point || 0;
        return record.type === "earn" ? (
          <Badge count={val} style={{ backgroundColor: "#52c41a" }} />
        ) : (
          <Badge count={val} />
        );
      },
    },
  ];
  const column2 = [
    {
      title: "From",
      dataIndex: "from",
      render: (v: number, r: PointHistory) => {
        const val = r.uId ? r.uId.split("@")[0] : "admin";
        return val;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a: PointHistory, b: PointHistory) =>
        new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
      render: (v: number, r: PointHistory) => {
        // console.log(moment(r.createdDate.value).format('YYYY-MMM-DD'), "rdata");
        // return format(new Date(Number(r.createdDate.value)), "yyyy-MMM-dd");
        return moment(r.createdDate.value).format("YYYY-MMM-DD");
      },
    },
    {
      title: "Expire Date",
      dataIndex: "expireDate",
      render: (v: number, r: PointHistory) => {
        console.log(r);
        const date = r.expiredAt
          ? new Date(r.expiredAt)
          : addMonths(new Date(r.createdDate.value), r.expireInMonth);
        console.log(date);
        // return format(date, "yyyy-MMM-dd");
        return moment(date).format("YYYY-MMM-DD");
      },
    },
    {
      title: "Reference ",
      dataIndex: "reference",
      render: (v: number, record: PointHistory) => {
        const val = record.referenceNo || "";
        return val;
      },
    },
    {
      title: "+100 Points",
      dataIndex: "point",
      render: (v: number, record: PointHistory) => {
        const val = record.point || 0;
        return record.type === "earn" ? (
          <Badge count={val} style={{ backgroundColor: "#52c41a" }} />
        ) : (
          <Badge count={val} />
        );
      },
    },
  ];
  return (
    <React.Fragment>
      {state.status === "loading" && <Card loading={true} />}
      {state.status === "loaded" && (
        <Card
          style={styles.card}
          title={props.title || "Recent"}
          extra={<Link to={paths.pointHistory}>Show more...</Link>}>
          <Table
            showHeader={props.showHearder || false}
            columns={
              props.columnType && props.columnType === "two" ? column2 : columns
            }
            dataSource={(state.status === "loaded" ? state.data : []).map(
              (row, i) => {
                return { ...row, key: i + 1 };
              }
            )}
            pagination={{
              pageSize: 6,
              position: props.showPage ? ["bottomRight"] : ["none"],
            }}
          />
        </Card>
      )}
    </React.Fragment>
  );
}
const styles = {
  card: {
    height: "100%",
  },
};
