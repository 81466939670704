import React, { useEffect } from 'react';
import Rs from './routes/routes';
import "antd/dist/antd.css";
import './style.css';
import Login from './auth/login'
import { Provider } from "react-redux";
import { paths } from "./routes/paths";
import { store } from "./store";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import { ApolloProvider } from "@apollo/client";
import client from './gql/appollo_client';
import { availableCRM } from "./helpers/crm_helper";
import { updateTitle } from "./helpers/helmet_helper";

const App = () => {
    useEffect(() => {
        let title = "Piti+ Report"
        if (availableCRM()) {
            title = "Piti+ CRM"
        }
        updateTitle(title);
    }, []);

    return (
        <ApolloProvider client={client}>
            <Provider store={store} >
                <ToastProvider>
                    <Router >
                        <Routes>
                            <Route index element={<Login />} />
                            <Route path={paths.login} element={<Login />} />
                            <Route path={"/*"} element={<Rs />} />
                        </Routes>
                    </Router>
                </ToastProvider>
            </Provider>
        </ApolloProvider>
    );
}
export default App;
