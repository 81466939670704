import { Avatar, Col, Input, Row, Select, Table, Typography } from "antd";
import { TablePaginationConfig } from "antd/lib/table/Table";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllMembers } from "../../api/member_api";
import defaultPhoto from "../../assets/image/user.png";
import ExportButton from "../../components/export_button";
import LoadingSpinner from "../../components/loading_spinner";
import withUser from "../../hocs/with_user";
import { paths } from "../../routes/paths";
import { ExportExcel } from "../../services/xlsx_service";
import { Member } from "../state";
const { Search } = Input;
const { Option } = Select;
type S =
  | { status: "loading" }
  | { status: "loaded"; members: Member[] }
  | { status: "error"; error: string };
type Filter = "name" | "phone";
const makePhoneSearch = (phone: string) => {
  if (phone.startsWith("09")) {
    return "+95 " + phone.substring(1);
  }
  if (phone.startsWith("+959")) {
    return "+95 9" + phone.substring(4);
  }
  return phone;
};
const MemberList = (props: any) => {
  const defaultPageSize = 50;
  const merchantId = props.user?.userInfo?.merchantId || null;
  const [state, setState] = useState({ status: "loading" } as S);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  const [searchText, setSearchText] = useState(null as string | null);
  const [selectedFilter, setSelectedFilter] = useState("name" as Filter);
  const [testLoading, setTestLoading] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig | null>(null);
  const [pageNum, setPageNum] = useState(1);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const next50Handler = async () => {
    // setTestLoading(true);
    // setOffset((prev) => prev + pageSize);
    // setPageNum((prev) => prev + 1);
  };

  const prev50Handler = async () => {
    // setTestLoading(true);
    // setOffset((prev) => prev - pageSize);
    // setPageNum((prev) => prev - 1);
  };

  useEffect(() => {
    if (merchantId) {
      getAllMembers(merchantId, 0, pagination?.pageSize || defaultPageSize).then((res) => {
        if (res.success) {
          setState({ status: "loaded", members: res?.data || [] });
          setTotal(res.total||0)
        }
      });
    }
  }, [merchantId]);

  useEffect(() => {
    const limit = pagination?.pageSize || defaultPageSize;
    const offset = ((pagination?.current || 1) - 1) * limit;
    getAllMembers(merchantId, offset, limit).then((res) => {
      if (res.success) {
        setState({ status: "loaded", members: res?.data || [] });
        setTestLoading(false);
      }
    });
  }, [pagination]);

  const columns = [
    {
      title: "Photo",
      dataIndex: "image",
      align: "center",
      render: (v: number, record: Member) => (
        <Link to={paths.getMemberDetailRoute(record.id)}>
          <Avatar
            src={record.photo || defaultPhoto}
            size={45}
            onClick={() => { }}
          />
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      filteredValue:
        selectedFilter === "name" && searchText
          ? [searchText.toLowerCase()]
          : null,
      onFilter: (value: string, record: Member) =>
        (record.displayName || "").toLowerCase().includes(value.toLowerCase()),
      sorter: (a: Member, b: Member) =>
        a.displayName || "".localeCompare(b.displayName || ""),
      render: (v: number, record: Member) => {
        const val = record.displayName || record.phone || "";
        return val;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      filteredValue:
        selectedFilter === "phone" && searchText
          ? [makePhoneSearch(searchText)]
          : null,
      onFilter: (value: string, record: Member) =>
        (record.phone || "").toLowerCase().includes(value.toLowerCase()),
      sorter: (a: Member, b: Member) =>
        a.phone || "".localeCompare(b.phone || ""),
      render: (v: number, record: Member) => {
        const val = record.phone || "";
        return val;
      },
    },
    {
      title: "Join Date",
      dataIndex: "joinDate",
      key: "orders",
      render: (v: number, record: Member) => {
        return format(
          new Date(record.joinedAt || record.firstActiveAt),
          "yyyy-MMM-dd"
        );
      },
      sorter: (a: Member, b: Member) =>
        new Date(a.joinedAt || a.firstActiveAt).getDate() -
        new Date(b.joinedAt || b.firstActiveAt).getDate(),
    },
    {
      title: "Current Point",
      dataIndex: "memberPoint",
      key: "memberPoint",
      render: (v: number, record: Member) => {
        const val = record.memberPoint || 0;
        return val;
      },
      sorter: (a: Member, b: Member) => a.memberPoint - b.memberPoint,
    },
    {
      title: "Total Point",
      dataIndex: "totalPoint",
      key: "totalPoint",
      render: (v: number, record: Member) => {
        const val = record.totalPoint || 0;
        return val;
      },
      sorter: (a: Member, b: Member) => a.totalPoint - b.totalPoint,
    },
    {
      title: "Engagement",
      dataIndex: "pF",
      key: "pF",
      sorter: (a: Member, b: Member) => (a.pF || 0) - (b.pF || 0),
      render: (v: number, record: Member) => {
        const val = record.pF || 0;
        return val;
      },
    },
    {
      title: "First Date",
      dataIndex: "firstDate",
      key: "firstDate",
      sorter: (a: Member, b: Member) => a.firstActiveAt - b.firstActiveAt,
      render: (v: number, record: Member) => {
        return format(new Date(record.firstActive), "yyyy-MMM-dd");
      },
    },
    {
      title: "Last Date",
      key: "lastDate",
      sorter: (a: Member, b: Member) =>
        new Date(a.lastActive).getDate() - new Date(b.lastActive).getDate(),
      render: (v: number, record: Member) => {
        return format(new Date(record.lastActive), "yyyy-MMM-dd");
      },
    },
  ];
  const selectBefore = (
    <Select
      defaultValue={selectedFilter}
      className="select-before"
      style={{ width: 120 }}
      onChange={(v: Filter) => setSelectedFilter(v)}>
      <Option value="name">name</Option>
      <Option value="phone">phone</Option>
    </Select>
  );
  const onExport = () => {
    if (state.status !== "loaded") return;
    setExportLoading(true);
    const fileName = "members";
    let excelData: any[] = [];
    state.members.forEach((m) => {
      const Name = m.displayName || "";
      const PhoneNumber = m.phone || "";
      const Email = m.email || "";
      const JoinDate = format(new Date(m.joinedAt), "yyyy-MMM-dd");
      const totalPoint = m.totalPoint;
      const currentPoint = m.memberPoint;
      const engagement = m.pF;
      const firstDate = format(new Date(m.firstActive), "yyyy-MMM-dd");
      const lastDate = format(new Date(m.lastActive), "yyyy-MMM-dd");
      excelData.push({
        Name,
        PhoneNumber,
        Email,
        JoinDate,
        totalPoint,
        currentPoint,
        engagement,
        firstDate,
        lastDate,
      });
    });
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  return (
    <React.Fragment>
      {testLoading && <LoadingSpinner />}
      {state.status === "loading" && <LoadingSpinner />}
      {state.status === "loaded" && !testLoading && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={12}>
                  <Typography.Text strong> Members </Typography.Text>
                </Col>
                <Col span={12}>
                  <Search
                    addonBefore={selectBefore}
                    placeholder={
                      total > 0 ? `Search ${total} records...` : `Search ....`
                    }
                    size="middle"
                    allowClear
                    onSearch={(val: any) => setSearchText(val.toLowerCase())}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                <ExportButton
                  loading={exportLoading || state.status !== "loaded"}
                  onClick={onExport}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Table
              columns={columns}
              dataSource={(state.status === "loaded" ? state.members : []).map(
                (row, i) => {
                  return { ...row, key: i + 1 };
                }
              )}
              onChange={(p: TablePaginationConfig) => {
                setTestLoading(true);
                setPagination(p)
              }}
              pagination={{
                total,
                current: pagination?.current || 1,
                pageSize: pagination?.pageSize || defaultPageSize,
                defaultPageSize: defaultPageSize,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '150', '200','500','700','1000']
              }}
            />
          </Row>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "18px",
              marginBottom: "18px",
              alignItems: "center",
              gap: "8px",
            }}>
            <div style={{ color: "black", fontSize: "12px" }}>
              {pageSize} members / page
            </div>
            <Radio.Group
              value={"sm"}
            //  onChange={(e) => setSize(e.target.value)}
            >
              <Radio.Button
                disabled={offset == 0 && true}
                onClick={prev50Handler}
                value="large">
                <LeftOutlined />
              </Radio.Button>
              <Radio.Button value="default">{pageNum}</Radio.Button>
              <Radio.Button
                disabled={state.members.length === pageSize}
                onClick={next50Handler}
                value="small">
                <RightOutlined />
              </Radio.Button>
            </Radio.Group>
          </div> */}

          {/* {testLoading && <p>Loading ... </p>} */}
        </>
      )}
      {state.status === "error" && <span>{state.error}</span>}
    </React.Fragment>
  );
};
export default withUser(MemberList) as any;
