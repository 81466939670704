import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
interface P {
    cashPos: any;
    cashWeb: any;
    cashOther: any;
    bankPos: any;
    bankWeb: any;
    bankOther: any;
}

export default function VerticalBar(props: P) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Channel',
            },
        },
    };

    const labels = ['POS', 'Online'];

    const data = {
        labels,
        datasets: [
            {
                label: 'CASH',
                data: [props.cashPos, props.cashWeb],
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'BANK',
                data: [props.bankPos, props.bankWeb],
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}
