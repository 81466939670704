import React, { useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { BoughtTogether, MerchantCustomer2, MerchantUser } from "../../store";
import {
  merchant_customer_query as mcQuery,
  getMerchantCustomerVariables as mcVaribales,
  merchant_customer_bought_together_query as mcBTQuery,
  getMerchantCustomerBoughtTogetherVariables as mcBTVariables,
  get_merchant_users as muQuery,
  getMerchantUserVariables as muVaribales,
} from "../../gql/gql_query";
import {
  update_merchant_customer as mcUpdate,
  getUpdateMerchantCustomerVaribales as mcUpdateVariables,
} from "../../gql/gql_mutation";
import { Row, Col, Card } from "antd";
import withUser from "../../hocs/with_user";
import LoadingSpinner from "../../components/loading_spinner";
import CustomerDetailInfo from "../../components/customer_detail_info2";
import CustomerSummaryInfo from "../../components/customer_summary_info";
import CustomerOrder from "../../components/customer_detial_orders";
import CustomerBarChart from "../../charts/customer_detail_barchart";
import CustomerBoughtItem from "../../components/customer_detail_bought_items";
import MemberDetailInfo from "../../royalty/history/member_detail";
import MemberSummaryInfo from "../../components/member_summary_info";
import CustomerComment from "./customer_comment";
import { MemberDetail } from "../../royalty/state";

type S =
  | { status: "loading" }
  | { status: "loaded"; data: MemberDetail | null };
const CustomerDetial = (props: any) => {
  const { id: customerId } = useParams() as { id: string };
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  const [memberData, setMemberData] = useState<S>({ status: "loading" });
  const {
    loading: mcLoading,
    error: mcError,
    data: mcData,
    refetch: mcRefetch,
  } = useQuery(mcQuery, { variables: mcVaribales(customerId, merchantId) });
  const {
    loading: muLoading,
    error: muError,
    data: muData,
  } = useQuery(muQuery, { variables: muVaribales(merchantId) });
  const {
    loading: mcBTLoading,
    error: mcBTError,
    data: mcBTData,
  } = useQuery(mcBTQuery, { variables: mcBTVariables(customerId, merchantId) });
  const [
    updateMerchantCustomer,
    { data: addData, loading: addLoading, error: addError },
  ] = useMutation(mcUpdate);
  const loading = mcLoading || mcBTLoading;

  const onOwnerUpdate = async (ownerId: string) => {
    updateMerchantCustomer({
      variables: mcUpdateVariables({
        merchantId,
        customerId,
        data: { type: "owner", ownerId },
      }),
    }).then(() => mcRefetch());
  };
  const onEmailUpdate = async (email: string) => {
    updateMerchantCustomer({
      variables: mcUpdateVariables({
        merchantId,
        customerId,
        data: { type: "email", email },
      }),
    }).then(() => mcRefetch());
  };
  const onDOBUpdate = async (dob: string) => {
    updateMerchantCustomer({
      variables: mcUpdateVariables({
        merchantId,
        customerId,
        data: { type: "dob", dateOfBirth: dob },
      }),
    }).then(() => mcRefetch());
  };
  const onAddressUpdate = async (address: string) => {
    updateMerchantCustomer({
      variables: mcUpdateVariables({
        merchantId,
        customerId,
        data: { type: "address", address },
      }),
    }).then(() => mcRefetch());
  };
  const firstRow = () => {
    const mc = mcData["merchantCustomer"] as MerchantCustomer2;
    return (
      <Row gutter={24}>
        <Col span={8}>
          <CustomerDetailInfo
            onOwnerUpdate={onOwnerUpdate}
            onAddressUpdate={onAddressUpdate}
            onDOBUpdate={onDOBUpdate}
            onEmailUpdate={onEmailUpdate}
            data={mc}
            merchantUsers={(muData?.merchantUsers || []) as MerchantUser[]}
          />
        </Col>
        <Col span={16}>
          <CustomerBarChart data={mc?.orders || []} />
        </Col>
      </Row>
    );
  };
  const summaryRow = () => {
    const mc = mcData["merchantCustomer"] as MerchantCustomer2;
    return (
      <Row gutter={24} style={{ marginTop: 5 }}>
        <Col span={12}>
          <CustomerSummaryInfo data={mc} />
        </Col>
        <Col span={12}>
          {memberData.status === "loaded" ? (
            <MemberSummaryInfo data={memberData.data || null} />
          ) : (
            <Card style={styles.card} size="small" loading={true} />
          )}
        </Col>
      </Row>
    );
  };
  const secondRow = () => {
    const mc = mcData["merchantCustomer"] as MerchantCustomer2;
    const bt = mcBTData["boughtTogethers"] as BoughtTogether[];
    return (
      <Row gutter={24} style={{ marginTop: 5 }}>
        <Col span={12}>
          <Card stype="small" title="Orders" style={styles.card}>
            <CustomerOrder data={mc?.orders || []} />
          </Card>
        </Col>
        <Col span={12}>
          <Card stype="small" title="Bought" style={styles.card}>
            <CustomerBoughtItem data={bt} />
          </Card>
        </Col>
      </Row>
    );
  };
  const memberRow = () => {
    if (loading) return null;
    const mc = mcData["merchantCustomer"] as MerchantCustomer2;
    if (!mc || !mc.customer?.contact_number) return null;
    return (
      <MemberDetailInfo
        showLoading={false}
        showDetail={false}
        showTitle={false}
        showMemberTier={true}
        merchantId={merchantId}
        memberId={mc.customer.contact_number}
        onLoad={(mData?: MemberDetail) => {
          setMemberData({ status: "loaded", data: mData || null });
        }}
      />
    );
  };
  const commentRow = () => {
    return (
      <Row gutter={24} style={{ marginTop: 5 }}>
        <Col span={24}>
          <CustomerComment merchantId={merchantId} customerId={customerId} />
        </Col>
      </Row>
    );
  };
  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {firstRow()}
          {summaryRow()}
          {secondRow()}
          {memberRow()}
          {commentRow()}
        </div>
      )}
    </React.Fragment>
  );
};
export default withUser(CustomerDetial);

const styles = {
  card: {
    height: "100%",
  },
};
