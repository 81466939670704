import { Action } from 'redux';
import { getAuth } from "@firebase/auth";
import { AuthState, UserInfo } from '../state';
import { LoginActionTypes as actionTypes } from '../actions/action_types';
const {
    LOGGED_IN,
    LOGOUT_REQUEST,
    LOGGED_OUT,
} = actionTypes;

export const actions = {
    logginIn: () => (
        {
            type: actionTypes.LOGGING_IN,
            payload: {}
        }
    ),
    loggedIn: (userInfo: UserInfo) => (
        {
            type: LOGGED_IN,
            payload: { userInfo },
        }
    ),
    logOutRequest: () => (
        {
            type: LOGOUT_REQUEST,
            payload: {},
        }
    ),
    loggedOut: () => (
        {
            type: LOGGED_OUT,
            payload: {},
        }
    )
}

type LoggedAction = Action & { payload: { userInfo: UserInfo } | { error: string } };
export const reducers = (state: AuthState = { status: 'none' }, action: LoggedAction) => {
    switch (action.type) {
        case actionTypes.LOGGING_IN: {
            return {
                status: 'loading',
            };
        }
        case LOGGED_IN: {
            const { userInfo } = action.payload as { userInfo: UserInfo };
            return {
                status: 'loggedIn',
                userInfo,
            };
        }
        case LOGGED_OUT:
            getAuth().signOut();
            return { status: 'none' };
        default:
            break;
    }

    return state;
};

