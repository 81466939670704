import React, { useState } from 'react';
import { MerchantCustomer2, MerchantUser, Order } from "../store";
import { Card, Typography, Row, Col, Avatar, Modal, Button, Input, InputNumber } from "antd";
import OwnerSelector from '../components/customer_owner_selector';
import { EditOutlined } from "@ant-design/icons";
import { format } from "date-fns";
import DatePicker from '../components/date';
import defaultPhoto from '../assets/image/user.png';
const { TextArea } = Input;


const PopupInput = (props:
    {
        type: "text" | "number" | "textarea" | "date",
        title: string;
        okayText: string;
        label?: string;
        defaultValue: any,
        onSave: (val: any) => any,

    }) => {
    const { type, defaultValue } = props
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [currentValue, setValue] = useState(defaultValue || null)
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setLoading(true);
        if (currentValue) props.onSave(currentValue);
        setTimeout(() => {
            setLoading(false);
            setVisible(false);
        }, 3000);

    };
    const handleCancel = () => {
        setVisible(false);
    };
    const getCurrentInput = () => {
        let CurrentForm = <></>
        if (type === 'number') {
            CurrentForm = <span>{props.label} <InputNumber defaultValue={defaultValue} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)} /></span>;
        }
        if (type === "textarea") {
            CurrentForm = <span>{props.label} <TextArea row={4} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)} defaultValue={defaultValue} /></span>
        }
        if (type === 'text') {
            CurrentForm = <span>{props.label} <Input onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)} defaultValue={defaultValue} /></span>
        }
        if (type === 'date') {
            CurrentForm =
                <span>
                    {props.label}<br />
                    <DatePicker
                        title={"Birthday Date"}
                        disable={false}
                        date={defaultValue ? (new Date(defaultValue)) : undefined}
                        onChange={(val) => setValue(val)}
                    />
                </span>
        }
        return CurrentForm;
    }

    return (
        <>
            <EditOutlined onClick={showModal} />
            <Modal
                visible={visible}
                title={props.title}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                    >
                        {props.okayText}
                    </Button>,
                ]}


            >
                {getCurrentInput()}
            </Modal>
        </>
    )
}


export default function CustomerDetailInfo2(props:
    {
        data: MerchantCustomer2,
        merchantUsers: MerchantUser[],
        onOwnerUpdate: (ownerId: string) => void,
        onEmailUpdate: (email: string) => void,
        onDOBUpdate: (date: string) => void,
        onAddressUpdate: (address: string) => void
    }) {
    const { data, merchantUsers } = props;
    let address: string | null = null;
    if (data.customer.shipping_address) address = data.customer.shipping_address;
    if (data.customer.shipping_township) address = address ? address + ',' + data.customer.shipping_township : data.customer.shipping_township;
    if (data.customer.shipping_region) address = address ? address + ',' + data.customer.shipping_region : data.customer.shipping_region;
    return (
        <React.Fragment>
            <Card
                style={styles.card}
                title={<Typography.Title level={3} >{data.name || data.customer.name || data.customer.contact_number}</Typography.Title>}
                bordered={false}
                size="small"
            >
                <Row gutter={24}>
                    <Col offset={8} >
                        <Avatar src={data.photo || defaultPhoto} size={120} />
                    </Col>
                </Row>
                <Row gutter={24} style={styles.item}>
                    <Col span={10}>
                        <Typography.Title style={styles.typography}>Owner</Typography.Title>
                    </Col>
                    <Col span={14}>
                        <span>
                            {data.owner?.name || '.....'}
                            <OwnerSelector ownerId={props.data.owner_id || null} users={merchantUsers} onOk={(ownerId: string) => props.onOwnerUpdate(ownerId)} />
                        </span>
                    </Col>
                </Row>
                <Row gutter={24} style={styles.item}>
                    <Col span={10}>
                        <Typography.Title style={styles.typography} strong>Phone</Typography.Title>
                    </Col>
                    <Col span={14}>
                        {data.customer.contact_number}
                    </Col>
                </Row>
                <Row gutter={12} style={styles.item}>
                    <Col span={10}>

                        <Typography.Title style={styles.typography}> Email</Typography.Title>
                    </Col>
                    <Col span={14}>
                        <span>
                            {data?.email || "......"}
                            <PopupInput
                                type="text"
                                label="Email"
                                title="Change Email"
                                defaultValue={data.email || ""}
                                okayText="Save Email"
                                onSave={(val: any) => props.onEmailUpdate(val)}
                            />
                        </span>

                    </Col>
                </Row>
                <Row gutter={12} style={styles.item}>
                    <Col span={10}>
                        <Typography.Title style={styles.typography}> DOB</Typography.Title>
                    </Col>
                    <Col span={14}>
                        <span>
                            {data.date_of_birth ? format(new Date(data.date_of_birth), 'dd MMM,yyyy') : "......."}
                            <PopupInput
                                type="date"
                                label="DOB"
                                title="Change DOB"
                                defaultValue={data.date_of_birth || null}
                                okayText="Save DOB"
                                onSave={(val: any) => props.onDOBUpdate(val)}
                            />
                        </span>

                    </Col>
                </Row>
                <Row gutter={12} style={styles.item}>
                    <Col span={10}>
                        <Typography.Title style={styles.typography}> Address</Typography.Title>
                    </Col>
                    <Col span={14}>
                        <span>
                            {address ? <Typography.Paragraph>{address} </Typography.Paragraph> : "......"}
                            <PopupInput
                                type="textarea"
                                label="Address"
                                title="Update Address"
                                defaultValue={address || ""}
                                okayText="Save Address"
                                onSave={(val: any) => props.onAddressUpdate(val)}
                            />
                        </span>

                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    )
}
const styles = {
    card: {
        height: "100%"
    },
    cardTitle: {
        textAlign: 'center',
    },
    typography: {
        fontSize: 14,
        padding: 2,
    },
    item: {
        marginTop: 5
    },
    cardMeta: {
        justifyContent: "center", display: "flex"
    }
}