import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);
interface P {
    pos: number;
    web: number;
    other: number;
}
export default function PieChart(props: P) {
    const data = {
        labels: ['POS', 'ONLINE'],
        datasets: [
            {
                label: '# of Votes',
                data: [props.pos, props.web],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return <div style={styles.pieContainer}> <Pie data={data} /></div>;
}
const styles = {
    pieContainer: {
        margin: "10%",
    }
}