import React from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Tag,
  Avatar,
  Button,
  List,
  Skeleton,
  Badge,
  Timeline,
  Comment,
  Form,
  Input,
} from "antd";
import { DeliverySearchIcon, LocationIcon } from "../../assets/icons/svg_icons";
import { useParams, Link } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { paths } from "../../routes/paths";
import {
  get_order_byId,
  getOrderByIdVariables,
  merchant_user_byUid,
  getMerchantUserByUidVariables,
  order_delivery_shipments,
  getOrderDeliveryShipmentVariables,
} from "../../gql/gql_query";
import {
  updateOrderMerchantNoteVariables,
  update_order,
} from "../../gql/gql_mutation";
import {
  ArrowsAltOutlined,
  CheckCircleFilled,
  CheckSquareFilled,
  CheckSquareOutlined,
  EditOutlined,
  ExpandAltOutlined,
  ExpandOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import { Divider } from "antd";
import LoadingSpinner from "../../components/loading_spinner";
import { format } from "date-fns";
import { AuthState, MerchantUser, Order, OrderShipment } from "../../store";
import withUser from "../../hocs/with_user";
import defaultPhoto from "../../assets/image/user.png";
import { orderBy } from "lodash";
import { formatMoney } from "../../helpers/util";
import ButttonWithIcon from "../../components/button_with_icon";
import CustomerSelector from "../../components/customer_selector";
const defaultNetworkImage =
  "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=";
const { TextArea } = Input;
const styles = {
  innerCardHeader: {
    backgroundColor: "transparent",
    border: "none",
  },
  cardStyle: {
    backgroundColor: "#fff",
    border: "none",
  },
  cartWithMinSize: {
    height: 50,
  },
  btn: {
    border: "none",
    backgroundColor: "transparent",
  },
};
interface TimeLineVal {
  name: string;
  profile: string;
  comment: string;
  uid: string;
  created_date: Date | string;
}
interface EditorProps {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (comment: string) => void;
  submitting: boolean;
  value: string;
}
interface Props {
  user: AuthState;
}

const Editor = ({ onSubmit, submitting, value }: EditorProps) => {
  const [val, setValue] = React.useState(value);
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };
  const handleSubmit = () => {
    if (!val) return;
    onSubmit(val);
  };
  return (
    <>
      <Form.Item>
        <TextArea rows={3} onChange={onChange} value={val} />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={handleSubmit}
          type="primary">
          Add Comment
        </Button>
      </Form.Item>
    </>
  );
};

function OrderDetail(props: Props) {
  const [showcmd, setcmd] = React.useState(false);
  const [expandLine, setLineExpand] = React.useState(false);
  const { id: orderId } = useParams() as { id: string };
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  const {
    loading: oLoading,
    error: oError,
    data: orderData,
    refetch: oRefetch,
  } = useQuery(get_order_byId, {
    variables: getOrderByIdVariables(orderId),
    fetchPolicy: "network-only",
  });
  const {
    loading: muLoading,
    error: muError,
    data: muData,
  } = useQuery(merchant_user_byUid, {
    variables: getMerchantUserByUidVariables(
      props.user.status === "loggedIn" ? props.user.userInfo?.userId! : ""
    ),
  });
  const {
    loading: shipLoading,
    error: shipError,
    data: shipData,
  } = useQuery(order_delivery_shipments, {
    variables: getOrderDeliveryShipmentVariables(orderId),
  });
  const [addComment, { data: addData, loading: addLoading, error: addError }] =
    useMutation(update_order);
  const handleCommentSubmit = async (cmd: string) => {
    const { order: o } = orderData as { order: Order };
    let lines: TimeLineVal[] = [];
    if (o.merchant_note) {
      const _ls = JSON.parse(o.merchant_note);
      if (Array.isArray(_ls)) {
        lines = _ls;
      }
    }

    if (muData && muData.merchantUser) {
      const mu = muData.merchantUser as MerchantUser;
      const newVal: TimeLineVal = {
        comment: cmd,
        profile: mu.photo || defaultNetworkImage,
        created_date: new Date().toISOString(),
        name: mu.name || "Admin",
        uid: mu.uid,
      };
      lines.push(newVal);
    }
    addComment({
      variables: updateOrderMerchantNoteVariables({
        orderId,
        merchant_note: JSON.stringify(lines),
      }),
    }).then(() => oRefetch());
  };
  const InfoPanel = (props: any) => {
    const { order: o } = orderData as { order: Order };
    let desc_str = `${format(
      new Date(o.created_at),
      "yyyy-MMM-dd HH:mm a"
    )} from ${o.branch?.name || "default branch"}`;
    if (o.cashier_name) {
      desc_str += ` sold by ${o.cashier_name}`;
    }
    let statusColor = "gray";
    if (o.order_status === "COMPLETE") {
      statusColor = "green";
    } else if (
      o.order_status === "MERCHANT_CANCEL" ||
      o.order_status === "CUSTOMER_CANCEL"
    ) {
      statusColor = "red";
    } else {
      statusColor = "orange";
    }
    return (
      <Card type="inner" style={styles.innerCardHeader}>
        <List.Item
          actions={[
            <Typography.Paragraph strong={true}>
              {desc_str}
            </Typography.Paragraph>,
          ]}>
          <List.Item.Meta
            title={
              <Row gutter={6}>
                <Col>
                  {" "}
                  <Typography.Title level={3}>
                    #{o.order_number}
                  </Typography.Title>
                </Col>
                <Col>
                  {" "}
                  <div>
                    <Tag color={statusColor}>{o.order_status}</Tag>
                  </div>
                </Col>
                <Col>
                  <div>
                    <Tag color="cyan">{o.sale_channel}</Tag>
                  </div>
                </Col>
              </Row>
            }
            // description="Dec-12-2022 at 3:30 AM from Mandalay Branch sold by staff"
          />
          <Typography>{""}</Typography>
        </List.Item>
        {/* <Card.Meta
                    style={{ ...styles.innerCardHeader, padding: 0 }}
                    title={
                        <Row style={{ padding: 0 }}>
                            <Typography.Title level={3}>#2344444</Typography.Title>
                            <div><Tag color="green">Completed</Tag></div>
                        </Row>
                    }
                    description="Dec-12-2022 at 3:30 AM from Mandalay Branch sold by staff"
                /> */}
      </Card>
    );
  };
  const LineItems = (props: any) => {
    const { order: o } = orderData as { order: Order };
    let totalLine = 0;
    let line_items: Array<{
      name: string;
      subname?: string;
      qty: number;
      price: number | string;
      total: string | number;
      photo: string;
      productId?: string | null;
    }> = [];
    o.order_items.forEach((oi) => {
      totalLine += 1;
      let subname = oi.stock_item?.name || oi.quick_order_item_name || "";
      const qty = oi.count;
      const photo = oi?.stock_item?.thumbnail_image || "";
      const price = Number(oi.price) / Number(oi.count);
      const total = qty * price;
      let productId: string | null = null;
      let name: string | null = null;
      if (oi.stock_item?.product) {
        name = oi.stock_item.product.name;
        productId = oi.stock_item.product.id;
      }
      line_items.push({
        name: name || subname,
        subname: name && name !== subname ? subname : "",
        total,
        photo,
        price,
        qty,
        productId,
      });
    });
    return (
      <Card
        type="inner"
        size="small"
        headStyle={{ ...styles.innerCardHeader }}
        title={
          <Typography.Title level={5} strong={true}>
            Line Items({totalLine})
          </Typography.Title>
        }
        extra={
          <Row>
            <LocationIcon />
            <Typography.Title level={5} strong={true} mark={true}>
              {o.branch?.name || "default branch"}
            </Typography.Title>
          </Row>
        }>
        <List
          className="demo-loadmore-list"
          loading={false}
          itemLayout="horizontal"
          loadMore={
            totalLine > 5 && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: 12,
                  height: 32,
                  lineHeight: "32px",
                }}>
                {!expandLine ? (
                  <ButttonWithIcon
                    onClick={() => setLineExpand(true)}
                    toolTip="Expand"
                    loading={false}
                    icon={ArrowsAltOutlined}
                    iconStyle={{ ...styles.btn, fontSize: 30 }}
                  />
                ) : (
                  <ButttonWithIcon
                    onClick={() => setLineExpand(false)}
                    toolTip="Collapse"
                    loading={false}
                    icon={ShrinkOutlined}
                    iconStyle={{ ...styles.btn, fontSize: 30 }}
                  />
                )}
              </div>
            )
          }
          dataSource={
            totalLine > 5 && !expandLine ? line_items.slice(0, 5) : line_items
          }
          renderItem={(item: any) => (
            <List.Item
              actions={[
                <Typography>MMK {formatMoney(item.total)}</Typography>,
              ]}>
              <List.Item.Meta
                avatar={
                  <Badge count={item.qty} color="blue">
                    <Avatar src={item.photo} />
                  </Badge>
                }
                title={
                  item.productId ? (
                    <Link to={paths.getProductDetailRoute(item.productId)}>
                      {item.name}
                    </Link>
                  ) : (
                    <Typography.Title level={5}>{item.name}</Typography.Title>
                  )
                }
                description={
                  <Typography.Paragraph>{item.subname} </Typography.Paragraph>
                }
              />
              <Typography.Paragraph
                style={{ minWidth: 250, textAlign: "left" }}>
                MMK {item.price} x {item.qty}
              </Typography.Paragraph>
            </List.Item>
          )}
        />
      </Card>
    );
  };
  const LineSummary = (props: any) => {
    const { order: o } = orderData as { order: Order };
    const len = o.order_items.length;
    let lines: Array<{ name: string; label: string; amount: string }> = [];
    lines.push({
      name: "Subtotal",
      label: `${len} ${len > 0 ? "items" : "item"}`,
      amount: `MMK ${formatMoney(o.total_price)}`,
    });
    if (o.shipping_fee_price && o.shipping_fee_name) {
      lines.push({
        name: "Shipping",
        label: o.shipping_fee_name,
        amount: `MMK ${formatMoney(o.shipping_fee_price)}`,
      });
    }
    if (o.cupon_code && o.cupon_discount) {
      lines.push({
        name: "Coupon Discount",
        label: o.cupon_code,
        amount: `MMK ${formatMoney(o.cupon_discount)}`,
      });
    }
    if (o.discount > 0) {
      lines.push({ name: "Discount", label: "", amount: `MMK ${o.discount}` });
    }
    return (
      <Card
        type="inner"
        size="small"
        headStyle={{ ...styles.innerCardHeader }}
        title={
          <Row gutter={4}>
            <Col>
              {" "}
              <CheckCircleFilled
                style={{
                  color:
                    o.order_status === "PAID" || o.order_status === "COMPLETE"
                      ? "darkblue"
                      : "gray",
                  fontSize: 25,
                }}
              />
            </Col>
            <Col>
              <Typography.Title
                level={5}
                strong={
                  o.order_status === "PAID" || o.order_status === "COMPLETE"
                    ? true
                    : false
                }>
                Paid
              </Typography.Title>
            </Col>
          </Row>
        }>
        <List
          loading={false}
          size="small"
          bordered={false}
          split={false}
          itemLayout="horizontal"
          dataSource={lines}
          renderItem={(item: any) => (
            <List.Item actions={[<Typography>{item.amount}</Typography>]}>
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  title={<Typography>{item.name}</Typography>}
                  // description={ }
                />
                <Typography>{item.label}</Typography>
              </Skeleton>
            </List.Item>
          )}
          loadMore={
            <>
              <Divider
                type="horizontal"
                style={{ margin: 0, paddingBottom: 4 }}
              />
              <List.Item
                style={{ paddingTop: 0, paddingBottom: 0 }}
                actions={[
                  <Typography>MMK {formatMoney(o.grand_total)}</Typography>,
                ]}>
                <List.Item.Meta
                  title={<Typography>{"Total"}</Typography>}
                  // description={ }
                />
                <Typography>{""}</Typography>
              </List.Item>
            </>
          }
        />
      </Card>
    );
  };
  const TimeLine = (props: any) => {
    const { order: o } = orderData as { order: Order };
    let lines: TimeLineVal[] = [];
    if (o.merchant_note) {
      lines = JSON.parse(o.merchant_note);
    }
    return (
      <Card
        style={{ ...styles.innerCardHeader }}
        title={
          <Typography.Title level={5} strong={true}>
            Timeline
          </Typography.Title>
        }
        extra={
          <Row gutter={4}>
            <Col>
              <Button
                style={styles.btn}
                onClick={() => setcmd(!showcmd)}
                icon={
                  showcmd ? (
                    <CheckSquareFilled
                      style={{ color: "blue", fontSize: 20 }}
                    />
                  ) : (
                    <CheckSquareOutlined
                      style={{ color: "gray", fontSize: 20 }}
                    />
                  )
                }
              />
            </Col>
            <Col style={{ padding: 4 }}>
              <Typography.Title level={5} strong={true}>
                Show comments
              </Typography.Title>
            </Col>
          </Row>
        }>
        {showcmd && lines.length === 0 && (
          <Typography.Paragraph italic={true} strong={true}>
            No comments
          </Typography.Paragraph>
        )}
        {showcmd && (
          <Row>
            <Col span={12}>
              <Timeline mode={"left"}>
                {orderBy(lines, "created_date", "desc").map((ln, i) => (
                  <Timeline.Item
                    key={`timeline_${i}`}
                    label={
                      <Row gutter={4}>
                        <Col>
                          {" "}
                          <Typography.Paragraph>
                            {" "}
                            {format(
                              new Date(ln.created_date),
                              "yyyy-MMM-dd HH:mm a"
                            )}
                          </Typography.Paragraph>
                        </Col>
                        <Col>
                          <Typography.Paragraph mark={true}>
                            {" "}
                            {ln.name}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    }
                    color="gray"
                    dot={
                      <Avatar size="small" src={ln.profile || defaultPhoto} />
                    }>
                    <Typography.Paragraph> {ln.comment}</Typography.Paragraph>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Col>
            <Col span={12}>
              <Typography.Text
                strong={true}
                italic={true}
                style={{ paddingLeft: 20 }}>
                Only you and other staff can see comments
              </Typography.Text>
              {muData && muData.merchantUser && (
                <Comment
                  avatar={
                    <Avatar
                      src={
                        (muData.merchantUser as MerchantUser).photo ||
                        defaultPhoto
                      }
                    />
                  }
                  content={
                    <Editor
                      onSubmit={handleCommentSubmit}
                      submitting={addLoading}
                      value={""}
                    />
                  }
                />
              )}
            </Col>
          </Row>
        )}
      </Card>
    );
  };
  const CustomerNote = (props: any) => {
    const { order: o } = orderData as { order: Order };
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            Notes
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}>
        <Typography.Paragraph>
          {" "}
          {o.customer_note || "No notes from customer"}{" "}
        </Typography.Paragraph>
      </Card>
    );
  };

  const CustomerLink = (props: any) => {
    const { order: o } = orderData as { order: Order };
    let _name: string | null = null;
    if (o.merchant_customer?.customer) {
      _name = o.merchant_customer?.name || o.merchant_customer?.customer?.name;
    }
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            Customer
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}
        extra={
          <CustomerSelector
            merchantId={merchantId}
            orderId={o.id}
            onOk={() => oRefetch()}
            customerId={o.merchant_customer?.id}
          />
        }>
        {_name ? (
          <Link
            to={paths.getCustomerDetailRoute(
              o.merchant_customer?.customer?.id!
            )}>
            {" "}
            {_name}
          </Link>
        ) : (
          "No customer"
        )}
      </Card>
    );
  };
  const CustomerContact = (props: any) => {
    const { order: o } = orderData as { order: Order };
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            CONTACT INFORMATION
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}>
        <Typography.Paragraph>
          {" "}
          {o.merchant_customer?.customer?.contact_number ||
            "No phone number"}{" "}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {o.merchant_customer?.email || "No email provided"}{" "}
        </Typography.Paragraph>
      </Card>
    );
  };
  const CustomerShipping = (props: any) => {
    const { order: o } = orderData as { order: Order };
    let addr: string | null = null;
    if (o.shipping_address) {
      addr = o.shipping_address;
    }
    if (o.shipping_region && o.shipping_township) {
      if (addr) addr += `,${o.shipping_township},${o.shipping_region}`;
    }
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            SHIPPING ADDRESS
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}>
        <Typography.Paragraph>
          {" "}
          {addr || "No shipping address provided"}{" "}
        </Typography.Paragraph>
      </Card>
    );
  };
  const CustomerBilling = (props: any) => {
    const { order: o } = orderData as { order: Order };
    let addr: string | null = null;
    if (o.merchant_customer?.customer) {
      if (o.merchant_customer.customer.shipping_address) {
        addr = o.merchant_customer.customer.shipping_address;
      }
      if (
        o.merchant_customer.customer.shipping_region &&
        o.merchant_customer.customer.shipping_township
      ) {
        if (addr)
          addr += `,${o.merchant_customer.customer.shipping_township},${o.merchant_customer.customer.shipping_region}`;
      }
    }
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            BILLING ADDRESS
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}>
        <Typography.Paragraph>
          {" "}
          {addr || "No billing address provided "}{" "}
        </Typography.Paragraph>
      </Card>
    );
  };
  const DeliveryInfo = (props: any) => {
    const { orderShipments: ships = [] } = shipData as {
      orderShipments?: OrderShipment[];
    };
    let agent: string | null = null;
    let trackingNo: string | null = null;
    const len = ships.length;
    if (len > 0) {
      ships?.forEach((sh) => {
        if (!agent) agent = sh.agent;
        if (!trackingNo && sh.type === "ID") trackingNo = sh.agent_ref_no;
      });
    }
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            DELIVERY INFORMATION
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}
        extra={
          agent && (
            <Typography.Paragraph mark={true} strong={true}>
              {agent}
            </Typography.Paragraph>
          )
        }>
        {len === 0 && (
          <Typography.Paragraph>
            {" "}
            {"No delivery information provided"}
          </Typography.Paragraph>
        )}
        {trackingNo && (
          <Row gutter={4}>
            <Col>
              {" "}
              <DeliverySearchIcon />{" "}
            </Col>
            <Col>
              <Typography.Title level={5} strong={true}>
                {trackingNo}
              </Typography.Title>
            </Col>
          </Row>
        )}
        {len > 0 && (
          <Row style={{ marginTop: 5 }}>
            <Timeline mode={"left"}>
              {ships.map((ln, i) => (
                <Timeline.Item
                  key={`deli_ship_${i}`}
                  label={
                    <Row gutter={[0, 4]}>
                      {/* <Col span={24}><Typography.Paragraph strong={true}> </Typography.Paragraph></Col> */}
                      <Col span={24}>
                        <Typography.Paragraph strong={true} italic={true}>
                          {ln.status}{" "}
                          {format(new Date(ln.updated_at), "yyyy-MMM-dd")}
                        </Typography.Paragraph>
                      </Col>
                    </Row>
                  }>
                  <Row gutter={3} style={{ marginTop: 4 }}>
                    <Col span={24}>
                      {" "}
                      <Typography.Paragraph> {ln.comment}</Typography.Paragraph>
                    </Col>
                    <Col span={24}>
                      {" "}
                      <Typography.Paragraph>
                        {" "}
                        {ln.comment_two}
                      </Typography.Paragraph>
                    </Col>
                  </Row>
                </Timeline.Item>
              ))}
            </Timeline>
          </Row>
        )}
      </Card>
    );
  };
  const FeedBackInfo = () => {
    const { order: o } = orderData as { order: Order };
    let fb: any | null = null;
    if (o.metadata) {
      try {
        const meta = JSON.parse(o.metadata);
        if (meta["feedback"]) {
          fb = meta["feedback"];
        }
      } catch (error) {}
    }
    return (
      <Card
        title={
          <Typography.Title level={5} strong={true}>
            FEEDBACK
          </Typography.Title>
        }
        type="inner"
        headStyle={styles.innerCardHeader}
        extra={
          fb && (
            <Typography.Paragraph mark={true} strong={true}>
              {fb?.feedback_button?.name}
            </Typography.Paragraph>
          )
        }>
        {!fb && <Typography.Paragraph> {"No feeback"}</Typography.Paragraph>}
        {fb && (
          <Row gutter={4}>
            <Col>
              {" "}
              <Avatar src={fb?.feedback_button?.image} />{" "}
            </Col>
            <Col>
              <Typography.Title level={5} strong={true}>
                {fb?.comment || ""}
              </Typography.Title>
            </Col>
          </Row>
        )}
      </Card>
    );
  };
  return (
    <React.Fragment>
      {oLoading && <LoadingSpinner />}
      {orderData && orderData.order && (
        <>
          <Row>
            <Col span={24}>
              <InfoPanel />
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={16}>
              <Row gutter={[2, 8]}>
                <Col span={24}>
                  <LineItems />
                </Col>
                <Col span={24}>
                  <LineSummary />
                </Col>
                <Col span={24}>
                  <TimeLine />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <CustomerNote />
                </Col>
                <Col span={24} style={{ marginTop: 6 }}>
                  <CustomerLink />
                </Col>
                <Col span={24}>
                  <FeedBackInfo />
                </Col>
                <Col span={24}>
                  <CustomerContact />
                </Col>
                <Col span={24}>
                  <CustomerShipping />
                </Col>
                <Col span={24}>
                  <CustomerBilling />
                </Col>

                <Col span={24}>
                  <DeliveryInfo />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  );
}
export default withUser(OrderDetail) as any;
