import React, { useEffect, useState } from "react";
import { getDoc, doc, getFirestore } from "@firebase/firestore";
import { paths } from "../routes/paths";
import { connect } from "react-redux";
import { AppState, AuthState } from "../store";
import { Layout, Menu, Tooltip } from "antd";
import { actions } from "../store/actions";
import logo from "../assets/image/admin.png";
import { availableCRM } from "../helpers/crm_helper";
import { FaRegMoneyBillAlt } from "react-icons/fa";

import {
  DashboradIcon,
  DiscountIcon,
  RewardIcon,
  MoneyIcon,
  SocialIcon,
  UserGroupIcon,
  InventoryIcon,
  OrderIcon,
  ReportIcon,
  LogoutIcon,
  ProductIcon,
  DelvieryIcon,
} from "../assets/icons/svg_icons";
const { SubMenu } = Menu;
const { Sider } = Layout;
interface P {
  authState: AuthState;
  onMenuClik: (item: { key: string }) => void;
  openSider: boolean;
  currentMenuItem?: string;
  logout: () => void;
}
type S = { logo: string | null };
const menuList = [
  {
    isMain: false,
    crm: false,
    label: "Dashboard",
    icon: DashboradIcon,
    path: paths.dashboard,
    sub: [
      { label: "Sale Dashboard", path: paths.saleDashboard },
      { label: "Order Dashboard", path: paths.orderDashboard },
    ],
  },
  {
    isMain: false,
    crm: false,
    label: "Report",
    icon: ReportIcon,
    path: paths.report,
    sub: [
      { label: "Sale", path: paths.saleReport },
      { label: "Order", path: paths.orders },
      // { label: "Expense", path: paths.expense },
    ],
  },
  {
    isMain: false,
    crm: false,
    label: "Expense",
    icon: ReportIcon,

    path: paths.report,
    sub: [
      { label: "Expense", path: paths.expense },
      { label: "Income", path: paths.income },
      { label: "Piti Income", path: paths.pitiIncome },
      // { label: "Summary", path: paths.summary },
    ],
  },
  // {
  //     isMain: true,
  //     crm: false,
  //     label: "Inventory",
  //     icon: InventoryIcon,
  //     path: paths.productStocks,
  //     sub: [],
  // },
  {
    isMain: false,
    crm: false,
    label: "Product",
    icon: ProductIcon,
    path: paths.products,
    sub: [
      { label: "Product Inventory", path: paths.productStocks },
      { label: "Product Price", path: paths.productPrices },
      { label: "Product List", path: paths.products },
      { label: "Product Sorting", path: paths.productSort },
    ],
  },
  // {
  //     isMain: true,
  //     label: "Orders",
  //     icon: OrderIcon,
  //     path: paths.orders,
  //     sub: [],
  // },
  {
    isMain: true,
    label: "Coupons",
    crm: false,
    icon: DiscountIcon,
    path: paths.coupons,
    sub: [],
  },
  {
    isMain: true,
    crm: true,
    label: "Customers",
    icon: UserGroupIcon,
    path: paths.customers,
    sub: [],
  },
  {
    isMain: true,
    crm: false,
    label: "Shipping Fee",
    icon: DelvieryIcon,
    path: paths.shippingFees,
    sub: [],
  },
  {
    isMain: false,
    crm: false,
    label: "Loyalty",
    icon: RewardIcon,
    path: paths.pointDashboard,
    sub: [
      { label: "Point Dashboard", path: paths.pointDashboard },
      { label: "Member Dashboard", path: paths.memberDashboard },
      { label: "Reward Dashboard", path: paths.rewardDashboard },
      { label: "Point History", path: paths.pointHistory },
      { label: "Reward History", path: paths.rewardHistory },
      { label: "Point Expire", path: paths.pointExpire },
      { label: "All Members", path: paths.members },
      { label: "Top Members", path: paths.topMembers },
      { label: "Tier Members", path: paths.tierMember },
      { label: "Active Members", path: paths.activityMember },
    ],
  },
];

const SideNav = (props: P) => {
  const [state, setState] = useState({ logo } as S);
  const isAdmin =
    props.authState.status === "loggedIn" &&
    props.authState.userInfo?.merchantId === "piticafe";
  useEffect(() => {
    if (props.authState.status === "loggedIn") {
      let docId: String | null = null;
      if (props.authState.userInfo?.userId) {
        const uid = props.authState.userInfo.userId;
        if (uid.split("@").length === 2) {
          docId = uid.split("@")[1].split(".")[0];
        }
      }
      if (docId) {
        const ref = doc(getFirestore(), `merchants/${docId}`);
        getDoc(ref).then((doc) => {
          if (doc.exists()) {
            const m = doc.data() as { photo?: string };
            setState({ logo: m.photo || null });
          }
        });
      }
    }
  }, [props]);
  return (
    <React.Fragment>
      <Sider
        className="side-nav"
        collapsible
        collapsed={props.openSider}
        trigger={
          <Tooltip placement="rightTop" title="Logout">
            <span>
              {" "}
              <LogoutIcon
                style={{ color: "red" }}
                onClick={() => props.logout()}
              />
            </span>
          </Tooltip>
        }
        style={{ backgroundColor: "#02080f" }}>
        <div className="logo">
          <img alt={"logo"} src={state.logo || logo} />
          <h2>Admin</h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={
            props.currentMenuItem ? [props.currentMenuItem] : ["home"]
          }
          onClick={props.onMenuClik}>
          {(availableCRM() ? menuList : menuList.filter((m) => !m.crm)).map(
            (item, index) =>
              item.isMain ? (
                <Menu.Item key={item.path} icon={<item.icon />}>
                  <span>{item.label}</span>
                </Menu.Item>
              ) : (
                <SubMenu
                  key={`sub-menu_${index}`}
                  icon={<item.icon />}
                  title={<span>{item.label}</span>}>
                  {item.sub.map((sub) => (
                    <Menu.Item key={sub.path}>{sub.label}</Menu.Item>
                  ))}
                </SubMenu>
              )
          )}
          {isAdmin && (
            <SubMenu
              key={`sub-menu_deli`}
              icon={<DelvieryIcon />}
              title={<span>Delivery</span>}>
              <Menu.Item key={paths.ninjavanPrice}>Ninjavan Price</Menu.Item>
              <Menu.Item key={paths.arlupostPrice}>ArLuPost Price</Menu.Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
    </React.Fragment>
  );
};
const mapStateToProps = (state: AppState) => {
  return {
    openSider: state.menuState.openSideToogle,
    authState: state.authState,
  };
};
const mapStateToDispatch = (dispatch: any) => {
  return {
    logout: () => dispatch(actions.authAction.loggedOut()),
  };
};
export default connect(mapStateToProps, mapStateToDispatch)(SideNav);
