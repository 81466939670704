import { report_v2 } from "./constants";
import { PointHistory, MemberPointActivity, MemberPointSummary, MemberPointMonthly, PointTrend } from "../royalty/state";
import { startOfDay, endOfDay } from "date-fns";
import axios from "axios";

export const getPointHistory = async (data: { merchantId: string, startDate: Date, endDate: Date }) => {
    const { merchantId, startDate, endDate } = data;
    const start = startOfDay(startDate).getTime().toString();
    const end = endOfDay(endDate).getTime().toString();
    const url = report_v2 + '/history/point/' + merchantId + '/' + start + '/' + end;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: PointHistory[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}

export const getPointExpire = async (data: { merchantId: string, startDate: Date, endDate: Date }) => {
    const { merchantId, startDate, endDate } = data;
    const start = startOfDay(startDate).getTime().toString();
    const end = endOfDay(endDate).getTime().toString();
    const url = report_v2 + '/member/expire/' + merchantId + '/' + start + '/' + end;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: MemberPointActivity[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}

export const getPointSummary = async (data: { merchantId: string, startDate?: Date, endDate: Date }) => {
    const { merchantId, startDate, endDate } = data;
    const start = (startDate ? startOfDay(startDate).getTime() : 0).toString();
    const end = endOfDay(endDate).getTime().toString();
    const url = report_v2 + '/pointAndMember/summary/' + merchantId + '/' + start + '/' + end;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: MemberPointSummary[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}
export const getPointMonthly = async (data: { merchantId: string, startDate?: Date, endDate: Date }) => {
    const { merchantId, startDate, endDate } = data;
    const start = (startDate ? startOfDay(startDate).getTime() : 0).toString();
    const end = endOfDay(endDate).getTime().toString();
    const url = report_v2 + '/pointAndMember/monthly/' + merchantId + '/' + start + '/' + end;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: MemberPointMonthly[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}

export const getPointTrend = async (data: { merchantId: string, startDate?: Date, endDate: Date }) => {
    const { merchantId, startDate, endDate } = data;
    const start = (startDate ? startOfDay(startDate).getTime() : 0).toString();
    const end = endOfDay(endDate).getTime().toString();
    const url = report_v2 + '/trend/point/' + merchantId + '/' + start + '/' + end;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: PointTrend[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}

export const getRecentHistory = async (data: { merchantId: string }) => {
    const { merchantId } = data;
    const url = report_v2 + '/recent/point/' + merchantId;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: PointHistory[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}