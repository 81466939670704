import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCE6RIUa7WZivuZauGuFRb7Gx3KLLA3qF8",
    authDomain: "piti-commerce.firebaseapp.com",
    databaseURL: "https://piti-commerce-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "piti-commerce",
    storageBucket: "piti-commerce.appspot.com",
    messagingSenderId: "254558078304",
    appId: "1:254558078304:web:58dfefee45eea259ed8a05",
    measurementId: "G-2TKDZWD7P5"
};

const initApp = () => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
}
export default initApp;