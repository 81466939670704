import { ImportOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation, } from '@apollo/client';
import { Button, Result, Typography, Upload, message, } from 'antd';
import { groupBy } from 'lodash';
import { useState } from 'react';
import { v4 as uid } from "uuid";
import * as XLSX from 'xlsx';
import {
    create_product_many,
    create_psi_many,
    create_variation_many,
    create_variation_option_many,
    getCreatePSIManyVar,
    getCreateProductManyVar,
    getCreateVOManyVar,
    getCreateVariationManyVar,
    getUpdatePSI,
    getUpdateProductVar,
    update_product,
    update_psi
} from '../../gql/gql_import';
import { createCombinations } from '../../helpers/util';
import withUser from '../../hocs/with_user';
import { ExportExcel } from '../../services/xlsx_service';
import { AuthState } from '../../store';

const { Paragraph, Text } = Typography
interface P {
    user: AuthState,
}
interface ImportData {
    productName: string;
    category?: string;
    description: string;
    variation: string;
    options: string
}
interface ImportVariationData {
    productName: string,
    productCategory?: string,
    description?: string
    variation1: string,
    option1: string,
    variation2?: string,
    option2?: string,
    variation3?: string,
    option3?: string,
    price: string | number,
    originalPrice: string | number,
    stock: string | number
    activeInventory: string,
    sku?: string,
    image?: string
}

interface Product {
    id: string;
    name: string;
    image?: string
    description?: string;
    category?: Category;
    images?: string[],
}
interface Category {
    id: string,
    name: string,
}
interface Variation {
    id: string;
    name: string;
    order: number;
    productId: string;
}
interface VariationOption {
    id: string;
    name?: string;
    order: number;
    slug: string;
    variationId: string;
}
interface PSI {
    id: string;
    name: string;
    productId: string;
    isDefault: boolean;
    image?: string
    sku?: string,
    stock?: number | string,
    price?: number | string,
    originalPrice?: number | string,
    variationOptionIds: string[],
    enableInventory?: boolean,
}

const defaultImage = "https://firebasestorage.googleapis.com/v0/b/piti-commerce.appspot.com/o/defaultbox.png?alt=media&token=72c98b74-b8f2-43f1-bf98-7230118fb9a5";

function isEmptyOrNull(value: any) {
    return value === '' || value === null || value === undefined;
}
const ProductImport = (p: P) => {
    const merchantId = p.user.status === 'loggedIn' ? p.user.userInfo?.merchantId! : "";
    const [data, setData] = useState<ImportData[] | ImportVariationData[]>([]);
    const [createProducts, { loading: prodLoading, data: prodData }] = useMutation(create_product_many);
    const [createVs, { loading: vLoading, data: vData }] = useMutation(create_variation_many);
    const [createVOs, { loading: voLoading, data: voData }] = useMutation(create_variation_option_many)
    const [createPSIs, { loading: psiLoading, data: psiData }] = useMutation(create_psi_many);
    const [updatePSI, { loading: uPSILoading, data: uPSIData }] = useMutation(update_psi);
    const [updateProduct, { loading: uProductloading }] = useMutation(update_product)
    const loading = prodLoading || vLoading || voLoading || psiLoading || uPSILoading || uProductloading;
    const props: any = {
        beforeUpload: async (file: File) => {
            const isExcel = file.type === 'application/vnd.ms-excel' || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isExcel) {
                message.error(`${file.name} is not support excel file`);
            }
            var reader = new FileReader();
            reader.onload = function (e: any) {
                var data = e.target.result;
                var excelData = XLSX.read(data, {
                    type: 'binary'
                });
                let products: any[] = [];
                for (const sheet of excelData.SheetNames) {
                    const sData = XLSX.utils.sheet_to_json(excelData.Sheets[sheet]);
                    products = sData as any[];
                }
                if (products.length > 100) {
                    message.error(`Maximum(100) import limit exceeded`);
                } else {
                    setData(products);
                }
            };
            reader.onerror = function (ex) {
                console.log(ex);
            };
            reader.readAsBinaryString(file);
            return false;
        },
        onChange: (info: any) => {
            console.log("fileinfo", info.fileList);
        },
    };
    const sampleExport = () => {
        const fileName = "sample_products";
        let excelData: ImportData[] = [];
        // product with 1 variations
        excelData.push({ productName: "Single Item", category: "One Category", description: "Add product with only one variation", variation: "default", options: "onlyOne" })
        // product with 2 variations
        excelData.push({ productName: "Shirt", description: "Add product with two variations(size,color)", variation: "Size", options: "small,medium,large" })
        excelData.push({ productName: "Shirt", description: "Add product with two variations(size,color)", variation: "Color", options: "red,green,blue" })
        // product with 3 variations
        excelData.push({ productName: "iPhone", category: "Three Category", description: "Add product with three variations(Model,Screen,Color)", variation: "Model", options: "iPhoneX,iPhoneXR,iPhoneXS" })
        excelData.push({ productName: "iPhone", category: "Three Category", description: "Add product with three variations(Model,Screen,Color)", variation: "Screen", options: "5.8,6.1,6.5" })
        excelData.push({ productName: "iPhone", category: "Three Category", description: "Add product with three variations(Model,Screen,Color)", variation: "Color", options: "black" })
        ExportExcel(excelData, fileName);
    }
    const filterProduct = () => {
        const prodGroups = groupBy(data as ImportData[], 'productName');
        let _prods: Product[] = [];
        let _variations: Variation[] = [];
        let _variationOptions: VariationOption[] = [];
        let _productStockItems: PSI[] = [];
        const prods = Object.keys(prodGroups);
        for (const prod of prods) {
            let count = 0;
            let productName: string | null = null, description: string | null = null, productId = uid(), categoryName: string | null = null;
            const _prod = prodGroups[prod] || [];
            const _ivo: VariationOption[] = [];
            const _iv: Variation[] = [];
            for (const p of _prod) {
                count++;
                if (!productName) productName = p.productName;
                if (!description) description = p.description;
                if (!categoryName && p.category) categoryName = p.category;
                const variationId = uid();
                if (p.variation) {
                    _iv.push({ id: variationId, name: p.variation, productId, order: count });
                    if (p.options) {
                        p.options.split(',').map((vo, i) => {
                            const voId = uid();
                            _ivo.push({ slug: vo, id: `${voId}`, variationId, order: i + 1 });
                        })
                    }
                }
            }
            const _ivoDic = groupBy(_ivo, 'id')
            if (productName && _iv.length > 0 && _ivo.length > 0) {
                let category: Category | undefined = undefined;
                if (categoryName) {
                    const catId = `${merchantId}_${categoryName.toLocaleLowerCase()}`;
                    category = { id: catId, name: categoryName };
                }
                _prods.push({ id: productId, name: productName, description: description || undefined, category, image: defaultImage, images: [defaultImage] });
                _variations = _variations.concat(_iv);
                _variationOptions = _variationOptions.concat(_ivo)
                let _fields: any[] = [];
                const _voGp = groupBy(_ivo, 'variationId')
                Object.keys(_voGp).map(o => _fields.push(_voGp[o].map(o2 => o2.id)))
                const psi_list = createCombinations(_fields)

                let _psi: PSI[] = [];
                let j = 0;
                for (const psi of psi_list) {
                    const psiId = uid();
                    const psiName = psi.split(',').map((pvoId: any) => _ivoDic[pvoId][0].slug).join(',');
                    _psi.push({ id: psiId, name: psiName, variationOptionIds: psi.split(','), productId, isDefault: j === 0, image: defaultImage });
                    j++;
                }
                if (_psi.length > 0) _productStockItems = _productStockItems.concat(_psi)
            }
        }
        // console.log('products', _prods, '\n variations', _variations, '\n vo', _variationOptions, '\npsi', _productStockItems)
        return { products: _prods, variations: _variations, variationOptions: _variationOptions, productStockItems: _productStockItems };
    }

    const filterProductWithVariation = () => {
        const prodGroups = groupBy(data as ImportVariationData[], 'productName');
        let _prods: Product[] = [];
        let _variations: Variation[] = [];
        let _variationOptions: VariationOption[] = [];
        let _productStockItems: PSI[] = [];

        let _errors: Array<{ name: string, desc: string }> = []

        const prods = Object.keys(prodGroups);
        for (const prodName of prods) {
            const prodData = prodGroups[prodName];
            let productId = uid(), categoryName: string | null = null, productDesc: string | null = null, productImage: string | undefined = undefined;
            let productImages: string[] = [];
            const prodCategory = prodData.filter(pc => !isEmptyOrNull(pc.productCategory));
            if (prodCategory.length > 0) {
                categoryName = prodCategory[0].productCategory!;
            }
            const prodImage = prodData.filter(pi => !isEmptyOrNull(pi.image));
            if (prodImage.length > 0) {
                productImage = prodImage[0].image!;
                productImages = prodImage.map(p => p.image!);
            } else {
                productImages.push(defaultImage)
            }
            const prodDesc = prodData.filter(pc => !isEmptyOrNull(pc.description));
            if (prodDesc.length > 0) {
                productDesc = prodDesc[0].description!;
            }
            let v1: string[] = Array.from(new Set(prodData.map(v11 => v11.variation1).filter(v11 => !isEmptyOrNull(v11))));
            let vo1: string[] = Array.from(new Set(prodData.map(vo11 => vo11.option1).filter(vo11 => !isEmptyOrNull(vo11))));

            let v2: string[] | any[] = Array.from(new Set(prodData.map(v22 => v22.variation2).filter(v22 => !isEmptyOrNull(v22))));
            let vo2: string[] | any[] = Array.from(new Set(prodData.map(vo22 => vo22.option2).filter(vo22 => !isEmptyOrNull(vo22))));

            const _ivo: VariationOption[] = [];
            const _iv: Variation[] = [];

            if (v1.length > 0 && vo1.length > 0) {
                const variationId = uid();
                _iv.push({ id: variationId, name: v1[0], productId, order: 1 });
                vo1.map((vo, i) => {
                    const voId = uid();
                    _ivo.push({ slug: vo, id: `${voId}`, variationId, order: i + 1 });
                });
            }
            if (v2.length > 0 && vo2.length > 0) {
                const variationId = uid();
                _iv.push({ id: variationId, name: v2[0], productId, order: 2 });
                vo2.map((vo, i) => {
                    const voId = uid();
                    _ivo.push({ slug: vo, id: `${voId}`, variationId, order: i + 1 });
                });
            }
            let _fields: any[] = [];
            const _voGp = groupBy(_ivo, 'variationId')
            Object.keys(_voGp).map(o => _fields.push(_voGp[o].map(o2 => o2.id)))
            const pairs = createCombinations(_fields) as string[];

            if (pairs.length === prodData.length) {
                let category: Category | undefined = undefined;
                if (categoryName) {
                    const catId = `${merchantId}_${categoryName.toLocaleLowerCase()}`;
                    category = { id: catId, name: categoryName };
                }
                let _psi: PSI[] = [];
                const _ivoDic = groupBy(_ivo, 'id')
                // console.log('combination pair', pairs.length, 'productName', prodName);

                const psiDic: Record<string, ImportVariationData> = {}

                prodData.forEach(item => {
                    const key = item.option2 ? `${item.option1},${item.option2}` : item.option1;
                    // Add to dictionary
                    psiDic[key] = item;
                });
                for (const [idx, psi] of pairs.entries()) {
                    const psiId = uid();
                    const psiName = psi.split(',').map((pvoId: any) => _ivoDic[pvoId][0].slug).join(',');
                    const psiValue = psiDic[psiName];
                    if (psiValue) {
                        _psi.push({
                            id: psiId,
                            name: psiName, variationOptionIds: psi.split(','), productId,
                            isDefault: idx === 0, price: `${psiValue.price}`, originalPrice: `${psiValue.originalPrice}`,
                            image: !isEmptyOrNull(psiValue.image) ? psiValue.image : undefined, sku: psiValue.sku,
                            stock: psiValue.stock,
                            enableInventory: (psiValue.activeInventory || "no").toLocaleLowerCase() === "yes"
                        });
                    }
                }
                if (_psi.length === pairs.length) {
                    _prods.push({ id: productId, name: prodName, description: productDesc || undefined, category, image: productImage || defaultImage, images: productImages });
                    _variations = _variations.concat(_iv);
                    _variationOptions = _variationOptions.concat(_ivo);
                    _productStockItems = _productStockItems.concat(_psi)
                } else {
                    _errors.push({ name: `Product (${prodName})`, desc: "Invalid product data" })
                }
            }
        }
        if (_errors.length > 0) {
            const err = _errors[0];
            message.error(`${err.name} : ${err.name}`);
        }

        return { products: _prods, variations: _variations, variationOptions: _variationOptions, productStockItems: _productStockItems };
    }

    const handleImport = async () => {
        const filterProductResult = filterProduct();
        const { products, productStockItems, variationOptions, variations } = filterProductResult.products.length > 0 ? filterProductResult : filterProductWithVariation();
        if (products.length < 0) return;
        await createProducts({
            variables: getCreateProductManyVar(products.map(p => {
                return { id: p.id, name: p.name, image: p.image ?? defaultImage, thumbnail_image: p.image ?? defaultImage, merchant_id: merchantId, description: p.description, base_sku: `${new Date().getTime()}` }
            }))
        });
        for (const p of products) {
            if (p.category) {
                await updateProduct({ variables: getUpdateProductVar({ id: p.id, category: p.category, merchantId, images: p.images }) })
            }
        }
        await createVs({
            variables: getCreateVariationManyVar(variations.map(v => {
                return { id: v.id, name: v.name, order: v.order, product_id: v.productId }
            }))
        });
        await createVOs({
            variables: getCreateVOManyVar(variationOptions.map(vo => {
                return { id: vo.id, slug: vo.slug, sku: "", variation_id: vo.variationId }
            }))
        })
        await createPSIs({
            variables: getCreatePSIManyVar(productStockItems.map(p => {
                return { id: p.id, image: p.image ?? defaultImage, name: p.name, product_id: p.productId, thumbnail_image: p.image ?? defaultImage, price: p.price ?? "0", original_price: p.originalPrice ?? "0", stock: p.stock ?? 0, active_inventory_control: p.enableInventory, sku: p.sku ?? "" }
            }))
        });
        for (const p of productStockItems) {
            await updatePSI({
                variables: getUpdatePSI({
                    id: p.id,
                    voIds: p.variationOptionIds.map(pvo => {
                        return { id: pvo };
                    }),
                    productId: p.productId,
                    isDefault: p.isDefault
                })
            });
        }
        message.success(`successfully imported ${data.length} products`);
        setData([]);
    }



    const sampleVariationExport = () => {
        const fileName = "sample_variation_products";
        let excelData: ImportVariationData[] = [];
        // product with 1 variations
        excelData.push({ productName: "Shampoo", productCategory: "Hair Care", description: "", variation1: "Size", option1: "10 ml", variation2: "", option2: "", activeInventory: "No", price: 100, originalPrice: 1200, stock: 0, sku: "SKU-0001", image: "" });
        excelData.push({ productName: "Shampoo", productCategory: "", description: "", variation1: "", option1: "20 ml", variation2: "", option2: "", activeInventory: "Yes", price: 100, originalPrice: 100, stock: 2, sku: "SKU-0002", image: "" });
        excelData.push({ productName: "Shampoo", productCategory: "", description: "", variation1: "", option1: "30 ml", variation2: "", option2: "", activeInventory: "Yes", price: 100, originalPrice: 100, stock: 3, sku: "SKU-0003", image: "" });

        // product with 2 variations
        excelData.push({ productName: "Shirt", productCategory: "Fashion", description: "Experience unmatched comfort and style with our Premium Cotton Shirt. Designed for a perfect fit, this versatile shirt features breathable fabric, durable stitching, and a sleek finish. Whether dressing up or keeping it casual, this shirt is your go-to choice for any occasion", variation1: "Size", option1: "small", variation2: "Color", option2: "red", activeInventory: "Yes", price: 100, originalPrice: 100, stock: 23, sku: "SKU-0004", image: "" })
        excelData.push({ productName: "Shirt", productCategory: "", description: "", variation1: "", option1: "small", variation2: "", option2: "green", activeInventory: "Yes", price: 1200, originalPrice: 1400, stock: 3, sku: "SKU-0005", image: "" })
        excelData.push({ productName: "Shirt", productCategory: "", description: "", variation1: "", option1: "small", variation2: "", option2: "blue", activeInventory: "No", price: 1200, originalPrice: 1400, stock: 4, sku: "SKU-0006", image: "" })
        excelData.push({ productName: "Shirt", productCategory: "", description: "", variation1: "", option1: "medium", variation2: "", option2: "red", activeInventory: "No", price: 1200, originalPrice: 1200, stock: 5, sku: "SKU-0007", image: "" })
        excelData.push({ productName: "Shirt", productCategory: "", description: "", variation1: "", option1: "medium", variation2: "", option2: "green", activeInventory: "No", price: 1300, originalPrice: 1400, stock: 6, sku: "SKU-0008", image: "" })
        excelData.push({ productName: "Shirt", productCategory: "", description: "", variation1: "", option1: "medium", variation2: "", option2: "blue", activeInventory: "Yes", price: 1400, originalPrice: 1450, stock: 7, sku: "SKU-0010", image: "" })

        ExportExcel(excelData, fileName);
    }

    const handleVariationImport = () => {

    }

    return (
        <Result
            status="info"
            icon={<ImportOutlined />}
            title="Product excel import!"
            subTitle="Download a sample sample xls file and compare it to your import file to ensure you have the file perfect for the import."
            extra={[
                <Button type="primary" key="console" onClick={sampleExport}>
                    Download sample
                </Button>,
                <Button type="primary" key="console" onClick={sampleVariationExport}>
                    Download Variation Sample
                </Button>,
                <br />,
                <div style={{ height: "30px" }} />,
                <Upload
                    accept=".xlsx, .xls"
                    showUploadList={false}
                    maxCount={1}
                    {...props}
                >
                    <Button icon={<UploadOutlined />}>Upload Excel File</Button>
                </Upload>,
            ]
            }
        >
            {data.length > 0 && <div style={{ display: "flex", alignItems: "center", flexDirection: 'column' }}>
                <Paragraph >
                    <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        The content you uploaded has {data.length} {data.length > 1 ? "rows" : "row"}:
                    </Text>

                </Paragraph>
                <Button onClick={handleImport} type="primary" loading={loading} disabled={data.length <= 0}>Import</Button>
            </div>
            }
        </Result>
    );

}
export default withUser(ProductImport);