import { setContext } from "@apollo/client/link/context";
import { access_token } from "../../constants/gql";

const authLink = setContext((req, previousContext) => {
    const { headers = {} } = previousContext;
    const token = access_token //localStorage.getItem("token");
    return {
        headers: {  
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

export default authLink;
