import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../components/loading_spinner';
import { Link } from 'react-router-dom';
import { Col, Row, Typography, } from "antd";
import { subDays, format, differenceInDays } from "date-fns";
import * as _ from 'lodash';
import DateRange from '../../components/date_range';
import { paths } from "../../routes/paths";
import PanelCard from '../../components/panel_card';
import CircleProgress from '../../components/circle_progress';
import { getPointSummary, getPointMonthly, } from "../../api/point_api";
import { getMemberTrend, getMemberActivity } from "../../api/member_api";
import { AuthState, Order } from '../../store';
import { formatMoney, numFormatter } from '../../helpers/util';
import withUser from '../../hocs/with_user';
import { MemberPointMonthly, MemberPointSummary, MemberTrend, MemberPointActivity } from '../state';
import PointLineChart from '../../charts/point_dashboard_linechart';
import EarnSpendChart from '../../charts/earn_spend_stackbar';
import RecentHistory from '../history/recent_member_history';
import RecentTopHistory from '../history/recent_top_member';
import ActivityBarChart from '../../charts/activity_barchart';
import { Card } from 'antd';
const panelList = [
    {
        title: "Total Member",
        key: "totalMember",
        totalKey: "totalMember",
        icon: CircleProgress,
        isIcon: false,
        color: "green",
        value: 0,
        isMoney: false,
    },
    {
        title: "Reward Point",
        key: "reward",
        totalKey: "totalPoint",
        icon: CircleProgress,
        isIcon: false,
        color: "blue",
        value: 0,
        isMoney: false,
    },
    {
        title: "Redeem Point",
        key: "redeem",
        totalKey: "totalPoint",
        icon: CircleProgress,
        isIcon: false,
        color: "blue",
        value: 0,
        isMoney: false,
    },
    {
        title: "Earn Engage",
        key: "earn",
        totalKey: "totalEngage",
        icon: CircleProgress,
        isIcon: false,
        color: "yellow",
        value: 0,
        isMoney: false,
    },
    // {
    //     title: "Spend Engage",
    //     key: "spend",
    //     totalKey: "totalEngage",
    //     icon: CircleProgress,
    //     color: "gray",
    //     value: 0,
    //     isIcon: false,
    //     isMoney: false,
    // }
]
interface P {
    user: AuthState
}
type S = { status: "loading" } | { status: "loaded", data: MemberPointSummary } | { status: "error", msg: string };
type M = { status: "loading" } | { status: "loaded", data: MemberPointActivity[] } | { status: "error", msg: string };
type T = { status: "loading" } | { status: "loaded", data: MemberTrend[] } | { status: "error", msg: string };
const Dashboard = (props: P) => {
    const [summary, setSummary] = useState({ status: "loading" } as S);
    const [trend, setTrend] = useState({ status: 'loading' } as T);
    const [monthly, setMonth] = useState({ status: 'loading' } as M);
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 3 * 30), endDate: new Date() });
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    useEffect(() => {
        if (merchantId) {
            getPointSummary({ merchantId, startDate: ranges.startDate, endDate: ranges.endDate }).then(res => {
                if (res.success) {
                    const data = res.data && res.data.length == 1 ? res.data[0] : { earnEngages: 0, spendEngages: 0, earnPoints: 0, spendPoints: 0, totalMembers: 0 } as MemberPointSummary;
                    setSummary({ status: 'loaded', data })
                } else {
                    setSummary({ status: "error", msg: res.msg! })
                }
            })
        }
    }, [ranges]);
    useEffect(() => {
        if (merchantId) {
            getMemberTrend({ merchantId, startDate: ranges.startDate, endDate: ranges.endDate }).then(res => {
                if (res.success) {
                    setTrend({ status: 'loaded', data: (res.data || []) })
                } else {
                    setSummary({ status: "error", msg: res.msg! })
                }
            })
        }
    }, [ranges]);
    useEffect(() => {
        if (merchantId) {
            getMemberActivity({ merchantId, startDate: ranges.startDate, endDate: ranges.endDate }).then(res => {
                if (res.success) {
                    setMonth({ status: 'loaded', data: (res.data || []) })
                } else {
                    setMonth({ status: "error", msg: res.msg! })
                }
            })
        }
    }, [ranges]);

    const onSelect = async (startDate: Date, endDate: Date) => {
        setSummary({ status: 'loading' });
        setTrend({ status: 'loading' });
        setMonth({ status: 'loading' })
        setRanges({ startDate, endDate });
    }
    const panelData = () => {
        let totalEngage = 0, spend = 0, earn = 0, totalPoint = 0, reward = 0, redeem = 0, totalMember = 0;
        if (summary.status === 'loaded') {
            totalEngage = (summary.data.earnEngages || 0) + (summary.data.spendEngages || 0);
            totalPoint = (summary.data.earnPoints || 0) + ((summary.data.spendPoints || 0) * -1);
            spend = summary.data.spendEngages || 0;
            earn = summary.data.earnEngages || 0;
            reward = summary.data.earnPoints || 0;
            redeem = (summary.data.spendPoints || 0) * -1;
            totalMember = summary.data.TotalMembers || 0;
        }
        return { totalEngage, totalPoint, spend, earn, reward, redeem, totalMember }
    }
    const control = () => (
        <Row>
            <Col flex={4}>
                <Row >
                    <Col span={12}><Typography.Text level={3} strong> Member Dashboard </Typography.Text></Col>
                    <Col span={12} > </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
            </Col>
        </Row>
    );
    const panels = () => {
        const data = panelData() as any;
        return (
            <Row style={{ marginTop: 4 }} gutter={[48, 16]}>
                {
                    panelList.map(panel => (
                        <Col span={6} >
                            <PanelCard
                                loading={false}
                                icon={!panel.isIcon &&
                                    <panel.icon width={80} total={data[panel.totalKey]} value={data[panel.key]} color={panel.color || "blue"} showPercent={true} />
                                }
                                title={panel.title}
                                primary={panel.isMoney ? formatMoney(data[panel.key]) : numFormatter(data[panel.key])} secondary={panel.isMoney ? "MMK" : null} />
                        </Col>)
                    )
                }
            </Row>
        );
    }
    const trendData = () => {
        let _labels: string[] = [], _data: string[] = [];
        let _temp: Array<{ sort: number, value: any, date: string }> = [];
        const data = trend.status === "loaded" ? trend.data : [];
        const days = _.groupBy(data, 'joinedDate');
        Object.keys(days).forEach(day => {
            let total = 0, sort = new Date(day).getTime(), date = day;
            days[day].forEach(t => {
                total += t.memberCount;
            })
            _temp.push({ sort, date, value: total })
        });
        _.orderBy(_temp, 'sort', 'asc').forEach(t => {
            _labels.push(format(new Date(t.date), 'yyyy-MMM-dd'))
            _data.push(t.value)
        })
        return { _labels, _data };
    }
    const pointTrend = () => {
        const data = trendData();
        return (
            <Row gutter={24} style={{ marginTop: 4 }}>
                <Col span={24}>
                    <Card style={styles.card}>
                        <PointLineChart title="Member Trend" lables={data._labels} data={data._data} />
                    </Card>
                </Col>
            </Row>
        )
    }
    const activityData = () => {
        let _labels: string[] = [], _data: any[] = [];
        _labels = ['Active', 'Inactive', 'New', 'Sleep', 'At Risk', 'Return', 'Red Alert', 'Royal']
        let riskMembers = 0, redMembers = 0, royalMembers = 0, returnMembers = 0;
        let activeMembers = 0, inActiveMembers = 0, newMembers = 0, sleepMembers = 0;
        const data = monthly.status === 'loaded' ? monthly.data : [];
        activeMembers = data.filter(mp => mp.totalPoint > 0).length;
        inActiveMembers = data.filter(mp => mp.totalPoint === 0).length;
        newMembers = data.filter(mp => mp.totalPoint !== 0).length;
        redMembers = data.filter(mp => mp.earnPoint > 0 && differenceInDays(new Date(), mp.lastActiveAt) > 365).length;
        returnMembers = data.filter(mp => mp.earnPoint > 0).length;
        riskMembers = data.filter(mp => mp.earnPoint > 3 && differenceInDays(new Date(), mp.lastActiveAt) > 90 && differenceInDays(new Date(), mp.lastActiveAt) < 365).length;
        royalMembers = data.filter(mp => mp.earnPoint > 3 && differenceInDays(new Date(), mp.lastActiveAt) < 90).length;
        sleepMembers = data.filter(mp => mp.earnPoint > 3 && differenceInDays(new Date(), mp.lastActiveAt) > 90).length;
        _data = [activeMembers, inActiveMembers, newMembers, sleepMembers, riskMembers, returnMembers, redMembers, royalMembers]
        return { _labels, _data }
    }
    const activityRow = () => {
        const data = activityData();
        return (
            <Row gutter={24} style={{ marginTop: 4 }}>
                <Col span={24}>
                    <Card>
                        <ActivityBarChart lable="Member Count" lables={data._labels} data={data._data} title="Member Activity" />
                    </Card>
                </Col>
            </Row>
        )
    }
    return (
        <React.Fragment>
            {summary.status === 'loading' && <LoadingSpinner />}
            {summary.status === 'loaded' && <> {control()}{panels()}</>}
            {trend.status === 'loaded' && pointTrend()}
            {monthly.status === 'loaded' && activityRow()}
            {summary.status === 'loaded' &&
                <Row gutter={24} style={{ marginTop: 3 }}>
                    <Col span={12}> <RecentHistory merchantId={merchantId} /></Col>
                    <Col span={12}> <RecentTopHistory merchantId={merchantId} /></Col>
                </Row>}
        </React.Fragment>
    )
}
export default withUser(Dashboard);
const styles = {
    card: {
        height: "100%"
    },
    card2: {
        height: "60%"
    }
}