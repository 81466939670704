import React from 'react'
import { Avatar, Table, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import { BoughtTogether } from '../store'
import { paths } from '../routes/paths';
import { format } from 'date-fns';
import { uniqBy } from "lodash";

const calData = (data: BoughtTogether[]) => {
    let _list: BoughtTogether[] = [];
    for (const bt of data) {
        const bc = bt.bought_counts || [];
        let buyCount = 0
        if (bc.length === 1) {
            buyCount = bc[0].buy_count;
        }
        if (bt.main_item_id && bt.sub_item_id && bt.main_item_id === bt.sub_item_id) {
            _list.push({
                main_item: bt.main_item,
                main_item_id: bt.main_item_id,
                total_buy_count: buyCount,
                created_at: bt.created_at,
                updated_at: bt.updated_at,
            });
        } else {
            const mfinder = _list.findIndex(l => l.main_item_id === bt.main_item_id);
            if (mfinder === -1) {
                _list.push({
                    main_item: bt.main_item,
                    main_item_id: bt.main_item_id,
                    total_buy_count: buyCount,
                    created_at: bt.created_at,
                    updated_at: bt.updated_at,
                });
            }
            const sfinder = _list.findIndex(l => l.main_item_id === bt.sub_item_id);
            if (sfinder === -1) {
                _list.push({
                    main_item: bt.sub_item,
                    main_item_id: bt.sub_item_id,
                    total_buy_count: buyCount,
                    created_at: bt.created_at,
                    updated_at: bt.updated_at,
                });
            }
        }
    }
    return uniqBy(_list, 'main_item_id');
}
export default function CustomerBI(props: { data: BoughtTogether[] }) {
    const { data } = props;
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            key: 'photo',

            render: (v: number, record: BoughtTogether) => < Avatar src={record.main_item?.image} size={45} onClick={() => navigate(paths.getProductDetailRoute(record.main_item!.id))} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (v: BoughtTogether, record: BoughtTogether) => {
                return record.main_item?.name;
            },
        },
        {
            title: 'Buy Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: BoughtTogether, b: BoughtTogether) => a.total_buy_count - b.total_buy_count,
            render: (v: BoughtTogether, record: BoughtTogether) => {
                return record.total_buy_count;
            },
        },
        {
            title: 'Last Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a: BoughtTogether, b: BoughtTogether) => new Date(a.updated_at).getDate() - new Date(b.updated_at).getDate(),
            render: (v: BoughtTogether, record: BoughtTogether) => {
                return format(new Date(record.updated_at), 'yyyy-MMM-dd');
            },
        },
    ];
    return (
        <React.Fragment>
            {data.length === 0 ?
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span>
                            Empty Data
                        </span>
                    }
                />
                :
                <Table
                    showHeader={true}
                    columns={columns}
                    dataSource={calData(data)}
                    pagination={{
                        pageSize: 5,
                    }}
                />
            }
        </React.Fragment>
    )
}
