import React from 'react';
import { Card, Row, Col, Typograpy, Badge } from 'antd';
import { Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons'

interface ParentCompProps {
    icon: React.ReactNode;
    title: string;
    primary: any;
    loading: boolean;
    secondary?: any;
}
interface Props extends ParentCompProps {
    title: string;
    loading: boolean;
    primary: any;
    secondary?: any;
}
const PanelCard: React.FC<ParentCompProps> = (props: Props) => {
    const { icon, title, primary, secondary, loading } = props
    return (
        <Card
            size="small"
            loading={loading}
            bordered={false}
            hoverable={true}
        >
            <Row gutter={24}>
                <Col span={10} style={{ marginTop: 12, textAlign: 'center' }} >
                    {icon}
                </Col>
                <Col span={14}>
                    <Row>
                        <h1 >{title}</h1>
                    </Row>
                    <Row>
                        <Typography.Title level={3} strong>{primary}</Typography.Title>
                    </Row>

                    <Row>
                        {!secondary && <Badge count={<CheckCircleFilled style={{ color: 'green' }} />} />}
                        {secondary && <Typography.Text italic>{secondary}</Typography.Text>}
                    </Row>
                </Col>
            </Row>
        </Card>

    )
}

export default PanelCard;