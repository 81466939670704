import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../components/loading_spinner';
import { Link } from 'react-router-dom';
import { Col, Row, Typography, Card } from "antd";
import { subDays, format, differenceInDays } from "date-fns";
import * as _ from 'lodash';
import DateRange from '../../components/date_range';
import { paths } from "../../routes/paths";
import PanelCard from '../../components/panel_card';
import CircleProgress from '../../components/circle_progress';
import { getPointSummary, getPointMonthly, } from "../../api/point_api";
import { getMemberTrend, getMemberActivity } from "../../api/member_api";
import { getRewardHistory } from "../../api/reward_api";
import { AuthState, Order } from '../../store';
import { formatMoney, numFormatter } from '../../helpers/util';
import withUser from '../../hocs/with_user';
import { MemberPointSummary, RewardHistory } from '../state';
import LineChart from '../../charts/reward_dashboard_linechart';
import RecentHistory from '../history/reward_summary_history';
import { uniq } from "lodash";
const panelList = [
    {
        title: "Total Reward",
        key: "totalReward",
        totalKey: "totalReward",
        icon: CircleProgress,
        isIcon: false,
        color: "blue",
        value: 0,
        isMoney: false,
    },
    {
        title: "Claimed",
        key: "claimed",
        totalKey: "totalReward",
        icon: CircleProgress,
        isIcon: false,
        color: "green",
        value: 0,
        isMoney: false,
    },
    {
        title: "Redeem",
        key: "redeem",
        totalKey: "totalReward",
        icon: CircleProgress,
        isIcon: false,
        color: "gray",
        value: 0,
        isMoney: false,
    },
    {
        title: "Total Member",
        key: "totalMember",
        totalKey: "totalMember",
        icon: CircleProgress,
        isIcon: false,
        color: "blue",
        value: 0,
        isMoney: false,
    },
]
interface P {
    user: AuthState
}
type S = { status: "loading" } | { status: "loaded", data: RewardHistory[] } | { status: "error", msg: string };
const Dashboard = (props: P) => {
    const [summary, setSummary] = useState({ status: "loading" } as S);
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 3 * 30), endDate: new Date() });
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    useEffect(() => {
        if (merchantId) {
            getRewardHistory({ merchantId, startDate: ranges.startDate, endDate: ranges.endDate }).then(res => {
                if (res.success) {
                    setSummary({ status: 'loaded', data: res.data || [] })
                } else {
                    setSummary({ status: "error", msg: res.msg! })
                }
            })
        }
    }, [ranges]);

    const onSelect = async (startDate: Date, endDate: Date) => {
        setSummary({ status: 'loading' });
        setRanges({ startDate, endDate });
    }
    const panelData = () => {
        let totalReward = 0, totalMember = 0, claimed = 0, redeem = 0;
        let memberIds: string[] = [];
        let _lables: string[] = [], _claimedData: any[] = [], _redeemData: any[] = []
        let _temp: Array<{ sort: number, claimed: number, redeem: number, date: any }> = []
        let _list: Array<{ sort: number, claimed: number, redeem: number, date: any }> = []
        if (summary.status === 'loaded') {
            summary.data.forEach(r => {
                totalReward += 1;
                if (r.status && r.status === 'claimed') claimed += 1;
                if (!r.status) redeem += 1;
                if (r.memberId) memberIds.push(r.memberId);
                if (r.claimedAt) {
                    const d = new Date(Number(r.claimedAt));
                    const date = format(d, 'yyyy-MMM-dd')
                    const sort = d.getTime();
                    _temp.push({ sort, claimed: 1, redeem: 0, date })
                } else {
                    const d = new Date(Number(r.createdAt));
                    const date = format(d, 'yyyy-MMM-dd')
                    const sort = d.getTime();
                    _temp.push({ sort, claimed: 0, redeem: 1, date })
                }
            });
        }
        totalMember = uniq(memberIds).length;
        const days = _.groupBy(_temp, 'date');
        Object.keys(days).forEach(day => {
            let sort = 0, date = day, claimed = 0, redeem = 0;
            days[day].forEach(r => {
                if (r.sort > sort) sort = r.sort;
                claimed += r.claimed;
                redeem += r.redeem;
            });
            _list.push({ sort, date, claimed, redeem })
        })
        _.orderBy(_list, 'sort', 'asc').forEach(r => {
            _lables.push(r.date);
            _claimedData.push(r.claimed);
            _redeemData.push(r.redeem);
        })
        return { totalReward, totalMember, claimed, redeem, _lables, _claimedData, _redeemData }
    }
    const control = () => (
        <Row>
            <Col flex={4}>
                <Row >
                    <Col span={12}><Typography.Text level={3} strong> Reward Dashboard </Typography.Text></Col>
                    <Col span={12} > </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
            </Col>
        </Row>
    );
    const panels = () => {
        const data = panelData() as any;
        return (
            <>
                <Row style={{ marginTop: 4 }} gutter={[48, 16]}>
                    {
                        panelList.map(panel => (
                            <Col span={6} >
                                <PanelCard
                                    loading={false}
                                    icon={!panel.isIcon &&
                                        <panel.icon width={80} total={data[panel.totalKey]} value={data[panel.key]} color={panel.color || "blue"} showPercent={true} />
                                    }
                                    title={panel.title}
                                    primary={panel.isMoney ? formatMoney(data[panel.key]) : numFormatter(data[panel.key])} secondary={panel.isMoney ? "MMK" : null} />
                            </Col>)
                        )
                    }
                </Row>
                <Row gutter={24} style={{ marginTop: 4 }}>
                    <Col span={24}><Card> <LineChart title="Reward Trend" lables={data._lables} label1="Redeem" data1={data._redeemData} label2="Claimed" data2={data._claimedData} /></Card></Col>
                </Row>
            </>
        );
    }

    return (
        <React.Fragment>
            {summary.status === 'loading' && <LoadingSpinner />}
            {summary.status === 'loaded' && <> {control()}{panels()}</>}
            {summary.status === "loaded" && <Row gutter={24} style={{ marginTop: 4 }}><Col span={24}><RecentHistory data={summary.data} /> </Col></Row>}
        </React.Fragment>
    )
}
export default withUser(Dashboard);