import { gql } from '@apollo/client';

export const create_product_many = gql`
mutation CreateManyProduct($data: [ProductCreateManyInput!]!, $skipDuplicates: Boolean) {
    createManyProduct(data: $data, skipDuplicates: $skipDuplicates) {
      count
    }
  }
`;

export const getCreateProductManyVar = (data: Array<{ id: string, merchant_id: string, name: string, image: string, thumbnail_image: string, description?: string }>) => ({
  data,
  "skipDuplicates": false
})

export const create_variation_many = gql`
mutation CreateManyVariation($data: [VariationCreateManyInput!]!, $skipDuplicates: Boolean) {
    createManyVariation(data: $data, skipDuplicates: $skipDuplicates) {
      count
    }
  }
`;
export const getCreateVariationManyVar = (data: Array<{ id: string, product_id: string, name: string, order: number }>) => ({
  data,
  "skipDuplicates": false
})

export const create_variation_option_many = gql`
mutation CreateManyVariationOption($data: [VariationOptionCreateManyInput!]!, $skipDuplicates: Boolean) {
    createManyVariationOption(data: $data, skipDuplicates: $skipDuplicates) {
      count
    }
  }
`;
export const getCreateVOManyVar = (data: Array<{ id: string, slug: string, variation_id: string, sku: string }>) => ({
  data,
  "skipDuplicates": false
})
export const create_psi_many = gql`
mutation CreateManyProductStockItem($data: [ProductStockItemCreateManyInput!]!, $skipDuplicates: Boolean) {
    createManyProductStockItem(data: $data, skipDuplicates: $skipDuplicates) {
      count
    }
  }
`;

export const getCreatePSIManyVar = (data: Array<{ id: string, name: string, image: string, thumbnail_image: string, product_id: string, original_price: string | number }>) => ({
  data,
  "skipDuplicates": false
})

export const update_psi = gql`
mutation UpdateProductStockItem($data: ProductStockItemUpdateInput!, $where: ProductStockItemWhereUniqueInput!) {
    updateProductStockItem(data: $data, where: $where) {
      id
    }
  }
`;

export const getUpdatePSI = (val: { id: string, voIds: Array<{ id: string }>, isDefault: boolean, productId: string }) => {
  const { id, voIds, isDefault, productId } = val;
  let variables: any = {}, data: any = {}
  variables.where = { id };
  variables.data = {
    "variations": {
      "connect": voIds
    }
  }
  if (isDefault) {
    variables.data = {
      ...variables.data,
      "default_product": { "connect": { "id": productId } },
      "default_item": { "set": true }
    }
  }
  return variables;
}
export const update_product = gql`
mutation UpdateProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
  updateProduct(data: $data, where: $where) {
    id
  }
}
`;

export const getUpdateProductVar = (val: { id: string, merchantId: string, category: { id: string, name: string }, images?: string[] }) => {
  const { id, category, merchantId, images = [] } = val;
  let variables: any = {}, data: any = {}
  variables.where = { id };
  variables.data = {
    "product_categories": {
      "connectOrCreate": [
        {
          "where": {
            "id": category.id
          },
          "create": {
            "name": category.name,
            "id": category.id,
            "merchant": {
              "connect": {
                "id": merchantId
              }
            }
          }
        }
      ]
    },
    "product_images": {
      "createMany": {
        "data": images.map(i => ({
          "image": i,
          "name": (new Date()).getTime().toString(),
          "thumbnail_image": i
        }))
      }
    },
    "product_channel": {
      "connect": ["POS", "WEB", "AShop"].map(c => ({ sale_channel: c }))
    }
  }

  return variables;
}