import React from 'react'
import { Button, Tooltip } from "antd";

interface P {
    loading: boolean;
    toolTip?: string;
    transparent?: boolean;
    style?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    icon: React.ElementType,
    onClick: () => void;
}

export default function BtnIcon(props: P) {
    return <Tooltip
        title={props.toolTip} {...props}>
        <Button style={props.style} onClick={props.onClick} icon={<props.icon style={props.iconStyle} />} />
    </Tooltip>
}
