import React, { useState } from 'react'
import { Steps, Button, Card, Select } from 'antd';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as yup from 'yup';
import withUser from '../../hocs/with_user';
import DatePicker from '../../components/date';
import { AuthState, Coupon } from '../../store';
import { paths } from '../../routes/paths';
import { addDays, addMonths, differenceInMonths, startOfDay } from "date-fns";
import { useToasts } from 'react-toast-notifications';
import { RightOutlined, LeftOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { utcDateToZonedDate, zonedDateToUtcDate } from '../../helpers/util';
import { findCoupon, createCoupon } from "../../helpers/gql_helper";
import { useNavigate } from "react-router-dom";
const { Step } = Steps;
const { Option } = Select;
const codeSchema = yup.object().shape({
    code: yup.string().trim('code cannot include leading and trailing spaces').strict(true).required("code is required")

});
const submitSchema = yup.object().shape({
    status: yup.boolean().required("status is required"),
});
const amountSchema = yup.object().shape({
    amount: yup.number().required("amount is required")
        .min(0, 'Min value 0.')
        .max(99999, 'too much amount'),
    minAmount: yup.number().required("min purchase amout is required")
        .min(1, 'amount not enough')
        .max(99999, 'too much amount'),
    priceType: yup.string().required("coupon type is required")
});
const limitSchema = yup.object().shape({
    limit: yup.number().required("customer limit is required")
        .min(-1, 'Min value 1')
        .max(1000, 'too much amount'),
});
const timeSchema = yup.object().shape({
    date: yup.string().required("start date is required"),
    day: yup.number().required("expire day is required")
        .min(0, 'Min value 0.')
        .max(200, 'too much amount'),
});
type priceType = "PERCENTAGE" | "VALUE"
type statusType = "active" | "inactive";
interface codeValue {
    code: string,
}
interface limitValue {
    limit: number,
    total: number,
}
interface amountValue {
    amount: number,
    minAmount: number,
    priceType: priceType
}
interface timeValue {
    date: Date;
    day: number
}
interface submitValue {
    status: statusType
}
interface P {
    user: AuthState
}
const calExpireDaysToMonths = (sDate: Date, days: number) => {
    const startDate = utcDateToZonedDate(sDate);
    const endDate = addDays(startDate, days);
    const months = differenceInMonths(endDate, startDate);
    return months;
}
const calExpireDate = (sDate: Date, days: number) => {
    const startDate = utcDateToZonedDate(sDate);
    const endDate = addDays(startDate, days);
    return zonedDateToUtcDate(endDate);
}
const CreateCoupon = (props: P) => {
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null as string | null);
    const [codeFormData, setCodeFormData] = useState({ code: "" } as codeValue)
    const [submitFormData, setSubmitFormData] = useState({ status: 'active' } as submitValue)
    const [limitFormData, setLimitFormData] = useState({ limit: 0, total: 0 } as limitValue)
    const [amoutFormData, setAmountFormData] = useState({ amount: 0, minAmount: 0, priceType: "VALUE" } as amountValue)
    const [timeFormData, setTimeFormData] = useState({ date: startOfDay(new Date()), day: 0 } as timeValue)
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const CodeStep = () => {
        const onCodeSumit = async (val: codeValue) => {
            setLoading(true)
            setCodeFormData(val);
            const finder = await findCoupon(val.code, merchantId);
            if (finder) {
                setError("Code already exist");
            } else {
                setError(null);
                next()
            }
            setLoading(false)
        }
        return (
            <div style={{ justifyContent: 'center', width: 200 }}>
                <Formik
                    initialValues={codeFormData}
                    validationSchema={codeSchema}
                    onSubmit={onCodeSumit}
                >
                    {({ dirty, values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                        return (
                            <FormikForm autoComplete="off">
                                <label htmlFor="email">Coupon code </label><br />
                                <Field id="code" name="code" placeholder="enter code" type="text" value={values.code} /><br />
                                <ErrorMessage
                                    name="code"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                {error && <span style={{ color: 'red' }}>{error}<br /></span>}
                                <Button type="primary" size="default" disabled={!dirty} icon={loading ? <LoadingOutlined /> : <RightOutlined />} htmlType="submit" >Next</Button>
                            </FormikForm>
                        );
                    }}
                </Formik >
            </div>
        )
    }

    const AmountStep = () => {
        let _amountError: string | null = null;
        const onAmountSubmit = (val: amountValue) => {
            setAmountFormData(val)
            next()
        }
        return (
            <div style={{ justifyContent: 'center', width: 300 }}>
                <Formik
                    initialValues={amoutFormData}
                    validationSchema={amountSchema}
                    onSubmit={onAmountSubmit}
                >
                    {({ dirty, values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                        return (
                            <FormikForm autoComplete="off">
                                <label htmlFor="email">Coupon Amount(Price)</label><br />
                                <Field id="amount" name="amount" value={values.amount} type="number" label="Amount" /><br />
                                <ErrorMessage
                                    name="amount"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                <label htmlFor="email">Minimum purchase amount </label><br />
                                <Field id="minAmount" name="minAmount" value={values.minAmount} type="number" label="Min purchase amount" /><br />
                                <ErrorMessage
                                    name="minAmount"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                <label htmlFor="email"> Price Type </label><br />
                                <Field
                                    id="priceType"
                                    name="priceType"
                                    type="text"
                                    render={() =>
                                    (<Select defaultValue={values.priceType} style={{ width: 120 }} onChange={(val: any) => handleChange('priceType')(val)}>
                                        <Option value={"VALUE"}>Value</Option>
                                        <Option value={"PERCENTAGE"}>Percentage</Option>
                                    </Select>)
                                    }

                                /><br />
                                <ErrorMessage
                                    name="priceType"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                {_amountError && <span style={{ color: 'red' }}>{_amountError}</span>}
                                <Button type="primary" size="default" icon={<LeftOutlined />} onClick={() => prev()}>Back</Button>
                                <Button type="primary" size="default" icon={<RightOutlined />} disabled={!dirty} htmlType="submit" style={{ marginLeft: 3 }} >Next</Button>
                            </FormikForm>
                        );
                    }}
                </Formik >
            </div>
        )
    }
    const LimitStep = () => {
        let _codeError: string | null = null;
        const onLimitSumit = (val: limitValue) => {
            setLimitFormData(val);
            next()
        }
        return (
            <div style={{ justifyContent: 'center', width: 200 }}>
                <Formik
                    initialValues={limitFormData}
                    validationSchema={limitSchema}
                    onSubmit={onLimitSumit}
                >
                    {({ dirty, values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                        return (
                            <FormikForm autoComplete="off">
                                <label htmlFor="email">Per Customer Limit </label><br />
                                <Field id="limit" name="limit" placeholder="per customer limit" type="number" value={values.limit} /><br />
                                <ErrorMessage
                                    name="limit"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                <label htmlFor="email">Coupon total count </label><br />
                                <Field id="total" name="total" placeholder="total count" type="number" value={values.total} /><br />
                                <ErrorMessage
                                    name="total"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                {_codeError && <span style={{ color: 'red' }}>{_codeError}</span>}
                                <Button type="primary" size="default" icon={<LeftOutlined />} onClick={() => prev()} style={{ marginRight: 4 }}>Back</Button>
                                <Button type="primary" size="default" icon={<RightOutlined />} disabled={!dirty} htmlType="submit" >Next</Button>
                            </FormikForm>
                        );
                    }}
                </Formik >
            </div>
        )
    }
    const TimeStep = () => {
        let _amountError: string | null = null;
        const onTimeSubmit = (val: timeValue) => {
            setTimeFormData(val)
            next()
        }
        return (
            <div style={{ justifyContent: 'center', width: 300 }}>
                <Formik
                    initialValues={timeFormData}
                    validationSchema={timeSchema}
                    onSubmit={onTimeSubmit}
                >
                    {({ dirty, values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                        return (
                            <FormikForm autoComplete="off">
                                <label htmlFor="email"> Start Date </label><br />
                                <Field
                                    id="date"
                                    name="date"
                                    type="text"
                                    render={() =>
                                    (
                                        <DatePicker
                                            date={values.date}
                                            timeOn='StartOfDay'
                                            title="Start Date"
                                            onChange={(utc: string) => {
                                                console.log('return of', utc);
                                                setFieldValue("date", utc);
                                            }
                                            }
                                        />
                                    )
                                    }

                                /><br />
                                <ErrorMessage
                                    name="date"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                <label htmlFor="email">Expire Days after start date</label><br />
                                <Field id="day" name="day" value={values.day} type="number" /><br />
                                <ErrorMessage
                                    name="day"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />

                                {_amountError && <span style={{ color: 'red' }}>{_amountError}</span>}
                                <Button type="primary" size="default" icon={<LeftOutlined />} onClick={() => prev()}>Back</Button>
                                <Button type="primary" size="default" icon={<RightOutlined />} disabled={!dirty} htmlType="submit" style={{ marginLeft: 3 }} >Next</Button>
                            </FormikForm>
                        );
                    }}
                </Formik >
            </div>
        )
    }
    const CreateStep = () => {
        const onSubmit = async (val: submitValue) => {
            const code = codeFormData.code;
            const status = val.status === 'active' ? true : false;
            const amount = amoutFormData.amount;
            const minAmount = amoutFormData.minAmount;
            const priceType = amoutFormData.priceType;

            const per_customer_limit = limitFormData.limit;
            const total_count = limitFormData.total;

            const startDate = timeFormData.date;
            const expireDays = timeFormData.day;

            const expireInMonth = calExpireDaysToMonths(startDate, expireDays);
            const expireDate = calExpireDate(startDate, expireDays)

            const createData = {
                id: code,
                code,
                status,
                price: amount,
                min_amount: minAmount,
                price_type: priceType,
                per_customer_limit: per_customer_limit,
                total_count,
                remain_count: total_count,
                start_date: startDate,
                expire_month: expireInMonth,
                expire_date: expireDate,
            } as Coupon;
            setSubmitFormData(val);
            setLoading(true)
            const resData = await createCoupon(createData, merchantId)
            if (resData) {
                addToast(`Success create to ${createData.code}`, { autoDismiss: true, appearance: 'success' })
                setTimeout(() => {
                    navigate(paths.coupons)
                }, 1000)
            } else {
                addToast(`Failed create to ${createData.code}`, { autoDismiss: true, appearance: 'error' });
                setError("failed to create");
            }
            setLoading(false)
            // next()
        }
        return (
            <div style={{ justifyContent: 'center', width: 200 }}>
                <Formik
                    initialValues={submitFormData}
                    // validationSchema={submitSchema}
                    onSubmit={onSubmit}
                >
                    {({ dirty, values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                        return (
                            <FormikForm autoComplete="off">
                                <label htmlFor="email">Active Status</label><br />
                                <Field
                                    id="status"
                                    name="status"
                                    type="text"
                                    render={() =>
                                    (<Select defaultValue={values.status} style={{ width: 100 }} onChange={(status: any) => handleChange('status')(status)}>
                                        <Option value={"active"}>Active</Option>
                                        <Option value={"inactive"}>Inactive</Option>
                                    </Select>)
                                    }

                                /><br />
                                <ErrorMessage
                                    name="status"
                                    component="div"
                                    className="field-error"
                                    render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                /> <br />
                                {error && <span style={{ color: 'red' }}>{error}</span>}<br />
                                <Button type="primary" size="default" icon={<LeftOutlined />} onClick={() => prev()} style={{ marginRight: 4 }}>Back</Button>
                                <Button type="primary" size="default" icon={loading ? <LoadingOutlined /> : <SaveOutlined />} disabled={loading} htmlType="submit" >Create</Button>
                            </FormikForm>
                        );
                    }}
                </Formik >
            </div>
        )
    }
    const steps = [
        {
            title: 'Code',
            content: <CodeStep />,
        },
        {
            title: 'Amount',
            content: <AmountStep />,
        },
        {
            title: 'Limit',
            content: <LimitStep />,
        },
        {
            title: 'Time',
            content: <TimeStep />,
        },
        {
            title: 'Submit',
            content: <CreateStep />,
        },
    ];
    return (
        <Card size="small" title="Create coupon">
            <Steps current={current} size="small" style={{ padding: 40, width: '70%' }}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <div style={{ alignContent: 'center', paddingLeft: 40 }}>{steps[current].content}</div>
        </Card>
    );
};

export default withUser(CreateCoupon) as any;