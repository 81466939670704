import React from 'react'
import { Progress } from "antd";
import { numFormatter } from '../helpers/util';
interface Props {
    total: number;
    value: number;
    width: number;
    showPercent?: boolean;
    showLableOnly?: boolean;
    label?: string;
    color: string
}

export default function CircleProgress(props: Props) {
    const { total, value, width, showPercent, color } = props
    const calPercent = () => {
        return (value / total) * 100
    }
    const percentValue = () => Math.round(calPercent());
    return (
        <Progress
            type="circle"
            strokeColor={{
                from: color,
                to: color,
            }}
            width={width}
            percent={calPercent()}
            format={(percent: any) =>
                showPercent ?
                    `${percentValue()}%` :
                    props.showLableOnly ?
                        `${props.label || ""}` :
                        `${numFormatter(value)} ${props.label}`}
        />
    )
}
