import { report_v1, report_v2 } from "./constants";
import {
  Member,
  TopMember,
  MemberPointActivity,
  MemberTrend,
  MemberDetail,
} from "../royalty/state";
import { startOfDay, endOfDay } from "date-fns";
import axios from "axios";

export const getAllMember = async (merchantId: string) => {
  const url = report_v1 + "/getAllMember/" + merchantId;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: Member[];
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data || [] };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};

export const getAllMembers = async (merchantId: string, offset: number, limit: number = 50) => {
  const url = report_v1 + "/getAllMember/" + merchantId + `?limit=${limit}&offset=${offset}`;
  const res = await axios.get(url);
  if (res.status === 200) {

    const { status, data, code, total = 0 } = res.data as {
      status: "Ok" | "error";
      data?: Member[];
      total?: number
      code?: string;
    };
    console.log("getAllMembers", res)
    if (status === "Ok") {
      return { success: true, data: data || [], total };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};

export const getTierMember = async (merchantId: string) => {
  const url = report_v1 + "/member/topMemberSummary/" + merchantId;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: TopMember[];
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data || [] };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};

export const getMemberActivity = async (data: {
  merchantId: string;
  startDate?: Date;
  endDate: Date;
}) => {
  const { merchantId, startDate, endDate } = data;
  const start = (startDate ? startOfDay(startDate).getTime() : 0).toString();
  const end = endOfDay(endDate).getTime().toString();
  const url =
    report_v2 +
    "/pointAndMember/activity/" +
    merchantId +
    "/" +
    start +
    "/" +
    end;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: MemberPointActivity[];
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data || [] };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};

export const getMemberTrend = async (data: {
  merchantId: string;
  startDate?: Date;
  endDate: Date;
}) => {
  const { merchantId, startDate, endDate } = data;
  const start = (startDate ? startOfDay(startDate).getTime() : 0).toString();
  const end = endOfDay(endDate).getTime().toString();
  const url =
    report_v2 + "/trend/member/" + merchantId + "/" + start + "/" + end;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: MemberTrend[];
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data || [] };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};
export const getRecentMember = async (data: { merchantId: string }) => {
  const { merchantId } = data;
  const url = report_v2 + "/recent/member/" + merchantId;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: Member[];
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data || [] };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};
export const getRecentTopMember = async (data: { merchantId: string }) => {
  const { merchantId } = data;
  const url = report_v2 + "/recent/topMember/" + merchantId;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: TopMember[];
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data || [] };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};

export const getMemberDetail = async (data: {
  merchantId: string;
  memberId: string;
}) => {
  const { merchantId, memberId } = data;
  const url = report_v2 + "/member/detail/" + merchantId + "/" + memberId;
  const options = {
    method: "get",
    headers: { ["Content-Type"]: "application/json" },
  } as any;
  const res = await axios(url, { ...options });
  if (res.status === 200) {
    const { status, data, code } = res.data as {
      status: "Ok" | "error";
      data?: MemberDetail;
      code?: string;
    };
    if (status === "Ok") {
      return { success: true, data: data };
    }
    return { success: false, msg: code || "Bad reqeust" };
  }
  return { success: false, msg: "Something was wrong" };
};
