import React, { useState } from 'react';
import { Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

interface SOption {
    value: string,
    label: string,
}
interface P {
    options: SOption[],
    onChange: (val: string[]) => void,
    selected: string[],
    placeholder?: string,
}
const C = (props: P) => {
    const [selectedItems, setSelectedItems] = useState<string[]>(props.selected);
    const optDic = Object.assign({}, ...props.options.map((x) => ({ [x.value]: x.label })))
    const filteredOptions = props.options.filter((o) => !selectedItems.includes(o.value));
    const handleChange = (v: string[]) => {
        setSelectedItems(v);
        props.onChange(v);
    }
    const tagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                key={value}
                // color={value}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {optDic[value]}
            </Tag>
        );
    };
    return (
        <Select
            mode="multiple"
            size="large"
            placeholder={props.placeholder || ""}
            value={selectedItems}
            tagRender={tagRender}
            onChange={handleChange}
            style={{ width: '100%', minWidth: '50%', minHeight: "30%" }}
            options={filteredOptions}
        />
    );
};

export default C;