import React, { useEffect, useState } from "react";
import withUser from "../../hocs/with_user";
import { Member, PointHistory } from "../state";
import { getPointHistory } from "../../api/point_api";
import DateRange from "../../components/date_range";
import LoadingSpinner from "../../components/loading_spinner";
import { format, subDays, addMonths } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Input, Select } from "antd";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import { Badge } from "antd";
type S =
  | { status: "loading" }
  | { status: "loaded"; data: PointHistory[] }
  | { status: "error"; error: string };

const PointHistoryList = (props: any) => {
  const merchantId = props.user?.userInfo?.merchantId || null;
  const [state, setState] = useState({ status: "loading" } as S);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 60),
    endDate: new Date(),
  });
  useEffect(() => {
    if (merchantId) {
      getPointHistory({
        merchantId,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      }).then((res) => {
        if (res.success) {
          setState({ status: "loaded", data: res.data || [] });
        }
      });
    }
  }, [merchantId, ranges]);
  const columns = [
    {
      title: "From",
      dataIndex: "from",
      align: "center",
      render: (v: number, r: PointHistory) => {
        const val = r.uId ? r.uId.split("@")[0] : "admin";
        return val;
      },
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      ellipsis: true,
      render: (v: number, r: PointHistory) => {
        const val = r.memberName || "";
        return val;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (v: number, r: PointHistory) => {
        return format(new Date(r.createdDate.value), "yyyy-MMM-dd");
      },
    },
    {
      title: "Expire Date",
      dataIndex: "expireDate",
      key: "expireDate",
      render: (v: number, r: PointHistory) => {
        const date = Number(r.expiredAt)
          ? new Date(Number(r.expiredAt))
          : addMonths(new Date(r.createdDate.value), r.expireInMonth);
        return format(date, "yyyy-MMM-dd");
      },
    },
    {
      title: "Reference ",
      dataIndex: "reference",
      key: "reference",
      render: (v: number, record: PointHistory) => {
        const val = record.referenceNo || "";
        return val;
      },
    },
    {
      title: "+100 Points",
      dataIndex: "point",
      key: "totalPoint",
      render: (v: number, record: PointHistory) => {
        const val = record.point || 0;
        return record.type === "earn" ? (
          <Badge count={val} style={{ backgroundColor: "#52c41a" }} />
        ) : (
          <Badge count={val} />
        );
      },
    },
  ];
  const onExport = () => {
    if (state.status !== "loaded") return;
    setExportLoading(true);
    const fileName =
      "pointHistory_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];
    state.data.forEach((p) => {
      const from = p.uId ? p.uId.split("@")[0] : "admin";
      const to = p.memberName || "";
      const date = format(new Date(p.createdDate), "yyyy-MMM-dd");
      const reference = p.referenceNo || "";
      const point = p.point;
      const type = p.type;
      const expireDate =
        type === "earn"
          ? format(
              p.expiredAt
                ? new Date(p.expiredAt)
                : addMonths(new Date(p.createdDate), p.expireInMonth),
              "yyyy-MMM-dd"
            )
          : "";
      excelData.push({ from, to, date, point, reference, type, expireDate });
    });
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const onSelect = async (startDate: Date, endDate: Date) => {
    setState({ status: "loading" });
    setRanges({ startDate, endDate });
  };
  return (
    <React.Fragment>
      {state.status === "loading" && <LoadingSpinner />}
      {state.status === "loaded" && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={12}>
                  <Typography.Text strong> Point history </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                <DateRange
                  startDate={ranges.startDate}
                  endDate={ranges.endDate}
                  onSelect={onSelect}
                />
                <ExportButton
                  loading={exportLoading || state.status !== "loaded"}
                  onClick={onExport}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Table
              columns={columns}
              dataSource={(state.status === "loaded" ? state.data : []).map(
                (row, i) => {
                  return { ...row, key: i + 1 };
                }
              )}
              pagination={{
                position: ["bottomRight"],
              }}
            />
          </Row>
        </>
      )}
      {state.status === "error" && <span>{state.error}</span>}
    </React.Fragment>
  );
};
export default withUser(PointHistoryList) as any;
