import React, { useEffect, useState } from 'react'
import { getDoc, doc, getFirestore } from "@firebase/firestore";
import { Layout, Col, Row, Menu, Dropdown, Avatar, Divider } from "antd";
import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { actions } from '../store/actions';
import { AppState, AuthState } from '../store';
const { menuAction } = actions;
interface P {
    authState: AuthState;
    siderControl: () => void;
    logout: () => void;
}
type S = { profile: string | null }
function TopBar(props: P) {
    const [state, setState] = useState({ profile: null } as S);
    useEffect(() => {
        if (props.authState.status === 'loggedIn') {
            let docId: String | null = null;
            if (props.authState.userInfo?.userId) {
                const uid = props.authState.userInfo.userId;
                if (uid.split('@').length === 2) {
                    docId = uid.split('@')[1].split('.')[0];
                }
            }
            if (docId) {
                const ref = doc(getFirestore(), `merchants/${docId}`);
                getDoc(ref).then(doc => {
                    if (doc.exists()) {
                        const m = doc.data() as { photo?: string };
                        setState({ profile: m.photo || null })
                    }
                })
            }
        }
    }, [props]);
    const menu = (
        <Menu className="menu" selectedKeys={[]} onClick={props.logout}>
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
                Log out
            </Menu.Item>
        </Menu>
    );
    const profile = state.profile ? state.profile : "/profile.png";
    return (
        <Layout className="top-bar">
            <Row justify='end'>
                <Col flex={1}>
                    <MenuOutlined style={{ color: 'black', fontSize: 18 }} onClick={props.siderControl} />
                </Col>
                <Col >
                    <Dropdown overlay={menu}>
                        <span className="action account">
                            {props.authState.status === 'loggedIn' ? props.authState.userInfo?.userId : ""}
                            <Divider type="vertical" />
                            <Avatar
                                className="avator"
                                size="small"
                                icon="user"
                                src={profile}
                            />
                        </span>
                    </Dropdown>
                </Col>
            </Row>

        </Layout>
    );
}
const mapStateToProps = ({ authState }: AppState) => {
    return {
        authState,
    }
}
const mapStateToDispatch = (dispatch: any) => {
    return {
        siderControl: () => dispatch(menuAction.sideOnOff()),
        logout: () => dispatch(actions.authAction.loggedOut())
    }
}
export default connect(mapStateToProps, mapStateToDispatch)(TopBar) as any;
