import React, { useState } from 'react'
import { Row, Col, Table, Card, Input, Tag, Typography, Select, Button, Avatar } from 'antd';
import { EditTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { paths } from '../../routes/paths';
import defaultPhoto from '../../assets/image/user.png';
import { AuthState, MerchantCustomer2, Order } from '../../store'
import withUser from '../../hocs/with_user';
import { subDays, format } from "date-fns";
import ButtonGroup from 'antd/lib/button/button-group';
import LoadingSpinner from '../../components/loading_spinner';
import OwnerSelector from '../../components/customer_owner_selector';

const { Search } = Input;

interface P {
    user: AuthState
}
interface FacebookUser {
    id: string;
    name: string;
    email?: string;
    phone?: string;
    photo?: string;
}
const userList = [
    { id: "1", name: "User1", email: "user1@gmail.com", phone: "091111111", photo: defaultPhoto },
    { id: "2", name: "User2", email: "user1@gmail.com", phone: "092222222", photo: defaultPhoto },
    { id: "3", name: "User3", email: "user3@gmail.com", phone: "093333333", photo: defaultPhoto },
    { id: "4", name: "User4", email: "user4@gmail.com", phone: "094444444", photo: defaultPhoto }
]

const FacebookUsers = (props: P) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(null as string | null);
    const [exportLoading, setExportLoading] = useState(null as boolean | null);
    const [page, setPage] = useState({ current: 1, size: 50 });
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const onChange = (current: number, size: number) => {
        setPage({ current, size })
    }
    const tableColumns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            align: 'center',
            render: (v: number, record: FacebookUser) => < Avatar src={record.photo || defaultPhoto} size={45} onClick={() => { }} />
        },
        {
            title: 'Name',
            dataIndex: 'customer',
            key: 'customer',
            filteredValue: searchText ? [searchText] : null,
            onFilter: (value: string, record: FacebookUser) => (record.name).toLowerCase().includes(value.toLowerCase()),
            ellipsis: true,
            sorter: (a: FacebookUser, b: FacebookUser) =>
                a.name.localeCompare(b.name),
            render: (v: number, record: FacebookUser) => {
                const val = record.name;
                return val;
            },
        },
        {
            title: 'AccountId',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            sorter: (a: FacebookUser, b: FacebookUser) =>
                a.id.localeCompare(b.id),
            render: (v: number, record: FacebookUser) => {
                const val = record.id;
                return val;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (v: number, record: FacebookUser) => {
                const val = record.phone || ""
                return val;
            },
            sorter: (a: FacebookUser, b: FacebookUser) =>
                a.phone || "".localeCompare(b.phone || ""),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (v: number, record: FacebookUser) => {
                const val = record.email || "";
                return val;
            },
            sorter: (a: FacebookUser, b: FacebookUser) =>
                a.email || "".localeCompare(b.email || ""),

        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (v: number, record: MerchantCustomer2) => {
                return (
                    <ButtonGroup>
                        <OwnerSelector onOk={() => { }} users={[]} label="*Select Customer" title={`Link Customer (${record.name}) `} okText="Link" ownerId={null} />
                        {/* <Button shape="square" icon={<EditTwoTone />}
                            onClick={() => { }}
                        /> */}
                    </ButtonGroup>
                );

            }
        }
    ];
    const expandedRowRender = (record: any) => {
        const rowData = record.orders;

        return <Card size="small" title="Chat"> Chat List</Card>;
    };
    return (
        <React.Fragment>
            {/* {loading && <LoadingSpinner />} */}
            <>
                <Row>
                    <Col flex={4}>
                        <Row >
                            <Col span={12}><Typography.Text strong> Facebook </Typography.Text></Col>
                            <Col span={12} >
                                <Search
                                    defaultValue={searchText}
                                    // addonBefore={selectBefore}
                                    placeholder={`search ${userList.length}...`}
                                    size="middle"
                                    allowClear
                                    onSearch={(val: any) => setSearchText(val.toLowerCase())}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: 5 }} >
                    <Table
                        columns={tableColumns}
                        dataSource={userList.map((row, i) => { return { ...row, key: i + 1 } })}
                        expandable={{
                            expandedRowRender,
                            //rowExpandable: (record: Order) => record.order_status !== 'Not Expandable',
                        }}
                        pagination={{
                            position: ['bottomRight',],
                            pageSize: page.size,
                            current: page.current,
                            showSizeChanger: true,
                            total: 4,
                            pageSizeOptions: [50, 100, 150, 200],
                            onChange: onChange,
                        }}
                    />
                </Row>
            </>

        </React.Fragment>
    )
}

export default withUser(FacebookUsers) as any;
