import React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { paths } from "./paths";
import { SideNav, TopBar } from "../layout";
import { Layout } from "antd";
import withUser from "../hocs/with_user";

import DashboardPage from "../pages/dashboards/dashboard";
import ReportPage from "../pages/report/report";
import ProductList from "../pages/product/product_list";
import ProductImport from "../pages/product/product_import";
import StockProductList from "../pages/product/product_stock_list";
import OrderList from "../pages/order/order_list";
import CustomerList from "../pages/customer/customer_list";
import CouponList from "../pages/coupon/coupon_list";
import CouponCreate from "../pages/coupon/create_coupon";

import SaleReport from "../pages/report/sale_report";
import OrderReport from "../pages/report/order_report";
import ProductDetailPage from "../pages/product/product_detail";
import CustomerDetailPage from "../pages/customer/customer_detial";

import SaleDashboard from "../pages/dashboard2/sale_dashboard";
import OrderDashboard from "../pages/dashboard2/order_dashboard";
import ProductDashboard from "../pages/dashboard2/product_dashboard";

import PointDashboard from "../royalty/dashboard/point_dashboard";
import RewardDashboard from "../royalty/dashboard/reward_dashboard";
import MemberDashboard from "../royalty/dashboard/member_dashboard";

import PointHistory from "../royalty/history/point_history";
import RewardHistory from "../royalty/history/reward_history";
import PointExpire from "../royalty/history/point_expire";
import AllMemberHistory from "../royalty/history/all_member";
import TopMemberHistory from "../royalty/history/top_member";
import ActivityMemberHistory from "../royalty/history/activity_member";
import TierMemberHistory from "../royalty/history/tier_member";

import MemberDetail from "../royalty/history/member_detail";
import FacebookUsers from "../pages/socials/facebook_users";
import { availableCRM } from "../helpers/crm_helper";

import OrderDetail from "../pages/order/order_detail";
import ExpenseCategory from "../pages/expense/expense_categories";

import NinjaPriceList from "../pages/ninjavan/ninjavan_price_list";
import ArlupostPriceList from "../pages/arlupost/arlupost_price_list";

import ShippingFeePage from "../pages/shipping_fee/shipping_fees";
import ShippingDetail from "../pages/shipping_fee/shipping_fee_detail";
import Income from "../pages/expense/income";
import PitiIncome from "../pages/expense/piti-income";
import Summary from "../pages/expense/summary";


const { Header, Content, Footer } = Layout;
function PRoutes(props: any) {
  const Navigate = useNavigate();
  const location = useLocation();
  const currentMenuItem =
    location && location.pathname ? location.pathname : "/";
  return (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        <SideNav
          onMenuClik={(val) => Navigate(`${val.key}`)}
          currentMenuItem={currentMenuItem}
        />
        <Layout>
          <Header>
            <TopBar />
          </Header>
          <Content>
            <Routes>
              <Route path={paths.home} element={<OrderList />} />
              <Route path={paths.dashboard} element={<SaleDashboard />} />
              <Route path={paths.saleDashboard} element={<SaleDashboard />} />
              <Route path={paths.orderDashboard} element={<OrderDashboard />} />
              <Route
                path={paths.productDashboard}
                element={<ProductDashboard />}
              />
              <Route path={paths.report} element={<ReportPage />} />
              <Route
                path={paths.products}
                element={<ProductList pageType="Product List" />}
              />
              <Route
                path={paths.productSort}
                element={<ProductList pageType="Product Sorting" />}
              />
              <Route
                path={paths.productDetail}
                element={<ProductDetailPage />}
              />
              <Route path={paths.productImport} element={<ProductImport />} />
              <Route
                path={paths.productStocks}
                element={<StockProductList pageType="Product Inventory" />}
              />
              <Route
                path={paths.productPrices}
                element={<StockProductList pageType="Product Price" />}
              />
              <Route path={paths.orders} element={<OrderList />} />
              <Route path={paths.orderDetail} element={<OrderDetail />} />
              <Route path={paths.customers} element={<CustomerList />} />
              <Route
                path={paths.customerDetail}
                element={<CustomerDetailPage />}
              />
              <Route path={paths.coupons} element={<CouponList />} />
              <Route path={paths.create_coupon} element={<CouponCreate />} />
              <Route path={paths.saleReport} element={<SaleReport />} />
              <Route path={paths.orderReport} element={<OrderReport />} />

              <Route path={paths.pointDashboard} element={<PointDashboard />} />
              <Route
                path={paths.rewardDashboard}
                element={<RewardDashboard />}
              />
              <Route
                path={paths.memberDashboard}
                element={<MemberDashboard />}
              />

              <Route path={paths.pointHistory} element={<PointHistory />} />
              <Route path={paths.rewardHistory} element={<RewardHistory />} />
              <Route path={paths.pointExpire} element={<PointExpire />} />

              <Route path={paths.members} element={<AllMemberHistory />} />
              <Route path={paths.topMembers} element={<TopMemberHistory />} />
              <Route
                path={paths.activityMember}
                element={<ActivityMemberHistory />}
              />
              <Route path={paths.tierMember} element={<TierMemberHistory />} />
              <Route path={paths.memberDetail} element={<MemberDetail />} />

              <Route path={paths.facebookUsers} element={<FacebookUsers />} />
              <Route path={paths.expense} element={<ExpenseCategory />} />
              <Route path={paths.income} element={<Income />} />
              <Route path={paths.pitiIncome} element={<PitiIncome />} />
              {/* <Route path={paths.summary} element={<Summary />} /> */}
              <Route path={paths.ninjavanPrice} element={<NinjaPriceList />} />
              <Route path={paths.arlupostPrice} element={<ArlupostPriceList />} />
              <Route path={paths.shippingFees} element={<ShippingFeePage />} />
              <Route path={paths.shipping_fee} element={<ShippingDetail />} />
            </Routes>
          </Content>
          <Footer>
            <span>&copy; {new Date().getFullYear()} Piti Commerce</span>
          </Footer>
        </Layout>
      </Layout>
      {/* <Outlet /> */}
    </div>
  );
}
export default withUser(PRoutes);
