import { Action } from 'redux';
import { MenuState } from "../state";
import { AntdActionTypes as actionTypes } from '../actions/action_types';
const {
    SIDE_TOOGLE_CHANGE,
} = actionTypes;

export const actions = {
    sideOnOff: () => (
        {
            type: actionTypes.SIDE_TOOGLE_CHANGE,
            payload: {}
        }
    )
}
type MenuAction = Action;
export const reducers = (state: MenuState = { openSideToogle: false }, action: MenuAction) => {
    switch (action.type) {
        case SIDE_TOOGLE_CHANGE: {
            return {
                openSideToogle: !state.openSideToogle
            };
        }
        default:
            break;
    }
    return state;
};

