import React, { useState } from 'react'
import { useNavigate, Navigator, } from "react-router-dom";
import { Table, Card, Tag, Avatar, Empty } from "antd";
import { format } from "date-fns";
import { BoughtTogether } from "../store";
import { paths } from '../routes/paths';


const ProductBoughtTogether = (props: { data: BoughtTogether[] }) => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            key: 'photo',

            render: (v: number, record: BoughtTogether) => < Avatar src={record.main_item?.image} size={45} onClick={() => navigate(paths.getProductDetailRoute(record.main_item!.id))} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (v: BoughtTogether, record: BoughtTogether) => {
                return record.main_item?.name;
            },
        },
        {
            title: 'Buy Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: BoughtTogether, b: BoughtTogether) => a.total_buy_count - b.total_buy_count,
            render: (v: BoughtTogether, record: BoughtTogether) => {
                return record.total_buy_count;
            },
        },
        {
            title: 'Last Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a: BoughtTogether, b: BoughtTogether) => new Date(a.updated_at).getDate() - new Date(b.updated_at).getDate(),
            render: (v: BoughtTogether, record: BoughtTogether) => {
                return format(new Date(record.updated_at), 'yyyy-MMM-dd');
            },
        },
    ];
    return (
        <React.Fragment>
            {props.data.length === 0 ?
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span>
                            Empty Data
                        </span>
                    }
                />
                :
                <Table
                    showHeader={true}
                    columns={columns}
                    dataSource={props.data}
                    pagination={{
                        pageSize: 4,
                    }}
                />
            }
        </React.Fragment>
    )
}

export default ProductBoughtTogether;
