import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import auth_link from './links/auth_link';
import http_link from './links/http_link';

const link = from([http_link, auth_link]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});


export default client;

