import { Card } from "antd";
import { Order, Product } from "../store";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";
import * as _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.scale.grid.display = false;
ChartJS.defaults.scale.ticks.display = true;
const chartData = (data: Order[], prod: Product) => {
  // console.log(data,"data")
  let _labels: string[] = [],
    _data: string[] = [],
    days: any[] = [];
  data = data.map((or) => {
    const ym = format(new Date(or.created_at), "yyyy-MMM");
    const sec = new Date(or.created_at).getTime();
    return {
      ...or,
      filter: ym,
      filterValue: sec,
    };
  });
  const ymGroups = _.groupBy(data, "filter");
  Object.keys(ymGroups).forEach((day) => {
    const date = day;
    let totalCount = 0;
    let sec: any | null = null;
    ymGroups[day].forEach((or) => {
      console.log(or,"orr");
      
      const stockIds = (prod.product_stock_items || []).map((psi) => psi.id);
      or.order_items.forEach((oi) => {
        if (stockIds.includes(oi.stock_item_id!)) {
          totalCount += oi.count;
        }
      });
      if (!sec) sec = or.filterValue;
    });
    days.push({ date, totalCount, sec });
  });
  _.orderBy(days, "sec", "asc").forEach((f) => {
    _labels.push(f.date);
    _data.push(f.totalCount);
  });
  return { _labels, _data };
};
export default function DetailBarChart(props: {
  data: Order[];
  product: Product;
  simpleData: any;
}) {
  // console.log('====================================');
  // console.log(props.data,"data");
  // console.log('====================================');
  // console.log('====================================');
  console.log(props.product, "products");

  // console.log('====================================');
  const cData = chartData(props.data, props.product);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: true,
        text: `${props.product.name} sales`,
      },
    },
  };
  const data = {
    labels: cData._labels,
    datasets: [
      {
        label: "Buy count",
        data: cData._data,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  console.log(data, "data");

  return (
    <Card size="small" style={styles.card}>
      <Bar options={options} data={data} />
    </Card>
  );
}
const styles = {
  card: {
    height: "100%",
  },
};
