import React, { useState } from 'react'
import { Row, Col, Table, Card, Input, Tag, Typography, Select, Button } from 'antd';
import { EyeOutlined } from "@ant-design/icons";
import { useQuery } from '@apollo/client';
import {
    get_customer_variables, get_customers,
    customer_count as countQuery,
    getCustomerCountVariables as countVariables,

} from "../../gql/gql_query";
import { useNavigate } from "react-router-dom";
import { paths } from '../../routes/paths';
import DateRange from '../../components/date_range';
// import { useToasts } from 'react-toast-notifications';
import { AuthState, MerchantCustomer2, Order } from '../../store'
import withUser from '../../hocs/with_user';
import { subDays, format } from "date-fns";
import ButtonGroup from 'antd/lib/button/button-group';
import LoadingSpinner from '../../components/loading_spinner';
import ExportButton from '../../components/export_button';
import { ExportExcel } from '../../services/xlsx_service';
import { trim } from "lodash";
const { Search } = Input;
const { Option } = Select
interface P {
    user: AuthState
}
type Filter = 'name' | 'phone' | 'group';
export const convertPhone = (phone: string | null) => {
    if (phone && phone.startsWith('+959')) {
        return phone
    }
    if (phone && (phone.startsWith('09') || phone.startsWith('0'))) {
        return "+95" + phone.substring(1);
    }
    return phone;
}

const CustomerList = (props: P) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState(null as string | null);
    const [exportLoading, setExportLoading] = useState(null as boolean | null);
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: new Date() })
    // const [selectedFilter, setSelectedFilter] = useState('name' as Filter);
    const [page, setPage] = useState({ current: 1, size: 50 });
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const { loading, error, data, } = useQuery(get_customers, { variables: get_customer_variables({ merchantId, startDate: ranges.startDate, endDate: ranges.endDate, take: page.size, skip: (page.current - 1) * page.size, search: convertPhone(searchText) }) });
    const { loading: countLoading, error: countError, data: countData } = useQuery(countQuery, { variables: countVariables(merchantId) });
    const onChange = (current: number, size: number) => {
        setPage({ current, size })
    }
    const onSelect = async (startDate: Date, endDate: Date) => {
        setRanges({ startDate, endDate });
        //await refetch();
    }
    const onExport = () => {
        setExportLoading(true);
        const fileName = "customers_" + format(ranges.startDate, 'yyyy-MMM-dd') + "_" + format(ranges.endDate, 'yyyy-MMM-dd');
        let excelData: any[] = [];
        console.log('data', data);
        ((data && data.merchantCustomers) ? data.merchantCustomers : []).forEach((o: MerchantCustomer2) => {
            const name = o.customer.name || "";
            const phone = o.customer.contact_number;
            const order = o.orders.length;
            const totalOrder = o._count ? o._count.orders : 0;
            const joinedAt = format(new Date(o.created_at), 'yyyy-MMM-dd');
            const orderNo = "", orderDate = "", orderAmount = "";
            if (o.orders.length > 0) {
                o.orders.forEach((oi, index) => {
                    let orderAmount = oi.grand_total, orderNo = oi.order_number, orderDate = format(new Date(oi.created_at), 'yyyy-MMM-dd');
                    if (index === 0) {
                        excelData.push({ name, phone, order, totalOrder, joinedAt, orderNo, orderDate, orderAmount });
                    } else {
                        excelData.push({ name: "", phone: "", order: "", totalOrder: "", joinedAt: "", orderNo, orderDate, orderAmount });
                    }
                });
            } else {
                excelData.push({ name, phone, order, totalOrder, joinedAt, orderNo, orderDate, orderAmount });
            }
        });
        ExportExcel(excelData, fileName);
        setExportLoading(false)
    }
    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'customer',
            key: 'customer',
            //  filteredValue: (selectedFilter === 'name' && searchText) ? [searchText] : null,
            // onFilter: (value: string, record: MerchantCustomer2) => (record.customer.name || record.customer.contact_number).toLowerCase().includes(value.toLowerCase()),
            ellipsis: true,
            sorter: (a: MerchantCustomer2, b: MerchantCustomer2) =>
                a.customer.name.localeCompare(b.customer.name),
            render: (v: number, record: MerchantCustomer2) => {
                const val = record.customer.name || record.customer.contact_number
                return val;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'customer',
            key: 'merchant_customer',
            //  filteredValue: (selectedFilter === 'phone' && searchText) ? [searchText] : null,
            // onFilter: (value: string, record: MerchantCustomer2) => (record.customer.contact_number).toLowerCase().includes(value.toLowerCase()),
            render: (v: number, record: MerchantCustomer2) => {
                const val = record.customer.contact_number
                return val;
            },
            sorter: (a: MerchantCustomer2, b: MerchantCustomer2) =>
                a.customer.contact_number.localeCompare(b.customer.contact_number),
        },
        {
            title: 'Order',
            dataIndex: 'orders',
            key: 'orders',
            render: (v: number, record: MerchantCustomer2) => {
                const val = record.orders.length;
                return val;
            },
            sorter: (a: MerchantCustomer2, b: MerchantCustomer2) => a.orders.length - b.orders.length,

        },
        {
            title: 'Lifetime Order',
            dataIndex: '_count',
            key: '_count',
            render: (v: number, record: MerchantCustomer2) => {
                const val = record._count ? record._count.orders : 0;
                return val;
            },
            sorter: (a: MerchantCustomer2, b: MerchantCustomer2) => (a._count ? a._count.orders : 0) - (b._count ? b._count.orders : 0),

        },
        {
            title: 'GroupIn',
            dataIndex: 'customer_group',
            key: 'customer_group',
            // filteredValue: (selectedFilter === 'group' && searchText) ? [searchText] : null,
            // onFilter: (value: string, record: MerchantCustomer2) => (record.customer_group ? record.customer_group.name : "").toLowerCase().includes(value.toLowerCase()),
            render: (v: number, record: MerchantCustomer2) => {
                const val = record.customer_group ? record.customer_group.name : null;
                return <Tag color={"cyan"}>{val ? val : 'NONE'}</Tag>;
            },
        },
        {
            title: 'JoinedAt',
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: (a: MerchantCustomer2, b: MerchantCustomer2) => new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
            render: (v: number, record: MerchantCustomer2) => {
                return format(new Date(record.created_at), 'yyyy-MMM-dd');
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '12%',
            fixed: 'right',
            render: (v: number, record: MerchantCustomer2) => {
                return (
                    <ButtonGroup>
                        <Button shape="circle" icon={<EyeOutlined />}
                            onClick={() => navigate(paths.getCustomerDetailRoute(record.customer.id), { state: { merchantCustomerId: record.id } })}
                        />
                    </ButtonGroup>
                );

            }
        }
    ];
    const expandedRowRender = (record: MerchantCustomer2) => {
        const rowData = record.orders;
        const columns = [
            {
                title: 'OrderNo',
                dataIndex: 'order_number',
                key: 'order_number',
                render: (v: number, record: Order) => {
                    return record.order_number || "";
                },
                sorter: (a: Order, b: Order) =>
                    a.order_number.localeCompare(b.order_number),
            },
            {
                title: 'Date',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: (a: Order, b: Order) => new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
                render: (v: number, record: Order) => {
                    return format(new Date(record.created_at), 'yyyy-MMM-dd HH:mm');
                },
            },
            {
                title: 'Shipping',
                dataIndex: 'shipping_fee_name',
                key: 'shipping_fee_name',
                render: (v: number, record: Order) => {
                    return record.shipping_fee_name || "";
                },
                sorter: (a: Order, b: Order) => (a.shipping_fee_name || "").localeCompare(b.shipping_fee_name || "")
            },
            {
                title: 'Amount',
                dataIndex: 'grand_total',
                key: 'grand_total',
                sorter: (a: Order, b: Order) => a.grand_total - b.grand_total,
                render: (v: number, record: Order) => {
                    return record.grand_total;
                },
            },
            {
                title: 'Status',
                dataIndex: 'order_status',
                key: 'order_status',
                render: (v: number, record: Order) => {
                    let color = "orange";
                    if (record.order_status === "ACCEPT") color = "lime";
                    if (record.order_status === "COMPLETE") color = "green";
                    if (record.order_status === "CUSTOMER_CANCEL" || record.order_status === 'MERCHANT_CANCEL') color = "red";
                    if (record.order_status === "PAID") color = "cyan"
                    return <Tag color={color}>{record.order_status}</Tag>;
                },
            },
            {
                title: 'Channel',
                dataIndex: 'sale_channel',
                key: 'sale_channel',
                sorter: (a: Order, b: Order) => a.sale_channel.localeCompare(b.sale_channel),
                render: (v: number, record: Order) => {
                    return record.sale_channel;
                },
            },


        ];
        return <Card> <Table showHeader={false} size="small" columns={columns} dataSource={rowData} pagination={{ pageSize: 5, position: ['topLeft',] }} /> </Card>;
    };
    // const selectBefore = (
    //     <Select defaultValue={selectedFilter} className="select-before" style={{ width: 120 }} onChange={(v: Filter) => setSelectedFilter(v)}>
    //         <Option value="name">name</Option>
    //         <Option value="phone">phone</Option>
    //         <Option value="group">group</Option>
    //     </Select>
    // );
    return (
        <React.Fragment>
            {loading && <LoadingSpinner />}
            {(!loading && !error && countData) &&
                <>
                    <Row>
                        <Col flex={4}>
                            <Row >
                                <Col span={12}><Typography.Text strong> Customers </Typography.Text></Col>
                                <Col span={12} >
                                    <Search
                                        defaultValue={searchText}
                                        // addonBefore={selectBefore}
                                        placeholder={`search ${countData?.aggregateMerchantCustomer?._count.id}...`}
                                        size="middle"
                                        allowClear
                                        onSearch={(val: any) => setSearchText(val.toLowerCase())}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col flex={0}>
                            <Row >
                                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
                                <ExportButton loading={exportLoading || loading} onClick={onExport} />
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 }} >
                        <Table
                            columns={tableColumns}
                            dataSource={(data.merchantCustomers as MerchantCustomer2[]).map((row, i) => { return { ...row, key: i + 1 } })}
                            expandable={{
                                expandedRowRender,
                                //rowExpandable: (record: Order) => record.order_status !== 'Not Expandable',
                            }}
                            pagination={{
                                position: ['bottomRight',],
                                pageSize: page.size,
                                current: page.current,
                                showSizeChanger: true,
                                total: countData?.aggregateMerchantCustomer._count.id || page.size,
                                pageSizeOptions: [50, 100, 150, 200],
                                onChange: onChange,
                            }}
                        />
                    </Row>
                </>
            }
        </React.Fragment>
    )
}

export default withUser(CustomerList) as any;
