import React, { useState } from 'react';
import { Card, Timeline, Comment, Form, Input, Button, Col, Row } from "antd";
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
    get_system_data as sysQuery,
    getSystemDataVariables as sysVariables,
} from "../../gql/gql_query";
import {
    upsert_system_data as upsertQuery,
    get_upsert_system_data_varible as upsertVariables
} from "../../gql/gql_mutation";
import { format } from "date-fns";
import { orderBy } from "lodash";

const { TextArea } = Input;
interface P {
    customerId: string;
    merchantId: string;
}
interface Comment {
    date: Date | number;
    comment: string;
    actionBy: string;
}
interface EditorProps {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSubmit: () => void;
    submitting: boolean;
    value: string;
}

const Editor = ({ onChange, onSubmit, submitting, value }: EditorProps) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);
export default function CustomerComment(props: P) {
    const sys_code = `${props.customerId}_${props.merchantId}_comment`;
    const actionBy = "Administrator"
    const [comment, setComment] = useState("");
    const { loading, error, data, refetch } = useQuery(sysQuery, { variables: sysVariables(props.merchantId, sys_code), fetchPolicy: 'network-only' });
    const [addComment, { data: addData, loading: addLoading, error: addError }] = useMutation(upsertQuery);
    const getComments = () => {
        let _list: Comment[] = []
        if (data) {
            const sd = data.findManySystemData.find((sd: any) => sd.code === sys_code);
            if (sd) _list = JSON.parse(sd.metadata) as Comment[];
        }
        return orderBy(_list, 'date', 'desc')
    }
    const handleSubmit = () => {
        if (!comment) return;
        const comments = getComments();
        comments.push({ comment, actionBy, date: new Date() })
        addComment({ variables: upsertVariables({ merchantId: props.merchantId, code: sys_code, metadata: JSON.stringify(comments) }) }).then(() => refetch());
        setComment('');
    }
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
    };
    return (
        <Card size="small">
            <Row gutter={24} >
                <Col span={12}>
                    {(!loading && !error) &&
                        <Timeline mode={"left"}>
                            {
                                getComments().map((cm, index) => (<Timeline.Item key={`comment_${index}`} label={`${format(new Date(cm.date), 'dd MMM,yyyy HH:mm:ss')} by ${cm.actionBy}`}>{cm.comment}</Timeline.Item>))
                            }
                        </Timeline>

                    }
                </Col>
                {
                    <Col span={12}>
                        <Comment
                            content={
                                <Editor
                                    onChange={handleChange}
                                    onSubmit={handleSubmit}
                                    submitting={addLoading}
                                    value={comment}
                                />
                            }
                        />
                    </Col>
                }
            </Row>
        </Card>
    )
}
