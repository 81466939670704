import React, { useEffect, useState } from 'react'
import withUser from '../../hocs/with_user';
import { MemberPointActivity } from "../state";
import { getPointExpire } from "../../api/point_api";
import DateRange from '../../components/date_range';
import LoadingSpinner from '../../components/loading_spinner';
import { format, subDays, addDays } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Tag, Select } from "antd";
import ExportButton from '../../components/export_button';
import { ExportExcel } from '../../services/xlsx_service';
import defaultPhoto from '../../assets/image/user.png'

const { Option } = Select
type S = { status: "loading" } | { status: "loaded", data: MemberPointActivity[] } | { status: "error", error: string };
const PointExpireList = (props: any) => {
    const merchantId = props.user?.userInfo?.merchantId || null;
    const [state, setState] = useState({ status: "loading" } as S);
    const [exportLoading, setExportLoading] = useState(null as boolean | null);
    const [ranges, setRanges] = useState({ startDate: new Date(), endDate: addDays(new Date(), 30) })
    useEffect(() => {
        if (merchantId) {
            getPointExpire({
                merchantId, startDate: ranges.startDate, endDate: ranges.endDate
            }).then(res => {
                if (res.success) {
                    setState({ status: 'loaded', data: res.data || [] })
                }
            })
        }
    }, [merchantId, ranges])
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            align: 'center',
            render: (v: number, record: MemberPointActivity) => < Avatar src={record.memberPhoto || defaultPhoto} size={45} onClick={() => { }} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            render: (v: number, record: MemberPointActivity) => {
                const val = record.memberName || ""
                return val;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            align: 'center',
            render: (v: number, r: MemberPointActivity) => {
                const val = r.memberPhone || ""
                return val;
            },
        },
        {
            title: 'Point To Expire',
            dataIndex: 'pte',
            key: 'pte',
            ellipsis: true,
            render: (v: number, r: MemberPointActivity) => {
                const val = r.totalPoint || 0;
                return val;
            },
        },
        {
            title: 'Last Expire Date',
            dataIndex: 'date',
            key: 'date',
            render: (v: number, r: MemberPointActivity) => {
                return format(new Date(ranges.endDate), 'yyyy-MMM-dd');
            },
        },
        {
            title: 'Current Point',
            dataIndex: 'currentPoint',
            key: 'currentPoint',
            render: (v: number, r: MemberPointActivity) => {
                const val = r.memberPoint || 0;
                return val;
            },
        },
    ];
    const onExport = () => {
        if (state.status !== 'loaded') return;
        setExportLoading(true);
        const fileName = "pointExpire_" + format(ranges.startDate, 'yyyy-MMM-dd') + "_" + format(ranges.endDate, 'yyyy-MMM-dd');
        let excelData: any[] = [];
        state.data.forEach(p => {
            const memberName = p.memberName || "";
            const memberPhone = p.memberPhone || "";
            const memberEmail = p.memberEmail || "";
            const currentPoint = p.memberPoint || 0;
            const totalPoint = p.memberTotalPoint || 0;
            const pointToExpire = p.totalPoint || 0;
            const lastExpireDate = format(ranges.endDate, 'yyyy-MMM-dd')
            excelData.push({ memberName, memberPhone, memberEmail, currentPoint, totalPoint, pointToExpire, lastExpireDate })
        })
        ExportExcel(excelData, fileName);
        setExportLoading(false);
    }
    const onSelect = async (startDate: Date, endDate: Date) => {
        setState({ status: 'loading' });
        setRanges({ startDate, endDate });
    }
    return (
        <React.Fragment>
            {state.status === "loading" && <LoadingSpinner />}
            {state.status === "loaded" &&
                <>
                    <Row>
                        <Col flex={4}>
                            <Row >
                                <Col span={12}><Typography.Text strong> Point Expire </Typography.Text></Col>
                            </Row>
                        </Col>
                        <Col flex={0}>
                            <Row >
                                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
                                <ExportButton loading={exportLoading || state.status !== 'loaded'} onClick={onExport} />
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 }} >
                        <Table
                            columns={columns}
                            dataSource={(state.status === 'loaded' ? state.data : []).map((row, i) => { return { ...row, key: i + 1 } })}
                            pagination={{
                                position: ['bottomRight',]
                            }}
                        />
                    </Row>
                </>
            }
            {state.status === 'error' && <span>{state.error}</span>}
        </React.Fragment>

    )
}
export default withUser(PointExpireList) as any;