import { Button, Modal, Select, Row, Tag, Spin, Col, Input } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from "@ant-design/icons";
import { Typography } from 'antd';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { customer_search, getCustomerSearchVariables } from '../gql/gql_query';
import { MerchantCustomer2 } from '../store';
import { getLinkOrderCustomerVariables, update_order } from '../gql/gql_mutation';
const { Option } = Select;
const { Search } = Input;
interface P {
    merchantId: string,
    orderId: string,
    customerId?: string,
    onOk: (merchantCustomerId: string) => void;
}

const CustomerSelector: React.FC<P> = (props: P) => {
    const [searchText, setSearchText] = useState(null as string | null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [currentValue, setValue] = useState(props.customerId || null)
    const [searchCustomer, { loading: mcLoading, data: mcData, error: mcError }] = useLazyQuery(customer_search);
    const [linkOrderCustomer, { data: linkData, loading: linkLoading, error: linkError }] = useMutation(update_order);
    React.useEffect(() => {
        if (props.customerId) {
            searchCustomer({ variables: getCustomerSearchVariables(props.merchantId, props.customerId) });
        }
    }, [props.customerId])
    const showModal = () => {
        setVisible(true);
    };
    const handleOnSearch = async (val: any) => {
        await searchCustomer({ variables: getCustomerSearchVariables(props.merchantId, val) })
        setSearchText(val);
        setValue(null)
    }
    const handleOk = async () => {
        if (!currentValue) return;
        await linkOrderCustomer({ variables: getLinkOrderCustomerVariables({ orderId: props.orderId, merchantCustomerId: currentValue }) });
        if (currentValue) props.onOk(currentValue);
        setVisible(false)
    };

    const handleCancel = () => {
        setVisible(false);
    };
    return (
        <>
            <EditOutlined onClick={showModal} />
            <Modal
                visible={visible}
                title={props.customerId ? "Change customer" : "Choose Customer"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={linkLoading}
                        onClick={handleOk}
                    >
                        {"Link customer"}
                    </Button>,
                ]}


            >
                <Spin tip="Search...." spinning={mcLoading}>
                    <Row gutter={[4, 0]}>
                        <Col span={24}>
                            <Search
                                allowClear
                                defaultValue={searchText}
                                // addonBefore={selectBefore}
                                placeholder={`search customer...`}
                                size="middle"
                                onSearch={handleOnSearch}
                            />
                        </Col>
                        <Col span={24}>
                            <Typography style={{ padding: 3 }}>{"select customer"} </Typography>
                            <Select
                                allowClear
                                showSearch
                                loading={mcLoading}
                                enterButton={false}
                                style={{ width: 250, marginLeft: 5 }}
                                placeholder="select customer...."
                                defaultValue={currentValue}
                                onChange={(val: any) => setValue(val)}
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => ((option?.label ?? '').toLowerCase()).includes(input.toLowerCase())}
                                filterSort={(optionA: any, optionB: any) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())

                                }
                                options={((mcData?.merchantCustomers || []) as MerchantCustomer2[]).map(mc => ({ value: mc.id, label: ` ${(mc.name || mc.customer.name)} ${mc.customer.contact_number}` }))}
                            />

                        </Col>
                    </Row>
                </Spin>
            </Modal>
        </>
    );
};

export default CustomerSelector;