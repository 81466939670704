import {
  Button,
  Modal,
  Tabs,
  Select,
  Row,
  Timeline,
  Card,
  Checkbox,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  doc,
  collection,
  onSnapshot,
  getFirestore,
  query,
  orderBy,
} from "firebase/firestore";
import { getApp } from "firebase/app";
import DeliveryButton from "./delivery_button";
import { utcDateToZonedDate } from "../helpers/util";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import {
  checkDeliveryStatus,
  sendDeeDelivery,
  generateWaybill,
  sendDeeDeliveryCancel,
} from "../api/dee_delivery";
import * as yup from "yup";
import { Order } from "../store";
import { Typography } from "antd";
const { TabPane } = Tabs;
interface P {
  merchantId: string;
  orderNo: string;
  shipmentId: string;
  customerAddress?: string;
  orderId: string;
  order: Order;
}
const shipFormData = {
  cashOnDelivery: true,
  cashOnDeliveryAmount: "",
  itemAmount: "",
  customerAddress: "",
};
const shipSchema = yup.object().shape({
  cashOnDeliveryAmount: yup.number().required("COD amount  is required"),
  itemAmount: yup.number().required("Item amount  is required"),
  customerAddress: yup.string().required("Customer address  is required"),
  cashOnDelivery: yup.boolean().required("Cash on delivery  is required"),
});
const DeliveryInfo: React.FC<P> = (props: P) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [trackNo, setTrackNo] = useState(null);
  const [activeKey, setActiveKey] = useState("1");
  const [logs, setLogs] = useState([] as any);
  const [checkpull, setCheckPull] = useState(false);
  const [historyPull, setHistoryPull] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null as string | null);
  const [formData, setFormDat] = useState(
    props.customerAddress
      ? Object.assign(shipFormData, { customerAddress: props.customerAddress })
      : shipFormData
  );
  useEffect(() => {
    let unsub = null;
    if (checkpull) {
      unsub = onSnapshot(
        doc(
          getFirestore(getApp()),
          `merchants/${props.merchantId}/deeEvents`,
          props.orderId
        ),
        (doc) => {
          const data = doc.data();
          if (data) {
            if (data?.trackingId) setTrackNo(data?.trackingId);
            setFormDat(Object.assign(formData, { ...data }));
            setActiveKey("2");
          }
          setCheckPull(false);
        }
      );
    }
    if (unsub) return unsub;
  }, [checkpull]);
  useEffect(() => {
    let unsub = null;
    if (historyPull) {
      const colPath = `merchants/${props.merchantId}/deeEvents/${props.orderId}/logs`;
      const q = query(
        collection(getFirestore(getApp()), colPath),
        orderBy("createdOn", "desc")
      );
      unsub = onSnapshot(q, (querySnapshot) => {
        let logs: any[] = [];
        querySnapshot.forEach((doc) => {
          logs.push(doc.data());
        });

        setLogs(logs);
      });
    }
    if (unsub) return unsub;
  }, [historyPull]);
  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {};
  const handleCancel = () => {
    setVisible(false);
  };
  const handleToShip = async (data: any) => {
    const payload = data as {
      cashOnDelivery: boolean;
      cashOnDeliveryAmount: number;
      itemAmount: number;
      customerAddress: string;
    };
    const shipInfo = {
      ...payload,
      merchantId: props.merchantId,
      orderId: props.orderId,
      shipmentId: props.shipmentId,
      customerName:
        props.order.merchant_customer?.name ||
        props.order.merchant_customer?.customer?.name ||
        "",
      customerPhone: props.order.merchant_customer?.customer?.contact_number!,
    };
    setLoading(true);
    await sendDeeDelivery(shipInfo);
    setActiveKey("2");
    setLoading(false);
  };
  const handleToWaybill = async () => {
    if (!trackNo) return;
    setLoading(true);
    await generateWaybill({
      merchantId: props.merchantId,
      orderId: props.orderId,
      trackingId: trackNo!,
    });
    setLoading(false);
  };

  const handleToCancel = async () => {
    setLoading(true);
    await sendDeeDeliveryCancel({
      type: "cancelOrder",
      merchantId: props.merchantId,
      orderId: props.orderId,
      trackingNo: trackNo!,
    });
    setActiveKey("2");
    setLoading(false);
  };

  const handleToCheck = async () => {
    if (!trackNo) return;
    setLoading(true);
    const res = await checkDeliveryStatus(trackNo!);
    if (res.Status) setCurrentStatus(res.Status!);
    setLoading(false);
  };
  const onChange = (tabKey: any) => {
    setActiveKey(`${tabKey}`);
  };
  const Shipping = () => {
    return (
      <Formik
        initialValues={formData}
        validationSchema={shipSchema}
        enableReinitialize={true}
        onSubmit={handleToShip}
      >
        {({
          dirty,
          values,
          isValid,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleReset,
          setFieldValue,
        }) => {
          return (
            <FormikForm autoComplete="off">
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Cash On Delivery
              </label>
              <Field
                id="cashOnDelivery"
                name="cashOnDelivery"
                render={() => (
                  <Checkbox
                    checked={values.cashOnDelivery}
                    onChange={(v: any) =>
                      setFieldValue("cashOnDelivery", v.target.checked)
                    }
                  >
                    {values.cashOnDelivery ? "Allowed" : "Not allowed"}{" "}
                    {values.cashOnDelivery}
                  </Checkbox>
                )}
              />
              <br />
              <ErrorMessage
                name="cashOnDeliveryAmount"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                COD Amount
              </label>
              <Field
                id="cashOnDeliveryAmount"
                name="cashOnDeliveryAmount"
                type="number"
                value={values.cashOnDeliveryAmount}
              />
              <br />
              <ErrorMessage
                name="cashOnDeliveryAmount"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <label htmlFor="email" style={{ paddingRight: 10 }}>
                Item Amount
              </label>
              <Field
                id="itemAmount"
                name="itemAmount"
                type="number"
                value={values.itemAmount}
              />
              <br />
              <ErrorMessage
                name="itemAmount"
                component="div"
                className="field-error"
                render={(error) => (
                  <span style={{ color: "red" }}>{error}</span>
                )}
              />{" "}
              <br />
              <div style={{ display: "grid" }}>
                <label htmlFor="email">Customer Address</label>
                <Field
                  id="customerAddress"
                  name="customerAddress"
                  component="textarea"
                  rows="3"
                  value={values.customerAddress}
                />
                <ErrorMessage
                  name="customerAddress"
                  component="div"
                  className="field-error"
                  render={(error) => (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                />{" "}
                <br />
              </div>
              <Button disabled={!dirty} type="primary" htmlType="submit">
                Send To Ship
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
    );
  };
  return (
    <>
      <DeliveryButton
        loading={loading}
        onClick={() => {
          showModal();
          setCheckPull(true);
          setHistoryPull(true);
        }}
      />
      <Modal
        destroyOnClose={true}
        zIndex={500}
        visible={visible}
        title={
          trackNo
            ? `Deee Delivery(OrderNo:${props.orderNo},TrackNo:${trackNo})`
            : `Deee Delivery(OrderNo:${props.orderNo})`
        }
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {(checkpull || loading) && <Card loading={true} />}
        {!checkpull && !loading && (
          <Row justify="center">
            <Tabs size="large" defaultActiveKey={activeKey} onChange={onChange}>
              <TabPane tab="Shipping" key="1" disabled={trackNo}>
                <Shipping />
              </TabPane>
              <TabPane tab="History" key="2">
                <Timeline>
                  {logs.map((lg: any, i: number) => (
                    <Timeline.Item key={`deli_log_${i}`}>
                      {lg && lg.status && <p>{lg.status}</p>}
                      {lg && lg.comments && <p>{lg.comments}</p>}
                      {lg && lg.timestamp && (
                        <p>{utcDateToZonedDate(lg.timestamp).toString()}</p>
                      )}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </TabPane>
              <TabPane tab="Status" key="3" disabled={!trackNo}>
                {currentStatus && <Typography> {currentStatus}</Typography>}
                <Button
                  loading={loading}
                  style={{ margin: 60, backgroundColor: "cyan" }}
                  onClick={handleToCheck}
                >
                  Show Status
                </Button>
              </TabPane>
              <TabPane tab="Waybill" key="4" disabled={!trackNo}>
                <Button
                  loading={loading}
                  style={{ margin: 60, backgroundColor: "cyan" }}
                  onClick={handleToWaybill}
                >
                  Waybill
                </Button>
              </TabPane>
              <TabPane tab="Rollback" key="5" disabled={!trackNo}>
                <Button
                  loading={loading}
                  style={{ margin: 60, backgroundColor: "red" }}
                  onClick={handleToCancel}
                >
                  Cancel To Ship
                </Button>
              </TabPane>
            </Tabs>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default DeliveryInfo;
