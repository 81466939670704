import axios from "axios";
const base_url = "https://arlupostdelivery-judrztptdq-uc.a.run.app/api";

export const sendArlupostDelivery = async (data: {
  merchantId: string;
  orderId: string;
  customerAddress: string;
  customerPhone: string;
  customerName: string;
  customerCity: string;
  customerState: string;
  amount: string;
  note: string;
}) => {
  const uri = base_url + "/createOrder?token=alp";
  const APIOptions = {
    method: "post",
    headers: {
      ["Content-Type"]: "application/json",
      ["Authorization"]: `ff5e8581-f243-4d73-b55f-0e199a722e29`,
    },
    data: JSON.stringify({
      ...data,
    }),
  } as any;
  const res = await axios(uri, APIOptions);
  if (res.status === 200) {
    const { success, code } = res.data as { success: boolean; code?: string };
    return { success, code };
  }
  return { success: false, code: "bad-request" };
};
