import React, { useEffect, useState } from "react";
import withUser from "../../hocs/with_user";
import { MemberDetail, PointHistory } from "../state";
import { useParams } from "react-router-dom";
import { getMemberDetail } from "../../api/member_api";
import LoadingSpinner from "../../components/loading_spinner";
import { Avatar, Table, Row, Col, Typography, Tag, Select, Card } from "antd";
import MemberDetailInfo from "../../components/member_detail_info";
import EarnSpendChart from "../../charts/earn_spend_stackbar";
import { format } from "date-fns";
import _ from "lodash";
import RecentPointHistory from "./recent_point_history";
import RecentRewardHistory from "./recent_reward_history";
import defaultPhoto from "../../assets/image/user.png";

type S =
  | { status: "loading" }
  | { status: "loaded"; data: MemberDetail }
  | { status: "error"; error: string };
const RewardHistoryList = (props: any) => {
  const merchantId =
    props.merchantId || props.user?.userInfo?.merchantId || null;
  const { id: paramId } = useParams() as { id: string };
  const memberId = props.memberId || paramId || null;
  const {
    showLoading = true,
    showTitle = true,
    showMemberTier = false,
    showDetail = true,
  } = props;
  const [state, setState] = useState({ status: "loading" } as S);
  useEffect(() => {
    if (merchantId && memberId) {
      getMemberDetail({
        merchantId,
        memberId,
      }).then((res) => {
        let d: any | null = null;
        if (res.success) {
          d = res.data;
          setState({ status: "loaded", data: res.data! });
        } else {
          setState({ status: "error", error: "Member not found" });
        }
        if (props.onLoad) props.onLoad(res.data!);
      });
    }
  }, [merchantId, memberId]);
  const bar = () => {
    if (!showTitle) return null;
    return (
      <Row>
        <Col flex={4}>
          <Row>
            <Col span={12}>
              <Typography.Text strong> Member </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  const tier = () => {
    if (!showMemberTier) return null;
    const _tier =
      state.status === "loaded" && state.data.memberTier?.length === 1
        ? state.data.memberTier[0]
        : null;
    //  const member = state.status === 'loaded' ? state.data.member?.[0] : null;
    if (!_tier) return null;
    return (
      <Card size="small" style={styles.card}>
        <Row gutter={24}>
          <Col offset={2} span={8}>
            <Avatar
              style={{ width: 150, height: 80 }}
              src={_tier.photo}
              size="large"
              shape="square"
            />
            <span style={{ marginLeft: 10 }}>{_tier.name}</span>
          </Col>
          {/* <Col span={8}>
                        <Avatar src={member?.photo || defaultPhoto} size={50} shape="circle" />
                        <span style={{ marginLeft: 8, }}>  {member?.displayName}</span>
                    </Col> */}
        </Row>
      </Card>
    );
  };
  const chartData = (data: PointHistory[]) => {
    let _labels: string[] = [],
      _earn: any[] = [],
      _spend: any[] = [];
    let _temp: Array<{ sort: number; earn: number; spend: number; date: any }> =
      [];
    const _list: Array<{
      sort: number;
      earn: number;
      spend: number;
      date: any;
    }> = [];
    data.forEach((p) => {
      const date = new Date();
      date.setFullYear(p.createdYear, p.createdMonth - 1);
      const sort = date.getTime();
      const earn = p.type === "earn" ? (p.point < 0 ? 0 : p.point) : 0;
      let spend =
        p.type === "spend" ? (p.point > 0 ? p.point * -1 : p.point) : 0;
      spend = p.type === "earn" && p.point < 0 ? p.point : 0;
      const d = format(date, "yyyy-MMM");
      _temp.push({ sort, earn, spend, date: d });
    });

    const months = _.groupBy(_temp, "date");
    Object.keys(months).forEach((r) => {
      let sort = 0,
        earn = 0,
        spend = 0,
        date = r;
      months[r].forEach((v) => {
        if (v.sort > sort) sort = v.sort;
        earn += v.earn;
        spend += v.spend;
      });
      _list.push({ sort, earn, spend, date });
    });
    _.orderBy(_list, "date", "asc").forEach((rv) => {
      _labels.push(rv.date);
      _earn.push(rv.earn);
      _spend.push(rv.spend);
    });
    return { _labels, _earn, _spend };
  };
  const firstRow = () => {
    if (!showDetail) return null;
    if (state.status === "loading") return <></>;
    if (state.status === "loaded" && state.data.member?.length !== 1)
      return <></>;
    const member = state.status === "loaded" ? state.data.member?.[0] : null;
    const pointHistory = state.status === "loaded" ? state.data.point : [];
    const _tier =
      state.status === "loaded" && state.data.memberTier?.length === 1
        ? state.data.memberTier[0]
        : null;
    const cData = chartData(pointHistory);
    return (
      <Row gutter={24}>
        <Col span={10}>
          {member && <MemberDetailInfo data={member} tier={_tier} />}
        </Col>
        <Col span={14}>
          <Card style={styles.card}>
            <EarnSpendChart
              title="Earn And Spend"
              lables={cData._labels}
              lable1="Earn"
              data1={cData._earn}
              lable2="Spend"
              data2={cData._spend}
            />
          </Card>
        </Col>
      </Row>
    );
  };
  const secondRow = () => {
    if (state.status === "loading") return <></>;
    const pointHistory = state.status === "loaded" ? state.data.point : [];
    const rewardHistory = state.status === "loaded" ? state.data.reward : [];
    return (
      <Row gutter={24} style={{ marginTop: 4 }}>
        <Col span={12}>
          <RecentPointHistory
            title="Point History"
            columnType="two"
            showHearder={true}
            showPage={true}
            data={pointHistory}
            merchantId={merchantId}
          />
        </Col>
        <Col span={12}>
          <RecentRewardHistory title="Reward History" data={rewardHistory} />
        </Col>
      </Row>
    );
  };
  return (
    <React.Fragment>
      {showLoading && state.status === "loading" && <LoadingSpinner />}
      {!showLoading && state.status === "loading" && (
        <Row gutter={24}>
          <Col span={24}>
            {" "}
            <Card loading={true} />
          </Col>
        </Row>
      )}
      {state.status === "loaded" && (
        <>
          {bar()}
          {firstRow()}
          {secondRow()}
        </>
      )}
      {state.status === 'error' && <span style={{ fontWeight: 'bold', color: 'red' }}>{state.error}</span>}
    </React.Fragment>
  );
};

const styles = {
  card: {
    height: "100%",
  },
};
export default withUser(RewardHistoryList) as any;
