import React, { useState } from "react";
import {
  get_order_shipment_prices,
  getArLuPostShipmentPriceVariables,
} from "../../gql/gql_query";
import {
  update_ordershipment_price,
  getUpdateOrderShipmentPriceVariables,
} from "../../gql/gql_mutation";
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  Switch,
  Table,
  Input,
  Select,
  Card,
  Row,
  Col,
  Tag,
  Popconfirm,
  Typography,
  InputNumber,
  Form,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  LoadingOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import { OrderShipmentPrice2, Product } from "../../store";
import LoadingSpinner from "../../components/loading_spinner";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import withAdmin from "../../hocs/with_admin";
import CreateArLuPostPrice from "../../components/arlupost_create_price";
const { Search } = Input;

const { Option } = Select;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch";
  record: Product;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : inputType === "switch" ? (
      <Switch defaultChecked={record.active} />
    ) : (
      <Input disabled={false} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ArlupostPriceList = (props: any) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [form] = Form.useForm();
  const [searchText, setSearch] = useState(null as string | null);
  const [page, setPage] = useState({ current: 1, size: 50 });
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: OrderShipmentPrice2) => record.id === editingKey;
  const {
    loading,
    error: error,
    data: arlupostData,
    refetch,
  } = useQuery(get_order_shipment_prices, {
    variables: getArLuPostShipmentPriceVariables(),
  });
  const [updatePrice, { loading: saveLoading, data }] = useMutation(
    update_ordershipment_price
  );
  const edit = (record: Partial<OrderShipmentPrice2>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };
  const onChange = (current: number, size: number) => {
    setEditingKey("");
    setPage({ current, size });
  };
  const onSave = async (id: string, name: string) => {
    const ship = (await form.validateFields()) as OrderShipmentPrice2;
    const variables = getUpdateOrderShipmentPriceVariables(
      id,
      ship.price,
      ship.active
    );
    const res = await updatePrice({ variables });
    setEditingKey("");
    if (res.data) {
      addToast(`Success save to ${name}`, {
        autoDismiss: true,
        appearance: "success",
      });
      await refetch();
    } else {
      addToast(`Failed save to ${name}`, {
        autoDismiss: true,
        appearance: "error",
      });
    }
  };
  let columm: any[] = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      align: "center",
      render: (v: number, r: OrderShipmentPrice2) => {
        return (
          r.from_region?.sr_name_mmr || r.from_township?.township_name_mmr || ""
        );
      },
      editable: false,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      filteredValue: searchText ? [searchText] : null,
      onFilter: (value: string, r: OrderShipmentPrice2) => {
        let isFound: boolean = false;
        if (r.to_township) {
          isFound = r.to_township.township_name_eng
            .toLowerCase()
            .includes(value.toLowerCase());
          if (!isFound)
            isFound = r.to_township.township_name_mmr.includes(
              value.toLowerCase()
            );
        }
        if (!isFound && r.to_region?.sr_name_eng) {
          isFound = r.to_region.sr_name_eng
            .toLowerCase()
            .includes(value.toLowerCase());
          if (!isFound)
            isFound = r.to_region.sr_name_mmr.includes(value.toLowerCase());
        }
        return isFound;
      },
      render: (v: number, r: OrderShipmentPrice2) => {
        let label: string =
          r.to_region?.sr_name_mmr || r.to_township?.township_name_mmr || "";
        if (r.to_address_type === "TOWNSHIP" && r.to_township) {
          label += ` (${r.to_township.sr.sr_name_mmr})`;
        }
        return label;
      },
      editable: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a: OrderShipmentPrice2, b: OrderShipmentPrice2) =>
        Number(a.price) - Number(b.price),
      render: (v: number, r: OrderShipmentPrice2) => {
        return r.price;
      },
      editable: true,
    },
    {
      title: "Status",
      dataIndex: "active",
      key: "active",
      sorter: (a: OrderShipmentPrice2, b: OrderShipmentPrice2) =>
        Number(a.active) - Number(b.active),
      render: (v: number, r: OrderShipmentPrice2) => {
        return r.active ? (
          <Tag color="success"> active</Tag>
        ) : (
          <Tag color="default">inactive</Tag>
        );
      },
      editable: true,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "12%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, r: OrderShipmentPrice2) => {
        const editable = isEditing(r);
        const name = `${
          r.from_region?.sr_name_mmr || r.from_township?.township_name_mmr
        }- ${r.to_region?.sr_name_mmr || r.to_township?.township_name_mmr}`;
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${name}?`}
                  onConfirm={() => onSave(r.id, name!)}
                  onCancel={() => setEditingKey("")}>
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(r)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const mergedColumns = columm.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: OrderShipmentPrice2) => ({
        record,
        inputType:
          col.dataIndex === "price"
            ? "number"
            : col.dataIndex === "active"
            ? "switch"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Row gutter={24}>
            <Col span={10}>
              <Typography> {"ArLuPost Prices"} </Typography>
            </Col>
            <Col span={12}>
              <Search
                //  addonBefore={selectBefore}
                defaultValue={searchText}
                placeholder="search..."
                allowClear
                onSearch={(val: any) => setSearch(val.toLowerCase())}
              />
            </Col>
            <Col span={2}>
              <CreateArLuPostPrice
                onFinish={async () => {
                  await refetch();
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <Form form={form} component={false}>
                <Table
                  size="small"
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  dataSource={(
                    (arlupostData?.orderShipmentPrices ||
                      []) as OrderShipmentPrice2[]
                  ).map((p, i) => ({ ...p, key: p.id }))}
                  pagination={{
                    position: ["bottomRight"],
                    pageSize: page.size,
                    current: page.current,
                    showSizeChanger: true,
                    pageSizeOptions: [50, 100, 150, 200],
                    onChange: onChange,
                  }}
                />
              </Form>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withAdmin(ArlupostPriceList) as any;
