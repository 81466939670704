import { ShoppingCartOutlined } from "@ant-design/icons";
import { useQuery } from '@apollo/client';
import { Badge, Card, Col, Row, Table, Tag, Typography } from "antd";
import { format, subDays, } from "date-fns";
import * as _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MoneyIcon, UpcommingIcon, UserIcon } from '../../assets/icons/svg_icons';
import SaleLineChart from '../../charts/sale_dashboard_linechart';
import PaymentBarChart from '../../charts/sale_dashboard_payment_barchart';
import SalePieChart from '../../charts/sale_dashboard_piechart';
import DateRange from '../../components/date_range';
import LoadingSpinner from '../../components/loading_spinner';
import PanelCard from '../../components/panel_card';
import { get_order_variables, get_orders } from "../../gql/gql_query";
import { formatMoney, numFormatter } from '../../helpers/util';
import withUser from '../../hocs/with_user';
import RecentOrder from '../../pages/order/recent_list';
import { paths } from "../../routes/paths";
import { AuthState, Order } from '../../store';
const panelList = [
    {
        title: "Orders",
        key: "orders",
        icon: ShoppingCartOutlined,
        value: 0,
        isMoney: false,
    },
    {
        title: "Net Sale",
        key: "netSale",
        icon: MoneyIcon,
        value: 0,
        isMoney: true,
    },
    {
        title: "Total Sale",
        key: "grosSale",
        icon: UpcommingIcon,
        value: 0,
        isMoney: true,
    },
    {
        title: "Customers",
        key: "customers",
        icon: UserIcon,
        value: 0,
        isMoney: false,
    }
]
interface P {
    user: AuthState
}
const Dashboard = (props: P) => {
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: new Date() });
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const { loading, error, data } = useQuery(get_orders, { variables: get_order_variables(merchantId, ranges.startDate, ranges.endDate), fetchPolicy: 'network-only' });
    // console.log('host ', availableCRM())
    const onSelect = async (startDate: Date, endDate: Date) => {
        setRanges({ startDate, endDate });
        //await refetch();
    }
    // useEffect(() => {
    //     const app = getApp()
    //     const functions = getFunctions(app);
    //     const func = httpsCallable(functions, 'createPaymentOrder');
    //     const args = {
    //         host: window.location.hostname,
    //         merchantId: "piticafe",
    //         orderId: "0232234222",
    //         amount: 100,
    //         orderNo: "OP-12333",
    //         env: "staging",
    //         agent: "aplus",
    //         envURL: "createPayOrderUrl"
    //     };
    //     func(args).then(res => {
    //         console.log('res of payment', res.data)
    //     })
    // }, [])
    const columns2 = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a: { status: string }, b: { status: string }) => a.status.localeCompare(b.status),
            render: (v: number, record: { status: string }) => {
                let color = "orange";
                if (record.status === "progress") color = "lime";
                if (record.status === "complete") color = "green";
                if (record.status === "cancel") color = "red";
                return <Tag color={color}>{record.status}</Tag>;
            },
        },
        {
            title: 'Amount',
            key: 'amount',
            sorter: (a: { amount: number }, b: { amount: number }) => a.amount - b.amount,
            render: (v: number, record: { amount: number }) => {
                return formatMoney(record.amount) + " MMK";
            },
        },
        {
            title: 'Count',
            key: 'count',
            sorter: (a: { count: number }, b: { count: number }) => a.count - b.count,
            render: (v: number, record: { count: number }) => {
                return <Badge count={record.count} style={{ backgroundColor: "blue" }} />;
            },
        },

    ];
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a: { date: string }, b: { date: string }) => a.date.localeCompare(b.date),
        },
        {
            title: 'Net Sale',
            key: 'totalSale',
            sorter: (a: { totalSale: number }, b: { totalSale: number }) => a.totalSale - b.totalSale,
            render: (v: number, record: { totalSale: number }) => {
                return formatMoney(record.totalSale);
            },
        },
        {
            title: 'Total tax',
            key: 'totalTax',
            sorter: (a: { totalTax: number }, b: { totalTax: number }) => a.totalTax - b.totalTax,
            render: (v: number, record: { totalTax: number }) => {
                return formatMoney(record.totalTax);
            },
        },
        {
            title: 'Total Sale',
            dataIndex: 'growSale',
            key: 'growSale',
            sorter: (a: { growSale: number }, b: { growSale: number }) => a.growSale - b.growSale,
            render: (v: number, record: { growSale: number }) => {
                return formatMoney(record.growSale);
            },
        },
    ];
    const panelData = () => {
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        let customerIds: string[] = [];
        let pCount = 0, pAmount = 0, psCount = 0, psAmount = 0, cCount = 0, cAmount = 0, caCount = 0, caAmount = 0, count = 0;
        orders.forEach(or => {
            count += 1
            if (or.merchant_customer) {
                customerIds.push(or.merchant_customer.id);
            }
            if (or.order_status === "ACCEPT") {
                psCount += 1;
                psAmount += Number(or.grand_total);
            }
            if (or.order_status === "PAID") {
                psCount += 1;
                psAmount += Number(or.grand_total);
            }
            if (or.order_status === 'PENDING') {
                pCount += 1;
                pAmount += Number(or.grand_total);
            }
            if (or.order_status === 'COMPLETE') {
                cCount += 1;
                cAmount += Number(or.grand_total);
            }
            if (or.order_status === 'CUSTOMER_CANCEL') {
                caCount += 1;
                caAmount += Number(or.grand_total);
            }
            if (or.order_status === "MERCHANT_CANCEL") {
                caCount += 1;
                caAmount += Number(or.grand_total);
            }
        });
        const customers = _.uniq(customerIds);
        return { orders: count, customers: customers.length, netSale: cAmount, grosSale: psAmount + pAmount }
    }

    const control = () => (
        <Row>
            <Col flex={4}>
                <Row >
                    <Col span={12}><Typography level={3}> Sale Dashboard </Typography></Col>
                    <Col span={12} > </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
            </Col>
        </Row>
    );
    const panels = () => {
        const _data = panelData() as any;
        return (
            <Row style={{ marginTop: 4 }} gutter={[48, 16]}>
                {
                    panelList.map(panel => (
                        <Col span={6} >
                            <PanelCard loading={false} icon={<panel.icon style={{ fontSize: 60 }} />} title={panel.title} primary={panel.isMoney ? formatMoney(_data[panel.key] || panel.value) : numFormatter((_data[panel.key] || panel.value))} secondary={panel.isMoney ? "MMK" : null} />
                        </Col>)
                    )
                }
            </Row>
        );
    }
    const saleRowData = () => {
        let _labels: string[] = [], _data: string[] = [], days: any[] = [];
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        let posCount = 0, webCount = 0, otherCount = 0;
        orders = orders.map(or => {
            if (or.sale_channel === 'WEB') {
                webCount += Number(or.grand_total);
            }
            if (or.sale_channel === 'POS') {
                posCount += Number(or.grand_total);
            }
            if (or.sale_channel === 'OTHER') {
                otherCount += Number(or.grand_total);
            }
            const day = format(new Date(or.created_at), 'yyyy-MMM-dd ')
            const sec = (new Date(or.created_at)).getTime();
            return {
                ...or,
                filter: day,
                filterValue: sec,
            }
        });
        const dayGroups = _.groupBy(orders, "filter");
        Object.keys(dayGroups).forEach(day => {
            const date = day;
            let totalSale = 0;
            let sec: number | null = null;
            dayGroups[day].forEach(or => {
                totalSale += Number(or.grand_total);
                if (!sec) sec = or.filterValue;
            });
            days.push({ date, totalSale, sec });
        });
        _.orderBy(days, 'sec', 'asc').forEach(f => {
            _labels.push(f.date);
            _data.push(f.totalSale);
        });
        return { _labels, _data, posCount, webCount, otherCount }
    }
    const saleRow = () => {
        const _chartData = saleRowData();
        return (
            <Row style={{ marginTop: 10 }} gutter={24}>
                <Col span={16}>
                    <Card bordered={false} hoverable={true} size="small" style={styles.card} >
                        <SaleLineChart lables={_chartData._labels} data={_chartData._data} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false} hoverable={true} size="small" style={styles.card} >
                        <SalePieChart web={_chartData.webCount} pos={_chartData.posCount} other={_chartData.otherCount} />
                    </Card>
                </Col>
            </Row>
        );
    }
    const paymentAndChannelData = () => {
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        orders = orders.filter(ov => ov.order_status === 'COMPLETE');
        let cashPos = 0, cashWeb = 0, cashOther = 0;
        let cashAmountPos = 0, cashAmountWeb = 0, cashAmountOther = 0;
        let bankPos = 0, bankWeb = 0, bankOther = 0;
        let bankAmountPos = 0, bankAmountWeb = 0, bankAmountOther = 0;
        let otherPos = 0, otherWeb = 0, otherToOther = 0, otherAmount = 0;
        let otherAmountPos = 0, otherAmountWeb = 0, otherAmountOther = 0;
        orders.forEach(or => {
            if (or.sale_channel === 'WEB') {
                if (or.payment_method === 'CASH') {
                    cashWeb += 1;
                    cashAmountWeb += Number(or.grand_total);
                } else if (or.payment_method !== 'OTHER') {
                    bankWeb += 1;
                    bankAmountWeb += Number(or.grand_total);
                } else {
                    otherWeb += 1;
                    otherAmountWeb += Number(or.grand_total);
                }
            }
            if (or.sale_channel === 'POS') {
                if (or.payment_method === 'CASH') {
                    cashPos += 1;
                    cashAmountPos += Number(or.grand_total);
                } else if (or.payment_method !== 'OTHER') {
                    bankPos += 1;
                    bankAmountPos += Number(or.grand_total);
                } else {
                    otherPos += 1;
                    otherAmountPos += Number(or.grand_total);
                }
            }
            if (or.sale_channel === 'OTHER') {
                if (or.payment_method === 'CASH') {
                    cashOther += 1;
                    cashAmountOther += Number(or.grand_total);
                } else if (or.payment_method !== 'OTHER') {
                    bankOther += 1;
                    bankAmountOther += Number(or.grand_total);
                } else {
                    otherToOther += 1;
                    otherAmountOther += Number(or.grand_total);
                }
            }
        });
        const totalBankAmount = bankAmountPos + bankAmountWeb + bankAmountOther;
        const totalCashAmount = cashAmountPos + cashAmountWeb + cashAmountOther;
        const totalOtherAmount = otherAmountPos + otherAmountWeb + otherAmountOther;

        const totalBankCount = bankPos + bankWeb + bankOther;
        const totalCashCount = cashPos + cashWeb + cashOther;
        const totalOtherCount = otherPos + otherWeb + otherToOther;
        const payments = [
            { status: "Bank", amount: totalBankAmount, count: totalBankCount },
            { status: "Cash", amount: totalCashAmount, count: totalCashCount },
            // { status: "Other", amount: totalOtherAmount, count: totalOtherCount }
        ]

        const totalWebChannelAmount = cashAmountWeb + bankAmountWeb + otherAmountWeb;
        const totalPOSChannelAmount = cashAmountPos + bankAmountPos + otherAmountPos;
        const totalOtherChannelAmount = cashAmountOther + bankAmountOther + otherAmountOther;

        const totalWebChannelCount = cashWeb + bankWeb + otherWeb;
        const totalPOSChannelCount = cashPos + bankPos + otherPos;
        const totalOtherChannelCount = otherPos + bankOther + otherToOther;

        const channels = [
            { status: "POS", amount: totalPOSChannelAmount, count: totalPOSChannelCount },
            { status: "Online", amount: totalWebChannelAmount, count: totalWebChannelCount },
            // { status: "Other", amount: totalOtherChannelAmount, count: totalOtherChannelCount }
        ]

        return { payments, channels }
    }
    const paymentRowData = () => {
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        let cashPos = 0, cashWeb = 0, cashOther = 0;
        let bankPos = 0, bankWeb = 0, bankOther = 0;
        let otherPos = 0, otherWeb = 0, otherToOther = 0;
        orders.forEach(or => {
            if (or.sale_channel === 'WEB') {
                if (or.payment_method === 'CASH') {
                    cashWeb += 1;
                } else if (or.payment_method !== 'OTHER') {
                    bankWeb += 1;
                } else {
                    otherWeb += 1;
                }
            }
            if (or.sale_channel === 'POS') {
                if (or.payment_method === 'CASH') {
                    cashPos += 1;
                } else if (or.payment_method !== 'OTHER') {
                    bankPos += 1;
                } else {
                    otherPos += 1;
                }
            }
            if (or.sale_channel === 'OTHER') {
                if (or.payment_method === 'CASH') {
                    cashOther += 1;
                } else if (or.payment_method !== 'OTHER') {
                    bankOther += 1;
                } else {
                    otherToOther += 1;
                }
            }
        });
        return { cashPos, cashWeb, cashOther, bankPos, bankWeb, bankOther, otherPos, otherWeb, otherToOther }
    }
    const paymentRow = () => {
        const pData = paymentRowData();
        const pcData = paymentAndChannelData();
        return (
            <Row style={{ marginTop: 10 }} gutter={24}>
                <Col span={8}>
                    <Card bordered={false} hoverable={true} size="small" style={styles.card}>
                        <Table
                            showHeader={false}
                            columns={columns2}
                            dataSource={pcData.payments}
                            pagination={{
                                pageSize: 4,
                                position: ['none',]
                            }}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false} hoverable={true} size="small" style={styles.card}>
                        <Table
                            showHeader={false}
                            columns={columns2}
                            dataSource={pcData.channels}
                            pagination={{
                                pageSize: 4,
                                position: ['none',]
                            }}
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false} hoverable={true} size="small" style={styles.card}>
                        <PaymentBarChart
                            cashPos={pData.cashPos}
                            cashWeb={pData.cashWeb}
                            cashOther={pData.cashOther}
                            bankPos={pData.bankPos}
                            bankWeb={pData.bankWeb}
                            bankOther={pData.bankOther}
                        />
                    </Card>
                </Col>
            </Row>
        )
    }
    const groupDays = (orders: Order[] = []) => {
        let days: any[] = [];
        orders = orders.map(or => {
            const day = format(new Date(or.created_at), 'yyyy-MMM-dd ');
            const sec = (new Date(or.created_at)).getTime();
            return {
                ...or,
                filter: day,
                filterValue: sec,
            }
        });
        const dayGroups = _.groupBy(orders, "filter");
        Object.keys(dayGroups).forEach(day => {
            const date = day;
            let totalSale = 0, growSale = 0, totalTax = 0;
            let sec: number | null = null;
            dayGroups[day].forEach(or => {
                if (!sec) sec = or.filterValue;
                if (or.order_status === "COMPLETE") {
                    totalTax += Number(or.tax);
                    totalSale += Number(or.grand_total);
                }
                if (or.order_status === "PENDING") {
                    growSale += Number(or.grand_total);
                }
                if (or.order_status === "PAID") {
                    growSale += Number(or.grand_total);
                }
            });
            days.push({ date, totalSale, growSale: growSale + totalSale, totalTax, sec });
        });
        return _.sortBy(days, 'sec', 'desc')
    }
    const recentRow = () => {
        return (
            <Row justify="space-between" style={{ marginTop: 8 }} gutter={24} >
                <Col span={12}>
                    <Card title="Summary" size="small" bordered={false}
                        style={styles.card}
                        extra={
                            <Link to={paths.saleReport}>
                                <Typography.Link>
                                    Show more...
                                </Typography.Link>
                            </Link>
                        }>
                        <Table
                            style={{ background: "transparent" }}
                            columns={columns}
                            dataSource={groupDays((data && data.orders) ? data.orders : [])}
                            pagination={{
                                pageSize: 5,
                                position: ['none',]
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12} >
                    <Card size="small" title="Recent" bordered={false}
                        style={styles.card}
                        extra={
                            <Link to={paths.orders}>
                                <Typography.Link>
                                    Show more...
                                </Typography.Link>
                            </Link>
                        }
                    >
                        <RecentOrder />
                    </Card>

                </Col>
            </Row>
        );
    }
    return (
        <React.Fragment>
            {loading && <LoadingSpinner />}
            {(!loading && !error) &&
                <>
                    {control()}
                    {panels()}
                    {saleRow()}
                    {paymentRow()}
                    {recentRow()}
                </>
            }
        </React.Fragment>
    )
}
export default withUser(Dashboard);

const styles = {
    card: {
        height: "95%"
    }
}