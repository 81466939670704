import apolloClient from "../gql/appollo_client";
import { update_stock_item, get_update_stock_variables, update_coupon, get_update_coupon_variables, create_coupon, get_create_coupon_variable, update_product, get_update_product_variables, update_many_product, getUpdateManyProductSortVariables } from "../gql/gql_mutation";
import { find_first_coupon, get_first_coupon_variales, get_product_variables } from "../gql/gql_query";
import { Coupon } from "../store";

export const updateStockItem = async (data: { id: string, stock?: number, price: number | string, origin_price: string | number }) => {
    let result;
    try {
        result = await apolloClient.mutate({
            mutation: update_stock_item,
            variables: get_update_stock_variables(data),
            fetchPolicy: "network-only",
        });
    } catch (err) {
        console.log(err);
    }
    //  console.log(result);

    if (result && result.data) {
        return result.data.updateProductStockItem;
    } else {
        //console.log(result);
    }
    return null;
};
export const updateProduct = async (data: { id: string, order: number }) => {
    let result;
    try {
        result = await apolloClient.mutate({
            mutation: update_product,
            variables: get_update_product_variables(data),
            fetchPolicy: "network-only",
        });
    } catch (err) {
        console.log(err);
    }
    //  console.log(result);
    if (result && result.data) {
        return result.data.updateProduct;
    } else {
        //console.log(result);
    }
    return null;
};
export const updateManyProductSortOrder = async (data: { merchantId: string, order: number, Ids: string[] }) => {
    let result;
    try {
        result = await apolloClient.mutate({
            mutation: update_many_product,
            variables: getUpdateManyProductSortVariables(data),
            fetchPolicy: "network-only",
        });
    } catch (err) {
        console.log(err);
    }
    //  console.log(result);
    if (result && result.data) {
        return result.data.updateManyProduct;
    } else {
        //console.log(result);
    }
    return null;
};
export const updateCoupon = async (id: string, coupon: Coupon) => {
    let result;
    try {
        result = await apolloClient.mutate({
            mutation: update_coupon,
            variables: get_update_coupon_variables(id, coupon),
            fetchPolicy: "network-only",
        });
    } catch (err) {
        console.log(err);
    }
    // console.log(result);

    if (result && result.data) {
        return result.data.updateCoupon;
    } else {
        // console.log(result);
    }
    return null;
};
export const createCoupon = async (coupon: Coupon, merchantId: string) => {
    let result;
    try {
        result = await apolloClient.mutate({
            mutation: create_coupon,
            variables: get_create_coupon_variable(coupon, merchantId),
            fetchPolicy: "network-only",
        });
    } catch (err) {
        console.log(err);
    }
    // console.log(result);

    if (result && result.data) {
        return result.data.createCoupon;
    } else {
        // console.log(result);
    }
    return null;
};
export const findCoupon = async (code: string, merchantId: string) => {
    let result;
    try {
        result = await apolloClient.query({
            query: find_first_coupon,
            variables: get_first_coupon_variales(code, merchantId),
            fetchPolicy: "network-only", // Doesn't check cache before making a network request
        });
    } catch (err) {
        console.log(err);
    }
    //  console.log(result);
    if (result && result.data) {
        const item = result.data.findFirstCoupon;
        return item;
    } else {
        // console.log(result);
    }
    return null;
};