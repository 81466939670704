import React, { useEffect, useState } from "react";
import withUser from "../../hocs/with_user";
import { MemberPointActivity } from "../state";
import { getMemberActivity } from "../../api/member_api";
import DateRange from "../../components/date_range";
import LoadingSpinner from "../../components/loading_spinner";
import { format, subDays, addDays, differenceInDays } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Tag, Select, Alert } from "antd";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import defaultPhoto from "../../assets/image/user.png";
import { Link } from "react-router-dom";
import { paths } from "../../routes/paths";

const { Option } = Select;
type S =
  | { status: "loading" }
  | { status: "loaded"; data: MemberPointActivity[] }
  | { status: "error"; error: string };
type Filter =
  | "Active Members"
  | "Inactive Members"
  | "New Members"
  | "RedAlert Members"
  | "Return Members"
  | "Risk Members"
  | "Royal Members"
  | "Sleep Members";

const getInfo = (type: Filter) => {
  if (type === "Active Members") {
    return "Member who get the first point in given period";
  }
  if (type === "Inactive Members") {
    return "Member earn no points in given period";
  }
  if (type === "New Members") {
    return "Member who get the first point in given period";
  }
  if (type === "RedAlert Members") {
    return "Members who have more than three earn points 3 times but have not earn point in 365";
  }
  if (type === "Return Members") {
    return "Members who earn point at least one time in the given period and have more than one lifetime points";
  }
  if (type === "Risk Members") {
    return "Previous earn points more than 3 times in total ,who have not earn point anything with 90 days";
  }
  if (type === "Royal Members") {
    return "Earn point more than 3 times in total, earn point in past 90 days";
  }
  if (type === "Sleep Members") {
    return "Previous earn point more than 4 time in total , who have not earn points anything with 90 days";
  }
  return "Invalid member activity";
};
const showDate = (type: Filter) => {
  if (type === "Active Members") {
    return true;
  }
  if (type === "Inactive Members") {
    return true;
  }
  if (type === "New Members") {
    return true;
  }
  if (type === "Return Members") {
    return true;
  }
  return false;
};

const getData = (type: Filter, data: MemberPointActivity[]) => {
  if (type === "Active Members") {
    return data.filter((mp) => mp.totalPoint > 0);
  }
  if (type === "Inactive Members") {
    return data.filter((mp) => mp.totalPoint === 0);
  }
  if (type === "New Members") {
    return data.filter((mp) => mp.totalPoint !== 0);
  }
  if (type === "RedAlert Members") {
    return data.filter(
      (mp) =>
        mp.earnPoint > 0 && differenceInDays(new Date(), mp.lastActiveAt) > 365
    );
  }
  if (type === "Return Members") {
    return data.filter((mp) => mp.earnPoint > 0);
  }
  if (type === "Risk Members") {
    return data.filter(
      (mp) =>
        mp.earnPoint > 3 &&
        differenceInDays(new Date(), mp.lastActiveAt) > 90 &&
        differenceInDays(new Date(), mp.lastActiveAt) < 365
    );
  }
  if (type === "Royal Members") {
    return data.filter(
      (mp) =>
        mp.earnPoint > 3 && differenceInDays(new Date(), mp.lastActiveAt) < 90
    );
  }
  if (type === "Sleep Members") {
    return data.filter(
      (mp) =>
        mp.earnPoint > 3 && differenceInDays(new Date(), mp.lastActiveAt) > 90
    );
  }
  return data;
};
const MemberActivity = (props: any) => {
  const merchantId = props.user?.userInfo?.merchantId || null;
  const [state, setState] = useState({ status: "loading" } as S);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  const [selectedFilter, setSelectedFilter] = useState(
    "Active Members" as Filter
  );
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 2 * 30),
    endDate: new Date(),
  });
  useEffect(() => {
    if (merchantId) {
      const startDate: Date | undefined = showDate(selectedFilter)
        ? ranges.startDate
        : undefined;
      const endDate: Date = showDate(selectedFilter)
        ? ranges.endDate
        : new Date();
      getMemberActivity({
        merchantId,
        startDate: startDate,
        endDate: endDate,
      }).then((res) => {
        if (res.success) {
          setState({
            status: "loaded",
            data: getData(selectedFilter, res.data || []),
          });
        }
      });
    }
  }, [merchantId, ranges, selectedFilter]);
  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      align: "center",
      render: (v: number, record: MemberPointActivity) => (
        <Link to={paths.getCustomerDetailRoute(record.memberId)}>
          {" "}
          <Avatar
            src={record.memberPhoto || defaultPhoto}
            size={45}
            onClick={() => {}}
          />
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberName || "".localeCompare(b.memberName || ""),
      render: (v: number, record: MemberPointActivity) => {
        const val = record.memberName || "";
        return val;
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      align: "center",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberPhone || "".localeCompare(b.memberPhone || ""),
      render: (v: number, r: MemberPointActivity) => {
        const val = r.memberPhone || "";
        return val;
      },
    },
    {
      title: "Total Point",
      dataIndex: "totalPoint",
      key: "totalPoint",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberTotalPoint - b.memberTotalPoint,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.memberTotalPoint || 0;
        return val;
      },
    },
    {
      title: "Point In Period",
      dataIndex: "pointInPeriod",
      key: "pointInPeriod",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.totalPoint - b.totalPoint,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.totalPoint || 0;
        return val;
      },
    },
    {
      title: "Engagement",
      dataIndex: "engagement",
      key: "engagement",
      ellipsis: true,
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.earnEngagement - b.earnEngagement,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.earnEngagement || 0;
        return val;
      },
    },
    {
      title: "Current Point",
      dataIndex: "currentPoint",
      key: "currentPoint",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        a.memberPoint - b.memberPoint,
      render: (v: number, r: MemberPointActivity) => {
        const val = r.memberPoint || 0;
        return val;
      },
    },
    {
      title: "First Date",
      dataIndex: "firstDate",
      key: "firstDate",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        Number(a.firstActiveAt) - Number(b.firstActiveAt),
      render: (v: number, r: MemberPointActivity) => {
        return format(new Date(Number(r.firstActiveAt)), "yyyy-MMM-dd");
      },
    },
    {
      title: "Last Date",
      dataIndex: "lastDate",
      key: "lastDate",
      sorter: (a: MemberPointActivity, b: MemberPointActivity) =>
        Number(a.lastActiveAt) - Number(b.lastActiveAt),
      render: (v: number, r: MemberPointActivity) => {
        return format(new Date(Number(r.lastActiveAt)), "yyyy-MMM-dd");
      },
    },
  ];
  const onExport = () => {
    if (state.status !== "loaded") return;
    setExportLoading(true);
    const fileName = showDate(selectedFilter)
      ? `${selectedFilter}_` +
        format(ranges.startDate, "yyyy-MMM-dd") +
        "_" +
        format(ranges.endDate, "yyyy-MMM-dd")
      : selectedFilter;
    let excelData: any[] = [];
    state.data.forEach((p) => {
      const memberName = p.memberName || "";
      const memberPhone = p.memberPhone || "";
      const memberEmail = p.memberEmail || "";
      const currentPoint = p.memberPoint || 0;
      const totalPoint = p.memberTotalPoint || 0;
      const pointInPeriod = p.totalPoint || 0;
      const spendEngagement = p.spendEngagement;
      const earnEngagement = p.earnEngagement;
      const firstActiveDate = format(new Date(p.firstActiveAt), "yyyy-MMM-dd");
      const lastActiveDate = format(new Date(p.lastActiveAt), "yyyy-MMM-dd");
      excelData.push({
        memberName,
        memberPhone,
        memberEmail,
        currentPoint,
        totalPoint,
        pointInPeriod,
        spendEngagement,
        earnEngagement,
        lastActiveDate,
        firstActiveDate,
      });
    });
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const onSelect = async (startDate: Date, endDate: Date) => {
    setState({ status: "loading" });
    setRanges({ startDate, endDate });
  };
  const onTypeSelect = (t: Filter) => {
    setState({ status: "loading" });
    setSelectedFilter(t);
  };
  const selectType = (
    <Select
      defaultValue={selectedFilter}
      className="select-before"
      style={{ width: 180, marginLeft: 3 }}
      onChange={onTypeSelect}>
      <Option value={"Active Members" as Filter}>Active Members</Option>
      <Option value={"Inactive Members" as Filter}>Inactive Members</Option>
      <Option value={"New Members" as Filter}>New Members</Option>
      <Option value={"RedAlert Members" as Filter}>RedAlert Members</Option>
      <Option value={"Return Members" as Filter}>Return Members</Option>
      <Option value={"Risk Members" as Filter}>Risk Members</Option>
      <Option value={"Royal Members" as Filter}>Royal Members</Option>
      <Option value={"Sleep Members" as Filter}>Sleep Members</Option>
    </Select>
  );
  return (
    <React.Fragment>
      {state.status === "loading" && <LoadingSpinner />}
      {state.status === "loaded" && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Row span={12}>
                  <Col> {selectType}</Col>
                  <Alert
                    style={{ marginLeft: 3 }}
                    message={getInfo(selectedFilter)}
                    type="info"
                    showIcon
                  />
                </Row>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                {showDate(selectedFilter) && (
                  <DateRange
                    startDate={ranges.startDate}
                    endDate={ranges.endDate}
                    onSelect={onSelect}
                  />
                )}
                <ExportButton
                  loading={exportLoading || state.status !== "loaded"}
                  onClick={onExport}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Table
              columns={columns}
              dataSource={(state.status === "loaded" ? state.data : []).map(
                (row, i) => {
                  return { ...row, key: i + 1 };
                }
              )}
              pagination={{
                position: ["bottomRight"],
              }}
            />
          </Row>
        </>
      )}
      {state.status === "error" && <span>{state.error}</span>}
    </React.Fragment>
  );
};
export default withUser(MemberActivity) as any;
