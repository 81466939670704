import React from 'react';
import { RewardHistory } from "../state";
import { Avatar, Card, Table } from "antd";
import { Link } from "react-router-dom";
import { paths } from "../../routes/paths";
import * as _ from 'lodash';

interface P {
    data: RewardHistory[];
}

interface Cell {
    rewardPhoto: string;
    rewardName: string;
    totalCount: number;
    members: Array<{ id: string, name: string, claimed: number, redeem: number }>
}
const makeRow = (data: RewardHistory[]) => {
    let _rows: Cell[] = [];
    const rewards = _.groupBy(data, 'rewardName');
    Object.keys(rewards).forEach(r => {
        const l = rewards[r];
        let rewardName = r, rewardPhoto = l[0].rewardPhoto || "", totalCount = l.length;
        let members: Array<{ id: string, name: string, claimed: number, redeem: number }> = [];
        l.forEach(rm => {
            if (rm.memberId) {
                const index = members.findIndex(m => m.id === rm.memberId);
                if (index === -1) {
                    members.push({ id: rm.memberId!, name: rm.memberName!, claimed: rm.claimedAt ? 1 : 0, redeem: rm.claimedAt ? 0 : 1 })
                } else {
                    const claimed = rm.claimedAt ? 1 : 0, redeem = rm.claimedAt ? 0 : 1;
                    members[index].claimed = members[index].claimed + claimed;
                    members[index].redeem = members[index].redeem + redeem;
                }
            }
        })
        _rows.push({ rewardName, rewardPhoto, totalCount, members })
    })
    return _.orderBy(_rows, 'totalCount', 'desc')
}
export default function RewardSummaryHistory(props: P) {

    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            render: (v: number, record: Cell) => < Avatar src={record.rewardPhoto} size={80} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a: Cell, b: Cell) =>
                (a.rewardName || "").localeCompare(b.rewardName || ""),
            render: (v: number, record: Cell) => {
                const val = record.rewardName;
                return val;
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            sorter: (a: Cell, b: Cell) => a.totalCount - b.totalCount,
            render: (v: number, record: Cell) => {
                const val = record.totalCount
                return val;
            },
        },
        {
            title: 'Members',
            dataIndex: 'members',
            fixed: 'right',
            render: (v: number, record: Cell) => {
                return (
                    <table>
                        <thead style={{ display: 'block' }}>
                            <tr key='0'>
                                <th style={{ color: 'black', width: '150px' }}>Member</th>
                                <th style={{ color: 'black', width: '150px' }}>Total</th>
                                <th style={{ color: 'black', width: '150px' }}>Claimed</th>
                                <th style={{ color: 'black', width: '150px' }}>Redeem</th>
                            </tr>
                        </thead>
                        <tbody style={{
                            display: 'block',
                            overflowY: 'scroll'
                        }} >
                            {record.members.map((m, index) => (
                                <tr key={index}>
                                    <td style={{ width: '150px' }}>{m.name}</td>
                                    <td style={{ width: '150px' }}>{m.claimed + m.redeem}</td>
                                    <td style={{ width: '150px' }}>{m.claimed}</td>
                                    <td style={{ width: '150px' }}>{m.redeem}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            },
        },
    ];
    return (
        <Card
            title="Recent"
            extra={
                <Link to={paths.rewardHistory}>
                    Show more...
                </Link>
            }>
            <Table
                showHeader={false}
                columns={columns}
                dataSource={makeRow(props.data).map((row, i) => { return { ...row, key: i + 1 } })}
                pagination={{
                    pageSize: 6,
                    position: ['bottomRight',]
                }}
            />
        </Card>
    )
}
