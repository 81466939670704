import { Button, Modal, Select, Row, Tag } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from "@ant-design/icons";
import { Typography } from 'antd';
import { MerchantUser } from '../store';
import Avatar from 'antd/lib/avatar/avatar';
import defaultPhoto from '../assets/image/user.png'
const { Option } = Select;
interface P {
    ownerId: string | null;
    users: MerchantUser[];
    title?: string;
    label?: string;
    okText?: string;
    onOk: (ownerId: string) => void;
}

const CustomerOwnerSelector: React.FC<P> = (props: P) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [currentValue, setValue] = useState(props.ownerId || null)
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setLoading(true);
        if (currentValue) props.onOk(currentValue);
        setTimeout(() => {
            setLoading(false);
            setVisible(false);
        }, 3000);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <EditOutlined onClick={showModal} />
            <Modal
                visible={visible}
                title={props.title || "Change Owner"}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={handleOk}
                    >
                        {props.okText || "Save Owner"}
                    </Button>,
                ]}


            >
                <Row>
                    <Typography level={3} style={{ marginTop: 4 }}>{props.label || "*Choose Owner"} </Typography>
                    <Select
                        showSearch
                        style={{ width: 250, marginLeft: 5 }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        // filterOption={(input: any, option: any) => ((option!.children as unknown as string).toLocaleLowerCase()).includes(input)}
                        // filterSort={(optionA: any, optionB: any) =>
                        //     (optionA!.children as unknown as string)
                        //         .toLowerCase()
                        //         .localeCompare((optionB!.children as unknown as string).toLowerCase())
                        // }
                        defaultValue={currentValue}
                        onChange={(val: any) => setValue(val)}
                    >
                        {props.users.map(u => (
                            <Option value={u.id}>
                                <Avatar src={u.photo || defaultPhoto} size="small" /> {u.name || ""}
                                {u.role && <Tag style={{ marginLeft: 4 }} color="blue">{u?.role}</Tag>}
                            </Option>))}
                    </Select>
                </Row>
            </Modal>
        </>
    );
};

export default CustomerOwnerSelector;