import React from 'react';
import { MerchantCustomer2, Order } from "../store";
import { Card, Typography, Row, Col } from "antd";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { formatMoney } from "../helpers/util";
export default function CustomerDetailInfo(props: { data: MerchantCustomer2 }) {
    const { data } = props
    let ltv = 0;
    const orders = orderBy((data.orders || []), 'created_at', 'asc');
    const firstOrder: Order | null = orders.length > 0 ? orders[0] : null;
    const lastOrder: Order | null = orders.length > 0 ? orders[orders.length - 1] : null;
    orders.map(o => ltv += Number(o.grand_total));
    return (
        <Card
            style={styles.card}
            title={<Typography.Title level={5}>Order Details</Typography.Title>}
            bordered={false}
            size="small"
        >
            <Row gutter={24} style={styles.item}>
                <Col span={10}>
                    <Typography.Title style={styles.typography}>Total Orders</Typography.Title>
                </Col>
                <Col span={14}>
                    {orders.length}
                </Col>
            </Row>
            <Row gutter={12} style={styles.item}>
                <Col span={10}>
                    <Typography.Title style={styles.typography}>Customer LTV</Typography.Title>
                </Col>
                <Col span={14}>
                    {formatMoney(ltv, 0) + ' MMK'}
                </Col>
            </Row>
            <Row gutter={12} style={styles.item}>
                <Col span={10}>
                    <Typography.Title style={styles.typography}>FirstOrder</Typography.Title>
                </Col>
                <Col span={14}>
                    {firstOrder ? format(new Date(firstOrder.created_at), 'yyyy-MMM-dd') : ""}
                </Col>
            </Row>
            <Row gutter={12} style={styles.item}>
                <Col span={10}>
                    <Typography.Title style={styles.typography}>LastOrder</Typography.Title>
                </Col>
                <Col span={14}>
                    {lastOrder ? format(new Date(lastOrder.created_at), 'yyyy-MMM-dd') : ""}
                </Col>
            </Row>
            <Row gutter={12} style={styles.item}>
                <Col span={10}>
                    <Typography.Title style={styles.typography}>First Order Channel</Typography.Title>
                </Col>
                <Col span={14}>
                    {firstOrder ? firstOrder.sale_channel : ""}
                </Col>
            </Row>
            <Row gutter={12} style={styles.item}>
                <Col span={10}>
                    <Typography.Title style={styles.typography}>Last Order Channel</Typography.Title>
                </Col>
                <Col span={14}>
                    {lastOrder ? lastOrder.sale_channel : ""}
                </Col>
            </Row>
        </Card>
    )
}
const styles = {
    card: {
        height: "100%"
    },
    cardTitle: {
        textAlign: 'center',
    },
    typography: {
        fontSize: 14,
        padding: 2,
    },
    item: {
        marginTop: 5,
    },
    cardMeta: {
        justifyContent: "center", display: "flex"
    }
}