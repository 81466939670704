import { gql } from "@apollo/client";

export const get_cash_flow = gql`
  query CashFlows(
    $where: CashFlowWhereInput
    $orderBy: [CashFlowOrderByWithRelationInput!]
  ) {
    cashFlows(where: $where, orderBy: $orderBy) {
      amount
      comment
      created_at
      image_one
      cateogry {
        label
        name
        id
      }
      id
      user {
        uid
        name
      }
      type
    }
  }
`;

export const getCashFlow = (merchanId: string, status: string) => ({
  where: {
    merchant_id: {
      equals: merchanId,
    },
    status: {
      equals: status,
    },
  },
  orderBy: [
    {
      created_at: "desc",
    },
  ],
});
