import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { paths } from "../../routes/paths";
import { BoughtCount, BoughtTogether, Order, Product } from "../../store";
import withUser from "../../hocs/with_user";
import {
  product_bought_customers_query as customerQuery,
  getProductBoughtCustomersVariables as customerVariables,
  product_orders_query as orderQuery,
  getProductOrdersVariables as orderVariables,
  product_bought_items_query as btiQuery,
  getProductBoughtItemsVariables as btiVariables,
  product_query as prodQuery,
  getProductVariables as prodVariables,
  orderItemsByStockId,
  getOrderItemsByStockIdVar,
} from "../../gql/gql_query";
import LoadingSpinner from "../../components/loading_spinner";
import { Row, Col, Card } from "antd";
import ProductInfo from "../../components/product_detail_info";
import ProductChart from "../../charts/product_detail_barchart";
import ProductBoughtTogether from "../../components/product_detail_bought_together";
import ProductBoughtCustomer from "../../components/product_detail_bought_customer";
const ProductDetail = (props: any) => {
  const { id: productId } = useParams() as { id: string };
  const stockIds = (p: Product) =>
    (p.product_stock_items || []).map((psi) => psi.id);

  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";

  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
  } = useQuery(customerQuery, { variables: customerVariables(productId) });
  const {
    loading: btiLoading,
    error: btiError,
    data: btiData,
  } = useQuery(btiQuery, { variables: btiVariables(productId, merchantId) });
  const [
    getOrders,
    { loading: orderLoading = true, error: orderError, data: orderData },
  ] = useLazyQuery(orderQuery);
  const [
    getOrderItemsByStockId,
    {
      loading: OIBStockIdLoading = true,
      error: OIBStockIdError,
      data: OIBStockIdData,
    },
  ] = useLazyQuery(orderItemsByStockId);
  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(prodQuery, { variables: prodVariables(productId) });
  const loading =
    (customerLoading && !customerError) ||
    (orderLoading && !orderError) ||
    (btiLoading && !orderError) ||
    (productLoading && !productError);
  useEffect(() => {
    if (productData) {
      getOrders({
        variables: orderVariables(merchantId, stockIds(productData.product)),
      });
      getOrderItemsByStockId({
        variables: getOrderItemsByStockIdVar(stockIds(productData.product)),
      });
    }
  }, [productData]);

  // console.log(productData.product);
  
  // const {
  //   loading: OIBStockIdLoading,
  //   error: OIBStockIdError,
  //   data: OIBStockIdData,
  // } = useQuery(orderItemsByStockId, {
  //   variables: getOrderItemsByStockIdVar(stockIds(productData.product)),
  // });
  console.log(OIBStockIdData, "oibstock");
  const total = OIBStockIdData?.orderItems
    .map((item: any) => item?.price)
    ?.reduce((a: string, b: string) => Number(a) + Number(b));
  // console.log(total, "prices");
  const filterId = productData?.product.product_stock_items.map(
    (item: any) => item.id
  );
  const filterData = filterId?.map((id: any) => {
    return OIBStockIdData?.orderItems
      .map((item: any) => item.stock_item)
      .filter((item: any) => item.id === id);
  });
  console.log(filterData);

  // const filterItem = OIBStockIdData?.orderItems
  //   .map((item: any) => item.stock_item, "testing")
  //   .filter((item: any) => filterId.includes(item.id));
  // console.log(filterItem);

  //  const filter2= filter?.filter((item:any)=> stockIds(productData.product).includes(item.id))
  // // console.log(JSON.stringify(filter2),'filter');
  // console.log('====================================');
  // console.log(filter2);
  // console.log('====================================');
  const firstRow = () => {
    const item_orders = ((orderData && orderData["orders"]) || []) as Order[];
    return (
      <Row gutter={24}>
        <Col span={8}>
          <ProductInfo
            totalPrice={total}
            product={productData.product}
            orders={item_orders}
          />
        </Col>
        <Col span={16}>
          <ProductChart
          simpleData ={filterData}
            data={item_orders}
            product={productData.product as Product}
          />
        </Col>
      </Row>
    );
  };
  const secondRow = () => {
    let btData: BoughtTogether[] = [];
    const main_items = (btiData["product"]["bought_together_main"] ||
      []) as BoughtTogether[];
    const sub_items = (btiData["product"]["bought_together_sub"] ||
      []) as BoughtTogether[];
    const bought_customers = customerData["boughtCounts"] as BoughtCount[];
    for (const mi of main_items) {
      btData.push({
        main_item: mi.sub_item,
        total_buy_count: mi.total_buy_count,
        updated_at: mi.updated_at,
        created_at: mi.created_at,
      });
    }
    for (const si of sub_items) {
      btData.push({
        main_item: si.main_item,
        total_buy_count: si.total_buy_count,
        updated_at: si.updated_at,
        created_at: si.created_at,
      });
    }
    return (
      <Row gutter={24} style={{ marginTop: 5 }}>
        <Col span={12}>
          <Card size="small" title="Bought Together" style={styles.card}>
            <ProductBoughtTogether data={btData} />
          </Card>
        </Col>
        <Col span={12}>
          <Card size="small" title="Bought Customer" style={styles.card}>
            <ProductBoughtCustomer data={bought_customers} />
          </Card>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div>
          {firstRow()}
          {secondRow()}
        </div>
      )}
    </>
  );
};

export default withUser(ProductDetail);

const styles = {
  card: {
    height: "100%",
  },
};
