import React, { useState } from "react";
import {
  get_products,
  get_product_variables,
  get_product_count as pCountQuery,
  get_product_count_variables as pCountVar,
  getMerchantBranches,
  getMerchantBranchVariables,
} from "../../gql/gql_query";
import { useQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Table,
  Input,
  Select,
  Card,
  Row,
  Col,
  Popconfirm,
  Typography,
  InputNumber,
  Form,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  LoadingOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import { AuthState, Product } from "../../store";
import LoadingSpinner from "../../components/loading_spinner";
import { useNavigate } from "react-router-dom";
import withUser from "../../hocs/with_user";
import { paths } from "../../routes/paths";
import { useToasts } from "react-toast-notifications";
import { format, subDays } from "date-fns";
import { uniqBy, orderBy } from "lodash";
import { formatMoney } from "../../helpers/util";
import { availableCRM } from "../../helpers/crm_helper";
import {
  updateManyProductSortOrder,
  updateProduct,
} from "../../helpers/gql_helper";
import ImportButton from "../../components/import_button";
import DateRange from "../../components/date_range";
const { Search } = Input;

const { Option } = Select;
interface P {
  user: AuthState;
  pageType?: "Product List" | "Product Sorting";
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Product;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const disable = dataIndex === "order";
  const inputNode =
    inputType === "number" ? (
      <InputNumber disabled={!disable} />
    ) : (
      <Input disabled={disable} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

// const columms = (goToDetail: (id: Product) => any, searchText: any) => {
//     let cl: any = [
//         {
//             title: 'Photo',
//             dataIndex: 'image',
//             align: 'center',
//             render: (v: number, record: Product) => < Avatar src={record.image} size={45} onClick={() => availableCRM() ? goToDetail(record) : {}} />
//         },
//         {
//             title: 'Name',
//             dataIndex: 'name',
//             key: 'name',
//             filteredValue: searchText ? [searchText] : null,
//             onFilter: (value: string, record: Product) => record.name.toLowerCase().includes(value),
//             ellipsis: true,
//             sorter: (a: Product, b: Product) =>
//                 a.name.localeCompare(b.name),
//         },
//         {
//             title: 'Variations',
//             dataIndex: 'variations',
//             key: 'variations',
//             sorter: (a: Product, b: Product) => a._count?.variations! - b._count?.variations!,
//             render: (v: number, record: Product) => {
//                 const vars = (record.variations || []).map(v => v.name)
//                 // return <ul>
//                 //     {vars.map((v, i) => (<li key={`si_${record.id}_${i}`}>{v}</li>))}
//                 // </ul>;
//                 return vars.length > 0 ? `${vars[0]}...` : "..."

//             },
//         },
//         {
//             title: 'Stocks',
//             dataIndex: 'product_stock_items',
//             key: 'product_stock_items',
//             width: 200,
//             sorter: (a: Product, b: Product) => a._count?.product_stock_items! - b._count?.product_stock_items!,
//             render: (v: number, record: Product) => {
//                 const stocks = (record.product_stock_items || []).map(v => v.name)
//                 // return <ul>
//                 //     {stocks.map((s, i) => (<li key={`si_${record.id}_${i}`}>{s}</li>))}
//                 // </ul>;
//                 return stocks.length > 0 ? `${stocks[0]}...` : "...";
//             },
//         },
//         {
//             title: 'Total Sales',
//             dataIndex: 'totalSales',
//             key: 'totalSales',
//             sorter: (a: Product, b: Product) => a.totalSales! - b.totalSales!,
//             render: (v: number, record: Product) => {
//                 return formatMoney(record.totalSales || 0, 0);
//             },
//         },
//         {
//             title: 'Total Orders',
//             dataIndex: 'totalOrders',
//             key: 'totalOrders',
//             sorter: (a: Product, b: Product) => a.totalOrders! - b.totalOrders!,
//             render: (v: number, record: Product) => {
//                 return record.totalOrders;
//             },
//         },
//         // {
//         //     title: 'CreatedAt',
//         //     dataIndex: 'created_at',
//         //     key: 'created_at',
//         //     sorter: (a: Product, b: Product) => new Date(a.created_at).getDate() - new Date(b.created_at).getDate(),
//         //     render: (v: number, record: Product) => {
//         //         return format(new Date(record.created_at), 'yyyy-MMM-dd');
//         //     },
//         // },
//         {
//             title: 'UpdateAt',
//             dataIndex: 'updated_at',
//             key: 'updated_at',
//             sorter: (a: Product, b: Product) => new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
//             render: (v: number, record: Product) => {
//                 return format(new Date(record.updated_at), 'yyyy-MMM-dd');
//             },
//         },

//     ];
//     if (availableCRM()) {
//         cl.push({
//             title: 'Action',
//             key: 'action',
//             align: 'center',
//             width: '12%',
//             fixed: 'right',
//             render: (v: number, record: Product) => {
//                 return (
//                     <ButtonGroup>
//                         <Button shape="circle" icon={<EyeOutlined />}
//                             onClick={() => goToDetail(record)}
//                         />
//                     </ButtonGroup>
//                 );

//             }
//         })
//     }
//     return cl;
// }
const ProductList = (props: P) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [sortOrder, setSortOrder] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [searchText, setSearch] = useState(null as string | null);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [page, setPage] = useState({ current: 1, size: 50 });
  const [currentBranch, setBranch] = useState("all");
  const [editingKey, setEditingKey] = useState("");
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });
  const isEditing = (record: Product) => record.id === editingKey;
  const hasSelected = selectedRowKeys.length > 0;
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  // const merchantId = "hello7mart";
  const { loading, error, data, refetch } = useQuery(get_products, {
    variables: get_product_variables({
      merchantId,
      take: page.size,
      skip: (page.current - 1) * page.size,
      search: searchText,
      startDate: ranges.startDate,
      endDate: ranges.endDate,
    }),
  });
  // console.log(data);

  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(pCountQuery, {
    variables: pCountVar(merchantId),
    fetchPolicy: "network-only",
  });
  const {
    loading: bLoading,
    error: bError,
    data: bData,
  } = useQuery(getMerchantBranches, {
    variables: getMerchantBranchVariables(merchantId),
  });
  const onSelectChange = (newSelectedRowKeys: string[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleSelectSubmit = async () => {
    if (selectedRowKeys.length === 0) return;
    setSaveLoading(true);
    const res = await updateManyProductSortOrder({
      merchantId,
      order: sortOrder,
      Ids: selectedRowKeys,
    });
    if (res) {
      addToast(`Successfully updated`, {
        autoDismiss: true,
        appearance: "success",
      });
      await refetch();
    } else {
      addToast(`Failed to updated`, { autoDismiss: true, appearance: "error" });
    }
    setSelectedRowKeys([]);
    setSaveLoading(false);
  };
  const goToDetail = (prod: Product) => {
    navigate(paths.getProductDetailRoute(prod.id), { state: prod });
  };
  const edit = (record: Partial<Product>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };
  const onChange = (current: number, size: number) => {
    setEditingKey("");
    setPage({ current, size });
  };
  const onSave = async (id: string, name: string) => {
    setSaveLoading(true);
    const prod = (await form.validateFields()) as Product;
    const res = await updateProduct({ id, order: prod.order });
    if (res) {
      addToast(`Success save to ${name}`, {
        autoDismiss: true,
        appearance: "success",
      });
      await refetch();
    } else {
      addToast(`Failed save to ${name}`, {
        autoDismiss: true,
        appearance: "error",
      });
    }
    setEditingKey("");
    setSaveLoading(false);
  };
  const onSelect = async (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };

  const makeRowData = (data: Product[]) => {
    data = data.map((prod, index) => {
      let totalOrders = 0;
      let totalSales;
      let totalCounts;
      if (prod.product_stock_items) {
        totalSales = prod.product_stock_items.reduce((a, o) => {
          const orderTotal = o.order_items?.reduce((itemTotal, item) => {
            return itemTotal + Number(item.price);
          }, 0);
          return a + Number(orderTotal);
        }, 0);
        totalCounts = prod.product_stock_items.reduce((a, o) => {
          const orderTotal = o.order_items?.reduce((itemTotal, item) => {
            return itemTotal + Number(item.count);
          }, 0);
          return a + Number(orderTotal);
        }, 0);

        for (const psi of prod.product_stock_items) {
          if (psi.order_items?.length !== 0) {
            const uniqueOrders = uniqBy(psi.order_items, "order_id");
            totalOrders += uniqueOrders.length;

            // psi.order_items.forEach((oi) => {
            //   //to update total sale with complete
            //   if (oi.order && oi.order.order_status === "COMPLETE") {
            //     totalSales += oi.count * Number(originalPrice);
            //   }
            // });
          }
        }
      }
      return {
        ...prod,
        totalOrders,
        totalSales,
        totalCounts,
      };
    });
    return orderBy(data, "totalOrders", "desc");
  };
  const onExport = () => {
    setExportLoading(true);
    // const fileName = "products";
    const fileName =
      "products" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];
    makeRowData(data && data.products ? data.products : []).map(
      (p: Product) => {
        const name = p.name;
        const categories = p.product_categories
          ? p.product_categories.map((pc) => pc.name).join(",")
          : "";
        const variations = (p.variations || []).map((pv) => pv.name).join(",");
        // const stocks = (p.product_stock_items || []).map(psi => psi.name).join(',');
        const createdAt = format(new Date(p.created_at), "yyyy-MMM-dd");
        const totalSales = p.totalSales || 0;
        const totalOrders = p.totalOrders || 0;
        const totalCounts = p.totalCounts || 0;

        (p.product_stock_items || []).forEach((psi, index) => {
          const stockOnHand = psi.stock;
          let stockOnSold = 0;
          const stockItem = psi.name;
          const stockSku = psi.sku || "";
          (psi.order_items || []).forEach((oi) => {
            if (oi.order?.order_status === "COMPLETE") {
              stockOnSold += oi.count;
            }
          });
          if (index === 0) {
            excelData.push({
              name,
              categories,
              variations,
              totalSales,
              totalCounts,
              totalOrders,
              createdAt,
              stockItem,
              stockSku,
              stockOnHand,
              stockOnSold,
            });
          } else {
            excelData.push({
              name: "",
              categories: "",
              variations: "",
              totalSales: "",
              totalCounts: "",
              totalOrders: "",
              createdAt: "",
              stockItem,
              stockSku,
              stockOnHand,
              stockOnSold,
            });
          }
        });
      }
    );
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const onExport2 = () => {
    setExportLoading(true);
    const fileName = "products";
    let excelData: any[] = [];
    makeRowData(data && data.products ? data.products : []).map(
      (p: Product) => {
        const id = p.id;
        const ProductName = p.name;
        const Description = p.description;
        const ProductLink = `https://hello7mart.com/product/${p.id}`;
        const ProductImageLink = p.image;
        const defaultProduct = p.product_stock_items?.find(
          (psi) => psi.default_item
        );
        const Price = defaultProduct?.price || 0;
        excelData.push({
          id,
          ProductName,
          Description,
          Price,
          ProductLink,
          ProductImageLink,
        });
      }
    );
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };
  const expandedRowRender = (row: Product) => {
    const columns = [
      { title: "Name", dataIndex: "name", key: "name" },
      // { title: "Id", dataIndex: "id", key: "id" },
      {
        title: "Stock On hand",
        key: "stock",
        render: (
          v: number,
          record: {
            key: number;
            name: string;
            stockOnHand: any;
            stockOnSold: any;
            path?: string | null;
          }
        ) => {
          return record.stockOnHand;
        },
      },
      {
        title: "Stock on sold",
        dataIndex: "sold",
        key: "sold",
        render: (
          v: number,
          record: {
            key: number;
            name: string;
            stockOnHand: any;
            stockOnSold: any;
            path?: string | null;
          }
        ) => {
          return record.stockOnSold;
        },
      },
    ];
    let rowData: Array<{
      key: number;
      id: string;
      name: string;
      stockOnHand: any;
      stockOnSold: any;
      path?: string | null;
    }> = [];
    (row.product_stock_items || []).forEach((psi, index) => {
      const stockOnHand = psi.stock;
      let stockOnSold = 0;
      const name = `${row.name || ""}(${psi.name})`;
      (psi.order_items || []).forEach((oi) => {
        if (oi.order?.order_status === "COMPLETE") {
          stockOnSold += oi.count;
        }
      });
      rowData.push({
        key: index + 1,
        name,
        stockOnHand,
        stockOnSold,
        id: psi.id,
      });
    });

    return (
      <Card>
        <Table
          showHeader={true}
          size="small"
          columns={columns}
          dataSource={rowData}
          pagination={false}
        />
      </Card>
    );
  };

  let columm2: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (v: number, record: Product) => (
        <Avatar
          src={record.image}
          size={45}
          onClick={() => (availableCRM() ? goToDetail(record) : {})}
        />
      ),
      editable: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: searchText ? [searchText.toLocaleLowerCase()] : null,
      onFilter: (value: string, record: Product) =>
        record.name.toLowerCase().includes(value),
      ellipsis: true,
      sorter: (a: Product, b: Product) => a.name.localeCompare(b.name),
      editable: false,
      render: (v: number, record: Product) => (
        <Tooltip title={record.name}>{record.name}</Tooltip>
      ),
    },
    {
      title: "Modifiers",
      dataIndex: "variations",
      key: "variations",
      sorter: (a: Product, b: Product) =>
        a._count?.variations! - b._count?.variations!,

      render: (v: number, record: Product) => {
        const vars = (record.variations || []).map((v) => v.name);
        // return <ul>
        //     {vars.map((v, i) => (<li key={`si_${record.id}_${i}`}>{v}</li>))}
        // </ul>;
        return vars.length > 0 ? `${vars[0]}...` : "...";
      },
      editable: false,
    },
    {
      title: "Variations",
      dataIndex: "product_stock_items",
      key: "product_stock_items",
      width: 200,
      sorter: (a: Product, b: Product) =>
        a._count?.product_stock_items! - b._count?.product_stock_items!,
      render: (v: number, record: Product) => {
        const stocks = (record.product_stock_items || []).map((v) => v.name);
        // return <ul>
        //     {stocks.map((s, i) => (<li key={`si_${record.id}_${i}`}>{s}</li>))}
        // </ul>;

        return stocks.length > 0 ? (
          <Tooltip title={stocks.length > 1 ? `${stocks} ,` : stocks}>
            {stocks[0]}
          </Tooltip>
        ) : (
          "..."
        );
      },
      editable: false,
    },
  ];
  if (props.pageType && props.pageType === "Product List") {
    columm2.push({
      title: "Total Sales",
      dataIndex: "totalSales",
      key: "totalSales",
      sorter: (a: Product, b: Product) => a.totalSales! - b.totalSales!,
      render: (v: number, record: Product) => {
        return formatMoney(record.totalSales || 0, 0);
      },
      editable: false,
    });
    columm2.push({
      title: "Total item  ",
      dataIndex: "total item ",
      key: "totalCounts",
      sorter: (a: Product, b: Product) => a.totalCounts! - b.totalCounts!,
      render: (v: number, record: Product) => {
        return record.totalCounts;
      },
      editable: false,
    });
    columm2.push({
      title: "Total Orders",
      dataIndex: "totalOrders",
      key: "totalOrders",
      sorter: (a: Product, b: Product) => a.totalOrders! - b.totalOrders!,
      render: (v: number, record: Product) => {
        return record.totalOrders;
      },
      editable: false,
    });
  }
  if (props.pageType && props.pageType === "Product Sorting") {
    columm2.push({
      title: "Sorting",
      dataIndex: "order",
      key: "order",
      sorter: (a: Product, b: Product) => a.order - b.order,
      render: (v: number, record: Product) => {
        return record.order;
      },
      editable: true,
    });
  }
  columm2.push({
    title: "UpdateAt",
    dataIndex: "updated_at",
    key: "updated_at",
    sorter: (a: Product, b: Product) =>
      new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
    render: (v: number, record: Product) => {
      return format(new Date(record.updated_at), "yyyy-MMM-dd");
    },
    editable: false,
  });
  if (props.pageType && props.pageType === "Product List" && availableCRM()) {
    columm2.push({
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",
      width: "12%",
      fixed: "right",
      editable: false,
      render: (v: number, record: Product) => {
        return (
          <ButtonGroup>
            <Button
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() => goToDetail(record)}
            />
          </ButtonGroup>
        );
      },
    });
  }
  if (props.pageType && props.pageType === "Product Sorting") {
    columm2.push({
      title: "Action",
      key: "action",
      align: "center",
      width: "12%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: Product) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => onSave(record.id, record.name)}
                  onCancel={() => setEditingKey("")}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    });
  }
  const mergedColumns = columm2.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Product) => ({
        record,
        inputType: col.dataIndex === "order" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const selectBefore = (
    <Select
      defaultValue={currentBranch}
      className="select-before"
      style={{ width: "auto", minWidth: 120 }}
      onChange={(v: string) => setBranch(v)}
    >
      <Option value="all">All</Option>
      {bData &&
        bData.merchantBranches &&
        bData.merchantBranches.map((mb: any) => (
          <Option value={mb.id}>{mb.name}</Option>
        ))}
      {/* <Option value="shipping">shipping</Option> */}
    </Select>
  );
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && !error && countData && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={8}>
                  <Typography level={2}> {props.pageType || ""} </Typography>
                  {hasSelected && (
                    <Row gutter={4}>
                      <Col>
                        <InputNumber
                          min={1}
                          max={100}
                          defaultValue={sortOrder}
                          onChange={(val: number) => setSortOrder(val)}
                        />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={handleSelectSubmit}
                          icon={<SaveOutlined />}
                          loading={saveLoading}
                          disabled={!hasSelected}
                        >
                          Save {selectedRowKeys.length} rows
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col span={8} offset={8}>
                  <Search
                    //  addonBefore={selectBefore}
                    defaultValue={searchText}
                    placeholder="search..."
                    allowClear
                    size="default"
                    onSearch={(val: any) => setSearch(val.toLowerCase())}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <DateRange
                startDate={ranges.startDate}
                endDate={ranges.endDate}
                onSelect={onSelect}
              />
              <ImportButton
                title="Product Import"
                label="excel import"
                loading={false}
                onClick={() => navigate(paths.productImport)}
              />
              <ExportButton
                loading={exportLoading || loading}
                onClick={onExport}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Form form={form} component={false}>
              <Table
                //columns={columms(goToDetail, searchText ? searchText.toLowerCase() : null)}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                rowSelection={
                  props.pageType === "Product Sorting" ? rowSelection : null
                }
                columns={mergedColumns}
                rowClassName="editable-row"
                dataSource={makeRowData(data.products || ([] as Product[])).map(
                  (p, i) => ({ ...p, key: p.id })
                )}
                expandable={{
                  expandedRowRender,
                }}
                pagination={{
                  position: ["bottomRight"],
                    pageSize: page.size,
                    current: page.current,
                    showSizeChanger: true,
                    total: countData?.aggregateProduct._count.id || page.size,
                    pageSizeOptions: [50, 100, 150, 200],
                    onChange: onChange,
                }}
              />
            </Form>
          </Row>
        </>
      )}
    </>
  );
};
export default withUser(ProductList) as any;
