import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
ChartJS.defaults.scale.grid.display = false;
ChartJS.defaults.scale.ticks.display = true;

export default function Barchart(props: { lables: any[], data: any[], title: string, lable: string }) {
    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false,
            },
            title: {
                display: true,
                text: `Member And Activity`,
            },
        },
    };
    const data = {
        labels: props.lables,
        datasets: [
            {
                label: props.lable,
                data: props.data,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    return (
        <div className="canvas-container">
            <Bar options={options} data={data} />
        </div>
    )
}
