import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { get_orders2, get_order_variables } from "../../gql/gql_query";
import { AuthState } from "../../store";
import withUser from "../../hocs/with_user";
import { subDays, format, startOfDay, endOfDay } from "date-fns";
import { Row, Col, Table, Input } from "antd";
import LoadingSpinner from "../../components/loading_spinner";
import moment from "moment";
import { ExportExcel } from "../../services/xlsx_service";
import ExportButton from "../../components/export_button";
import { Typography } from "antd";
import DateRange from "../../components/date_range";

const { Search } = Input;
interface P {
  user: AuthState;
}
const ExpenseList = (props: P) => {
  const [ranges, setRanges] = useState({
    startDate: startOfDay(subDays(new Date(), 30)),
    endDate: endOfDay(new Date()),
  });
  const [exportLoading, setExportLoading] = useState(false);

  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";

  const {
    loading: ordersLoading,
    error: ordersError,
    data: ordersData,
  } = useQuery(get_orders2, {
    variables: get_order_variables(
      merchantId,
      ranges.startDate,
      ranges.endDate
    ),
  });
  const [searchText, setSearchText] = useState("");

  const filteredData = ordersData?.orders?.filter((item: any) =>
    item?.order_number?.toLowerCase()?.includes(searchText?.toLowerCase())
  );

  const exportHandler = () => {
    setExportLoading(true);
    const exportData = filteredData?.length
      ? filteredData?.map((record: any) => ({
          Date: moment(record.created_at).format("L"),
          Order_No: record?.order_number,
          Account: record?.payment_method,
          Channel: record?.sale_channel,
          Amount: record?.total_price,
          Name: record?.merchant_customer?.customer?.name,
        }))
      : ordersData?.orders?.map((record: any) => ({
          Date: moment(record.created_at).format("L"),
          Order_No: record?.order_number,
          Account: record?.payment_method,
          Channel: record?.sale_channel,
          Amount: record?.total_price,
          Name: record?.merchant_customer?.customer?.name,
        }));
    ExportExcel(exportData, "piti income summary");
    setExportLoading(false);
  };
  const tableColumns: any = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (v: number, record: any) => {
        const val = moment(record.created_at).format("L");
        return val;
      },
    },

    {
      title: "Order No",
      dataIndex: "order_number",
      key: "order_number",
      render: (v: number, record: any) => {
        const val = record?.order_number;
        return val;
      },
    },
    {
      title: "Account",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (v: number, record: any) => {
        const val = record?.payment_method;
        return val;
      },
    },
    {
      title: "Channel",
      dataIndex: "sale_channel",
      key: "sale_channel",
      render: (v: number, record: any) => {
        const val = record?.sale_channel;
        return val;
      },
    },
    {
      title: "Amount",
      dataIndex: "total_price",
      key: "total_price",
      render: (v: number, record: any) => {
        const val = record?.total_price;
        return val;
      },
    },
    {
      title: "Name",
      dataIndex: "merchant_customer",
      key: "name",
      render: (v: number, record: any) => {
        const val = record?.merchant_customer?.customer?.name;
        return <>{val ? <p>{val}</p> : <p>-</p>}</>;
      },
    },
  ];
  const onSelect = async (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    //await refetch();
  };

  return (
    <>
      {ordersLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={8}>
                  <Typography.Text strong>Piti Income </Typography.Text>
                </Col>
                <Col span={8}></Col>
                <Col span={8}>
                  <Search
                    defaultValue={searchText}
                    // addonBefore={selectBefore}
                    placeholder={`search by order number ${ordersData?.orders?.length}...`}
                    size="middle"
                    allowClear
                    onSearch={(val: any) => setSearchText(val.toLowerCase())}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <Row>
                <DateRange
                  startDate={ranges.startDate}
                  endDate={ranges.endDate}
                  onSelect={onSelect}
                />
                {/* {excelFilter} */}
                <ExportButton
                  loading={exportLoading || ordersLoading}
                  onClick={exportHandler}
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col span={24}>
              <Table
                columns={tableColumns}
                dataSource={
                  filteredData?.length ? filteredData : ordersData?.orders
                }
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default withUser(ExpenseList) as any;
