import React from 'react';
import { Spin } from "antd";

export default function LoadingSpinner() {
    return (
        <div className="loading-spinner">
            <Spin size="large" tip="Loading..." />
        </div>
    )
}
