import React from 'react';
import { ApolloError } from '@apollo/client'
import { Button, Result, Typography } from 'antd';

const { Paragraph, Text } = Typography;

const Error = (props: { error: ApolloError }) => (
    <Result
        status="error"
        title={props.error.name}
        subTitle="Please contact your administrator"
        extra={[
            <Button type="primary" key="console" onClick={() => window.location.reload()}>
                Reload Page
            </Button>,
        ]}
    >
        <div style={{textAlign:'center'}}>
            <Paragraph>
                <Text
                    strong
                    style={{
                        fontSize: 16,
                    }}
                >
                    {props.error.message || ''}
                </Text>
            </Paragraph>
        </div>
    </Result>
);

export default Error;