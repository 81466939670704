import React, { useState } from "react";
import {
  get_product_stock_variables,
  get_product_stocks,
  get_product_stock_count as stockCountQuery,
  get_product_stock_count_variables as stockCountVariables,
  productStockItemLogs,
  getProductStockItemLogVariables,
  getMerchantBranches,
  getMerchantBranchVariables,
  PsiOrderItems,
  getPSIOrderItemsVar,
} from "../../gql/gql_query";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  Avatar,
  Button,
  Table,
  Input,
  InputNumber,
  Select,
  Card,
  Popconfirm,
  Tooltip,
  Form,
  Col,
  Row,
  Typography,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  LoadingOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import {
  ApolloResult,
  AuthState,
  OrderItem,
  Product,
  ProductStockItem,
  ProductStockItemLog,
} from "../../store";
import LoadingSpinner from "../../components/loading_spinner";
import { useToasts } from "react-toast-notifications";
import withUser from "../../hocs/with_user";
import { updateStockItem } from "../../helpers/gql_helper";
import {
  create_product_stock_item_log,
  getCreateProductStockItemLogVariables,
  get_update_stock_variables,
  update_new_psi,
} from "../../gql/gql_mutation";
import { format } from "date-fns";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import ConfirmPopupWithNote from "../../components/confirm_popup_note";
import { TablePaginationConfig } from "antd/lib/table/Table";
const { Search } = Input;
const { Option } = Select;
interface P {
  user: AuthState;
  pageType?: "Product Inventory" | "Product Price";
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: ProductStockItem;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const disable = dataIndex === "stock" || "price" || "original_price";
  const inputNode =
    inputType === "number" ? (
      <InputNumber disabled={!disable} />
    ) : (
      <Input disabled={disable} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const StockList = (props: P) => {
  const defaultPageSize = 20;
  const { addToast } = useToasts();
  const [currentBranch, setBranch] = useState("default");
  const [searchText, setSearch] = useState(null as string | null);
  const [editingKey, setEditingKey] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(null as boolean | null);
  //const [page, setPage] = useState({ current: 1, size: 50 });
  const [pagination, setPagination] = useState<TablePaginationConfig | null>(null);
  const [form] = Form.useForm();
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  const userId =
    props.user.status === "loggedIn" ? props.user.userInfo?.userId! : "";
  const take = pagination?.pageSize || defaultPageSize;
  const skip = ((pagination?.current || 1) - 1) * take;
  const { loading, error, data, refetch } = useQuery(get_product_stocks, {
    variables: get_product_stock_variables({
      merchantId,
      take,
      skip,
      search: searchText,
    }),
    fetchPolicy: "network-only",
  });
  //   console.log(data, "test data for product stock list");
  //   const filteredStockItems = data?.productStockItems?.filter(item => )
  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(stockCountQuery, {
    variables: stockCountVariables(merchantId, searchText),
    fetchPolicy: "network-only",
  });
  const {
    loading: bLoading,
    error: bError,
    data: bData,
  } = useQuery(getMerchantBranches, {
    variables: getMerchantBranchVariables(merchantId),
  });
  const [addLog, { data: addData, loading: addLoading, error: addError }] =
    useMutation(create_product_stock_item_log);
  const [getLogs, { loading: logLoading, data: logData, error: logError }] =
    useLazyQuery(productStockItemLogs);
  const [getOrderItems, { loading: oiLoading, data: oiData }] =
    useLazyQuery(PsiOrderItems);
  const [updatePSI, { loading: uLoading, data: uData }] =
    useMutation<ApolloResult<"updateNewProductStockItem", ProductStockItem>>(
      update_new_psi
    );
  const isEditing = (record: ProductStockItem) => record.id === editingKey;

  const onChange = (current: number, size: number) => {
    setEditingKey("");
    // setPage({ current, size });
  };
  const edit = (record: Partial<ProductStockItem>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };
  const onSave = async (record: ProductStockItem, comment?: string) => {
    setSaveLoading(true);
    const stock_item = (await form.validateFields()) as ProductStockItem;
    // const val = { id: record.id, stock: stock_item.stock, price: stock_item.price, origin_price: stock_item.original_price };
    const res = await updateStockItem({
      id: record.id,
      stock: stock_item.stock,
      price: stock_item.price,
      origin_price: stock_item.original_price,
    });
    //const variables = get_update_stock_variables(val);
    //const res = await updatePSI({ variables });
    if (res) {
      addToast(`Success save to ${record.name}`, {
        autoDismiss: true,
        appearance: "success",
      });
      const variables = getCreateProductStockItemLogVariables({
        type:
          stock_item.price && stock_item.stock
            ? "PRICEANDSTOCK"
            : stock_item.price
              ? "PRICE"
              : "STOCK",
        b4_price: record.price,
        b4_stock: record.stock,
        stock: stock_item.stock - record.stock,
        price: stock_item.price,
        psiId: record.id,
        userId: userId,
        comment,
        ref: "Updated from admin report",
      });
      await addLog({ variables });
      await refetch();
    } else {
      addToast(`Failed save to ${record.name}`, {
        autoDismiss: true,
        appearance: "error",
      });
    }
    setEditingKey("");
    setSaveLoading(false);
  };

  const onExport = () => {
    setExportLoading(true);
    const fileName = "stock_inventory";
    let excelData: any[] = [];
    (data.productStockItems || []).forEach((p: ProductStockItem) => {
      const id = p.id;
      const name = p.name;
      const branchDic = Object.assign(
        {},
        ...(p.stock_item_branches || []).map((x) => ({ [x.branch_id]: x }))
      );
      const avaiableQty = branchDic[currentBranch]?.stock || p.stock;
      const price = p.price;
      const originalPrice = p.original_price;
      const point = p.point;
      const sku = p.sku || "";
      const status = p.active_inventory_control === true ? "open" : "close";
      const lastUpdateAt = format(new Date(p.updated_at), "yyyy-MMM-dd");
      const productName = p.product?.name || "";
      excelData.push({
        id,
        productName,
        name,
        avaiableQty,
        sku,
        price,
        originalPrice,
        point,
        status,
        lastUpdateAt,
      });
    });
    // console.log(excelData, "excelData");
    ExportExcel(excelData, fileName);
    setExportLoading(false);
  };

  const groupStock = (data: Product[]) => {
    let _stocks: ProductStockItem[] = [];
    for (const p of data) {
      if (p.product_stock_items) {
        _stocks = _stocks.concat(p.product_stock_items!);
      }
    }
    return _stocks;
  };
  let columns: any[] = [
    // { title: "Id", dataIndex: 'id', editable: false, key: 'id' },
    {
      title: "Photo",
      dataIndex: "image",
      align: "center",
      render: (v: number, record: ProductStockItem) => (
        <Avatar src={record.image} size={45} />
      ),
      editable: false,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      editable: false,
      width: 150,
      // filteredValue: searchText ? [searchText] : null,
      // onFilter: (value: string, record: ProductStockItem) =>
      //   record.product?.name.toLowerCase().includes(value),
      ellipsis: true,
      sorter: (a: ProductStockItem, b: ProductStockItem) =>
        a.product?.name.localeCompare(b.product?.name || ""),
      render: (v: number, record: ProductStockItem) => {
        return (
          <Tooltip title={record.product?.name}>{record.product?.name}</Tooltip>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: false,
      width: 200,
      //  filteredValue: searchText ? [searchText] : null,
      // onFilter: (value: string, record: ProductStockItem) => record.name.toLowerCase().includes(value),
      ellipsis: false,
      sorter: (a: ProductStockItem, b: ProductStockItem) =>
        a.name.localeCompare(b.name),
    },
  ];
  if (props.pageType && props.pageType === "Product Inventory") {
    columns.push({
      title: "Qty",
      dataIndex: "stock",
      key: "stock",
      editable: true,
      sorter: (a: ProductStockItem, b: ProductStockItem) => a.stock - b.stock,
      render: (v: number, record: ProductStockItem) => {
        const stock = (record.stock_item_branches || []).find(
          (sib) => sib.branch_id === currentBranch
        );
        return currentBranch !== "default" ? stock?.stock || 0 : record.stock;
      },
    });
  }
  if (props.pageType && props.pageType === "Product Price") {
    columns.push({
      title: "Pirce",
      dataIndex: "price",
      key: "price",
      editable: true,
      sorter: (a: ProductStockItem, b: ProductStockItem) => a.price - b.price,
      render: (v: number, record: ProductStockItem) => {
        return record.price;
      },
    });
    columns.push({
      title: "Compare Price",
      dataIndex: "original_price",
      key: "original_price",
      editable: true,
      sorter: (a: ProductStockItem, b: ProductStockItem) =>
        a.original_price - b.original_price,
      render: (v: number, record: ProductStockItem) => {
        return record.original_price;
      },
    });
  }
  columns.push({
    title: "SKU",
    dataIndex: "sku",
    key: "sku",
    className: "hide",
    editable: false,
    sorter: (a: ProductStockItem, b: ProductStockItem) => a.sku?.localeCompare(b.sku || ""),
    render: (v: number, record: ProductStockItem) => {
      return record.sku ?? "";
    },
  });
  columns.push({
    title: "UpdateAt",
    dataIndex: "updated_at",
    key: "updated_at",
    className: "hide",
    editable: false,
    sorter: (a: ProductStockItem, b: ProductStockItem) =>
      new Date(a.updated_at).getDate() - new Date(b.updated_at).getDate(),
    render: (v: number, record: ProductStockItem) => {
      return format(new Date(record.updated_at), "yyyy-MMM-dd");
    },
  });
  columns.push({
    title: "Action",
    key: "action",
    align: "center",
    width: "12%",
    editable: false,
    fixed: "right",
    render: (v: number, record: ProductStockItem) => {
      const editable = isEditing(record);
      return (
        <ButtonGroup>
          {editable ? (
            saveLoading ? (
              <Button type="primary" icon={<LoadingOutlined />}>
                saving...
              </Button>
            ) : (
              <ConfirmPopupWithNote
                label={`Are you sure to save ${record.product?.name},${record.name}?`}
                controller={
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                }
                onOk={(note: string) => {
                  onSave(record, note);
                }}
                onCancel={() => setEditingKey("")}
              />
            )
          ) : (
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => edit(record)}
            />
          )}
        </ButtonGroup>
      );
    },
  });
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ProductStockItem) => ({
        record,
        inputType:
          col.dataIndex === "stock" || "price" || "original_price"
            ? "number"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const expandedRowRender = (row: ProductStockItem) => {
    const psi_logs: ProductStockItemLog[] =
      logData && logData["productStockItemLogs"]
        ? logData["productStockItemLogs"]
        : [];
    const psi_ois: OrderItem[] =
      oiData && oiData["orderItems"] ? oiData["orderItems"] : [];
    // console.log('psilogs', psi_logs)
    let columns: any[] = [
      // { title: 'LogType', dataIndex: 'type', key: 'type' },
    ];
    let columns2: any[] = [
      {
        title: "Count",
        dataIndex: "count",
        key: "count",
        render: (v: number, record: OrderItem) => {
          return record.count;
        },
      },
      {
        title: "OrderNo",
        dataIndex: "order",
        key: "order",
        render: (v: number, record: OrderItem) => {
          return record.order?.order_number;
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (v: number, record: OrderItem) => {
          return record.order?.order_status;
        },
      },
    ];
    if (props.pageType === "Product Inventory") {
      columns.push({
        title: "Stock Before",
        dataIndex: "stock_b4_update",
        key: "stock_b4_update",
        render: (v: number, record: ProductStockItemLog) => {
          return record.stock_b4_update || 0;
        },
      });
      columns.push({
        title: "Adjustment",
        key: "adjustment",
        render: (v: number, record: ProductStockItemLog) => {
          //  return (record.stock || 0) - (record.stock_b4_update || 0);
          return record.stock || 0;
        },
      });
      columns.push({
        title: "Available",
        key: "stock",
        render: (v: number, r: ProductStockItemLog) => {
          return (r.stock_b4_update || 0) + (r.stock || 0);
        },
      });
    }
    if (props.pageType === "Product Price") {
      columns.push({
        title: "Updated Price",
        key: "price",
        render: (v: number, record: ProductStockItemLog) => {
          return record.price;
        },
      });
      columns.push({
        title: "Adjustment",
        key: "adjustment",
        render: (v: number, record: ProductStockItemLog) => {
          return (
            Number(record.price || 0) - Number(record.price_b4_update || 0)
          );
        },
      });
      columns.push({
        title: "Price Before Update",
        dataIndex: "price_b4_update",
        key: "price_b4_update",
        render: (v: number, record: ProductStockItemLog) => {
          return record.price_b4_update;
        },
      });
    }
    columns.push({
      title: "UpdatedBy",
      key: "user",
      render: (v: number, record: ProductStockItemLog) => {
        return record.user?.name;
      },
    });
    columns.push({
      title: "Date",
      key: "created_at",
      render: (v: number, record: ProductStockItemLog) => {
        return format(new Date(record.created_at), "dd MMM,yyyy");
      },
    });
    columns.push({
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      with: "250",
      render: (v: number, record: ProductStockItemLog) => {
        return (
          <>
            <Typography.Paragraph strong={true}>
              {record.comment}{" "}
            </Typography.Paragraph>
            <Typography.Paragraph italic={true}>
              {record.ref ? `#${record.ref}` : ""}{" "}
            </Typography.Paragraph>
          </>
        );
      },
    });
    const logs = psi_logs.filter((psi) => psi.product_stock_item_id === row.id);
    let ois = psi_ois.filter(
      (oi) =>
        oi.stock_item_id === row.id && oi.order?.order_status === "COMPLETE"
    );
    ois =
      currentBranch === "default"
        ? ois.filter((oi) => !oi.order?.branch_id)
        : ois.filter((oi) => oi.order?.branch_id === currentBranch);
    const OrderItemTable = (
      <Card
        loading={oiLoading}
        title={`Total stock sold : ${ois.reduce((a, b) => b.count + a, 0)}`}
      >
        <Table
          showHeader={ois.length > 0}
          size="small"
          columns={columns2}
          dataSource={ois}
          pagination={true}
        />
      </Card>
    );
    const LogTable = (
      <Card loading={logLoading}>
        <Table
          showHeader={logs.length > 0}
          size="small"
          columns={columns}
          dataSource={
            currentBranch === "default"
              ? logs.filter((lg) => !lg.branch_id)
              : logs.filter((lg) => lg.branch_id === currentBranch)
          }
          pagination={true}
        />
      </Card>
    );
    if (props.pageType === "Product Inventory") {
      return (
        <Row gutter={24}>
          <Col span={16}>{LogTable}</Col>
          <Col span={8}>{OrderItemTable}</Col>
        </Row>
      );
    }
    return LogTable;
  };
  const onExpandedRowsChange = async (rows: string[]) => {
    let types =
      props.pageType === "Product Price"
        ? ["PRICE", "PRICEANDSTOCK"]
        : ["STOCK", "PRICEANDSTOCK"];
    await getLogs({ variables: getProductStockItemLogVariables(rows, types) });
    if (props.pageType === "Product Inventory") {
      await getOrderItems({ variables: getPSIOrderItemsVar(rows) });
    }
  };
  const selectBefore = (
    <Select
      defaultValue={currentBranch}
      className="select-before"
      style={{ width: "auto", minWidth: 120 }}
      onChange={(v: string) => setBranch(v)}
    >
      <Option value="default">
        <BranchesOutlined style={{ margin: 2 }} />
        <span>default</span>
      </Option>
      {bData &&
        bData.merchantBranches &&
        bData.merchantBranches.map((mb: any) => (
          <Option value={mb.id}>
            <BranchesOutlined style={{ margin: 2 }} />
            <span> {mb.name}</span>
          </Option>
        ))}
      {/* <Option value="shipping">shipping</Option> */}
    </Select>
  );

  const total = countData?.aggregateProductStockItem._count.id || defaultPageSize
  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && !error && countData && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={8}>
                  <Typography level={2}> {props.pageType}</Typography>
                </Col>
                <Col span={8} offset={8}>
                  <Search
                    addonBefore={
                      props.pageType === "Product Inventory" && selectBefore
                    }
                    defaultValue={searchText}
                    placeholder="search..."
                    allowClear
                    size="default"
                    onSearch={(val: any) => {
                      setEditingKey("")
                      setPagination(null)
                      setSearch(val.toLowerCase());
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col flex={0}>
              <ExportButton
                loading={exportLoading || loading}
                onClick={onExport}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                expandable={{
                  expandedRowRender,
                  onExpandedRowsChange,
                }}
                dataSource={(
                  (data.productStockItems || []) as ProductStockItem[]
                ).map((psi, i) => ({ ...psi, key: psi.id }))}
                columns={mergedColumns}
                rowClassName="editable-row"

                onChange={(p: TablePaginationConfig) => {
                  setPagination(p)
                }}

                // pagination={{
                //   pageSize: page.size,
                //   current: page.current,
                //   showSizeChanger: true,
                //   total:
                //     countData?.aggregateProductStockItem._count.id || page.size,
                //   pageSizeOptions: [50, 100, 150, 200],
                //   // onChange: (current: number, size: number) => setEditingKey(''),
                //   onChange: onChange,
                // }}
                pagination={{
                  total,
                  current: pagination?.current || 1,
                  pageSize: pagination?.pageSize || defaultPageSize,
                  defaultPageSize: defaultPageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['10','30', '50', '100', '150', '200']
                }}
              />
            </Form>
          </Row>
        </>
      )}
    </>
  );
};
export default withUser(StockList) as any;
