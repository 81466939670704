import React, { useState, useEffect } from 'react';
import { get_order_variables, get_orders } from "../../gql/gql_query";
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../components/loading_spinner';
import { Link } from 'react-router-dom';
import { Col, Row, Typography, Card, Progress, Button } from "antd";
import { subDays, format, } from "date-fns";
import * as _ from 'lodash';
import { ShoppingCartOutlined } from "@ant-design/icons";
import { MoneyIcon, UpcommingIcon, UserIcon } from '../../assets/icons/svg_icons';
import DateRange from '../../components/date_range';
import { paths } from "../../routes/paths";
import PanelCard from '../../components/panel_card';
import { AuthState, Order } from '../../store';
import { formatMoney, numFormatter } from '../../helpers/util';
import withUser from '../../hocs/with_user';
const panelList = [
    {
        title: "Orders",
        key: "orders",
        icon: ShoppingCartOutlined,
        value: 0,
        isMoney: false,
    },
    {
        title: "Net Sale",
        key: "netSale",
        icon: MoneyIcon,
        value: 0,
        isMoney: true,
    },
    {
        title: "Gros Sale",
        key: "grosSale",
        icon: UpcommingIcon,
        value: 0,
        isMoney: true,
    },
    {
        title: "Customers",
        key: "customers",
        icon: UserIcon,
        value: 0,
        isMoney: false,
    }
]
interface P {
    user: AuthState
}
const Dashboard = (props: P) => {
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: new Date() });
    const merchantId = props.user.status === 'loggedIn' ? props.user.userInfo?.merchantId! : "";
    const { loading, error, data } = useQuery(get_orders, { variables: get_order_variables(merchantId, ranges.startDate, ranges.endDate), fetchPolicy: 'network-only' });
    const onSelect = async (startDate: Date, endDate: Date) => {
        setRanges({ startDate, endDate });
        //await refetch();
    }
    const panelData = () => {
        let orders: Order[] = (data && data.orders) ? data.orders : [];
        let customerIds: string[] = [];
        let pCount = 0, pAmount = 0, psCount = 0, psAmount = 0, cCount = 0, cAmount = 0, caCount = 0, caAmount = 0, count = 0;
        orders.forEach(or => {
            count += 1
            if (or.merchant_customer) {
                customerIds.push(or.merchant_customer.id);
            }
            if (or.order_status === "ACCEPT") {
                psCount += 1;
                psAmount += Number(or.grand_total);
            }
            if (or.order_status === "PAID") {
                psCount += 1;
                psAmount += Number(or.grand_total);
            }
            if (or.order_status === 'PENDING') {
                pCount += 1;
                pAmount += Number(or.grand_total);
            }
            if (or.order_status === 'COMPLETE') {
                cCount += 1;
                cAmount += Number(or.grand_total);
            }
            if (or.order_status === 'CUSTOMER_CANCEL') {
                caCount += 1;
                caAmount += Number(or.grand_total);
            }
            if (or.order_status === "MERCHANT_CANCEL") {
                caCount += 1;
                caAmount += Number(or.grand_total);
            }
        });
        const customers = _.uniq(customerIds);
        return { orders: count, customers: customers.length, netSale: cAmount, grosSale: psAmount + pAmount }
    }

    const control = () => (
        <Row>
            <Col flex={4}>
                <Row >
                    <Col span={12}><Typography level={3}> Product Dashboard </Typography></Col>
                    <Col span={12} > </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
            </Col>
        </Row>
    );
    const panels = () => {
        const _data = panelData() as any;
        return (
            <Row style={{ marginTop: 4 }} gutter={[48, 16]}>
                {
                    panelList.map(panel => (
                        <Col span={6} >
                            <PanelCard loading={false} icon={<panel.icon style={{ fontSize: 60 }} />} title={panel.title} primary={panel.isMoney ? formatMoney(_data[panel.key] || panel.value) : numFormatter((_data[panel.key] || panel.value))} secondary={panel.isMoney ? "MMK" : null} />
                        </Col>)
                    )
                }
            </Row>
        );
    }

    return (
        <React.Fragment>
            {loading && <LoadingSpinner />}
            {(!loading && !error) &&
                <>
                    {control()}
                    {panels()}

                </>
            }
        </React.Fragment>
    )
}
export default withUser(Dashboard);