import React, { useEffect, useState } from 'react'
import withUser from '../../hocs/with_user';
import { RewardHistory } from "../state";
import { getRewardHistory } from "../../api/reward_api";
import DateRange from '../../components/date_range';
import LoadingSpinner from '../../components/loading_spinner';
import { format, subDays, addMonths } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Tag, Select } from "antd";
import ExportButton from '../../components/export_button';
import { ExportExcel } from '../../services/xlsx_service';

const { Option } = Select
type S = { status: "loading" } | { status: "loaded", data: RewardHistory[] } | { status: "error", error: string };
type Filter = 'redeem' | 'claimed' | 'all';
const RewardHistoryList = (props: any) => {
    const merchantId = props.user?.userInfo?.merchantId || null;
    const [state, setState] = useState({ status: "loading" } as S);
    const [exportLoading, setExportLoading] = useState(null as boolean | null);
    const [selectedFilter, setSelectedFilter] = useState("all" as Filter)
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 120), endDate: new Date() })
    useEffect(() => {
        if (merchantId) {
            getRewardHistory({
                merchantId, startDate: ranges.startDate, endDate: ranges.endDate
            }).then(res => {
                if (res.success) {
                    setState({ status: 'loaded', data: res.data || [] })
                }
            })
        }
    }, [merchantId, ranges])
    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            align: 'center',
            render: (v: number, record: RewardHistory) => < Avatar src={record.rewardPhoto} size={45} onClick={() => { }} />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            render: (v: number, record: RewardHistory) => {
                const val = record.rewardName || ""
                return val;
            },
        },
        {
            title: 'From',
            dataIndex: 'from',
            align: 'center',
            render: (v: number, r: RewardHistory) => {
                const val = "system"
                return val;
            },
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            ellipsis: true,
            render: (v: number, r: RewardHistory) => {
                const val = r.memberName || ""
                return val;
            },
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (v: number, r: RewardHistory) => {
                return format(new Date(Number(r.createdAt)), 'yyyy-MMM-dd');
            },
        },
        {
            title: 'Claimed Date',
            dataIndex: 'claimedDate',
            key: 'claimedDate',
            render: (v: number, record: RewardHistory) => {
                const val = record.claimedAt ? format(new Date(Number(record.createdAt)), 'yyyy-MMM-dd') : "no"
                return val;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filteredValue: (selectedFilter !== 'all') ? [selectedFilter] : null,
            onFilter: (value: string, record: RewardHistory) => selectedFilter === 'claimed' ? record.status === value : !record.status,
            key: 'status',
            render: (v: number, record: RewardHistory) => {
                return record.status ? < Tag color="green" > claimed</Tag> : <Tag color="volcano">redeem</Tag>
            },
        },
    ];
    const selectStatus = (
        <Select defaultValue={selectedFilter} className="select-before" style={{ width: 100, marginLeft: 3 }} onChange={(v: Filter) => setSelectedFilter(v)}>
            <Option value="all">All</Option>
            <Option value="redeem">Redeem</Option>
            <Option value="claimed">Claimed</Option>
        </Select>
    );
    const onExport = () => {
        if (state.status !== 'loaded') return;
        setExportLoading(true);
        const fileName = "rewardHistory_" + format(ranges.startDate, 'yyyy-MMM-dd') + "_" + format(ranges.endDate, 'yyyy-MMM-dd');
        let excelData: any[] = [];
        state.data.forEach(p => {
            const rewardName = p.rewardName || ""
            const from = "system";
            const to = p.memberName || "";
            const date = format(new Date(Number(p.createdAt)), 'yyyy-MMM-dd');
            const claimedDate = p.claimedAt ? format(new Date(Number(p.createdAt)), 'yyyy-MMM-dd') : "no"
            const status = p.status ? 'claimed' : "redeem";
            excelData.push({ rewardName, from, to, date, claimedDate, status })
        })
        ExportExcel(excelData, fileName);
        setExportLoading(false);
    }
    const onSelect = async (startDate: Date, endDate: Date) => {
        setState({ status: 'loading' });
        setRanges({ startDate, endDate });
    }
    return (
        <React.Fragment>
            {state.status === "loading" && <LoadingSpinner />}
            {state.status === "loaded" &&
                <>
                    <Row>
                        <Col flex={4}>
                            <Row >
                                <Col span={12}><Typography.Text strong> Reward history </Typography.Text></Col>
                            </Row>
                        </Col>
                        <Col flex={0}>
                            <Row >
                                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} />
                                {selectStatus}
                                <ExportButton loading={exportLoading || state.status !== 'loaded'} onClick={onExport} />
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 }} >
                        <Table
                            columns={columns}
                            dataSource={(state.status === 'loaded' ? state.data : []).map((row, i) => { return { ...row, key: i + 1 } })}
                            pagination={{
                                position: ['bottomRight',]
                            }}
                        />
                    </Row>
                </>
            }
            {state.status === 'error' && <span>{state.error}</span>}
        </React.Fragment>

    )
}
export default withUser(RewardHistoryList) as any;