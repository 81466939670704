import React, { useState } from 'react'
import { Table, Card, Tag, Empty } from "antd";
import { format } from "date-fns";
import { BoughtCount } from "../store";
import { useNavigate } from "react-router-dom";
import { paths } from '../routes/paths';
import { Typography } from 'antd';

const ProductBoughtCustomers = (props: { data: BoughtCount[] }) => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (v: BoughtCount, record: BoughtCount) => {
                const customerId = record.bought_customer.customer.id;
                return <Typography onClick={() => navigate(paths.getCustomerDetailRoute(customerId))}> {record.bought_customer.customer.name} </Typography>;
            },
        },
        {
            title: 'Buy Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a: BoughtCount, b: BoughtCount) => a.buy_count - b.buy_count,
            render: (v: BoughtCount, record: BoughtCount) => {
                return record.buy_count;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (v: BoughtCount, record: BoughtCount) => {
                const customerId = record.bought_customer.customer.id;
                return <Typography onClick={() => navigate(paths.getCustomerDetailRoute(customerId))}> {record.bought_customer.customer.contact_number} </Typography>;
            },
        },

        {
            title: 'Last Date',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a: BoughtCount, b: BoughtCount) => new Date(a.updated_at).getDate() - new Date(b.updated_at).getDate(),
            render: (v: BoughtCount, record: BoughtCount) => {
                return format(new Date(record.updated_at), 'yyyy-MMM-dd');
            },
        },
    ];
    return (
        <React.Fragment>
            {props.data.length === 0 ?
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span>
                            Empty Data
                        </span>
                    }
                />
                :
                <Table
                    showHeader={true}
                    columns={columns}
                    dataSource={props.data}
                    pagination={{
                        pageSize: 6,
                    }}
                />
            }
        </React.Fragment>
    )
}

export default ProductBoughtCustomers;
