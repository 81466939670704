import React from "react";
import { Member, MemberTier } from "../royalty/state";
import { Card, Typography, Row, Col, Avatar } from "antd";
import { format } from "date-fns";
import defaultPhoto from "../assets/image/user.png";
import moment from "moment";

export default function MemberDetailInfo(props: {
  data: Member;
  tier?: MemberTier | null;
}) {
  const { data } = props;
  return (
    <Card
      style={styles.card}
      title={
        <Typography.Title level={5} style={{ textAlign: "center" }}>
          {data.displayName}
        </Typography.Title>
      }
      bordered={false}
      size="small">
      <Row gutter={24}>
        <Col offset={8}>
          <Avatar src={data.photo || defaultPhoto} size={120} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginTop: 5 }}>
        <Col span={10}>
          <Typography.Title level={5}>Phone</Typography.Title>
        </Col>
        <Col span={14}>{data.phone}</Col>
      </Row>
      {data.email && (
        <Row gutter={12}>
          <Col span={10}>
            <Typography.Title level={5}> Email</Typography.Title>
          </Col>
          <Col span={14}>{data.email}</Col>
        </Row>
      )}
      {data.gender && (
        <Row gutter={12}>
          <Col span={10}>
            <Typography.Title level={5}> Gender</Typography.Title>
          </Col>
          <Col span={14}>
            <Typography.Paragraph>{data.gender || "NONE"}</Typography.Paragraph>
          </Col>
        </Row>
      )}
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}> First Order</Typography.Title>
        </Col>
        <Col span={14}>
          {format(new Date(Number(data.firstActiveAt)), "yyyy-MMM-dd ")}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}> Last Order</Typography.Title>
        </Col>
        <Col span={14}>
            {format(new Date(data.lastActive), "yyyy-MMM-dd ")}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}>Joined Since</Typography.Title>
        </Col>
        <Col span={14}>
          {format(new Date(data.joinedAt), 'yyyy-MMM-dd ')}
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}>Current Point</Typography.Title>
        </Col>
        <Col span={14}>{data.memberPoint}</Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}>Total Point</Typography.Title>
        </Col>
        <Col span={14}>{data.totalPoint}</Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <Typography.Title level={5}>Total Engagement</Typography.Title>
        </Col>
        <Col span={14}>{data.pF}</Col>
      </Row>
      {props.tier && (
        <Row gutter={12}>
          <Col span={10}>
            <Typography.Title level={5}>Member Tier</Typography.Title>
          </Col>
          <Col span={14}>
            <div style={{ flex: "row" }}>
              {props.tier?.name}
              <Avatar
                shape="square"
                style={{ marginLeft: 10, width: 75, height: 40 }}
                src={props.tier?.photo}
              />
            </div>
          </Col>
        </Row>
      )}
    </Card>
  );
}
const styles = {
  card: {
    height: "100%",
  },
  cardTitle: {
    textAlign: "center",
  },
  cardMeta: {
    justifyContent: "center",
    display: "flex",
  },
};
