import React, { useState } from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Empty,
  Avatar,
  Modal,
  Button,
  List,
} from "antd";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { formatMoney } from "../helpers/util";
import { MemberDetail } from "../royalty/state";
import withMerchant from "../hocs/with_merchant";
import CircleProgress from "./circle_progress";
import moment from "moment";

const AvailableRewards = (props: { rewards: any[]; point: number }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <React.Fragment>
      <Button type="link" onClick={showModal}>
        Avaiable Rewards
      </Button>
      <Modal
        visible={visible}
        title={"Point Rewards"}
        style={{ position: "absolute", top: 0, right: 0 }}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}>
        <List
          itemLayout="horizontal"
          dataSource={props.rewards}
          renderItem={(item: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item?.photo || ""} size={60} />}
                title={<Typography level={3}>{item?.name || ""}</Typography>}
                description={item?.description || ""}
              />
              <CircleProgress
                total={item?.point || 0}
                label={item?.point || 0}
                showLableOnly={true}
                color="green"
                showPercent={false}
                value={props.point}
                width={90}
              />
            </List.Item>
          )}
        />
      </Modal>
    </React.Fragment>
  );
};

function MemberSummaryInfo(props: { data: MemberDetail | null }) {
  const { data } = props;
  if (!data || data.member?.length !== 1) {
    return (
      <Card size="small" style={styles.card}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No Member</span>}
        />
      </Card>
    );
  }
  const member = data.member?.[0];
  const tier = data.memberTier?.length === 1 ? data.memberTier?.[0] : null;
  const point = orderBy(data.point || [], "createdAt", "asc");

  let totalPoint = 0;
  point.map((p) =>
    p.point > 0
      ? (totalPoint += Number(p.point))
      : (totalPoint += -1 * Number(p.point))
  );
  const orders = orderBy(point || [], "createdAt", "asc");
  const firstPoint: number | null = orders.length > 0 ? point[0].point : 0;
  const lastPoint: number | null =
    orders.length > 0 ? point[point.length - 1].point : 0;
  return (
    <Card
      style={styles.card}
      title={<Typography.Title level={5}>Member Ship</Typography.Title>}
      bordered={false}
      size="small"
      extra={
        <AvailableRewards
          rewards={orderBy(
            (props as any).merchant?.pointRewards || [],
            "point",
            "asc"
          )}
          point={member.memberPoint || 0}
        />
      }>
      <Row gutter={24} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography}>
            Join Since
          </Typography.Title>
        </Col>
        <Col span={14}>
          {/* {format(new Date(member.createdAt), 'yyyy-MMM-dd ')} */}
          {moment(new Date(member.createdAt)).format("YYYY-MMM-DD")}
        </Col>
      </Row>
      <Row gutter={12} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography}>
            First Point
          </Typography.Title>
        </Col>
        <Col span={14}>{firstPoint}</Col>
      </Row>
      <Row gutter={12} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography}>
            Last Point
          </Typography.Title>
        </Col>
        <Col span={14}>{lastPoint}</Col>
      </Row>
      <Row gutter={12} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography}>
            Current Point
          </Typography.Title>
        </Col>
        <Col span={14}>{formatMoney(member.memberPoint, 0)}</Col>
      </Row>
      <Row gutter={12} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography}>
            Total Point
          </Typography.Title>
        </Col>
        <Col span={14}>{formatMoney(totalPoint, 0)}</Col>
      </Row>
      <Row gutter={12} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography}>
            Member Tier
          </Typography.Title>
        </Col>
        <Col span={14}>
          {tier && <Avatar src={tier?.photo} size="large" shape="circle" />}
          {tier && <span style={{ marginLeft: 10 }}>{tier?.name}</span>}
          {!tier && "-"}
        </Col>
      </Row>
    </Card>
  );
}
const styles = {
  card: {
    height: "100%",
  },
  cardTitle: {
    textAlign: "center",
  },
  typography: {
    fontSize: 14,
    padding: 2,
  },
  item: {
    marginTop: 5,
  },
  cardMeta: {
    justifyContent: "center",
    display: "flex",
  },
};

export default withMerchant(MemberSummaryInfo);
