import React from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { paths } from "../routes/paths";
import { AppState, AuthState } from "../store";
const withAdmin = (WrappedComponent: any) => {
    const HOC = (props: any) => {
        const location = useLocation();
        const { pathname } = location;
        const auth = props.user as AuthState;
        return (auth.status === 'loggedIn' && auth.userInfo?.merchantId === 'piticafe') ? <WrappedComponent {...props} /> : <Navigate to={paths.orders} replace />;
    };
    const mapStateToProps = (state: AppState) => {
        return {
            user: state.authState,
        };
    };
    const mapStateToDispatch = (dispatch: any) => {
        return {
            dispatch
        }
    }
    return connect(mapStateToProps, mapStateToDispatch)(HOC);
};

export default withAdmin;
