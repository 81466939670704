import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface P {
    title: string
    lables: string[];
    lable1: string;
    lable2: string;
    data1: any[];
    data2: any[];
}
export default function App(props: P) {
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const options = {
        plugins: {
            title: {
                display: true,
                text: props.title,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    const data = {
        labels: props.lables,
        datasets: [
            {
                label: props.lable1,
                data: props.data1,
                backgroundColor: 'rgb(75, 192, 192) ',
            },
            {
                label: props.lable2,
                data: props.data2,
                backgroundColor: 'rgb(255, 99, 132)',
            },
        ],
    };
    return <Bar options={options} data={data} />;
}
