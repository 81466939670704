import React, { useState } from "react";
import { Table, Row, Col, Typography, Card, Tag, Badge } from "antd";
import LoadingSpinner from "../../components/loading_spinner";
import { useQuery } from "@apollo/client";
import { get_order_variables, get_orders } from "../../gql/gql_query";
import withUser from "../../hocs/with_user";
import { subDays } from "date-fns";
import { AuthState, Order } from "../../store";
import DateRange from "../../components/date_range";
import { format, getWeekOfMonth } from "date-fns";
import { formatMoney } from "../../helpers/util";
import RecentOrder from "../../pages/order/recent_list";
import * as _ from "lodash";
import ExportButton from "../../components/export_button";
import { ExportExcel } from "../../services/xlsx_service";
import { paths } from "../../routes/paths";
import { Link } from "react-router-dom";
interface P {
  user: AuthState;
}
type chanelType = "POS" | "ONLINE" | "OTHER" | "ALL";
const OrderReport = (props: P) => {
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: new Date(),
  });
  const merchantId =
    props.user.status === "loggedIn" ? props.user.userInfo?.merchantId! : "";
  const { loading, error, data } = useQuery(get_orders, {
    variables: get_order_variables(
      merchantId,
      ranges.startDate,
      ranges.endDate
    ),
    fetchPolicy: "network-only",
  });
  const onSelect = async (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    //await refetch(); no need refresh again , auto load with state change
  };
  const posChannel = () => {
    let orders: Order[] = data && data.orders ? data.orders : [];
    const channelGroups = _.groupBy(orders, "sale_channel");
    orders = channelGroups["POS"] || [];
    let pCount = 0,
      pAmount = 0,
      psCount = 0,
      psAmount = 0,
      cCount = 0,
      cAmount = 0,
      caCount = 0,
      caAmount = 0;
    orders.forEach((or) => {
      if (or.order_status === "ACCEPT") {
        psCount += 1;
        psAmount += Number(or.grand_total);
      }
      if (or.order_status === "PAID") {
        psCount += 1;
        psAmount += Number(or.grand_total);
      }
      if (or.order_status === "PENDING") {
        pCount += 1;
        pAmount += Number(or.grand_total);
      }
      if (or.order_status === "COMPLETE") {
        cCount += 1;
        cAmount += Number(or.grand_total);
      }
      if (or.order_status === "CUSTOMER_CANCEL") {
        caCount += 1;
        caAmount += Number(or.grand_total);
      }
      if (or.order_status === "MERCHANT_CANCEL") {
        caCount += 1;
        caAmount += Number(or.grand_total);
      }
    });
    return [
      { status: "pending", count: pCount, amount: pAmount },
      { status: "complete", count: cCount, amount: cAmount },
      { status: "progress", count: psCount, amount: psAmount },
      { status: "cancel", count: caCount, amount: caAmount },
    ];
  };

  const onlineChannel = () => {
    let orders: Order[] = data && data.orders ? data.orders : [];
    const channelGroups = _.groupBy(orders, "sale_channel");
    orders = channelGroups["WEB"] || [];
    let pCount = 0,
      pAmount = 0,
      psCount = 0,
      psAmount = 0,
      cCount = 0,
      cAmount = 0,
      caCount = 0,
      caAmount = 0;
    orders.forEach((or) => {
      if (or.order_status === "ACCEPT") {
        psCount += 1;
        psAmount += Number(or.grand_total);
      }
      if (or.order_status === "PAID") {
        psCount += 1;
        psAmount += Number(or.grand_total);
      }
      if (or.order_status === "PENDING") {
        pCount += 1;
        pAmount += Number(or.grand_total);
      }
      if (or.order_status === "COMPLETE") {
        cCount += 1;
        cAmount += Number(or.grand_total);
      }
      if (or.order_status === "CUSTOMER_CANCEL") {
        caCount += 1;
        caAmount += Number(or.grand_total);
      }
      if (or.order_status === "MERCHANT_CANCEL") {
        caCount += 1;
        caAmount += Number(or.grand_total);
      }
    });
    return [
      { status: "pending", count: pCount, amount: pAmount },
      { status: "complete", count: cCount, amount: cAmount },
      { status: "progress", count: psCount, amount: psAmount },
      { status: "cancel", count: caCount, amount: caAmount },
    ];
  };
  const allChannel = () => {
    let orders: Order[] = data && data.orders ? data.orders : [];
    let pCount = 0,
      pAmount = 0,
      psCount = 0,
      psAmount = 0,
      cCount = 0,
      cAmount = 0,
      caCount = 0,
      caAmount = 0;
    orders.forEach((or) => {
      if (or.order_status === "ACCEPT") {
        psCount += 1;
        psAmount += Number(or.grand_total);
      }
      if (or.order_status === "PAID") {
        psCount += 1;
        psAmount += Number(or.grand_total);
      }
      if (or.order_status === "PENDING") {
        pCount += 1;
        pAmount += Number(or.grand_total);
      }
      if (or.order_status === "COMPLETE") {
        cCount += 1;
        cAmount += Number(or.grand_total);
      }
      if (or.order_status === "CUSTOMER_CANCEL") {
        caCount += 1;
        caAmount += Number(or.grand_total);
      }
      if (or.order_status === "MERCHANT_CANCEL") {
        caCount += 1;
        caAmount += Number(or.grand_total);
      }
    });
    return [
      { status: "pending", count: pCount, amount: pAmount },
      { status: "complete", count: cCount, amount: cAmount },
      { status: "progress", count: psCount, amount: psAmount },
      { status: "cancel", count: caCount, amount: caAmount },
    ];
  };
  const onExport = (exportType: chanelType) => {
    let excelData: any[] = [],
      fileName: string = "orders";
    fileName =
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    if (exportType === "POS") {
      excelData = posChannel();
      fileName = "pos_orders(" + fileName + ")";
    }
    if (exportType === "ONLINE") {
      excelData = onlineChannel();
      fileName = "online_orders(" + fileName + ")";
    }
    if (exportType === "ALL") {
      excelData = allChannel();
      fileName = "channel_orders(" + fileName + ")";
    }
    ExportExcel(excelData, fileName);
  };
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: { status: string }, b: { status: string }) =>
        a.status.localeCompare(b.status),
      render: (v: number, record: { status: string }) => {
        let color = "orange";
        if (record.status === "progress") color = "lime";
        if (record.status === "complete") color = "green";
        if (record.status === "cancel") color = "red";
        return <Tag color={color}>{record.status}</Tag>;
      },
    },
    {
      title: "Count",
      key: "count",
      sorter: (a: { count: number }, b: { count: number }) => a.count - b.count,
      render: (v: number, record: { count: number }) => {
        return (
          <Badge count={record.count} style={{ backgroundColor: "blue" }} />
        );
      },
    },
    {
      title: "Amount",
      key: "amount",
      sorter: (a: { amount: number }, b: { amount: number }) =>
        a.amount - b.amount,
      render: (v: number, record: { amount: number }) => {
        return formatMoney(record.amount) + " MMK";
      },
    },
  ];
  return (
    <React.Fragment>
      {loading && <LoadingSpinner />}
      {!loading && !error && (
        <>
          <Row>
            <Col flex={4}>
              <Row>
                <Col span={12}>
                  <Typography level={2}> Order Report </Typography>
                </Col>
                <Col span={12}></Col>
              </Row>
            </Col>
            <Col flex={0}>
              <DateRange
                startDate={ranges.startDate}
                endDate={ranges.endDate}
                onSelect={onSelect}
              />
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 4 }}>
            <Col span={12}>
              <Card
                size="small"
                title="POS channel"
                bordered={false}
                style={{ background: "transparent" }}
                extra={
                  <ExportButton
                    transparent={true}
                    loading={loading}
                    onClick={() => onExport("POS")}
                  />
                }
              >
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={posChannel()}
                  pagination={{
                    pageSize: 4,
                    position: ["none"],
                  }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                size="small"
                title="Online channel"
                bordered={false}
                style={{ background: "transparent" }}
                extra={
                  <ExportButton
                    transparent={true}
                    loading={loading}
                    onClick={() => onExport("ONLINE")}
                  />
                }
              >
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={onlineChannel()}
                  pagination={{
                    pageSize: 4,
                    position: ["none"],
                  }}
                />
              </Card>
            </Col>
          </Row>
          <Row justify="space-between" style={{ marginTop: 4 }}>
            <Col span={12}>
              <Card
                size="small"
                title="All channel"
                bordered={false}
                style={{ background: "transparent" }}
                extra={
                  <ExportButton
                    transparent={true}
                    loading={loading}
                    onClick={() => onExport("ALL")}
                  />
                }
              >
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={allChannel()}
                  pagination={{
                    pageSize: 4,
                    position: ["none"],
                  }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                size="small"
                title="History"
                bordered={false}
                style={{ background: "transparent" }}
                extra={
                  <Link to={paths.orders}>
                    <Typography.Link>Show more...</Typography.Link>
                  </Link>
                }
              />
              <RecentOrder />
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  );
};

export default withUser(OrderReport) as any;
