import { gql } from "@apollo/client";
import { values } from "lodash";
import { ShippingFeeType } from "../store";
export const get_merchants = gql`
  query Merchants {
    merchants {
      id
      firestore_id
      facebook_id
      name
      image
      favicon
      phone
    }
  }
`;

export const get_merchant_variables = () => ({});
export const get_product_count = gql`
  query _count($where: ProductWhereInput) {
    aggregateProduct(where: $where) {
      _count {
        id
      }
    }
  }
`;
export const get_product_count_variables = (merchantId: string) => ({
  where: {
    merchant_id: {
      equals: merchantId,
    },
    soft_delete: {
      equals: false,
    },
  },
});

export const get_product_stock_count = gql`
  query _count($where: ProductStockItemWhereInput) {
    aggregateProductStockItem(where: $where) {
      _count {
        id
      }
    }
  }
`;
export const get_product_stock_count_variables = (merchantId: string, searchText?: string | null) => {

  let where: any = {
    product: {
      is: {
        merchant_id: {
          equals: merchantId,
        },
        soft_delete: {
          equals: false,
        },
      },
    },
  };

  if (searchText && searchText != "") {
    where = {
      ...where,
      "OR": [
        {
          product: {
            is: {
              name: {
                contains: searchText,
              },
            }
          },
        },
        {
          sku: {
            contains: searchText
          }
        }
      ]
    }
  }
  return { where };

};
export const get_product_stocks = gql`
  query ProductStockItems(
    $where: ProductStockItemWhereInput
    $orderBy: [ProductStockItemOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    productStockItems(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      name
      description
      sku
      image
      thumbnail_image
      stock
      active_inventory_control
      currency
      price
      original_price
      cost_per_price
      order
      unit
      point
      bar_code
      product_id
      default_item
      active
      show_price
      created_at
      updated_at
      tax_setting_id
      product {
        name
        id
        merchant_id
        order
        base_sku
        image
      }
      stock_item_branches {
        stock
        branch_id
      }
    }
  }
`;
export const get_product_stock_variables = (data: {
  merchantId: string;
  take: number;
  skip: number;
  search?: string | null;
}) => {
  let variables = {
    where: {
      product: {
        is: {
          merchant_id: {
            equals: data.merchantId,
          },
          soft_delete: {
            equals: false,
          },
        },
      },
      active: {
        equals: true,
      },
    },
    orderBy: [
      {
        stock: "asc",
      },
    ],
    take: data.take,
    skip: data.skip,
  };
  if (data.search && data.search != "") {
    variables = {
      ...variables,
      where: {
        ...variables.where,
        OR:
          [
            {
              product: {
                is: {
                  name: {
                    contains: data.search,
                  },
                }
              },
            },
            {
              sku: {
                contains: data.search
              }
            }
          ]
      },
    } as any;
  }
  return variables;
};

export const get_products = gql`
  query Products(
    $skip: Int
    $take: Int
    $orderBy: [ProductOrderByWithRelationInput!]
    $where: ProductWhereInput
    $orderItemsWhere2: OrderItemWhereInput
  ) {
    products(skip: $skip, take: $take, orderBy: $orderBy, where: $where) {
      id
      merchant_id
      name
      description
      base_sku
      image
      order
      thumbnail_image
      _count {
        variations
        product_stock_items
        product_categories
        product_images
      }
      variations {
        id
        name
        description
      }
      product_stock_items {
        id
        name
        description
        sku
        default_item
        image
        thumbnail_image
        stock
        active_inventory_control
        price
        original_price
        cost_per_price
        point
        created_at
        updated_at
        currency
        order_items(where: $orderItemsWhere2) {
          order_id
          price
          price_b4_discount
          stock_item_id
          count
          order {
            order_status
          }
        }
        product {
          id
          name
        }
      }
      product_categories {
        name
      }
      created_at
      updated_at
    }
  }
`;
export const get_product_variables = (data: {
  merchantId: string;
  take: number;
  skip: number;
  search?: string | null;
  startDate: Date;
  endDate: Date;
}) => {
  let val = {
    skip: data.skip,
    take: data.take,

    orderBy: [
      {
        updated_at: "desc",
      },
    ],
    where: {
      merchant_id: {
        equals: data.merchantId,
      },
      soft_delete: {
        equals: false,
      },
    },
    orderItemsWhere2: {
      AND: [
        {
          created_at: {
            gte: data.startDate,
          },
          AND: [
            {
              created_at: {
                lte: data.endDate,
              },
            },
          ],
        },
      ],
    },
  };
  if (data.search) {
    val = {
      ...val,
      where: {
        merchant_id: {
          equals: data.merchantId,
        },
        soft_delete: {
          equals: false,
        },
        name: {
          contains: data.search,
        },
      },
    } as any;
  }
  return val;
};

export const get_orders = gql`
  query Orders(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationInput!]
  ) {
    orders(where: $where, orderBy: $orderBy) {
      id
      total_price
      price_b4_discount
      tax
      grand_total
      point_used
      point_gain
      point_discount
      discount_info
      discount
      cupon_discount
      cupon_code
      order_number
      cashier_id
      cashier_name
      payment_method
      payment_info
      merchant_customer {
        name
        id
        customer {
          id
          name
          contact_number
        }
      }
      order_items {
        count
        price
        price_b4_discount
        quick_order_item
        quick_order_item_name
        stock_item {
          stock
          sku
          name
          image
          thumbnail_image
          price
          unit
          original_price
          product {
            name
            base_sku
            product_categories {
              name
            }
          }
        }
        optional_variations {
          slug
          sku
          price
          original_price
        }
      }
      merchant {
        firestore_id
        facebook_id
        name
        description
        address
        image
        phone
        urls {
          url
        }
      }
      sale_channel
      customer_note
      shipping_fee_name
      shipping_fee_price
      shipping_address
      shipping_township
      shipping_region
      created_at
      updated_at
      order_status
      shipment_id
      shipment {
        shipment_agent
        shipment_note
        shipment_id
        to_township {
          tsp_pcode
          township_name_eng
          township_name_mmr
          sr_pcode
          postal_code
          sr {
            sr_name_eng
            sr_name_mmr
            sr_pcode
          }
        }
      }
      branch {
        id
        name
      }
    }
  }
`;

export const get_orders2 = gql`
  query Orders(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationInput!]
  ) {
    orders(where: $where, orderBy: $orderBy) {
      id
      total_price
      grand_total
      order_number
      cashier_id
      cashier_name
      payment_method
      merchant_id
      sale_channel
      customer_note
      merchant_note
      created_at
      updated_at
      merchant_customer {
        customer {
          id
          name
          contact_number
        }
        id
        name
      }
    }
  }
`;

export const get_order_variables = (
  merchantId: string,
  startDate: Date,
  endDate: Date
) => ({
  where: {
    merchant: {
      is: {
        firestore_id: {
          equals: merchantId,
        },
      },
    },
    AND: [
      {
        created_at: {
          gte: startDate,
        },
        AND: [
          {
            created_at: {
              lte: endDate,
            },
          },
        ],
      },
    ],
  },
  orderBy: [
    {
      created_at: "desc",
    },
  ],
});
export const merchant_customer_query = gql`
  query MerchantCustomer(
    $where: MerchantCustomerWhereUniqueInput!
    $ordersWhere2: OrderWhereInput
  ) {
    merchantCustomer(where: $where) {
      id
      name
      photo
      date_of_birth
      owner_id
      email
      owner {
        id
        name
        photo
        address
        phone
        role
        uid
        active
        merchant_id
        metadata
        created_at
        updated_at
      }
      _count {
        orders
      }
      customer {
        contact_number
        name
        shipping_address
        shipping_township
        shipping_region
        id
      }
      merchant_id
      orders(where: $ordersWhere2) {
        id
        total_price
        price_b4_discount
        tax
        grand_total
        point_used
        point_gain
        point_discount
        discount_info
        discount
        order_number
        cashier_id
        cashier_name
        payment_method
        payment_info
        order_items {
          count
          price
          price_b4_discount
          stock_item {
            name
            image
            thumbnail_image
            price
            original_price
          }
        }
        sale_channel
        customer_note
        shipping_fee_name
        shipping_fee_price
        created_at
        order_status
      }
      created_at
      customer_group {
        id
        name
      }
    }
  }
`;
export const getMerchantCustomerVariables = (
  customerId: string,
  merchantId: string
) => {
  return {
    where: {
      customer_id_merchant_id: {
        customer_id: customerId,
        merchant_id: merchantId,
      },
    },
    ordersWhere2: {
      order_status: {
        notIn: ["MERCHANT_CANCEL", "CUSTOMER_CANCEL"],
      },
    },
  };
};
export const merchant_customer_bought_together_query = gql`
  query BoughtTogethers(
    $where: BoughtTogetherWhereInput
    $boughtCountsWhere2: BoughtCountWhereInput
    $boughtCustomersWhere2: BoughtCustomerWhereInput
  ) {
    boughtTogethers(where: $where) {
      id
      event_id
      bought_customers(where: $boughtCustomersWhere2) {
        customer_id
        buy_count
      }
      bought_counts(where: $boughtCountsWhere2) {
        bought_customer_id
        buy_count
        bought_customer {
          buy_count
          customer {
            name
            contact_number
            id
          }
        }
      }
      main_item {
        name
        id
        image
        thumbnail_image
      }
      sub_item {
        name
        id
        image
        thumbnail_image
      }
      total_buy_count
      main_item_id
      sub_item_id
      created_at
      updated_at
    }
  }
`;
export const getMerchantCustomerBoughtTogetherVariables = (
  customerId: string,
  merchantId: string
) => {
  return {
    where: {
      merchant_id: {
        equals: merchantId,
      },
      bought_customers: {
        some: {
          customer_id: {
            equals: customerId,
          },
        },
      },
      main_item: {
        is: {
          AND: [
            {
              active: {
                equals: true,
              },
              merchant_id: {
                equals: merchantId,
              },
            },
          ],
        },
      },
      sub_item: {
        is: {
          AND: [
            {
              active: {
                equals: true,
              },
              merchant_id: {
                equals: merchantId,
              },
            },
          ],
        },
      },
    },
    boughtCountsWhere2: {
      bought_customer_id: {
        equals: customerId,
      },
      bought_merchant_id: {
        equals: merchantId,
      },
    },
    boughtCustomersWhere2: {
      customer_id: {
        equals: customerId,
      },
    },
  };
};
export const customer_count = gql`
  query _count($where: MerchantCustomerWhereInput) {
    aggregateMerchantCustomer(where: $where) {
      _count {
        id
      }
    }
  }
`;
export const getCustomerCountVariables = (merchantId: string) => ({
  where: {
    merchant_id: {
      equals: merchantId,
    },
  },
});

export const get_customers = gql`
  query _count(
    $where: OrderWhereInput
    $merchantCustomersWhere2: MerchantCustomerWhereInput
    $orderBy: [MerchantCustomerOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    merchantCustomers(
      where: $merchantCustomersWhere2
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      _count {
        orders
      }
      customer {
        contact_number
        name
        id
      }
      merchant_id
      orders(where: $where) {
        id
        total_price
        price_b4_discount
        tax
        grand_total
        point_used
        point_gain
        point_discount
        discount_info
        discount
        order_number
        cashier_id
        cashier_name
        payment_method
        payment_info
        order_items {
          count
          price
          price_b4_discount
          stock_item {
            name
            image
            thumbnail_image
            price
            original_price
          }
        }
        sale_channel
        customer_note
        shipping_fee_name
        shipping_fee_price
        created_at
        order_status
      }
      created_at
      customer_group {
        id
        name
      }
    }
  }
`;

export const get_customer_variables = (data: {
  merchantId: string;
  startDate: Date;
  endDate: Date;
  take: number;
  skip: number;
  search?: string | null;
}) => {
  let val = {
    where: {
      merchant: {
        is: {
          firestore_id: {
            equals: data.merchantId,
          },
        },
      },
      AND: [
        {
          created_at: {
            gte: data.startDate,
          },
          AND: [
            {
              created_at: {
                lte: data.endDate,
              },
            },
          ],
        },
      ],
    },
    merchantCustomersWhere2: {
      merchant_id: {
        equals: data.merchantId,
      },
    },
    orderBy: [
      // {
      //   "created_at": "desc"
      // }
      {
        orders: {
          _count: "desc",
        },
      },
    ],
    take: data.take,
    skip: data.skip,
  };
  if (data.search) {
    val = {
      ...val,
      merchantCustomersWhere2: {
        merchant_id: {
          equals: data.merchantId,
        },
        OR: [
          {
            customer: {
              is: {
                contact_number: {
                  contains: data.search,
                },
              },
            },
          },
          {
            name: {
              contains: data.search,
            },
          },
        ],
      },
    } as any;
  }
  return val;
};

export const get_merchant_coupons = gql`
  query Coupons(
    $where: CouponWhereInput
    $orderBy: [CouponOrderByWithRelationInput!]
  ) {
    coupons(where: $where, orderBy: $orderBy) {
      id
      merchant_id
      code
      _count {
        coupon_customers
      }
      coupon_customers {
        used_count
        request_count
        coupon_id
        merchant_customer {
          name
          id
          photo
          customer {
            name
            contact_number
          }
        }
        merchant_customer_id
      }
      min_amount
      remain_count
      per_customer_limit
      total_count
      price_type
      price
      status
      start_date
      expire_month
      expire_date
      created_at
      updated_at
    }
  }
`;
export const get_merchant_coupon_variables = (merchantId: string) => ({
  where: {
    merchant_id: {
      equals: merchantId,
    },
  },
  orderBy: [
    {
      created_at: "desc",
    },
  ],
});

export const find_first_coupon = gql`
  query FindFirstCoupon($where: CouponWhereInput) {
    findFirstCoupon(where: $where) {
      id
      code
    }
  }
`;

export const get_first_coupon_variales = (
  code: string,
  merchantId: string
) => ({
  where: {
    merchant_id: {
      equals: merchantId,
    },
    AND: [
      {
        code: {
          equals: code.toUpperCase(),
        },
      },
    ],
  },
});
export const product_orders_query = gql`
  query Orders($where: OrderWhereInput) {
    orders(where: $where) {
      id
      order_status
      order_items {
        stock_item_id
        count
        price
        price_b4_discount
      }
      total_price
      price_b4_discount
      tax
      created_at
      updated_at
    }
  }
`;

export const getProductOrdersVariables = (
  merchantId: string,
  stockItemIds: string[]
) => {
  return {
    where: {
      merchant_id: {
        equals: merchantId,
      },
      order_items: {
        some: {
          stock_item_id: {
            in: stockItemIds,
          },
        },
      },
      order_status: {
        notIn: ["MERCHANT_CANCEL", "CUSTOMER_CANCEL"],
      },
    },
  };
};
export const product_bought_items_query = gql`
  query Product(
    $where: ProductWhereUniqueInput!
    $boughtTogetherMainWhere2: BoughtTogetherWhereInput
    $boughtTogetherSubWhere2: BoughtTogetherWhereInput
  ) {
    product(where: $where) {
      id
      name
      merchant_id
      bought_together_main(where: $boughtTogetherMainWhere2) {
        total_buy_count
        sub_item {
          id
          name
          image
        }
        created_at
        updated_at
      }
      bought_together_sub(where: $boughtTogetherSubWhere2) {
        main_item {
          name
          id
          image
        }
        total_buy_count
        created_at
        updated_at
      }
    }
  }
`;
export const getProductBoughtItemsVariables = (
  productId: string,
  merchantId: string
) => {
  return {
    where: {
      id: productId,
    },
    boughtTogetherMainWhere2: {
      AND: [
        {
          sub_item_id: {
            notIn: [productId],
          },
          sub_item: {
            is: {
              merchant_id: {
                equals: merchantId,
              },
            },
          },
        },
      ],
    },
    boughtTogetherSubWhere2: {
      AND: [
        {
          main_item_id: {
            notIn: [productId],
          },
          merchant_id: {
            equals: merchantId,
          },
        },
      ],
    },
  };
};
export const product_bought_customers_query = gql`
  query BoughtCounts($where: BoughtCountWhereInput) {
    boughtCounts(where: $where) {
      buy_count
      bought_customer {
        customer {
          id
          name
          contact_number
        }
      }
      created_at
      updated_at
    }
  }
`;
export const getProductBoughtCustomersVariables = (productId: string) => {
  return {
    where: {
      bought_together: {
        is: {
          main_item_id: {
            equals: productId,
          },
          sub_item_id: {
            equals: productId,
          },
        },
      },
    },
  };
};
export const product_query = gql`
  query Product($where: ProductWhereUniqueInput!) {
    product(where: $where) {
      id
      merchant_id
      name
      description
      base_sku
      image
      thumbnail_image
      _count {
        variations
        product_stock_items
        product_categories
        product_images
      }
      variations {
        id
        name
        description
      }
      product_stock_items {
        id
        name
        description
        sku
        image
        thumbnail_image
        stock
        active_inventory_control
        price
        original_price
        cost_per_price
        point
        created_at
        updated_at
        currency
      }
      product_categories {
        name
      }
      created_at
      updated_at
    }
  }
`;
export const getProductVariables = (productId: string) => {
  return {
    where: {
      id: productId,
    },
  };
};

export const get_system_data = gql`
  query Query($where: SystemDataWhereInput) {
    findManySystemData(where: $where) {
      code
      metadata
    }
  }
`;

export const getSystemDataVariables = (merchantId: string, code: string) => ({
  where: {
    code: {
      equals: code,
    },
    merchant_id: {
      equals: merchantId,
    },
  },
});
export const getSystemDataVariable2 = (
  merchantId: string,
  codes: string[]
) => ({
  where: {
    code: {
      in: codes,
    },
    merchant_id: {
      equals: merchantId,
    },
  },
});

export const get_merchant_users = gql`
  query MerchantUsers($where: MerchantUserWhereInput) {
    merchantUsers(where: $where) {
      id
      name
      photo
      address
      phone
      uid
      role
      active
      merchant_id
      created_at
      updated_at
      metadata
    }
  }
`;
export const getMerchantUserVariables = (merchantId: string) => ({
  where: {
    merchant_id: {
      equals: merchantId,
    },
  },
});

export const get_states = gql`
  query StateRegions($where: StateRegionWhereInput) {
    stateRegions(where: $where) {
      sr_pcode
      sr_name_eng
      sr_name_mmr
    }
  }
`;
export const getStateVariables = () => ({
  where: {},
});
export const get_townships = gql`
  query Query(
    $where: TownshipWhereInput
    $orderBy: [TownshipOrderByWithRelationInput!]
  ) {
    townships(where: $where, orderBy: $orderBy) {
      tsp_pcode
      township_name_eng
      township_name_mmr
      sr_pcode
      sr {
        sr_name_mmr
      }
    }
  }
`;
export const getTownshipVariables = (srPcode: string) => ({
  where: {
    // sr_pcode: {
    //   equals: srPcode,
    // },
  },
  orderBy: [
    {
      township_name_eng: "asc",
    },
  ],
});

export const get_order_byId = gql`
  query Order($where: OrderWhereUniqueInput!) {
    order(where: $where) {
      id
      total_price
      price_b4_discount
      tax
      grand_total
      point_used
      point_gain
      point_discount
      discount_info
      discount
      cupon_discount
      cupon_code
      order_number
      cashier_id
      cashier_name
      payment_method
      payment_info
      merchant_customer {
        name
        id
        customer {
          id
          name
          contact_number
        }
      }
      order_items {
        count
        price
        price_b4_discount
        quick_order_item
        quick_order_item_name
        stock_item {
          stock
          sku
          name
          image
          thumbnail_image
          price
          unit
          original_price
          product {
            id
            name
            base_sku
            product_categories {
              name
            }
          }
        }
        optional_variations {
          slug
          sku
          price
          original_price
        }
      }
      merchant {
        firestore_id
        facebook_id
        name
        description
        address
        image
        phone
        urls {
          url
        }
      }
      sale_channel
      customer_note
      merchant_note
      shipping_fee_name
      shipping_fee_price
      shipping_address
      shipping_township
      shipping_region
      created_at
      metadata
      updated_at
      order_status
      shipment_id
      shipment {
        shipment_agent
        shipment_note
        shipment_id
        to_township {
          tsp_pcode
          township_name_eng
          township_name_mmr
          sr_pcode
          postal_code
          sr {
            sr_name_eng
            sr_name_mmr
            sr_pcode
          }
        }
      }
      branch {
        id
        name
      }
    }
  }
`;
export const getOrderByIdVariables = (orderId: string) => ({
  where: {
    id: orderId,
  },
});
export const merchant_user_byUid = gql`
  query MerchantUser($where: MerchantUserWhereUniqueInput!) {
    merchantUser(where: $where) {
      uid
      photo
      phone
      name
      id
      address
      metadata
      role
    }
  }
`;
export const getMerchantUserByUidVariables = (uid: string) => ({
  where: {
    uid,
  },
});

export const order_delivery_shipments = gql`
  query OrderShipments(
    $where: OrderShipmentWhereInput
    $orderBy: [OrderShipmentOrderByWithRelationInput!]
  ) {
    orderShipments(where: $where, orderBy: $orderBy) {
      id
      order_id
      status
      type
      updated_at
      comment
      comment_two
      agent
      agent_ref_no
    }
  }
`;
export const getOrderDeliveryShipmentVariables = (orderId: string) => ({
  where: {
    order_id: {
      equals: orderId,
    },
  },
  orderBy: [
    {
      updated_at: "desc",
    },
  ],
});

export const customer_search = gql`
  query MerchantCustomers($where: MerchantCustomerWhereInput) {
    merchantCustomers(where: $where) {
      id
      name
      photo
      date_of_birth
      gender
      email
      memberId
      active
      customer_id
      merchant_id
      created_at
      customer {
        id
        name
        contact_number
        shipping_address
        shipping_township
        shipping_region
      }
      customer_group {
        id
        descripiton
        name
      }
    }
  }
`;

export const getCustomerSearchVariables = (
  merchanId: string,
  searchTerm: any
) => ({
  where: {
    merchant_id: {
      equals: merchanId,
    },
    OR: [
      {
        name: {
          contains: searchTerm,
        },
      },
      {
        id: {
          contains: searchTerm,
        },
      },
      {
        customer: {
          is: {
            OR: [
              {
                name: {
                  contains: searchTerm,
                },
              },
              {
                contact_number: {
                  contains: searchTerm,
                },
              },
            ],
          },
        },
      },
    ],
  },
});

export const productStockItemLogs = gql`
  query ProductStockItemLogs(
    $where: ProductStockItemLogWhereInput
    $orderBy: [ProductStockItemLogOrderByWithRelationInput!]
  ) {
    productStockItemLogs(where: $where, orderBy: $orderBy) {
      branch_id
      branch {
        name
      }
      comment
      created_at
      id
      price
      price_b4_update
      product_stock_item_id
      ref
      stock
      stock_b4_update
      type
      updated_at
      user {
        name
        role
        uid
        phone
        photo
        id
      }
    }
  }
`;

export const getProductStockItemLogVariables = (
  psiIds: string[],
  types: string[]
) => ({
  where: {
    product_stock_item_id: {
      in: psiIds,
    },
    type: {
      in: types,
    },
  },
  orderBy: [
    {
      created_at: "desc",
    },
  ],
});

export const getMerchantBranches = gql`
  query MerchantBranches($where: MerchantBranchWhereInput) {
    merchantBranches(where: $where) {
      id
      name
    }
  }
`;

export const getMerchantBranchVariables = (merchantId: string) => {
  return {
    where: {
      merchant_id: {
        equals: merchantId,
      },
    },
  };
};

export const getCashFlowCategoriesOriginal = gql`
  query CashFlowCategories(
    $orderBy: [CashFlowCategoryOrderByWithRelationInput!]
    $where: CashFlowCategoryWhereInput
  ) {
    cashFlowCategories(orderBy: $orderBy, where: $where) {
      thumbnail_image
      type
      created_at
      updated_at
      name
      id
      description
      _count {
        cash_flows
      }
      merchants {
        id
        name
      }
    }
  }
`;

export const getCashFlowCategories = gql`
  query CashFlowCategories(
    $orderBy: [CashFlowCategoryOrderByWithRelationInput!]
    $where: CashFlowCategoryWhereInput
  ) {
    cashFlowCategories(orderBy: $orderBy, where: $where) {
      thumbnail_image
      type
      created_at
      updated_at
      name
      id
      description
      _count {
        cash_flows
      }
      merchants {
        id
        name
      }
    }
  }
`;

export const getCashFlowCategoryVariables = (types: string[]) => ({
  orderBy: [
    {
      cash_flows: {
        _count: "desc",
      },
    },
  ],
  where: {
    type: {
      in: types,
    },
  },
});

export const getCashFlowSumByCategory = gql`
  query GroupByCashFlow(
    $by: [CashFlowScalarFieldEnum!]!
    $where: CashFlowWhereInput
  ) {
    groupByCashFlow(by: $by, where: $where) {
      _sum {
        amount
      }
      _count {
        id
      }
      category_id
    }
  }
`;
export const getCashFlowSumByCategoryVariables = (
  merchanId: string,
  startDate: Date,
  endDate: Date
) => ({
  by: ["category_id"],
  where: {
    merchant_id: {
      equals: merchanId,
    },
    created_at: {
      gte: startDate,
      lte: endDate,
    },
  },
});

export const getCashFlows = gql`
  query CashFlows(
    $where: CashFlowWhereInput
    $orderBy: [CashFlowOrderByWithRelationInput!]
  ) {
    cashFlows(where: $where, orderBy: $orderBy) {
      amount
      bank_account {
        name
      }
      category_id
      cateogry {
        name
        type
      }
      created_at
      comment
      id
      image_one
      image_two
      status
      type
      user {
        name
      }
    }
  }
`;

export const getCashFlowVariables = (
  merchantId: string,
  startDate: Date,
  endDate: Date,
  categoryIds?: string[]
) => {
  let where: any = {};
  where = {
    merchant_id: {
      equals: merchantId,
    },
    created_at: {
      gte: startDate,
      lte: endDate,
    },
  };
  if (categoryIds && categoryIds.length > 0) {
    where["category_id"] = {
      in: categoryIds,
    };
  }
  return {
    where,
    orderBy: [
      {
        created_at: "desc",
      },
    ],
  };
};

export const getAllCashflows = gql`
  query CashFlows(
    $orderBy: [CashFlowOrderByWithRelationInput!]
    $where: CashFlowWhereInput
  ) {
    cashFlows(orderBy: $orderBy, where: $where) {
      status
      type
      amount
      user {
        name
      }
      created_at
      comment
      cateogry {
        name
      }
    }
  }
`;

export const getAllCashFlowVariables = (
  merchantId: string,
  startDate: Date,
  endDate: Date,
  status: string[]
) => ({
  orderBy: [
    {
      cateogry: {
        name: "asc",
      },
    },
  ],
  where: {
    merchant_id: {
      equals: merchantId,
    },
    status: {
      in: status,
    },
    created_at: {
      gte: startDate,
      lte: endDate,
    },
  },
});

export const get_order_shipment_prices = gql`
  query OrderShipmentPrices($where: OrderShipmentPriceWhereInput) {
    orderShipmentPrices(where: $where) {
      price
      id
      active
      from_address_type
      from_agent_ref
      from_region {
        sr_name_eng
        sr_name_mmr
      }
      from_sr_id
      from_township {
        township_name_eng
        township_name_mmr
      }
      shipment_id
      shipment_agent
      to_address_type
      to_agent_ref
      to_region {
        sr_name_mmr
        sr_name_eng
      }
      to_township {
        township_name_mmr
        township_name_eng
        sr {
          sr_name_mmr
          sr_name_eng
        }
      }
      active
      to_tsp_id
      to_sr_id
    }
  }
`;

export const getNinjaShipmentPriceVariables = () => ({
  where: {
    shipment_agent: {
      equals: "NINJAVAN",
    },
  },
});
export const getArLuPostShipmentPriceVariables = () => ({
  where: {
    shipment_agent: {
      equals: "ARLUPOST",
    },
  },
});

export const getTopCashFlowCategory = gql`
  query Cash_flows(
    $where: CashFlowWhereInput
    $cashFlowCategoriesWhere2: CashFlowCategoryWhereInput
  ) {
    cashFlowCategories(where: $cashFlowCategoriesWhere2) {
      id
      name
      type
      _count {
        cash_flows
      }
      cash_flows(where: $where) {
        id
        created_at
      }
    }
  }
`;
export const getTopCashFlowCategroyVariables = (data: {
  merchantId: string;
  fromDate: Date;
  toDate: Date;
}) => ({
  where: {
    merchant_id: {
      equals: data.merchantId,
    },
    created_at: {
      gte: data.fromDate,
      lte: data.toDate,
    },
  },
  cashFlowCategoriesWhere2: {
    type: {
      equals: "EXPENSE",
    },
  },
});

export const get_shipping_fees = gql`
  query ShippingFees(
    $where: ShippingFeeWhereInput
    $orderBy: [ShippingFeeOrderByWithRelationInput!]
  ) {
    shippingFees(where: $where, orderBy: $orderBy) {
      price
      order
      type
      id
      merchant_id
      name
      note
      updated_at
      _count {
        fee_rates
      }
    }
  }
`;
export const getShippingFeeVar = (merchantId: string) => {
  let variables: any = {};
  variables.where = { merchant_id: { equals: merchantId } };
  variables.orderBy = [{ updated_at: "desc" }];
  return variables;
};

export const get_one_shipping_fee = gql`
  query ShippingFee($where: ShippingFeeWhereUniqueInput!) {
    shippingFee(where: $where) {
      id
      name
      merchant_id
      note
      price
      type
      description
      fee_rates {
        id
        name
        min_amount
        max_amount
        price
      }
      townships {
        tsp_pcode
        sr_pcode
        township_name_mmr
        township_name_eng
        sr {
          sr_pcode
          sr_name_mmr
          sr_name_eng
        }
      }
      state_regions {
        sr_pcode
        sr_name_mmr
        sr_name_eng
      }
    }
  }
`;

export const getOneSFVar = (id: string) => ({ where: { id } });

export const get_sf_by_type = gql`
  query ShippingFees($where: ShippingFeeWhereInput) {
    shippingFees(where: $where) {
      id
      name
      state_regions {
        sr_pcode
        sr_name_mmr
      }
      townships {
        tsp_pcode
        township_name_mmr
      }
    }
  }
`;

export const getSFByTypeVar = (data: {
  merchantId: string;
  type: ShippingFeeType;
  ids: string[];
}) => {
  let variables: any = {};
  variables.where = {
    merchant_id: { equals: data.merchantId },
    type: { in: [data.type] },
  };
  if (data.type === "SUPPORT_IN_SR") {
    variables.where = {
      ...variables.where,
      state_regions: { some: { sr_pcode: { in: data.ids } } },
    };
  }
  if (data.type === "SUPPORT_IN_TSP") {
    variables.where = {
      ...variables.where,
      townships: { some: { tsp_pcode: { in: data.ids } } },
    };
  }
  return variables;
};

export const PsiOrderItems = gql`
  query Stock_item($where: OrderItemWhereInput) {
    orderItems(where: $where) {
      stock_item_id
      count
      order {
        id
        order_number
        order_status
        branch_id
      }
    }
  }
`;

export const getPSIOrderItemsVar = (psiIds: string[]) => ({
  where: {
    stock_item_id: {
      in: psiIds,
    },
  },
});

export const orderItemsByStockId = gql`
  query OrderItems($where: OrderItemWhereInput) {
    orderItems(where: $where) {
      price
      count
      id
      stock_item {
        name
        id
        order_items {
          price
          order_id
          id
          price_b4_discount
        }
      }
    }
  }
`;
export const getOrderItemsByStockIdVar = (stockId: string[]) => ({
  where: {
    stock_item_id: {
      in: stockId,
    },
  },
});
