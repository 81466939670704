const updateFavicon = async (url: any) => {
    if (url !== null && url !== "" && url !== undefined) {
        const favicon = document.getElementById("favicon") as any;
        if (favicon) favicon.href = url;
    }
};
const updateTitle = async (title: string) => {
    if (title && typeof title === 'string') {
        document.title = title;
    }
}
export { updateFavicon, updateTitle }