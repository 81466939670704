export const LoginActionTypes = {
    LOGGING_IN: 'auth/LOGGING_IN',
    LOGOUT_REQUEST: 'auth/LOGOUT_REQUEST',
    LOGGED_OUT: 'auth/LOGGED_OUT',
    LOGGED_IN: 'auth/LOGGED_IN',
};

export const AntdActionTypes = {
    SIDE_TOOGLE_CHANGE: 'menu/SIDE_TOOGLE_CHANGE',
}
export const MerchantActionTypes = {
    SET_MERCHANT_DATA: "merchant/data"
}