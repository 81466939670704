import React, { useState, useEffect } from 'react';
import { getAuth, signInWithCustomToken } from "@firebase/auth";
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserInfo, actions } from '../store';
import { connect } from "react-redux";
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { Button, Input } from "antd";
import { UserOutlined, ShopOutlined, LoginOutlined, LoadingOutlined } from '@ant-design/icons'
import * as yup from 'yup';
import { availableCRM } from "../helpers/crm_helper";
const schema = yup.object().shape({
    merchantId: yup.string().required("merchantId is required"),
    username: yup.string().required("login username is required"),
    password: yup.string().required("password is required"),
});

interface Values {
    merchantId: string,
    username: string,
    password: string
}
interface P {
    loggedIn: (info: UserInfo) => void
}
type S = { status: 'loading' } | { status: "loaded", user?: UserInfo, error?: string }
const formData: Values = {
    merchantId: "",
    username: "",
    password: ""
}
const LoginForm = (props: P) => {
    const [state, setState] = useState({ status: 'loaded' } as S)
    const onSubmit = async (val: Values) => {
        let error: string | undefined = undefined
        setState({ status: 'loading' });
        const app = getApp()
        const functions = getFunctions(app, 'asia-southeast1');
        const authMerchant = httpsCallable(functions, 'authMerchant');
        try {
            const resp = await authMerchant(val) as { data: { success: boolean, token?: string, code?: string, error?: string } };
            if (resp.data.success) {
                await signInWithCustomToken(getAuth(), resp.data.token!);
            } else {
                error = "Invalid username or password";
            }
        } catch (e) {
            console.log(e)
        }
        setState({ status: 'loaded', error })
    }
    return (
        <div className="App">
            <div className={availableCRM() ? "login-crm-bg" : "login-bg"} >
                <div className="login-box">
                    <h2 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'white' }}>Piti Merchant</h2>
                    <Formik
                        initialValues={formData}
                        validationSchema={schema}
                        onSubmit={onSubmit}
                    >
                        {({ dirty, values, errors, touched, handleChange, handleSubmit, handleReset, setFieldValue }) => {
                            return (
                                <FormikForm autoComplete="off">
                                    <Field id="merchantId" name="merchantId" placeholder="merchantId" render={() => <Input placeholder="merchantId" suffix={<ShopOutlined />} onChange={(e: any) => handleChange('merchantId')(e.target.value)} />} /><br />
                                    <ErrorMessage
                                        name="merchantId"
                                        component="div"
                                        className="field-error"
                                        render={(error) => <span style={{ color: 'red', }}>{error}</span>}
                                    /> <br />

                                    <Field id="username" name="username" placeholder="login username" render={() => <Input placeholder="login username" suffix={<UserOutlined />} onChange={(e: any) => handleChange('username')(e.target.value)} />} /><br />
                                    <ErrorMessage
                                        name="username"
                                        component="div"
                                        className="field-error"
                                        render={(error) => <span style={{ color: 'red' }}>{error}</span>}
                                    /> <br />

                                    <Field id="password" name="password" placeholder="password" type="password" render={() => <Input.Password placeholder="password" onChange={(e: any) => handleChange('password')(e.target.value)} />} />  <br />
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="field-error"
                                        render={(error) => <span style={{ color: 'red' }}>{error}</span>}
                                    /> <br />
                                    {(state.status === 'loaded' && state.error) && <span style={{ color: 'red' }}>{state.error}</span>}
                                    <Button type="primary" size="large" disabled={!dirty || (state.status !== 'loaded')} icon={state.status === 'loaded' ? < LoginOutlined /> : <LoadingOutlined />} htmlType="submit" >Login</Button>
                                </FormikForm>
                            );
                        }}

                    </Formik>
                </div>
            </div>
        </div>
    )
}
const mapStateToDispatch = (dispatch: any) => {
    return {
        loggedIn: (info: UserInfo) => dispatch(actions.authAction.loggedIn(info))
    }
}
export default connect(null, mapStateToDispatch)(LoginForm) as any;
