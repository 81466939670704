import React, { useEffect, useState } from 'react'
import withUser from '../../hocs/with_user';
import { TopMember } from "../state";
import { getTierMember } from "../../api/member_api";
// import DateRange from '../../components/date_range';
import LoadingSpinner from '../../components/loading_spinner';
import { format, subDays, addDays } from "date-fns";
import { Avatar, Table, Row, Col, Typography, Tag, Select, Alert } from "antd";
import ExportButton from '../../components/export_button';
import { ExportExcel } from '../../services/xlsx_service';
import defaultPhoto from '../../assets/image/user.png'

const { Option } = Select
type S = { status: "loading" } | { status: "loaded", data: TopMember[] } | { status: "error", error: string };
const PointExpireList = (props: any) => {
    const merchantId = props.user?.userInfo?.merchantId || null;
    const [state, setState] = useState({ status: "loading" } as S);
    const [exportLoading, setExportLoading] = useState(null as boolean | null);
   // const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: new Date() })
    useEffect(() => {
        if (merchantId) {
            getTierMember(merchantId).then(res => {
                if (res.success) {
                    setState({ status: 'loaded', data: res.data || [] })
                }
            })
        }
    }, [merchantId])
    const columns = [
        {
            title: 'Tier',
            dataIndex: 'tier',
            align: 'center',
            render: (v: number, record: TopMember) => < Avatar shape="square" src={record.tierPhoto || defaultPhoto} size={50} onClick={() => { }} />
        },
        {
            title: 'Tier Name',
            dataIndex: 'tierName',
            key: 'tierName',
            ellipsis: true,
            sorter: (a: TopMember, b: TopMember) =>
                (a.tierName || "").localeCompare(b.tierName || ""),
            render: (v: number, record: TopMember) => {
                const val = record.tierName || ""
                return val;
            },
        },
        {
            title: 'Member',
            dataIndex: 'member',
            key: 'member',
            ellipsis: true,
            sorter: (a: TopMember, b: TopMember) =>
                (a.displayName || "").localeCompare(b.displayName || ""),
            render: (v: number, record: TopMember) => {
                const val = record.displayName || ""
                return val;
            },
        },
        {
            title: 'Photo',
            dataIndex: 'photo',
            align: 'center',
            render: (v: number, record: TopMember) => < Avatar src={record.memberPhoto || defaultPhoto} size={45} onClick={() => { }} />
        },

        {
            title: 'Phone',
            dataIndex: 'phone',
            align: 'center',
            sorter: (a: TopMember, b: TopMember) =>
                a.memberPhone || "".localeCompare(b.memberPhone || ""),
            render: (v: number, r: TopMember) => {
                const val = r.memberPhone || ""
                return val;
            },
        },
        {
            title: 'Earn Point',
            dataIndex: 'earnPoint',
            key: 'earnPoint',
            ellipsis: true,
            sorter: (a: TopMember, b: TopMember) => a.earnPoints - b.earnPoints,
            render: (v: number, r: TopMember) => {
                const val = r.earnPoints || 0;
                return val;
            },
        },
        {
            title: 'Used Point',
            dataIndex: 'usedPoint',
            key: 'usedPoint',
            ellipsis: true,
            sorter: (a: TopMember, b: TopMember) => a.spendPoints - b.spendPoints,
            render: (v: number, r: TopMember) => {
                const val = r.spendPoints || 0;
                return val;
            },
        },

        {
            title: 'Current Point',
            dataIndex: 'currentPoint',
            key: 'currentPoint',
            sorter: (a: TopMember, b: TopMember) => a.currentPoints - b.currentPoints,
            render: (v: number, r: TopMember) => {
                const val = r.currentPoints || 0;
                return val;
            },
        },
        {
            title: 'Earn Engagement',
            dataIndex: 'earnEngagement',
            key: 'earnEngagement',
            ellipsis: true,
            sorter: (a: TopMember, b: TopMember) => a.earnEngages - b.earnEngages,
            render: (v: number, r: TopMember) => {
                const val = r.earnEngages || 0;
                return val;
            },
        },
        {
            title: 'Spend Engagement',
            dataIndex: 'spendEngagement',
            key: 'spendEngagement',
            ellipsis: true,
            sorter: (a: TopMember, b: TopMember) => a.spendEngages - b.spendEngages,
            render: (v: number, r: TopMember) => {
                const val = r.spendEngages || 0;
                return val;
            },
        },

    ];
    const onExport = () => {
        if (state.status !== 'loaded') return;
        setExportLoading(true);
        const fileName = "tierMembers_" + format(new Date(), 'yyyy-MMM-dd');
        let excelData: any[] = [];
        state.data.forEach(p => {
            const memberName = p.displayName || "";
            const memberPhone = p.memberPhone || "";
            const memberEmail = p.memberEmail || "";
            const currentPoint = p.currentPoints || 0;
            const tierName = p.tierName || "";
            const earnPoint = p.earnPoints;
            const spendPoint = p.spendPoints;
            const spendEngagement = p.spendEngages;
            const earnEngagement = p.earnEngages;
            excelData.push({ memberName, memberPhone, memberEmail, tierName, currentPoint, earnPoint, spendPoint, spendEngagement, earnEngagement, })
        })
        ExportExcel(excelData, fileName);
        setExportLoading(false);
    }
    // const onSelect = async (startDate: Date, endDate: Date) => {
    //     setState({ status: 'loading' });
    //     setRanges({ startDate, endDate });
    // }
    return (
        <React.Fragment>
            {state.status === "loading" && <LoadingSpinner />}
            {state.status === "loaded" &&
                <>
                    <Row>
                        <Col flex={4}>
                            <Row >
                                <Row span={12}>
                                    {/* <Typography.Text strong> Top Member </Typography.Text> */}
                                    <Alert message="Tier members in your membership program" type="info" showIcon />
                                </Row>

                            </Row>
                        </Col>
                        <Col flex={0}>
                            <Row >
                                {/* <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onSelect} /> */}
                                <ExportButton loading={exportLoading || state.status !== 'loaded'} onClick={onExport} />
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{ marginTop: 5 }} >
                        <Table
                            columns={columns}
                            dataSource={(state.status === 'loaded' ? state.data : []).map((row, i) => { return { ...row, key: i + 1 } })}
                            pagination={{
                                position: ['bottomRight',]
                            }}
                        />
                    </Row>
                </>
            }
            {state.status === 'error' && <span>{state.error}</span>}
        </React.Fragment>

    )
}
export default withUser(PointExpireList) as any;