import React from "react";
import 'chart.js/auto';
import { Line } from "react-chartjs-2";
interface P {
    lables: string[];
    pendingData: string[];
    progressData: string[];
    completeData: string[];
    cancelData: string[];
}

export default function LineChart(props: P) {
    const data = {
        labels: props.lables,
        datasets: [
            {
                label: "cancel",
                data: props.cancelData,
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 1)'
            },
            {
                label: "progress",
                data: props.progressData,
                fill: false,
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)'
            },
            {
                label: "pending",
                data: props.pendingData,
                fill: false,
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
                borderColor: 'rgba(255, 206, 86, 1)'
            },
            {
                label: "complete",
                data: props.completeData,
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 1)'
            }
        ]
    };
    const options = {
        responsive: true,
        title: {
            display: true,
            text: 'Order Chart'
        },
        legend: {
            display: true,
            position: "right"
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },

    };
    return (
        <div style={styles.root}>
            <Line data={data} options={options} />
        </div>
    );
}
const styles = {
    root: {
        height: "50%",
        width: "100%"
    }
}