import React from 'react'
import { Button, Tooltip } from "antd";
import { PdfExportIcon } from '../assets/icons/svg_icons'

interface P {
    loading: boolean;
    transparent?: boolean;
    onClick: () => void;
}

export default function PdfExportButton(props: P) {
    return <Tooltip title="PDF Export">
        <Button onClick={props.onClick} icon={<PdfExportIcon />} style={{ border: 'none' }} />
    </Tooltip>
}
