import { report_v2 } from "./constants";
import { RewardHistory } from "../royalty/state";
import { startOfDay, endOfDay } from "date-fns";
import axios from "axios";

export const getRewardHistory = async (data: { merchantId: string, startDate: Date, endDate: Date }) => {
    const { merchantId, startDate, endDate } = data;
    const start = startOfDay(startDate).getTime();
    const end = endOfDay(endDate).getTime();
    const url = report_v2 + '/history/reward/' + merchantId;
    const options = {
        method: "get",
        headers: { ["Content-Type"]: "application/json" },
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { status, data, code } = res.data as { status: "Ok" | "error", data?: RewardHistory[], code?: string }
        if (status === 'Ok') {
            return { success: true, data: (data || []).filter(rh => rh.createdAt > start && rh.createdAt < end) }
        }
        return { success: false, msg: code || "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}