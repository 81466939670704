import { ninja_url } from "../constants/api";
import axios from 'axios';
const deliveryMerchantList = ['piticafe', 'lemonbaby', "hello7mart"];

export const availableDelivery = (merchantId: string) => {
    const find = deliveryMerchantList.findIndex(m => m === merchantId);
    if (find !== -1) {
        return true;
    }
    return false;
}
export const genterateNinjaWayBill = async (merchantId: string, trakingNo: string, env: "staging" | "production") => {
    const uri = ninja_url + `/waybill/${trakingNo}?env=${env}`
    axios(uri,
        {
            method: 'get',
            responseType: "blob",
            headers: { ["Content-Type"]: "application/json", ["Authorization"]: `${merchantId},ff5e8581-f243-4d73-b55f-0e199a722e29` },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${trakingNo}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
}
type sentRequest = {
    type: "createOrder",
    orderId: string,
    merchantId: string,
    env: "staging" | "production",
    deliverDate: string,
    deliverType: string,
    deliverTime: string,
    deliverNote: string,
    customerAddress: string,
    deliverWeight: string | number,
    customerState: string,
    customerCity: string,
    postCode: number,
} | {
    type: "cancelOrder",
    orderId: string,
    merchantId: string
    trackingNo: string,
}
export const sendNinjaDelveryOrderCreateOrCancel = async (data: sentRequest) => {
    const { type } = data;
    let url = ninja_url + `/${type}`;
    if (data.type === 'createOrder') {
        url += `?env=${data.env}`
    }
    const options = {
        method: "post",
        headers: { ["Content-Type"]: "application/json", ["Authorization"]: "ff5e8581-f243-4d73-b55f-0e199a722e29" },
        data: JSON.stringify({
            ...data
        })
    } as any
    const res = await axios(url, { ...options });
    if (res.status === 200) {
        const { success } = res.data as { success: boolean }
        if (success) {
            return { success: true, data: data || [] }
        }
        return { success: false, msg: "Bad reqeust" }
    }
    return { success: false, msg: "Something was wrong" }
}