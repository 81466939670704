import React from "react";
import 'chart.js/auto';
import { Line } from "react-chartjs-2";
interface P {
    lables: string[];
    title: string;
    label1: string;
    label2: string;
    data1: any[];
    data2: any[];
}

export default function LineChart(props: P) {
    const data = {
        labels: props.lables,
        datasets: [
            {
                label: props.label1,
                data: props.data1,
                fill: false,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 0.5)'
            },
            {
                label: props.label2,
                data: props.data2,
                fill: false,
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 1)'
            }
        ]
    };
    const options = {
        maintainAspectRatio: false,
        title: {
            display: true,
            text: props.title
        },
        legend: {
            display: true,
            position: "right"
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },

    };
    return (
        <div className="canvas-container">
            <Line data={data} options={options} />
        </div>
    );
}
