import React, { useState } from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { CalendarOutlined } from "@ant-design/icons";
import { addDays, format, startOfDay, endOfDay } from 'date-fns'
import { Modal, Button } from 'antd';

interface P {
    startDate?: Date,
    endDate?: Date,
    onSelect: (startDate: Date, endDate: Date) => any;
}

export default function DateRange(props: P) {
    const [selectDate, setSelectDate] = useState([
        {
            startDate: props.startDate || new Date(),
            endDate: props.endDate || addDays(new Date(), 7),
            key: 'selection'
        }
    ]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [label, setLabel] = useState((props.startDate && props.endDate) ? format(selectDate[0].startDate, 'dd MMM,yyyy') + '-' + format(selectDate[0].endDate, 'dd MMM,yyyy') : "select date")

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setLabel(format(selectDate[0].startDate, 'dd MMM,yyyy') + '-' + format(selectDate[0].endDate, 'dd MMM,yyyy'));
        props.onSelect(startOfDay(selectDate[0].startDate), endOfDay(selectDate[0].endDate));
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <>
            <Button icon={<CalendarOutlined />} onClick={showModal}>
                {label}
            </Button>
            <Modal bodyStyle={{ backgroundColor: 'none' }} width={700} style={{ top: 20 }} title="Date" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <DateRangePicker
                    editableDateInputs={true}
                    onChange={(item: any) => setSelectDate([item.selection])}
                    moveRangeOnFirstSelection={true}
                    ranges={selectDate}
                />
            </Modal>
        </>
    )
}
